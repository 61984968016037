export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';
export const SET_LANG = 'SET_LANG';

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'LOG_IN_FAIL';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const GET_TERRITORIES_SUCCESS = 'GET_TERRITORIES_SUCCESS';
export const GET_TERRITORIES_FAIL = 'GET_TERRITORIES_FAIL';

export const SELECT_TERRITORIES = 'SELECT_TERRITORIES';

export const GET_CITIES_TREE_SUCCESS = 'GET_CITIES_TREE_SUCCESS';
export const SET_CURRENT_CITY = 'SET_CURRENT_CITY';

export const GET_INDICATORS_GROUPS_SUCCESS = 'GET_INDICATORS_GROUPS_SUCCESS';
export const GET_INDICATORS_GROUPS_FAIL = 'GET_INDICATORS_GROUPS_FAIL';
export const SELECT_INDICATORS_GROUP = 'SELECT_INDICATORS_GROUP';

export const GET_INDICATORS_GROUP_SUCCESS = 'GET_INDICATORS_GROUP_SUCCESS';
export const GET_INDICATORS_GROUP_FAIL = 'GET_INDICATORS_GROUP_FAIL';

export const SELECT_INDICATOR = 'SELECT_INDICATOR';
export const SELECT_CHART_INDICATOR = 'SELECT_CHART_INDICATOR';

export const SELECT_INDICATOR_TAB = 'SELECT_INDICATOR_TAB';
export const DESELECT_INDICATOR_TAB = 'DESELECT_INDICATOR_TAB';
export const TOGGLE_INDICATOR_TAB = 'TOGGLE_INDICATOR_TAB';

export const SELECT_DATE_START = 'SELECT_DATE_START';
export const SELECT_DATE_END = 'SELECT_DATE_END';
export const SELECT_MONTH_START = 'SELECT_MONTH_START';
export const SELECT_MONTH_END = 'SELECT_MONTH_END';
export const SELECT_YEAR_START = 'SELECT_YEAR_START';
export const SELECT_YEAR_END = 'SELECT_YEAR_END';
export const TOGGLE_WEEK_DAY = 'TOGGLE_WEEK_DAY';
export const CHANGE_WEEKDAY_FILTER = 'CHANGE_WEEKDAY_FILTER';
export const TOGGLE_DAYTIME = 'TOGGLE_DAYTIME';
export const RESET_CURRENT_PERIOD_FILTER = 'RESET_CURRENT_PERIOD_FILTER';

export const GET_INDICATORS_SUCCESS = 'GET_INDICATORS_SUCCESS';
export const GET_INDICATORS_FAIL = 'GET_INDICATORS_FAIL';

export const GET_FACTS_SUCCESS = 'GET_FACTS_SUCCESS';
export const GET_FACTS_FAIL = 'GET_FACTS_FAIL';
export const SAVE_GET_FACTS_QUERY_STRING = 'SAVE_GET_FACTS_QUERY_STRING';
export const SAVE_GET_CHART_FACTS_QUERY_STRING = 'SAVE_GET_CHART_FACTS_QUERY_STRING';
export const SAVE_GET_CHART_PIE_FACTS_QUERY_STRING = 'SAVE_GET_CHART_PIE_FACTS_QUERY_STRING';
export const SAVE_GET_RATING_FACTS_QUERY_STRING = 'SAVE_GET_RATING_FACTS_QUERY_STRING';
export const GET_FACTS_DUAL_SUCCESS = 'GET_FACTS_DUAL_SUCCESS';
export const GET_FACTS_DUAL_FAIL = 'GET_FACTS_DUAL_FAIL';

export const GET_CHART_FACTS_SUCCESS = 'GET_CHART_FACTS_SUCCESS';
export const GET_CHART_FACTS_FAIL = 'GET_CHART_FACTS_FAIL';

export const GET_CHART_PIE_FACTS_SUCCESS = 'GET_CHART_PIE_FACTS_SUCCESS';
export const GET_CHART_PIE_FACTS_FAIL = 'GET_CHART_PIE_FACTS_FAIL';

export const GET_CHART_RATINGS_SUCCESS = 'GET_CHART_RATINGS_SUCCESS';
export const GET_CHART_RATINGS_FAIL = 'GET_CHART_RATINGS_FAIL';

export const GET_POLYGONS = 'GET_POLYGONS';
export const GET_POLYGONS_SUCCESS = 'GET_POLYGONS_SUCCESS';
export const GET_POLYGONS_FAIL = 'GET_POLYGONS_FAIL';
export const SET_CURRENT_POLYGONS_IDS = 'SET_CURRENT_POLYGONS_IDS';

export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAIL = 'GET_MENU_FAIL';

export const SET_CURRENT_MAP_LEVEL = 'SET_CURRENT_MAP_LEVEL';

export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';
export const SET_MAP_ZOOM_LEVEL = 'SET_MAP_ZOOM_LEVEL';
export const SET_POLYGONS_OPACITY = 'SET_POLYGONS_OPACITY';

export const SET_CHART_TAB_INNER_MODE = 'SET_CHART_TAB_INNER_MODE';

export const SET_TERRITORY_RANGE_FILTER = 'SET_TERRITORY_RANGE_FILTER';
export const SET_OBJECTS_RANGE_FILTER = 'SET_OBJECTS_RANGE_FILTER';

export const TOGGLE_CHARTS_FULL_SCREEN = 'TOGGLE_CHARTS_FULL_SCREEN';

export const SET_MEASURES_VALUES = 'SET_MEASURES_VALUES';
export const CHANGE_MEASURE_VALUE = 'CHANGE_MEASURE_VALUE';

export const GET_STATIC_PAGE_SUCCESS = 'GET_STATIC_PAGE_SUCCESS';

export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const PARSE_URL_PARAMS = 'PARSE_URL_PARAMS';

export const SET_FILTER_REGIONS = 'SET_FILTER_REGIONS';
export const UPDATE_FILTER_REGIONS = 'UPDATE_FILTER_REGIONS';
export const SET_FILTER_REGIONS_ITEM = 'SET_FILTER_REGIONS_ITEM';

export const SELECT_POLYGON = 'SELECT_POLYGON';
export const SELECT_POLYGONS = 'SELECT_POLYGONS';
export const RESET_SELECTED_POLYGONS = 'RESET_SELECTED_POLYGONS';
export const FILTER_SELECTED_POLYGONS = 'FILTER_SELECTED_POLYGONS';
export const SELECT_PARENT_POLYGON_BY_DOUBLE_CLICK = 'SELECT_PARENT_POLYGON_BY_DOUBLE_CLICK';

export const SET_TERRITORIES_CLEAN_STATE = 'SET_TERRITORIES_CLEAN_STATE';

export const TOGGLE_SELECTION_MODE = 'TOGGLE_SELECTION_MODE';
export const EXIT_SELECTION_MODE = 'EXIT_SELECTION_MODE';
export const SET_SELECTION = 'SET_SELECTION';
export const SET_SELECTION_COORDS_START = 'SET_SELECTION_COORDS_START';
export const SET_SELECTION_COORDS_END = 'SET_SELECTION_COORDS_END';

export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';

export const TOGGLE_FILTERS_PANEL = 'TOGGLE_FILTERS_PANEL';

export const GET_DEFAULT_INDICATOR_GROUP_SUCCESS = 'GET_DEFAULT_INDICATOR_GROUP_SUCCESS';

export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export const GET_OBJECTS_FOR_INDICATOR = 'GET_OBJECTS_FOR_INDICATOR';
export const GET_OBJECTS_FOR_INDICATOR_SUCCESS = 'GET_OBJECTS_FOR_INDICATOR_SUCCESS';
export const GET_OBJECTS_FOR_INDICATOR_FAIL = 'GET_OBJECTS_FOR_INDICATOR_FAIL';

export const TOGGLE_INDICATOR_OBJECT = 'TOGGLE_INDICATOR_OBJECT';

export const SET_TERRITORIES_FILTERS_VALUES = 'SET_TERRITORIES_FILTERS_VALUES';

export const SET_SELECTED_TERRITORIES_FILTERS = 'SET_SELECTED_TERRITORIES_FILTERS';

export const SET_CHART_GROUP_MODE = 'SET_CHART_GROUP_MODE';

export const SET_CURRENT_OBJECTS_MEASURES = 'SET_CURRENT_OBJECTS_MEASURES';

export const GET_FILTER_REGIONS_IS_FULL = 'GET_FILTER_REGIONS_IS_FULL';

export const SET_NOTIFICATION_REMOVABLE = 'SET_NOTIFICATION_REMOVABLE';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';

export const GET_TIME_RANGE_BY_INDICATOR = 'GET_TIME_RANGE_BY_INDICATOR';
export const CLEAR_TIME_RANGE_BY_INDICATOR = 'CLEAR_TIME_RANGE_BY_INDICATOR';

export const GET_REPORTS_PAGE = 'GET_REPORTS_PAGE';

export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_ERROR = 'GET_FAVORITES_ERROR';

export const DROP_PARAMETERS = 'DROP_PARAMETERS';

export const SET_RESET_FILTERS_STATE = 'SET_RESET_FILTERS_STATE';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import useToggleAndOutClick from 'use-toggle-and-outclick';

// Icons
import { ReactComponent as IconCaret } from 'assets/icons/icon-caret.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';

// Actions
import { getCitiesTree, setCurrentCity } from 'modules/main/actions';

// Misc
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const CitySelector = ({
  className,
  citiesFromStore,
  currentCity,
  dispatch,
  closeMobileMenu,
}) => {
  /** Переводы */
  const langOb = useLang('CitySelector');

  const [isDropOpened, dropEl, handleClick, closeExplicitly] = useToggleAndOutClick();

  const [isLoading, setIsLoading] = useState(false);
  const [currentCityInState, setCurrentCityInState] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    /** Если уже есть города - пробрасываем города в стейт */
    if (citiesFromStore && Array.isArray(citiesFromStore) && citiesFromStore.length) {
      passCitiesFromStoreToState(citiesFromStore);
      return;
    }

    setIsLoading(true);
    dispatch(getCitiesTree()).then(res => {
      setIsLoading(false);
      if (res && res.results) {
        passCitiesFromStoreToState(res.results);
      }
    });
  }, []);

  const passCitiesFromStoreToState = items => {
    let nextCurrentCity = null;

    setCountries(
      items.map((item, index) => {
        if (index === 0) {
          let cities;
          if (item.cities && item.cities.length) {
            cities = item.cities.map((city, cityIndex) => {
              if (
                ((!currentCity || currentCity.id === undefined) && cityIndex === 0) ||
                (currentCity && city.id === currentCity.id)
              ) {
                nextCurrentCity = { ...city };
                return {
                  ...city,
                  selected: true,
                };
              }
              return city;
            });
          } else {
            cities = [];
          }

          return {
            ...item,
            opened: true,
            cities,
          };
        }
        return item;
      }),
    );

    if (nextCurrentCity) {
      if (!currentCity || nextCurrentCity.id !== currentCity) {
        dispatch(setCurrentCity(nextCurrentCity));
      }
      setCurrentCityInState(nextCurrentCity);
    }
  };

  const selectCity = city => {
    if (currentCityInState === city) return;

    let nextCurrentCity = null;

    closeExplicitly();

    if (typeof closeMobileMenu === 'function') {
      closeMobileMenu();
    }

    dispatch(
      setCurrentCity(city, {
        resetAllData: true,
      }),
    );

    const nextCountries = countries.map(country => {
      if (country.cities && country.cities.find(localCity => localCity.id === city.id)) {
        return {
          ...country,
          cities: country.cities.map(localCity => {
            if (localCity.id === city.id) {
              nextCurrentCity = {
                ...localCity,
              };

              return {
                ...localCity,
                selected: true,
              };
            }
            return {
              ...localCity,
              selected: false,
            };
          }),
        };
      }
      return {
        ...country,
        cities: country.cities.map(localCity => ({
          ...localCity,
          selected: false,
        })),
      };
    });

    setCountries(nextCountries);
    setCurrentCityInState(nextCurrentCity);
  };

  const handleCountryClick = countryId => {
    setCountries(
      countries.map(country => {
        if (country.id === countryId) {
          return {
            ...country,
            opened: !country.opened,
          };
        }
        return {
          ...country,
        };
      }),
    );
  };

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('city-selector', {
        'city-selector_active': isDropOpened,
        [className]: className,
      })}
    >
      {!isLoading && countries.length > 0 && (
        <React.Fragment>
          <button
            type="button"
            className="city-selector__current"
            onClick={handleClick}
            title={currentCityInState ? currentCityInState.name : countries[0].cities[0].name}
          >
            <span className="city-selector__current-value">
              {currentCityInState ? currentCityInState.name : countries[0].cities[0].name}
            </span>
            <IconCaret className="city-selector__caret-icon" />
          </button>

          <CSSTransition
            in={isDropOpened}
            timeout={200}
            classNames="city-selector__drop"
            unmountOnExit
          >
            <div className="city-selector__drop" data-testid="city-selector__drop" ref={dropEl}>
              {countries.map(country => (
                <div className="city-selector__country" key={country.id}>
                  <button
                    type="button"
                    className={cx('city-selector__country-button', {
                      'city-selector__country-button_opened': country.opened,
                    })}
                    title={country.opened ? langOb.collapse : langOb.expand}
                    onClick={() => {
                      handleCountryClick(country.id);
                    }}
                  >
                    {country.name}
                    <span
                      className={cx('city-selector__country-icon', {
                        'city-selector__country-icon_opened': country.opened,
                      })}
                    />
                  </button>
                  <div
                    className={cx('city-selector__cities', {
                      'city-selector__cities_opened': country.opened,
                    })}
                    hidden={!country.opened}
                  >
                    {country.cities.map(city => (
                      <button
                        key={city.id}
                        type="button"
                        className={cx('city-selector__city', {
                          'city-selector__city_selected': city.selected,
                        })}
                        onClick={() => {
                          selectCity(city);
                        }}
                      >
                        {city.selected && <IconCheck className="city-selector__drop-item-icon" />}
                        {city.name}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </CSSTransition>
        </React.Fragment>
      )}

      {isLoading && (
        <div className="city-selector__loading">
          <IconLoader className="city-selector__loading-icon" />
        </div>
      )}
    </div>
  );
};

CitySelector.propTypes = {
  className: PropTypes.string,
  currentCity: PropTypes.shape({
    id: PropTypes.number,
  }),
  dispatch: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func,
  citiesFromStore: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CitySelector.defaultProps = {
  className: null,
  currentCity: null,
  closeMobileMenu: null,
};

const mapStateToProps = state => ({
  currentCity: state.main.currentCity,
  citiesFromStore: state.main.cities,
});

export default connect(mapStateToProps)(CitySelector);

import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Misc
import {
  CHART_GROUP_DAY,
  CHART_GROUP_MONTH,
  CHART_GROUP_YEAR,
  CHART_GROUP_WEEK,
  CHART_GROUP_HOUR,
  CHART_GROUP_HALFHOUR,
} from 'modules/main/constants';
import { generateStringDate, parseDateISOString } from 'helpers';
import useLang from 'hooks/useLang';

// Icons
import { ReactComponent as IconArrow } from 'assets/icons/icon-arrow.svg';

// Styles
import './assets/styles/styles.scss';

export const ChartsTimeNav = ({
  className,
  groupBy,
  generateChart,
  chartFacts,
  bigMode,
  loading,
  currentChartIndicator,
  currentPeriodFilter,
  chartTimeRanges,
  continuousRange,
  continuousRangeValue,
}) => {
  /** Переводы */
  const langOb = useLang('ChartsTimeNav');

  /* eslint-disable camelcase */
  const { data_stat: { start_date, end_date } = {} } = currentChartIndicator;
  /* eslint-disable camelcase */

  let scrollCount = 6;
  let scrollPeriod = '';
  let scrollPeriodShort = '';

  switch (groupBy) {
    case CHART_GROUP_HALFHOUR: {
      scrollCount = 6;
      scrollPeriod = (langOb && langOb.halfhour) || 'пол часа';
      scrollPeriodShort = (langOb && langOb.halfhours) || 'пол часа';
      break;
    }
    case CHART_GROUP_HOUR: {
      scrollCount = 6;
      scrollPeriod = (langOb && langOb.hour) || 'час';
      scrollPeriodShort = (langOb && langOb.hours) || 'часов';
      break;
    }
    case CHART_GROUP_DAY: {
      scrollCount = 7;
      scrollPeriod = (langOb && langOb.day) || 'день';
      scrollPeriodShort = (langOb && langOb.days) || 'дней';
      break;
    }
    case CHART_GROUP_WEEK: {
      scrollCount = 6;
      scrollPeriod = (langOb && langOb.week) || 'неделя';
      scrollPeriodShort = (langOb && langOb.weeks) || 'нед.';
      break;
    }
    case CHART_GROUP_MONTH: {
      scrollCount = 6;
      scrollPeriod = (langOb && langOb.month) || 'месяц';
      scrollPeriodShort = (langOb && langOb.months) || 'мес.';
      break;
    }
    case CHART_GROUP_YEAR: {
      scrollCount = 6;
      scrollPeriod = (langOb && langOb.month) || 'год';
      scrollPeriodShort = (langOb && langOb.months) || 'год.';
      break;
    }
    default: {
      scrollCount = 6;
      scrollPeriod = (langOb && langOb.month) || 'месяц';
      scrollPeriodShort = (langOb && langOb.months) || 'мес.';
    }
  }

  const getNewValues = (direction, count) => {
    /**  Если непрерывный диапозон, то добавляем значение к slice массива */
    if (continuousRange) {
      generateChart({
        slice: [continuousRange[0] + count * direction, continuousRange[1] + count * direction],
      });
    } else {
      const firstDate = new Date(Date.parse(parseDateISOString(chartFacts.data[0].time_group)));
      if (groupBy === CHART_GROUP_DAY) {
        firstDate.setDate(firstDate.getDate() + count * direction);
      } else if (groupBy === CHART_GROUP_WEEK) {
        firstDate.setDate(firstDate.getDate() + count * 7 * direction);
      } else if (groupBy === CHART_GROUP_HOUR) {
        firstDate.setHours(firstDate.getHours() + count * direction);
      } else if (groupBy === CHART_GROUP_HALFHOUR) {
        firstDate.setMinutes(firstDate.getMinutes() + count * 30 * direction);
      } else {
        firstDate.setDate(1);
        firstDate.setMonth(firstDate.getMonth() + count * direction);
      }

      generateChart({
        fromDate: generateStringDate(firstDate),
      });
    }
  };

  const checkContinuousRange = (direction, count) => {
    if (continuousRange) {
      return !(
        chartTimeRanges
          .filter(range => {
            if (currentPeriodFilter.dateStart && currentPeriodFilter.dateEnd) {
              return (
                new Date(parseDateISOString(range.value)) >=
                  parseDateISOString(currentPeriodFilter.dateStart) &&
                new Date(parseDateISOString(range.value)) <=
                  parseDateISOString(currentPeriodFilter.dateEnd)
              );
            }
            if (
              currentPeriodFilter.yearStart !== null &&
              currentPeriodFilter.monthStart !== null &&
              currentPeriodFilter.yearEnd !== null &&
              currentPeriodFilter.monthEnd !== null
            ) {
              return (
                new Date(parseDateISOString(range.value)) >=
                  new Date(
                    parseDateISOString(
                      `${currentPeriodFilter.yearStart}-${currentPeriodFilter.monthStart + 1}`,
                    ),
                  ) &&
                new Date(parseDateISOString(range.value)) <=
                  new Date(
                    parseDateISOString(
                      `${currentPeriodFilter.yearEnd}-${currentPeriodFilter.monthEnd + 1}`,
                    ),
                  )
              );
            }
            return null;
          })
          .slice(continuousRange[0] + direction * count, continuousRange[1] + direction * count)
          .length < continuousRangeValue
      );
    }

    return false;
  };

  const checkDatesRange = (direction, count) => {
    /** Считаем стартовую и конечную дату для получения чарт данных */
    const firstDate = new Date(Date.parse(parseDateISOString(chartFacts.data[0].time_group)));
    const endDate = new Date(
      Date.parse(parseDateISOString(chartFacts.data[chartFacts.data.length - 1].time_group)),
    );

    if (groupBy === CHART_GROUP_DAY) {
      firstDate.setDate(firstDate.getDate() + count * direction);
      endDate.setDate(endDate.getDate() + count * direction);
    } else if (groupBy === CHART_GROUP_WEEK) {
      firstDate.setDate(firstDate.getDate() + count * 7 * direction);
      endDate.setDate(endDate.getDate() + count * 7 * direction);
    } else if (groupBy === CHART_GROUP_HOUR) {
      firstDate.setHours(firstDate.getHours() + count * direction);
      endDate.setHours(endDate.getHours() + count * direction);
    } else if (groupBy === CHART_GROUP_HALFHOUR) {
      firstDate.setMinutes(firstDate.getMinutes() + count * 30 * direction);
      endDate.setMinutes(endDate.getMinutes() + count * 30 * direction);
    } else {
      firstDate.setDate(1);
      firstDate.setMonth(firstDate.getMonth() + 1 + count * direction);

      endDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + count * direction);
    }

    /** Выбран ли период */
    const periodSelected =
      (currentPeriodFilter.yearStart !== null && currentPeriodFilter.yearEnd !== null) ||
      (currentPeriodFilter.monthStart !== null && currentPeriodFilter.monthEnd !== null) ||
      (currentPeriodFilter.dateStart !== null && currentPeriodFilter.dateEnd !== null);

    if (periodSelected) {
      /** Проверяем, уходят ли данные за границы выбранного периода */
      let currentPeriodFilterStartDate = new Date();
      if (currentPeriodFilter.yearStart !== null) {
        currentPeriodFilterStartDate.setFullYear(currentPeriodFilter.yearStart);
      }
      if (currentPeriodFilter.monthStart !== null) {
        currentPeriodFilterStartDate.setMonth(currentPeriodFilter.monthStart);
      }
      if (currentPeriodFilter.dateStart !== null) {
        currentPeriodFilterStartDate = new Date(parseDateISOString(currentPeriodFilter.dateStart));
      }

      let currentPeriodFilterEndDate = new Date();
      if (currentPeriodFilter.yearEnd !== null) {
        currentPeriodFilterEndDate.setFullYear(currentPeriodFilter.yearEnd);
      }
      if (currentPeriodFilter.monthEnd !== null) {
        currentPeriodFilterEndDate.setMonth(currentPeriodFilter.monthEnd);
      }
      if (currentPeriodFilter.dateEnd !== null) {
        currentPeriodFilterEndDate = new Date(parseDateISOString(currentPeriodFilter.dateEnd));
      }

      if (firstDate < currentPeriodFilterStartDate || endDate > currentPeriodFilterEndDate) {
        return false;
      }
    }

    /** Генерируем объекты дат границ данных */
    const startBoundDate = new Date(parseDateISOString(Date.parse(start_date)));
    const endBoundDate = new Date(parseDateISOString(Date.parse(end_date)));

    if (groupBy === CHART_GROUP_MONTH) {
      startBoundDate.setDate(1);
      endBoundDate.setDate(1);
    }

    if (direction < 0) {
      if (count === 1) {
        return firstDate >= startBoundDate;
      }
      return endDate >= startBoundDate;
    }

    if (count === 1) {
      return endDate <= endBoundDate;
    }
    return firstDate <= endBoundDate;
  };

  if (!chartFacts || !chartFacts.data || !chartFacts.data.length) {
    return null;
  }

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('charts-time-nav', {
        'charts-time-nav_big': bigMode,
        [className]: className,
      })}
    >
      <div className="charts-time-nav__group">
        <button
          type="button"
          className="charts-time-nav__button"
          onClick={() => {
            getNewValues(-1, 1);
          }}
          disabled={
            loading || (continuousRange ? !checkContinuousRange(-1, 1) : !checkDatesRange(-1, 1))
          }
        >
          <IconArrow className="charts-time-nav__icon-prev" />
          {`${langOb.prev} ${scrollPeriod}`}
        </button>
        <button
          type="button"
          className="charts-time-nav__button charts-time-nav__button_long"
          onClick={() => {
            getNewValues(-1, scrollCount);
          }}
          disabled={
            loading ||
            (continuousRange
              ? !checkContinuousRange(-1, scrollCount)
              : !checkDatesRange(-1, scrollCount))
          }
        >
          <IconArrow className="charts-time-nav__icon-prev" />
          <IconArrow className="charts-time-nav__icon-prev" />
          {`${langOb.prev} ${scrollCount} ${scrollPeriodShort}`}
        </button>
      </div>

      <div className="charts-time-nav__group">
        <button
          type="button"
          className="charts-time-nav__button"
          onClick={() => {
            getNewValues(1, 1);
          }}
          disabled={
            loading || (continuousRange ? !checkContinuousRange(1, 1) : !checkDatesRange(1, 1))
          }
        >
          {`${langOb.next} ${scrollPeriod}`}
          <IconArrow className="charts-time-nav__icon-next" />
        </button>
        <button
          type="button"
          className="charts-time-nav__button charts-time-nav__button_long"
          onClick={() => {
            getNewValues(1, scrollCount);
          }}
          disabled={
            loading ||
            (continuousRange
              ? !checkContinuousRange(1, scrollCount)
              : !checkDatesRange(1, scrollCount))
          }
        >
          {`${langOb.next} ${scrollCount} ${scrollPeriodShort}`}
          <IconArrow className="charts-time-nav__icon-next" />
          <IconArrow className="charts-time-nav__icon-next" />
        </button>
      </div>
    </div>
  );
};

ChartsTimeNav.propTypes = {
  generateChart: PropTypes.func.isRequired,
  className: PropTypes.string,
  groupBy: PropTypes.oneOf([
    CHART_GROUP_DAY,
    CHART_GROUP_WEEK,
    CHART_GROUP_MONTH,
    CHART_GROUP_HOUR,
    CHART_GROUP_HALFHOUR,
  ]).isRequired,
  chartFacts: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        time_group: PropTypes.string,
        agg_value: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  }).isRequired,
  bigMode: PropTypes.bool,
  loading: PropTypes.bool,
  currentChartIndicator: PropTypes.shape({
    data_stat: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string,
    }),
  }).isRequired,
  currentPeriodFilter: PropTypes.shape({
    dateEnd: PropTypes.number,
    dateStart: PropTypes.number,
    monthEnd: PropTypes.number,
    monthStart: PropTypes.number,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
  }),
  chartTimeRanges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  continuousRange: PropTypes.arrayOf(PropTypes.number),
  continuousRangeValue: PropTypes.number,
};

ChartsTimeNav.defaultProps = {
  className: null,
  bigMode: false,
  loading: false,
  currentPeriodFilter: {},
  continuousRange: null,
  continuousRangeValue: 0,
};

const mapStateToProps = state => ({
  chartFacts: state.main.chartFacts,
  currentChartIndicator: state.main.currentChartIndicator,
  currentPeriodFilter: state.main.currentPeriodFilter.charts,
  chartTimeRanges: state.main.chartTimeRanges,
});

export default connect(mapStateToProps)(ChartsTimeNav);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// icons
import { ReactComponent as SearchIcon } from 'assets/icons/icon-search.svg';
import { ReactComponent as IconClearSearch } from 'assets/icons/icon-clear-search.svg';

// Styles
import './assets/styles/styles.scss';

const Search = ({ placeholder, onSearch, onClear }) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className="search">
      <SearchIcon />
      <input
        type="text"
        className="search__input"
        value={searchValue}
        placeholder={placeholder}
        onChange={({ target }) => {
          setSearchValue(target.value);
          onSearch(target.value);
        }}
      />
      <button
        type="button"
        className={cx('search__clear-button', {
          'search__clear-button_hide': searchValue === '',
        })}
        onClick={() => {
          setSearchValue('');
          onSearch('');
          onClear();
        }}
      >
        <IconClearSearch className="search__clear-icon" />
      </button>
    </div>
  );
};

Search.defaultProps = {
  placeholder: '',
  onClear: () => {},
};

Search.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default Search;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './assets/styles/styles.scss';

// Misc
import {
  CHART_GROUP_DAY,
  CHART_GROUP_MONTH,
  CHART_GROUP_YEAR,
  CHART_GROUP_WEEK,
  CHART_GROUP_HOUR,
  CHART_GROUP_HALFHOUR,
} from 'modules/main/constants';
import useLang from 'hooks/useLang';

const ChartsIntervalSelect = ({
  handleChange,
  className,
  withTitle,
  chartGroupMode,
  timeButtons,
}) => {
  /** Переводы */
  const langOb = useLang('ChartsIntervalSelect');

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('interval-select', {
        [className]: className,
      })}
    >
      {withTitle && <p className="interval-select__name">{langOb.title}</p>}

      <div className="interval-select__radio-button-group">
        {timeButtons.map(timeButton => (
          <button
            key={timeButton.value}
            type="button"
            role="radio"
            aria-checked={chartGroupMode === timeButton.value}
            className="interval-select__radio-button"
            onClick={() => handleChange(timeButton.value)}
          >
            {timeButton.name}
          </button>
        ))}
      </div>
    </div>
  );
};

ChartsIntervalSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  withTitle: PropTypes.bool,
  chartGroupMode: PropTypes.oneOf([
    CHART_GROUP_DAY,
    CHART_GROUP_WEEK,
    CHART_GROUP_MONTH,
    CHART_GROUP_YEAR,
    CHART_GROUP_HOUR,
    CHART_GROUP_HALFHOUR,
  ]).isRequired,
  timeButtons: PropTypes.arrayOf(
    PropTypes.shape({
      is_default: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

ChartsIntervalSelect.defaultProps = {
  className: null,
  withTitle: false,
};

export default ChartsIntervalSelect;

import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconPassword } from 'assets/icons/icon-password.svg';

const PasswordInput = React.forwardRef((props, ref) => {
  const { name, togglePasswordVisibility, value, onPasswordChange, submitForm } = props;

  return (
    <div className="auth__password">
      <p className="auth__password-description">{name}</p>
      <div className="auth__password-input-wrapper">
        <input
          name="password"
          className="auth__password-input"
          placeholder="•••••••••••"
          type="password"
          value={value}
          onChange={onPasswordChange}
          ref={ref}
          onKeyUp={e => {
            if (submitForm && e.key === 'Enter') {
              submitForm();
            }
          }}
        />
        {togglePasswordVisibility && (
          <button
            type="button"
            className="auth__password-input-toggle-button"
            onClick={togglePasswordVisibility}
            aria-label="Toggle password visibility"
          >
            <IconPassword className="auth__password-input-toggle-icon" />
          </button>
        )}
      </div>
    </div>
  );
});

PasswordInput.propTypes = {
  name: PropTypes.string,
  togglePasswordVisibility: PropTypes.func,
  value: PropTypes.string.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func,
};

PasswordInput.defaultProps = {
  name: null,
  togglePasswordVisibility: null,
  submitForm: null,
};

export default PasswordInput;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import useWindowSize from '@rehooks/window-size';

// Icons
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';

// Styles
import { mobile } from 'assets/breakpoints';
import './assets/styles/styles.scss';

const Popup = ({ children, closeHandler, popupBodyClassName }) => {
  const { innerWidth } = useWindowSize();

  return (
    <AriaModal
      titleText="demo one"
      onExit={closeHandler}
      underlayStyle={{
        paddingTop: innerWidth > mobile ? '40px' : 0,
        display: innerWidth > mobile ? 'block' : 'flex',
        flexDirection: 'column',
      }}
      focusDialog
      dialogStyle={{
        display: innerWidth > mobile ? 'inline-block' : 'flex',
        flexDirection: 'column',
        flexGrow: 20,
      }}
    >
      <div className="popup">
        <button
          type="button"
          onClick={closeHandler}
          className="popup__close-button"
          aria-label="Close the popup"
        >
          <IconClose className="popup__close-button-icon" />
        </button>

        <div
          className={cx('popup__body', {
            [popupBodyClassName]: popupBodyClassName,
          })}
        >
          {children}
        </div>
      </div>
    </AriaModal>
  );
};

Popup.propTypes = {
  children: PropTypes.element.isRequired,
  closeHandler: PropTypes.func.isRequired,
  popupBodyClassName: PropTypes.string,
};

Popup.defaultProps = {
  popupBodyClassName: null,
};

export default Popup;

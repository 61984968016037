import React, { useState, useEffect, useRef, useCallback } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import MapboxMap from 'react-mapbox-wrapper';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import hsl2hex from 'hsl-to-hex';

// Components
import SelectedPolygons from 'modules/main/components/SelectedPolygons';
import CurrentAreaSummary from 'modules/main/components/CurrentAreaSummary';
import MapTerritoryRangeFilter from 'modules/main/components/MapTerritoryRangeFilter';
import InfoBox from 'modules/main/components/InfoBox';
import MapSelection from 'modules/main/components/MapSelection';

// Icons
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';

// Misc
import { throttleMapBoxEvent, getDepthDecimal } from 'helpers';
import {
  INDICATOR_TERRITORY_TAB,
  FACT_UNIT_NAMES,
  DIMENSIONS_NAMES,
  DIMENSIONS_NAMES_CONSTRUCTOR,
  LANG_EN,
  INDICATOR_OBJECTS_TAB,
  INDICATORS_GROUP_PALETTE_DEFAULT,
  INDICATORS_GROUP_PALETTE_TEMPERATURE,
  INDICATORS_GROUP_PALETTE_TEMPERATURE_REVERSE,
  INDICATORS_GROUP_PALETTE_RED,
  INDICATORS_GROUP_PALETTE_YELLOW_RED,
  PALETTE_DEFAULT_HSL_COLORS,
  PALETTE_DEFAULT_HSL_COLORS_SECOND,
  PALETTE_TEMPERATURE_HEX_COLORS_GRADIENT,
  PALETTE_TEMPERATURE_REVERSE_HEX_COLORS_GRADIENT,
  PALETTE_RED_HEX_COLORS,
  PALETTE_YELLOW_RED_HEX_COLORS,
} from 'modules/main/constants';
import useLang from 'hooks/useLang';
import { factsType } from './types';

// Misc

// Styles
import './assets/styles/mapBoxStyles.scss';

const LayerVariants = {
  POLYGONS: 'POLYGONS',
  POINTS: 'POINTS',
  LINES: 'LINES',
};

const LAYERS = {
  [LayerVariants.POLYGONS]: 'polygons-layer',
  [LayerVariants.POINTS]: 'points-layer',
  [LayerVariants.LINES]: 'lines-layer',
};

const OBJECTS_LAYER_NAME = 'objects-layer';

const OBJECTS = {
  [LayerVariants.POINTS]: `${OBJECTS_LAYER_NAME}-points`,
  [LayerVariants.LINES]: `${OBJECTS_LAYER_NAME}-lines`,
};

const Map = props => {
  const {
    polygons,
    currentIndicator,
    polygonsOpacity,
    territoryRangeFilter,
    objectsRangeFilter,
    selectedPolygons,
    mapLevels,
    currentMapLevelId,
    currentCity,
    isInSelectionMode,
    facts,
    indicatorTabsEnabled,
    isLoadingPolygons,
    isLoadingObjects,
    currentIndicatorTab,
    chartTabInnerMode,
    currentChartIndicator,
    polygonsColor,
    currentObjects,
    polygonsHoverColor,
    measuresValuesObjects,
    namespace,
    selectParentPolygonByDoubleClick,
    setCurrentMapLevel,
    selectPolygon,
    selectPolygons,
    lang,
    mapName,
    currentObjectsMeasures,
    user,
  } = props;

  const initialState = {
    isSelectionStarted: false,
    arePolygonsHovered: false,
  };

  const langObInfoBox = useLang('InfoBox');

  /** Стейт */
  const [state, setState] = useState(initialState);
  const [map, setMap] = useState(null);
  const [mapDataUrl, setMapDataUrl] = useState('');
  const [selectionInState, setSelectionInState] = useState({});
  const [hoverValue, setHoverValue] = useState(null);
  const [palletTemperature, setPalletTemperature] = useState([]);

  const popup = useRef();
  const mapRef = useRef();

  const isPalletTemperature =
    polygonsColor === INDICATORS_GROUP_PALETTE_TEMPERATURE ||
    polygonsColor === INDICATORS_GROUP_PALETTE_TEMPERATURE_REVERSE ||
    polygonsColor === INDICATORS_GROUP_PALETTE_RED ||
    polygonsColor === INDICATORS_GROUP_PALETTE_YELLOW_RED;

  /** Назначем градиент для палитры температуры */
  useEffect(() => {
    switch (polygonsColor) {
      case INDICATORS_GROUP_PALETTE_TEMPERATURE:
        setPalletTemperature(PALETTE_TEMPERATURE_HEX_COLORS_GRADIENT);
        break;

      case INDICATORS_GROUP_PALETTE_TEMPERATURE_REVERSE:
        setPalletTemperature(PALETTE_TEMPERATURE_REVERSE_HEX_COLORS_GRADIENT);
        break;

      case INDICATORS_GROUP_PALETTE_RED:
        setPalletTemperature(PALETTE_RED_HEX_COLORS);
        break;

      case INDICATORS_GROUP_PALETTE_YELLOW_RED:
        setPalletTemperature(PALETTE_YELLOW_RED_HEX_COLORS);
        break;

      default:
        setPalletTemperature([PALETTE_DEFAULT_HSL_COLORS]);
    }
  }, [polygonsColor]);

  /** Закрытие попапа при смене показателя */
  useEffect(() => {
    if (popup.current && typeof popup.current.remove === 'function') {
      popup.current.remove();
    }
  }, [facts]);

  /** Изменение прозрачности полигонов с помощью контрола */
  useEffect(() => {
    if (map && map.getLayer(LAYERS.POLYGONS)) {
      map.setPaintProperty(LAYERS.POLYGONS, 'fill-opacity', (0.8 * polygonsOpacity) / 100);
    }
  }, [polygonsOpacity, map]);

  /**
   * Фильтруем территории фильтром внизу карты.
   * 0.1 - поправка на погрешность при сравнении float чисел
   */
  useEffect(() => {
    Object.values(LAYERS).forEach(layer => {
      if (map && map.getSource(layer)) {
        if (
          (territoryRangeFilter[namespace].min || territoryRangeFilter[namespace].min === 0) &&
          (territoryRangeFilter[namespace].max || territoryRangeFilter[namespace].max === 0)
        ) {
          map.setFilter(layer, [
            'any',
            [
              'all',
              [
                '>=',
                'value',
                territoryRangeFilter[namespace].min -
                  (territoryRangeFilter[namespace].min < 1 &&
                  territoryRangeFilter[namespace].min !== 0
                    ? getDepthDecimal(territoryRangeFilter[namespace].min)
                    : 0.1),
              ],
              [
                '<=',
                'value',
                territoryRangeFilter[namespace].max +
                  (territoryRangeFilter[namespace].max < 1 &&
                  territoryRangeFilter[namespace].max !== 0
                    ? getDepthDecimal(territoryRangeFilter[namespace].max)
                    : 0.1),
              ],
            ],
          ]);
        } else {
          map.setFilter(layer, ['any', ['all']]);
        }
      }
    });
  }, [territoryRangeFilter[namespace], map]);

  /**
   * Фильтруем объекты по измерениям объектов (слайдер во вкладке "объекты")
   */
  useEffect(() => {
    Object.values(OBJECTS).forEach(layer => {
      if (map && map.getSource(layer)) {
        map.setFilter(layer, [
          'any',
          [
            'all',
            ['>=', 'value', objectsRangeFilter.min - 1],
            ['<=', 'value', objectsRangeFilter.max + 1],
          ],
        ]);
      }
    });
  }, [objectsRangeFilter]);

  /** Добавляем слой с полигонами на карту */
  useEffect(() => {
    if (map) {
      Object.values(LAYERS).forEach(layer => {
        const paint = {};
        let type;
        const color = [
          'case', // Whether the polygon is selected or not
          ['boolean', ['feature-state', 'hover'], false],
          polygonsHoverColor, // This color is for selected state
          ['get', 'fillColor'], // And this is for unselected state
        ];
        const opacity = (0.8 * polygonsOpacity) / 100;
        switch (layer) {
          case LAYERS.POLYGONS:
            paint['fill-color'] = color;
            paint['fill-opacity'] = opacity;
            paint['fill-outline-color'] = 'rgba(20, 55, 64, 0.4)';
            type = 'fill';
            break;
          case LAYERS.LINES:
            paint['line-color'] = color;
            paint['line-opacity'] = opacity;
            paint['line-width'] = 4;
            type = 'line';
            break;
          case LAYERS.POINTS:
            paint['circle-color'] = color;
            paint['circle-opacity'] = opacity;
            paint['circle-radius'] = 10;
            type = 'circle';
            break;
          default:
            break;
        }

        map.addLayer({
          id: layer,
          type,
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          },
          paint,
        });
      });

      Object.values(LAYERS).forEach(layer => {
        map.addLayer({
          id: `${layer}_highlighted`,
          type: 'fill',
          source: layer,
          paint: {
            'fill-outline-color': polygonsHoverColor,
            'fill-color': polygonsHoverColor,
            'fill-opacity': 1,
          },
          filter: ['in', 'filterId', ''],
        });
      });

      /** Клик по кнопке в попапе */
      if (mapRef.current) {
        mapRef.current.addEventListener('click', handleInfoBoxButtonClick);
      }

      /** Добавляем scale control */
      const scale = new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: 'metric',
      });
      map.addControl(scale);
    }

    return () => {
      if (map) {
        Object.values(LAYERS).forEach(layer => {
          if (map.getLayer(layer)) {
            if (map.getLayer(`${layer}_highlighted`)) {
              map.removeLayer(`${layer}_highlighted`);
            }
            map.removeLayer(layer);
            if (map.getSource(layer)) {
              map.removeSource(layer);
            }
          }
        });
      }

      /** Клик по кнопке в попапе */
      if (mapRef.current) {
        mapRef.current.removeEventListener('click', handleInfoBoxButtonClick);
      }
    };
  }, [map]);

  /** Добавляем слои с объектами на карту */
  useEffect(() => {
    if (
      // indicatorTabsEnabled[INDICATOR_OBJECTS_TAB] &&
      !isLoadingPolygons &&
      polygons &&
      Object.keys(polygons).length &&
      map
    ) {
      if (Object.keys(measuresValuesObjects).length) {
        Object.keys(measuresValuesObjects).forEach(indicatorId => {
          Object.values(OBJECTS).forEach(layer => {
            const layerId = `${layer}_${indicatorId}`;

            if (!indicatorTabsEnabled[INDICATOR_OBJECTS_TAB]) {
              if (map.getLayer(layerId)) {
                map.removeLayer(layerId);

                if (map.getSource(layerId)) {
                  map.removeSource(layerId);
                }
              }
              return;
            }

            if (map.getLayer(layerId)) {
              map.removeLayer(layerId);

              if (map.getSource(layerId)) {
                map.removeSource(layerId);
              }
            }

            if (!measuresValuesObjects[indicatorId].selected) {
              if (map.getLayer(layerId)) {
                map.removeLayer(layerId);
              }

              if (map.getSource(layerId)) {
                map.removeSource(layerId);
              }
              return;
            }

            if (map.getLayer(layerId)) {
              return;
            }

            const paint = {};
            let type;

            if (layerId.startsWith(OBJECTS.LINES)) {
              paint['line-color'] = ['get', 'color'];
              paint['line-width'] = 4;
              type = 'line';
            }
            if (layerId.startsWith(OBJECTS.POINTS)) {
              paint['circle-color'] = ['get', 'color'];
              paint['circle-radius'] = ['get', 'radius'];
              type = 'circle';
            }

            map.addLayer({
              id: layerId,
              type,
              source: {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: [],
                },
              },
              paint,
            });
          });

          const minRadiusCircle = 5;
          const maxRadiusCircle = 17;

          const measurePercents = (v, maxV, minV) => ((v - minV) / (maxV - minV)) * 100;

          const measureRadius = (v, maxV, minV) =>
            ((maxRadiusCircle - minRadiusCircle) * measurePercents(v, maxV, minV)) / 100 +
            minRadiusCircle;

          const objects = (currentObjects[indicatorId] || []).filter(
            fact =>
              polygons[fact.territory_id] &&
              polygons[fact.territory_id].geometry &&
              polygons[fact.territory_id].geometry.coordinates,
          );

          /** Разделим объекты на типы LINES (geometry.type == Linestring | MultiLineString) и Point (все остальные) */
          const objectsLines =
            objects
              .filter(
                fact =>
                  polygons[fact.territory_id].geometry.type === 'LineString' ||
                  polygons[fact.territory_id].geometry.type === 'MultiLineString',
              )
              .map(fact => ({
                id: polygons[fact.territory_id].id,
                geometry: polygons[fact.territory_id].geometry,
                properties: {
                  color: measuresValuesObjects[indicatorId].color,
                  name: polygons[fact.territory_id].name,
                  comment: polygons[fact.territory_id].comment || '',
                  filterId: polygons[fact.territory_id].id,
                },
                type: 'Feature',
              })) || [];

          const objectsPoints =
            objects.filter(
              fact =>
                polygons[fact.territory_id].geometry.type !== 'LineString' &&
                polygons[fact.territory_id].geometry.type !== 'MultiLineString',
            ) || [];

          /** Добавление объектов с измерениями для Point */
          const measuredObjectsPoints = objectsPoints.reduce((acc, fact) => {
            /** Для объектов с измерениями генерим такое же количество точек, как количество измерений; */
            /** Генерим цвет, генерим размер точки, который зависит от значения измерения */
            if (fact.values && fact.values.length > 0) {
              let translation = 0;
              fact.values.forEach(measure => {
                acc.push({
                  id: polygons[fact.territory_id].id,
                  geometry: {
                    ...polygons[fact.territory_id].geometry,
                    coordinates: [
                      polygons[fact.territory_id].geometry.coordinates[0] + translation,
                      polygons[fact.territory_id].geometry.coordinates[1],
                    ],
                  },
                  properties: {
                    /** TODO: добавить отдельные цвета для измерений */
                    color: measuresValuesObjects[indicatorId].colorsMeasures.find(
                      measureColor => measureColor.id === measure.id,
                    ).color,
                    name: polygons[fact.territory_id].name,
                    comment: polygons[fact.territory_id].comment || '',
                    filterId: polygons[fact.territory_id].id,
                    radius:
                      fact.values.length !== 0 && measure.value !== null
                        ? measureRadius(
                            measure.value,
                            currentObjectsMeasures &&
                              currentObjectsMeasures
                                .find(obj => obj.id === Number(indicatorId))
                                .measures.find(smeasure => smeasure.id === measure.id).max,
                            currentObjectsMeasures
                              .find(obj => obj.id === Number(indicatorId))
                              .measures.find(smeasure => smeasure.id === measure.id).min,
                          )
                        : 7,
                    value: measure.value,
                    measureName:
                      currentObjectsMeasures &&
                      currentObjectsMeasures
                        .find(obj => obj.id === Number(indicatorId))
                        .measures.find(smeasure => smeasure.id === measure.id).name,
                    measureId: measure.id,
                    percents: `${measurePercents(
                      measure.value,
                      currentObjectsMeasures &&
                        currentObjectsMeasures
                          .find(obj => obj.id === Number(indicatorId))
                          .measures.find(smeasure => smeasure.id === measure.id).max,
                      currentObjectsMeasures
                        .find(obj => obj.id === Number(indicatorId))
                        .measures.find(smeasure => smeasure.id === measure.id).min,
                    )}`,
                  },
                  type: 'Feature',
                });
                translation += 0.005;
              });
            } else {
              acc.push({
                id: polygons[fact.territory_id].id,
                geometry: polygons[fact.territory_id].geometry,
                properties: {
                  color: measuresValuesObjects[indicatorId].color,
                  name: polygons[fact.territory_id].name,
                  comment: polygons[fact.territory_id].comment || '',
                  filterId: polygons[fact.territory_id].id,
                  radius: 7,
                },
                type: 'Feature',
              });
            }
            return acc;
          }, []);

          const terrGeoJSONPoints = {
            type: 'FeatureCollection',
            features: measuredObjectsPoints,
          };
          const terrGeoJSONLines = {
            type: 'FeatureCollection',
            features: objectsLines,
          };

          if (
            map.getLayer(`${OBJECTS.POINTS}_${indicatorId}`) &&
            map.getSource(`${OBJECTS.POINTS}_${indicatorId}`)
          ) {
            map.getSource(`${OBJECTS.POINTS}_${indicatorId}`).setData(terrGeoJSONPoints);
          }
          if (
            map.getLayer(`${OBJECTS.LINES}_${indicatorId}`) &&
            map.getSource(`${OBJECTS.LINES}_${indicatorId}`)
          ) {
            map.getSource(`${OBJECTS.LINES}_${indicatorId}`).setData(terrGeoJSONLines);
          }
        });
      } else {
        /** Если measureValuesObjects стали empty, обрабатываем и удаляем предыдущие слои с объектами */
        const layersObjects = map
          .getStyle()
          .layers.filter(layer => layer.id.startsWith(OBJECTS_LAYER_NAME));

        layersObjects &&
          layersObjects.forEach(layer => {
            if (map.getLayer(layer.id)) {
              map.removeLayer(layer.id);

              if (map.getSource(layer.id)) {
                map.removeSource(layer.id);
              }
            }
          });
      }
    }
  }, [
    measuresValuesObjects,
    map,
    currentObjects,
    polygons,
    isLoadingPolygons,
    indicatorTabsEnabled[INDICATOR_OBJECTS_TAB],
  ]);

  /** Добавляем полигоны на карту */
  useEffect(() => {
    /** Если выбрана группа показателей, пришли факты и полигоны, считаем интенсивность закраски полигонов */
    if (
      indicatorTabsEnabled[INDICATOR_TERRITORY_TAB] &&
      currentIndicator &&
      !isLoadingPolygons &&
      polygons &&
      facts &&
      facts.data &&
      map
    ) {
      Object.values(LAYERS).forEach(layer => {
        map.setLayoutProperty(layer, 'visibility', 'visible');
        map.setLayoutProperty(`${layer}_highlighted`, 'visibility', 'visible');
      });

      const aggFunc = facts.agg_func || 'avg_value';

      let minValue = null;
      let maxValue = null;
      let intensitySingle = null;

      minValue = facts.data
        .map(item => item.data[aggFunc])
        .reduce((acc, curItem) => Math.min(acc, curItem), Infinity);
      maxValue = facts.data
        .map(item => item.data[aggFunc])
        .reduce((acc, curItem) => Math.max(acc, curItem), -Infinity);

      if (Object.keys(facts.data).length === 1) {
        intensitySingle = 1;
      }

      /** Создаем объекты  */
      const objects = facts.data
        .filter(
          item =>
            polygons[item.territory_id] &&
            polygons[item.territory_id].geometry &&
            polygons[item.territory_id].geometry.coordinates,
        )
        .map(fact => {
          let fillColor;
          if (fact.data[aggFunc] !== null) {
            if (polygonsColor === INDICATORS_GROUP_PALETTE_DEFAULT) {
              const intensity =
                intensitySingle || (fact.data[aggFunc] - minValue) / (maxValue - minValue) || 1;
              const lightness = 85 - (85 - 46) * intensity;
              namespace === 'a'
                ? (fillColor = hsl2hex(
                    PALETTE_DEFAULT_HSL_COLORS[0],
                    PALETTE_DEFAULT_HSL_COLORS[1],
                    lightness,
                  ))
                : (fillColor = hsl2hex(
                    PALETTE_DEFAULT_HSL_COLORS_SECOND[0],
                    PALETTE_DEFAULT_HSL_COLORS_SECOND[1],
                    lightness,
                  ));
            } else if (isPalletTemperature) {
              if (minValue === maxValue) {
                [fillColor] = palletTemperature;
              } else {
                const intensity =
                  intensitySingle || (fact.data[aggFunc] - minValue) / (maxValue - minValue);
                const intensityLevels = [];
                let step = 0;
                for (let i = 0; i < palletTemperature.length + 1; i += 1) {
                  if (i === palletTemperature.length) {
                    intensityLevels.push(1);
                    break;
                  }
                  intensityLevels.push(step);
                  step += 1 / palletTemperature.length;
                }
                const color = intensityLevels.findIndex(
                  (lvl, index) => lvl <= intensity && intensity <= intensityLevels[index + 1],
                );
                fillColor = palletTemperature[color];
              }
            }
          } else {
            fillColor = '#d7d7d7';
          }

          return {
            id: polygons[fact.territory_id].id,
            geometry: polygons[fact.territory_id].geometry,
            properties: {
              parents: fact.territory_parents,
              fillColor,
              name: polygons[fact.territory_id].name,
              comment: polygons[fact.territory_id].comment || '',
              value: fact.data[aggFunc] !== null ? fact.data[aggFunc] : -1,
              filterId: polygons[fact.territory_id].id,
            },
            type: 'Feature',
          };
        });

      /** Создаем объекты type = Point */
      const objectsPoints = objects.filter(obj => obj.geometry.type === 'Point') || [];

      /** Создаем объекты type = LineString, MultiLineString */
      const objectsLines =
        objects.filter(
          obj => obj.geometry.type === 'LineString' || obj.geometry.type === 'MultiLineString',
        ) || [];

      /** Создаем объекты type = Polygon и другие */
      const objectsPolygons =
        objects.filter(
          obj =>
            obj.geometry.type !== 'Point' &&
            obj.geometry.type !== 'LineString' &&
            obj.geometry.type !== 'MultiLineString',
        ) || [];

      const terrGeoJSON = {
        type: 'FeatureCollection',
        features: objectsPolygons,
      };

      const terrPointsGeoJSON = {
        type: 'FeatureCollection',
        features: objectsPoints,
      };

      const terrLinesGeoJSON = {
        type: 'FeatureCollection',
        features: objectsLines,
      };

      if (map.getSource(LAYERS.POLYGONS)) {
        if (map.getLayer(LAYERS.POLYGONS)) {
          map.getSource(LAYERS.POLYGONS).setData(terrGeoJSON);
        }
        if (map.getLayer(LAYERS.LINES)) {
          map.getSource(LAYERS.LINES).setData(terrLinesGeoJSON);
        }
        if (map.getLayer(LAYERS.POINTS)) {
          map.getSource(LAYERS.POINTS).setData(terrPointsGeoJSON);
        }
      }
    }

    /** Скрываем слои при выключении территориального показателя */
    if (map && !indicatorTabsEnabled[INDICATOR_TERRITORY_TAB]) {
      Object.values(LAYERS).forEach(layer => {
        map.setLayoutProperty(layer, 'visibility', 'none');
        map.setLayoutProperty(`${layer}_highlighted`, 'visibility', 'none');
      });
    }
  }, [
    map,
    currentIndicator,
    indicatorTabsEnabled[INDICATOR_TERRITORY_TAB],
    isLoadingPolygons,
    polygons,
    facts,
  ]);

  /** События для выделения прямоугольником */
  useEffect(() => {
    const handleMapMouseDown = e => {
      if (isInSelectionMode) {
        e.preventDefault();

        setState(prevState => ({
          ...prevState,
          isSelectionStarted: true,
        }));

        setSelectionInState({
          startX: e.originalEvent.clientX,
          startY: e.originalEvent.clientY,
        });

        document.body.addEventListener('mousemove', handleBodyMouseMove);
      }
    };

    const handleBodyMouseMove = e => {
      setSelectionInState(prevState => ({
        ...prevState,
        endX: e.clientX,
        endY: e.clientY,
      }));
    };

    const handleMapMouseUp = e => {
      if (isInSelectionMode) {
        e.preventDefault();

        setState(prevState => ({
          ...prevState,
          isSelectionStarted: false,
        }));

        document.body.removeEventListener('mousemove', handleBodyMouseMove);
      }
    };

    if (map && isInSelectionMode) {
      map.on('mousedown', handleMapMouseDown);
      map.on('mouseup', handleMapMouseUp);
    }

    return () => {
      if (map) {
        map.off('mousedown', handleMapMouseDown);
        map.off('mouseup', handleMapMouseUp);
        document.body.removeEventListener('mousemove', handleBodyMouseMove);
      }
    };
  }, [map, isInSelectionMode]);

  /** События ховеров на полигонах */
  useEffect(() => {
    const handleMouseEnter = () => {
      setState(prevState => ({
        ...prevState,
        arePolygonsHovered: true,
      }));
    };

    const handleMouseLeave = () => {
      setState(prevState => ({
        ...prevState,
        arePolygonsHovered: false,
      }));
    };

    /** Хэндлер для ховера */
    let hoveredStateId = null;
    const throttledMouseMove = layer =>
      throttleMapBoxEvent(e => {
        if (
          e.features &&
          e.features.length > 0 &&
          e.features[0].properties.value !== -1 &&
          !isInSelectionMode
        ) {
          if (hoveredStateId) {
            map.setFeatureState({ source: layer, id: hoveredStateId }, { hover: false });
          }
          hoveredStateId = e.features[0].id;
          map.setFeatureState({ source: layer, id: hoveredStateId }, { hover: true });
          setHoverValue(e.features[0].properties.value);
        } else {
          if (hoveredStateId) {
            map.setFeatureState({ source: layer, id: hoveredStateId }, { hover: false });
          }
          hoveredStateId = null;
        }
      }, 0);

    /** Хэндлер для анховера */
    const throttledMouseLeave = layer =>
      throttleMapBoxEvent(e => {
        if (hoveredStateId) {
          map.setFeatureState({ source: layer, id: hoveredStateId }, { hover: false });
        }
        hoveredStateId = null;
        setHoverValue(null);
      }, 20);

    if (map) {
      Object.values(LAYERS).forEach(layer => {
        /** Смена курсора на палец */
        map.on('mouseenter', layer, handleMouseEnter);

        /** Смена курсора на обычный */
        map.on('mouseleave', layer, handleMouseLeave);

        /** Ховер */
        map.on('mousemove', layer, throttledMouseMove(layer));
        map.on('mouseleave', layer, throttledMouseLeave(layer));
      });
    }

    return () => {
      if (map) {
        Object.values(LAYERS).forEach(layer => {
          map.off('mouseenter', layer, handleMouseEnter);
          map.off('mouseleave', layer, handleMouseLeave);
          map.off('mousemove', layer, throttledMouseMove);
          map.off('mouseleave', layer, throttledMouseLeave);
        });
      }
    };
  }, [map, isInSelectionMode]);

  /** Установка фильтра для подсветки выбранных полигонов */
  useEffect(() => {
    if (map) {
      Object.values(LAYERS).forEach(layer => {
        if (selectedPolygons.length) {
          map.setFilter(`${layer}_highlighted`, [
            'in',
            'filterId',
            ...selectedPolygons.map(item => item.id),
          ]);
        } else {
          map.setFilter(`${layer}_highlighted`, ['in', 'filterId', '']);
        }
      });
    }
  }, [map, selectedPolygons]);

  /** Если изменился город перемещаем центр карты */
  useEffect(() => {
    goToCity();
  }, [currentCity.id]);

  /** При выделении прямоугольником ищем пересеченные полигоны */
  useEffect(() => {
    if (!state.isSelectionStarted && Object.keys(selectionInState).length === 4) {
      selectByRect();
    }
  }, [state.isSelectionStarted, selectionInState]);

  /** Делаем действия, которые возможны после установки map в state */
  useEffect(() => {
    if (map) {
      goToCity();
    }
  }, [map]);

  /** Переключаем курсоры */
  useEffect(() => {
    if (map) {
      if (isInSelectionMode) {
        map.getCanvas().style.cursor = 'crosshair';
      } else {
        map.getCanvas().style.cursor = state.arePolygonsHovered ? 'pointer' : '';
      }
    }
  }, [isInSelectionMode, state.arePolygonsHovered, map]);

  /** Drill-down по dblclick */
  useEffect(() => {
    const zoomToNextLevel = event => {
      if (!event.features.length) {
        return;
      }

      if (popup.current && typeof popup.current.remove === 'function') {
        popup.current.remove();
      }

      if (isInSelectionMode && (!mapLevels || !mapLevels.length || !map)) {
        return;
      }

      /** Находим следующий уровень детализации */
      const depthCurrentMapLevel = mapLevels.map(item => item.id).indexOf(currentMapLevelId);
      const depthNextMapLevel = depthCurrentMapLevel + 1;
      const nextMapLevel = mapLevels[depthNextMapLevel];
      if (!nextMapLevel) {
        return;
      }

      /** Ставим в фильтры кликнутый полигон */
      selectParentPolygonByDoubleClick(event, namespace);

      /** Ставим уровень детализации, загружаем факты и полигоны */
      setTimeout(() => {
        setCurrentMapLevel(nextMapLevel.id);
      }, 200);
    };

    /** Тач ивенты для эмуляции даблклика */
    let tapStarted = false;
    let tap = 0;
    let timerTapStarted = null;
    let timerTap = null;

    const touchstart = () => {
      tapStarted = true;
      timerTapStarted = setTimeout(() => {
        tapStarted = false;
      }, 200);
    };

    const touchend = e => {
      if (tapStarted) {
        tap += 1;
        if (tap === 2) {
          tap = 0;
          tapStarted = false;
          clearTimeout(timerTap);
          zoomToNextLevel(e);
          return;
        }
        tapStarted = false;
        timerTap = setTimeout(() => {
          tap = 0;
        }, 200);
        clearTimeout(timerTapStarted);
      }
    };

    Object.values(LAYERS).forEach(layer => {
      if (map && map.getLayer(layer)) {
        map.on('dblclick', layer, zoomToNextLevel);
        map.on('touchstart', layer, touchstart);
        map.on('touchend', layer, touchend);
      }
    });

    return () => {
      Object.values(LAYERS).forEach(layer => {
        if (map && map.getLayer(layer)) {
          map.off('dblclick', layer, zoomToNextLevel);
          map.off('touchstart', layer, touchstart);
          map.off('touchend', layer, touchend);
        }
      });
    };
  }, [
    map,
    mapLevels,
    isInSelectionMode,
    currentMapLevelId,
    currentIndicatorTab,
    chartTabInnerMode,
    currentChartIndicator,
  ]);

  /** Открываем info-box по клику или выбираем полигон по shift+click */
  useEffect(() => {
    const handleClick = e => {
      const [feature] = map.queryRenderedFeatures(e.point);
      if (feature && feature.source !== 'composite') {
        if (e.originalEvent.shiftKey) {
          selectPolygon(
            {
              id: feature.id,
              parent_ids: JSON.parse(feature.properties.parents || []),
              name: feature.properties.name,
            },
            namespace,
          );
        } else {
          /** Позиционирование попапа (ставится в большую часть свободного пространства - в низ или в верх) */
          const options = {};
          const { clientHeight } = mapRef.current;
          if (e.point.y > clientHeight - e.point.y) {
            options.anchor = 'bottom';
          } else {
            options.anchor = 'top';
          }
          popup.current = new mapboxgl.Popup(options)
            .setLngLat(e.lngLat)
            .setHTML(
              renderToString(
                <InfoBox
                  territoryId={feature.id}
                  currentIndicator={currentIndicator}
                  facts={facts}
                  territoryName={feature.properties.name}
                  comment={feature.properties.comment}
                  parents={feature.properties.parents}
                  color={feature.properties.color || null}
                  measureName={feature.properties.measureName || null}
                  measurePercents={feature.properties.percents || null}
                  measureValue={feature.properties.value || null}
                  langOb={langObInfoBox || {}}
                  isObject={Object.values(LAYERS).every(layer => feature.source !== layer)}
                />,
              ),
            )
            .addTo(map);
        }
      }
    };

    if (map) {
      map.on('click', handleClick);
    }

    return () => {
      if (map) {
        map.off('click', handleClick);
      }
    };
  }, [map, currentIndicator, facts]);

  /** Выбор территории по кнопке внутри попапа */
  const handleInfoBoxButtonClick = useCallback(
    ({ target }) => {
      const button = target.closest('.info-box__select-button');
      if (button) {
        const { id, parents, name } = button.dataset;
        if (id && parents) {
          selectPolygon(
            {
              id: +id,
              parent_ids: JSON.parse(parents || []),
              name,
            },
            namespace,
          );

          if (popup.current && typeof popup.current.remove === 'function') {
            popup.current.remove();
          }
        }
      }
    },
    [selectPolygon],
  );

  const onMapLoaded = res => {
    setMap(res);
    if (lang && lang.short && lang.short !== LANG_EN.short) {
      setTimeout(() => {
        res.addControl(
          new MapboxLanguage({
            defaultLanguage: lang.short,
          }),
        );
      }, 0);
    }
    if (res) {
      res.on('idle', () => {
        setMapDataUrl(res.getCanvas().toDataURL());
      });
    }
  };

  const goToCity = () => {
    if (!currentCity || !map) {
      return;
    }

    try {
      if (currentCity.point) {
        map.setCenter(new mapboxgl.LngLat(currentCity.point[1], currentCity.point[0]));
      }
    } catch (e) {
      /* eslint-disable no-console */
      console.log('Cant get city coordinates', currentCity.name);
    }
  };

  const selectByRect = () => {
    const rect = mapRef.current.getBoundingClientRect();

    /** Как то волшебно собираем полигоны которые входят в прямоугольник */
    const features = map.queryRenderedFeatures(
      [
        new mapboxgl.Point(selectionInState.startX - rect.left, selectionInState.startY - 70),
        new mapboxgl.Point(selectionInState.endX - rect.left, selectionInState.endY - 70),
      ],
      { layers: Object.values(LAYERS) },
    );

    /** Сбрасываем прямоугольник  */
    setSelectionInState({});

    /** Заполняем массив с полигонами */
    const selectedPolygonsByRect = features.map(item => ({
      id: item.properties.filterId,
      parent_ids: JSON.parse(item.properties.parents || []),
      name: item.properties.name,
    }));

    /** Выбираем полигоны */
    selectPolygons(selectedPolygonsByRect, namespace);
  };

  if (!currentCity || !currentCity.point) return null;

  /** Если выбрана группа показателей и пришли факты рисуем сводку внизу */
  let summary = [];
  if (
    currentIndicator &&
    currentIndicator.agg_total &&
    currentIndicator.agg_total.length &&
    facts
  ) {
    summary = currentIndicator.agg_total
      .map((item, index) => {
        if (facts[`${item}_summary`]) {
          return {
            caption:
              !facts.agg_func || facts.agg_func === `${item}_value`
                ? DIMENSIONS_NAMES[`${item}_summary`]
                : `${DIMENSIONS_NAMES_CONSTRUCTOR[`${item}_total`]} ${
                    DIMENSIONS_NAMES_CONSTRUCTOR[facts.agg_func]
                  } ${DIMENSIONS_NAMES_CONSTRUCTOR.for_period}`,
            value: facts[`${item}_summary`],
            id: index,
            unit: facts.unit_name || currentIndicator.unit_name,
          };
        }
        return null;
      })
      .filter(item => item);
  }

  return (
    <div className="map-component" ref={mapRef}>
      <MapboxMap
        accessToken="pk.eyJ1IjoiaXp2ZXJldiIsImEiOiJjbGZ0Zzh6ODEwMG1sM2dwaXV6aWtjemk0In0.IJyQNnkb7Q0IFMg3UHLVJA"
        coordinates={{ lat: currentCity.point[0], lng: currentCity.point[1] }}
        zoom={
          (currentIndicator &&
            currentIndicator.map_zoom &&
            (namespace === 'a'
              ? currentIndicator.map_zoom - 1
              : currentIndicator.to_map_zoom - 1)) ||
          9
        }
        className="map__map"
        onLoad={onMapLoaded}
        withZoom
        withFullscreen
        fullscreenControlPosition="bottom-right"
        mapboxStyle="mapbox://styles/mapbox/outdoors-v9"
        boxZoom={false}
        preserveDrawingBuffer
      />

      {/* Делаем подложку для экспорта карты (CORS) */}
      {map && mapDataUrl && user && user.can_export && (
        <img className="map-component__export-substrate" src={mapDataUrl} alt="Изображение карты" />
      )}

      {/* НИЖНЯЯ ГРУППА КОНТРОЛОВ */}
      {!isInSelectionMode && map && (
        <React.Fragment>
          {hoverValue !== null &&
            currentIndicator !== null &&
            facts !== null &&
            Boolean(facts.data) && (
              <CurrentAreaSummary
                className="map__summary"
                summary={[
                  {
                    caption: DIMENSIONS_NAMES[facts.agg_func],
                    id: 0,
                    unit: facts.unit_name,
                    value: hoverValue,
                  },
                ]}
                precision={
                  facts.unit_name === FACT_UNIT_NAMES.percent
                    ? currentIndicator.val_precision_percent
                    : currentIndicator.val_precision
                }
                mapName={mapName}
              />
            )}
          {hoverValue === null && summary.length > 0 && (
            <CurrentAreaSummary
              className="map__summary"
              summary={summary}
              precision={
                facts.unit_name === FACT_UNIT_NAMES.percent
                  ? currentIndicator.val_precision_percent
                  : currentIndicator.val_precision
              }
              mapName={mapName}
            />
          )}

          {/* фильтр внизу */}
          {indicatorTabsEnabled[INDICATOR_TERRITORY_TAB] &&
            facts.data &&
            facts.data.length > 0 &&
            !isLoadingPolygons && (
              <MapTerritoryRangeFilter
                className="map__territory-filter"
                namespace={namespace}
                facts={facts}
                currentIndicator={currentIndicator}
                palette={polygonsColor}
              />
            )}
          {/* /ФИЛЬТР ВНИЗУ */}
        </React.Fragment>
      )}
      {/* /НИЖНЯЯ ГРУППА КОНТРОЛОВ */}

      {/* ВЫБРАННЫЕ ТЕРРИТОРИИ */}
      {selectedPolygons && selectedPolygons.length > 0 && (
        <SelectedPolygons className="map__selected-polygons" namespace={namespace} />
      )}
      {/* /ВЫБРАННЫЕ ТЕРРИТОРИИ */}

      {map && (isLoadingPolygons || isLoadingObjects) && (
        <div className="map__loader">
          <IconLoader className="map__loading-icon" />
        </div>
      )}

      {isInSelectionMode && (
        <MapSelection className="map__selection" selection={selectionInState} />
      )}
    </div>
  );
};

Map.propTypes = {
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  currentIndicator: PropTypes.shape({
    agg_total: PropTypes.arrayOf(PropTypes.string),
    unit_name: PropTypes.string,
    val_precision: PropTypes.number,
    val_precision_percent: PropTypes.number,
    has_two_maps: PropTypes.bool,
    map_zoom: PropTypes.number,
    to_map_zoom: PropTypes.number,
  }),
  facts: factsType.isRequired,
  isLoadingPolygons: PropTypes.bool.isRequired,
  isLoadingObjects: PropTypes.bool.isRequired,
  indicatorTabsEnabled: PropTypes.shape({
    [INDICATOR_TERRITORY_TAB]: PropTypes.bool,
  }).isRequired,
  polygonsOpacity: PropTypes.number.isRequired,
  territoryRangeFilter: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  selectedPolygons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      parents: PropTypes.arrayOf(PropTypes.number),
    }),
  ).isRequired,
  mapLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      min_zoom: PropTypes.number,
    }),
  ).isRequired,
  currentMapLevelId: PropTypes.number,
  currentCity: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    point: PropTypes.arrayOf(PropTypes.number),
  }),
  isInSelectionMode: PropTypes.bool.isRequired,
  currentIndicatorTab: PropTypes.string,
  chartTabInnerMode: PropTypes.string.isRequired,
  currentChartIndicator: PropTypes.shape({
    chart_type: PropTypes.string,
  }),
  objectsRangeFilter: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  selectParentPolygonByDoubleClick: PropTypes.func.isRequired,
  setCurrentMapLevel: PropTypes.func.isRequired,
  selectPolygon: PropTypes.func.isRequired,
  selectPolygons: PropTypes.func.isRequired,
  polygonsColor: PropTypes.string.isRequired,
  polygonsHoverColor: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    short: PropTypes.string,
  }).isRequired,
  currentObjects: PropTypes.shape({}).isRequired,
  measuresValuesObjects: PropTypes.shape(),
  mapName: PropTypes.string,
  currentObjectsMeasures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.shape({
    can_export: PropTypes.bool,
  }),
};

Map.defaultProps = {
  currentIndicator: null,
  currentMapLevelId: null,
  currentCity: null,
  currentIndicatorTab: null,
  currentChartIndicator: null,
  measuresValuesObjects: {},
  mapName: null,
};

export default Map;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import useToggleAndOutClick from 'use-toggle-and-outclick';

// Components
// import LanguageSelector from 'modules/main/components/LanguageSelector';
import CitySelector from 'modules/main/components/CitySelector';
import Popup from 'shared/Popup';
import AuthSignIn from 'modules/main/components/AuthSignIn';
import AuthPasswordReset from 'modules/main/components/AuthPasswordReset';
import Favorites from 'modules/main/components/FavoritesList';

// Actions
import { logOut } from 'modules/main/actions';

// Icons
import { ReactComponent as IconKey } from 'assets/icons/icon-key.svg';
import { ReactComponent as IconUser } from 'assets/icons/icon-user.svg';
import { ReactComponent as IconStar } from 'assets/icons/icon-star.svg';

// Misc
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const HeaderTools = ({
  className,
  closeMobileMenu,
  dispatch,
  user,
  isEnabledLanguageSelector,
}) => {
  /** Переводы */
  const langOb = useLang('HeaderTools');

  const [isSignInPopupOpen, setIsSignInPopupOpen] = useState(false);
  const [isPassResetPopupOpen, setIsPassResetPopupOpen] = useState(false);
  const [isFavoritesPopupOpen, setIsFavoritesPopupOpen] = useState(false);

  const [isDropOpened, dropEl, handleClick, closeExplicitly] = useToggleAndOutClick();

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('header-tools', {
        [className]: className,
      })}
    >
      {/* {isEnabledLanguageSelector && <LanguageSelector className="header-tools__lang-selector" />} */}

      <CitySelector className="header-tools__city-selector" closeMobileMenu={closeMobileMenu} />

      {!user && (
        <button
          type="button"
          className="header-tools__button"
          onClick={() => {
            setIsSignInPopupOpen(true);
          }}
          aria-label={langOb.authButtonLabel}
        >
          <IconKey className="header-tools__button-icon" />
        </button>
      )}

      {user && (
        <div className="header-tools__user">
          <button
            className="header-tools__button"
            type="button"
            onClick={handleClick}
            aria-label={langOb.profileButtonLabel}
          >
            <IconUser className="header-tools__button-icon" />
          </button>

          <CSSTransition
            in={isDropOpened}
            timeout={200}
            classNames="header-tools__user-drop"
            unmountOnExit
          >
            <div
              className="header-tools__user-drop"
              ref={dropEl}
              data-testid="header-tools-user-drop"
            >
              <button
                type="button"
                className="header-tools__user-drop-item"
                onClick={() => {
                  closeExplicitly();
                  dispatch(logOut())
                    .then(() => {
                      /* eslint-disable no-self-assign */
                      window.location.href = '/';
                      /* eslint-enable no-self-assign */
                    })
                    .catch(() => {});
                }}
              >
                {langOb.logout}
              </button>
            </div>
          </CSSTransition>
          <button
            type="button"
            className="header-tools__button"
            onClick={() => setIsFavoritesPopupOpen(true)}
          >
            <IconStar className="header-tools__button-icon" />
          </button>
        </div>
      )}

      {isFavoritesPopupOpen && (
        <Popup
          closeHandler={() => {
            setIsFavoritesPopupOpen(false);
          }}
        >
          <Favorites />
        </Popup>
      )}

      {isSignInPopupOpen && (
        <Popup
          closeHandler={() => {
            setIsSignInPopupOpen(false);
          }}
        >
          <AuthSignIn
            successHandler={() => {
              setIsSignInPopupOpen(false);
            }}
            openPasswordResetForm={() => {
              setIsPassResetPopupOpen(true);
            }}
          />
        </Popup>
      )}

      {isPassResetPopupOpen && (
        <Popup
          closeHandler={() => {
            setIsPassResetPopupOpen(false);
          }}
        >
          <AuthPasswordReset
            closeHandler={() => {
              setIsPassResetPopupOpen(false);
            }}
          />
        </Popup>
      )}
    </div>
  );
};

HeaderTools.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func,
  user: PropTypes.shape({}),
  isEnabledLanguageSelector: PropTypes.bool,
};

HeaderTools.defaultProps = {
  className: null,
  user: null,
  closeMobileMenu: null,
  isEnabledLanguageSelector: false,
};

const mapStateToProps = state => ({
  user: state.main.user,
});

export default connect(mapStateToProps)(HeaderTools);

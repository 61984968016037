import { MIXED_EXCLUDE, MIXED_INCLUDE } from 'modules/main/constants';

/**
 * @param filterRegions {object} - фильтры территорий из стора
 * @param currentMapLevelId {number} - id текущего уровня детализации
 */
const getRegionsQueryParams = (filterRegions, currentMapLevelId, mapLevels) => {
  const terrs = [];
  const parentsArr = [];
  const excludeArr = [];

  /**
   * Функция достает включаемые территории, исключаемые территории и парент территории
   * @param item - текущая итерируемая территория
   */
  const fillRegionsQueryParams = item => {
    if (item.level === currentMapLevelId || item.territory_type === currentMapLevelId) {
      /**
       * Нет парента или парент в режиме INCLUDE
       * и айтем чекнутый -
       * добавляем айтем в территории
       *
       * Также проверяем территории, найденные по поиску (parent_ids)
       */
      if (
        (!item.parentId ||
          (item.parentId &&
            filterRegions[item.parentId] &&
            filterRegions[item.parentId].checked === MIXED_INCLUDE)) &&
        item.checked === true
      ) {
        terrs.push(item);
      } else if (
        ((!item.parentId &&
          item.parent_ids &&
          item.parent_ids.length > 0 &&
          item.parent_ids.every(parentId => filterRegions[parentId]) &&
          item.parent_ids.every(parentId => filterRegions[parentId].checked === MIXED_EXCLUDE)) ||
          (item.parentId &&
            filterRegions[item.parentId] &&
            filterRegions[item.parentId].checked === MIXED_EXCLUDE)) &&
        item.checked === false
      ) {
        /**
         * Парент в режиме EXCLUDE и айтем анчекнут
         * добавляем парента в паренты
         * добавляем айтем в эксклуд
         *
         * Также проверяем территории, найденные по поиску (parent_ids)
         */
        if (item.parentId) {
          if (!parentsArr.find(p => p.id === filterRegions[item.parentId].id)) {
            parentsArr.push(filterRegions[item.parentId]);
          }
        } else {
          item.parent_ids.forEach(parentId => {
            if (!parentsArr.find(p => p.id === filterRegions[parentId].id)) {
              parentsArr.push(filterRegions[parentId]);
            }
          });
        }
        excludeArr.push(item);
      } else if (
        item.parentId &&
        filterRegions[item.parentId] &&
        filterRegions[item.parentId].checked === true &&
        item.checked === true
      ) {
        /** Все дети отмечены - добавляем только парента */
        if (!parentsArr.find(p => p.id === filterRegions[item.parentId].id)) {
          parentsArr.push(filterRegions[item.parentId]);
        }
      } else if (item.checked === MIXED_EXCLUDE || item.checked === MIXED_INCLUDE) {
        /**
         * Айтем в режиме EXCLUDE или INCLUDE
         * Добавляем территорию
         */
        terrs.push(item);
      }
    }
    const depthMapLevel = mapLevels.map(level => level.id).indexOf(item.level);
    const depthCurrentMapLevel = mapLevels.map(level => level.id).indexOf(currentMapLevelId);
    if (depthMapLevel < depthCurrentMapLevel && item.checked === true) {
      if (!parentsArr.find(p => p.id === item.id)) {
        /** Если есть выбранные территории, не выбираем их родителя
         *  (иначе будут отображаться все потомки родителя + выбранные территории, а надо только выбранные территории */
        if (terrs && terrs.some(terr => terr.level === item.level + 1)) return;
        parentsArr.push(item);
      }
    }
  };

  Object.values(filterRegions).forEach(fillRegionsQueryParams);

  return { terrs, parentsArr, excludeArr };
};

export default getRegionsQueryParams;

import React from 'react';
import './assets/styles/styles.scss';
import PropTypes from 'prop-types';

const AuthWindow = ({ children }) => (
  <div className="auth__block">
    <div className="auth__window">{children}</div>
  </div>
);

AuthWindow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthWindow;

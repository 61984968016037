import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Map from 'modules/main/components/Map';

// Actions
import {
  getFactsMemo,
  getPolygons,
  selectPolygon,
  setCurrentMapLevel,
  selectParentPolygonByDoubleClick,
  selectPolygons,
  getRatingFactsMemo,
  getChartFactsMemo,
} from 'modules/main/actions';

// Misc
import {
  INDICATOR_TERRITORY_TAB,
  INDICATOR_CHARTS_TAB,
  CHART_TAB_INNER_MODE_CHART,
  INDICATORS_GROUP_PALETTE_DEFAULT,
} from 'modules/main/constants';
import getTerritoryIds from 'helpers/get-territory-ids';

// Styles
import './styles.scss';

const MapWrapper = props => {
  const {
    polygons,
    currentIndicator,
    currentIndicatorsGroup,
    polygonsOpacity,
    territoryRangeFilter,
    objectsRangeFilter,
    selectedPolygons,
    mapLevels,
    currentMapLevelId,
    currentCity,
    isInSelectionMode,
    dispatch,
    facts,
    factsTwoMaps,
    indicatorTabsEnabled,
    isLoadingPolygons,
    isLoadingObjects,
    currentIndicatorTab,
    chartTabInnerMode,
    currentChartIndicator,
    currentObjects,
    measuresValuesObjects,
    lang,
    currentObjectsMeasures,
    user,
  } = props;

  const handleSelectParentPolygonByDoubleClick = (event, namespace) => {
    /** Ставим в фильтры кликнутый полигон */
    dispatch(
      selectParentPolygonByDoubleClick(
        {
          id: event.features[0].id,
          parent_ids: JSON.parse(event.features[0].properties.parents || []),
        },
        namespace,
      ),
    );
  };

  const handleSetCurrentMapLevel = mapLevelId => {
    dispatch(setCurrentMapLevel(mapLevelId))
      .then(() => {
        /** Если открыта вкладка с графиками - перестраиваем */
        if (
          currentIndicatorTab === INDICATOR_CHARTS_TAB &&
          chartTabInnerMode === CHART_TAB_INNER_MODE_CHART &&
          currentChartIndicator
        ) {
          if (currentChartIndicator.chart_type === 'rating') {
            dispatch(getRatingFactsMemo(null, mapLevelId));
          } else {
            dispatch(getChartFactsMemo(null, mapLevelId));
          }
        }

        return dispatch(getFactsMemo(null, mapLevelId));
      })
      .then(res => {
        /**
         * Получение полигонов по территориям,
         * которые пришли в фактах (мерах)
         */
        const territoryIds = getTerritoryIds(res, polygons);
        if (territoryIds.length) {
          return dispatch(getPolygons(territoryIds));
        }

        return null;
      })
      .catch(() => {});
  };

  const handleSelectPolygon = (polygon, namespace) => {
    dispatch(selectPolygon(polygon, namespace));
  };

  const handleSelectPolygons = (polygonsToSelect, namespace) => {
    dispatch(selectPolygons(polygonsToSelect, namespace));
  };

  return (
    <div className="map-wrapper">
      <Map
        polygons={polygons}
        currentIndicator={currentIndicator}
        facts={currentIndicator && currentIndicator.has_second_map ? factsTwoMaps.data_from : facts}
        isLoadingPolygons={isLoadingPolygons}
        isLoadingObjects={isLoadingObjects}
        indicatorTabsEnabled={indicatorTabsEnabled}
        polygonsOpacity={polygonsOpacity}
        territoryRangeFilter={territoryRangeFilter}
        objectsRangeFilter={objectsRangeFilter}
        selectedPolygons={selectedPolygons.a}
        mapLevels={mapLevels}
        currentMapLevelId={currentMapLevelId}
        currentCity={currentCity}
        isInSelectionMode={isInSelectionMode.a}
        currentIndicatorTab={currentIndicatorTab}
        chartTabInnerMode={chartTabInnerMode}
        currentChartIndicator={currentChartIndicator}
        selectParentPolygonByDoubleClick={handleSelectParentPolygonByDoubleClick}
        setCurrentMapLevel={handleSetCurrentMapLevel}
        selectPolygon={handleSelectPolygon}
        selectPolygons={handleSelectPolygons}
        polygonsColor={
          (currentIndicatorsGroup && currentIndicatorsGroup.palette) ||
          INDICATORS_GROUP_PALETTE_DEFAULT
        }
        polygonsHoverColor="#4A90E2"
        currentObjects={currentObjects}
        namespace="a"
        key={currentIndicator && currentIndicator.has_second_map ? 'two-maps' : 'one-map'}
        measuresValuesObjects={measuresValuesObjects}
        lang={lang}
        mapName={
          currentIndicator && currentIndicator.has_second_map ? currentIndicator.map_name : null
        }
        currentObjectsMeasures={currentObjectsMeasures}
        user={user}
      />

      {currentIndicator && currentIndicator.has_second_map && (
        <Map
          polygons={polygons}
          currentIndicator={currentIndicator}
          facts={factsTwoMaps.data_to}
          isLoadingPolygons={isLoadingPolygons}
          isLoadingObjects={isLoadingObjects}
          indicatorTabsEnabled={indicatorTabsEnabled}
          polygonsOpacity={polygonsOpacity}
          territoryRangeFilter={territoryRangeFilter}
          selectedPolygons={selectedPolygons.b}
          mapLevels={mapLevels}
          currentMapLevelId={currentMapLevelId}
          currentCity={currentCity}
          isInSelectionMode={isInSelectionMode.b}
          currentIndicatorTab={currentIndicatorTab}
          chartTabInnerMode={chartTabInnerMode}
          currentChartIndicator={currentChartIndicator}
          selectParentPolygonByDoubleClick={handleSelectParentPolygonByDoubleClick}
          setCurrentMapLevel={handleSetCurrentMapLevel}
          selectPolygon={handleSelectPolygon}
          selectPolygons={handleSelectPolygons}
          polygonsColor={
            (currentIndicatorsGroup && currentIndicatorsGroup.palette) ||
            INDICATORS_GROUP_PALETTE_DEFAULT
          }
          currentObjects={currentObjects}
          polygonsHoverColor="#8C4F06"
          namespace="b"
          measuresValuesObjects={measuresValuesObjects}
          lang={lang}
          mapName={currentIndicator && currentIndicator.to_map_name}
          currentObjectsMeasures={currentObjectsMeasures}
          user={user}
        />
      )}
    </div>
  );
};

MapWrapper.propTypes = {
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  currentIndicator: PropTypes.shape({
    agg_total: PropTypes.arrayOf(PropTypes.string),
    unit_name: PropTypes.string,
    val_precision: PropTypes.number,
    has_second_map: PropTypes.bool,
    map_name: PropTypes.string,
    to_map_name: PropTypes.string,
  }),
  currentIndicatorsGroup: PropTypes.shape(),
  facts: PropTypes.shape({
    agg_func: PropTypes.string,
    sum_summary: PropTypes.number,
    min_summary: PropTypes.number,
    max_summary: PropTypes.number,
    avg_summary: PropTypes.number,
    median_summary: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        territory_id: PropTypes.number,
        territory_parents: PropTypes.arrayOf(PropTypes.number),
        data: PropTypes.shape({
          avg_value: PropTypes.number,
        }),
      }),
    ),
  }),
  factsTwoMaps: PropTypes.shape({
    data_from: PropTypes.shape({
      agg_func: PropTypes.string,
      sum_summary: PropTypes.number,
      min_summary: PropTypes.number,
      max_summary: PropTypes.number,
      avg_summary: PropTypes.number,
      median_summary: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          territory_id: PropTypes.number,
          territory_parents: PropTypes.arrayOf(PropTypes.number),
          data: PropTypes.shape({
            avg_value: PropTypes.number,
          }),
        }),
      ),
    }),
    data_to: PropTypes.shape({
      agg_func: PropTypes.string,
      sum_summary: PropTypes.number,
      min_summary: PropTypes.number,
      max_summary: PropTypes.number,
      avg_summary: PropTypes.number,
      median_summary: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          territory_id: PropTypes.number,
          territory_parents: PropTypes.arrayOf(PropTypes.number),
          data: PropTypes.shape({
            avg_value: PropTypes.number,
          }),
        }),
      ),
    }),
  }).isRequired,
  isLoadingPolygons: PropTypes.bool.isRequired,
  isLoadingObjects: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  indicatorTabsEnabled: PropTypes.shape({
    [INDICATOR_TERRITORY_TAB]: PropTypes.bool,
  }).isRequired,
  polygonsOpacity: PropTypes.number.isRequired,
  territoryRangeFilter: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  objectsRangeFilter: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  selectedPolygons: PropTypes.shape({
    a: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        parents: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    b: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        parents: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  }).isRequired,
  mapLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      min_zoom: PropTypes.number,
    }),
  ).isRequired,
  currentMapLevelId: PropTypes.number,
  currentCity: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    point: PropTypes.arrayOf(PropTypes.number),
  }),
  isInSelectionMode: PropTypes.shape({
    a: PropTypes.bool,
    b: PropTypes.bool,
  }).isRequired,
  currentIndicatorTab: PropTypes.string,
  chartTabInnerMode: PropTypes.string.isRequired,
  currentChartIndicator: PropTypes.shape({
    chart_type: PropTypes.string,
  }),
  currentObjects: PropTypes.shape().isRequired,
  lang: PropTypes.shape({
    short: PropTypes.string,
  }).isRequired,
  measuresValuesObjects: PropTypes.shape(),
  currentObjectsMeasures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measures: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
  ),
  user: PropTypes.shape({
    can_export: PropTypes.bool,
  }),
};

MapWrapper.defaultProps = {
  facts: null,
  currentIndicator: null,
  currentMapLevelId: null,
  currentCity: null,
  currentIndicatorTab: null,
  currentChartIndicator: null,
  currentObjectsMeasures: null,
  currentIndicatorsGroup: null,
  measuresValuesObjects: {},
};

const mapStateToProps = state => ({
  polygons: state.polygons,
  facts: state.main.facts,
  factsTwoMaps: state.main.factsTwoMaps,
  currentIndicator: state.main.currentIndicator,
  currentIndicatorsGroup: state.main.currentIndicatorsGroup,
  isLoadingPolygons: state.main.isLoadingPolygons,
  isLoadingObjects: state.main.isLoadingObjects,
  indicatorTabsEnabled: state.main.indicatorTabsEnabled,
  polygonsOpacity: state.main.polygonsOpacity,
  territoryRangeFilter: state.main.territoryRangeFilter,
  objectsRangeFilter: state.main.objectsRangeFilter,
  selectedPolygons: state.main.selectedPolygons,
  mapLevels: state.main.mapLevels,
  currentMapLevelId: state.main.currentMapLevelId,
  currentCity: state.main.currentCity,
  isInSelectionMode: state.main.isInSelectionMode,
  chartTabInnerMode: state.main.chartTabInnerMode,
  currentChartIndicator: state.main.currentChartIndicator,
  currentIndicatorTab: state.main.currentIndicatorTab,
  currentObjects: state.main.currentObjects,
  measuresValuesObjects: state.main.measuresValues.objects,
  lang: state.main.lang,
  currentObjectsMeasures: state.main.currentObjectsMeasures,
  user: state.main.user,
});

export default connect(mapStateToProps)(MapWrapper);

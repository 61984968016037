import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { font } from 'assets/fonts/pt-sans-v10-cyrillic-ext_latin-regular-normal';

/**
 * Генерирует pdf для графика
 * @param {string} chartImage dataURL графика
 * @param {string} chartName заголовок для pdf-документа
 */
export const generateChartPdf = (chartImage, chartName) => {
  if (!chartImage) {
    return;
  }

  const doc = new JsPDF();

  /** Текст */
  doc.addFileToVFS('PTSans.ttf', font);
  doc.addFont('PTSans.ttf', 'PTSans', 'normal');
  doc.setFont('PTSans');
  doc.setFontSize(20);
  const splitText = doc.splitTextToSize(chartName, 180);
  doc.text(15, 15, splitText);

  /** Картинка */
  const image = new Image();
  image.onload = () => {
    doc.addImage(chartImage, 'PNG', 15, 40, 180, 180 / (image.width / image.height));
    doc.save(`${chartName}.pdf`);
  };

  image.src = chartImage;
};

export const generateRatingPdf = (chartImage, chartName) => {
  if (!chartImage) {
    return;
  }

  const doc = new JsPDF('p', 'pt', 'letter');

  /** Текст */
  doc.addFileToVFS('PTSans.ttf', font);
  doc.addFont('PTSans.ttf', 'PTSans', 'normal');
  doc.setFont('PTSans');
  doc.setFontSize(20);
  const splitText = doc.splitTextToSize(chartName, 580);
  let yPos = 30;
  doc.text(15, yPos, splitText);
  yPos += 20 * splitText.length + 20;

  html2canvas(chartImage).then(canvas => {
    if (chartImage.clientHeight > 980) {
      for (let i = 0; i < parseInt(chartImage.clientHeight / 980, 10); i += 1) {
        const sX = 0;
        const sY = 1120 * i; // start 980 pixels down for every new page
        const sWidth = 778;
        const sHeight = 1120;
        const dX = 0;
        const dY = 0;
        const dWidth = 778;
        const dHeight = i === 0 ? 1000 : 1120;

        window.onePageCanvas = document.createElement('canvas');
        window.onePageCanvas.setAttribute('width', sWidth);
        window.onePageCanvas.setAttribute('height', sHeight);
        const ctx = window.onePageCanvas.getContext('2d');
        ctx.drawImage(canvas, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

        const canvasDataURL = window.onePageCanvas.toDataURL('image/png', 1.0);
        const wd = window.onePageCanvas.width;
        const ht = window.onePageCanvas.height;

        if (i > 0) {
          doc.addPage();
        }
        doc.setPage(i + 1);
        doc.addImage(canvasDataURL, 'PNG', 0, i === 0 ? yPos : 0, wd * 0.72, ht * 0.71);
      }
    } else {
      doc.addImage(
        canvas.toDataURL(),
        'PNG',
        15,
        yPos,
        500,
        500 / (chartImage.clientWidth / chartImage.clientHeight),
      );
    }
    doc.save(`${chartName}.pdf`);
  });
};

/**
 * Генерирует pdf для карты
 * @param {string} mapImage dataURL карты
 * @param {string} mapName заголовок для pdf-документа
 * @param {Array.} mapData данные формата [{name: ..., value: ...}, ...]
 */
export const generateMapPdf = (mapImage, mapName, mapData) => {
  if (!mapImage) {
    return;
  }

  const doc = new JsPDF();
  const pageHeight = doc.internal.pageSize.height;

  /** Заголовок */
  doc.addFileToVFS('PTSans.ttf', font);
  doc.addFont('PTSans.ttf', 'PTSans', 'normal');
  doc.setFont('PTSans');
  doc.setFontSize(20);
  const splitText = doc.splitTextToSize(mapName, 180);
  doc.text(15, 15, splitText);

  /** Данные по карте */
  let yPos = 40;
  doc.setFontSize(12);
  mapData.forEach(item => {
    if (!item.description || !item.value || item.value === '') return;
    const txt = doc.splitTextToSize(`${item.description}: ${item.value}`, 180, { pagesplit: true });
    txt.forEach(str => {
      yPos += 5;
      if (yPos >= pageHeight - 20) {
        doc.addPage();
        yPos = 20;
      }
      doc.text(str, 20, yPos);
    });
    yPos += 5;
  });

  yPos += 10;

  /** Картинка */
  const image = new Image();
  image.onload = () => {
    if (yPos + 180 / (image.width / image.height) >= pageHeight - 20) {
      doc.addPage();
      yPos = 20;
    }
    doc.addImage(mapImage, 'PNG', 20, yPos, 180, 180 / (image.width / image.height));
    doc.save(`${mapName}.pdf`);
  };

  image.src = mapImage;
};

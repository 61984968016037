import {
  CHART_GROUP_DAY,
  CHART_GROUP_WEEK,
  CHART_GROUP_HOUR,
  CHART_GROUP_HALFHOUR,
  MONTHS_SHORT,
  MIXED_INCLUDE,
  MIXED_EXCLUDE,
  INDICATOR_CHARTS_TAB,
} from 'modules/main/constants';
import getTerritoryIds from 'helpers/get-territory-ids';
import zip from 'zip-numbers';

export const isFullscreen = element =>
  (document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement) === element;

export const requestFullscreen = element => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullScreen) {
    element.msRequestFullScreen();
  }
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msCancelFullScreen) {
    document.msCancelFullScreen();
  }
};

export const fetchWrapper = (
  url,
  { method = 'GET', lang = null, csrfToken = null, body = null } = {},
) => {
  if (!url) {
    throw new Error('Url should be specified');
  }
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
  };

  if (process.env.REACT_APP_USERNAME && process.env.REACT_APP_PASSWORD) {
    /* eslint-disable prefer-template */
    headers.Authorization =
      'Basic ' +
      Buffer.from(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD).toString();
    /* eslint-enable prefer-template */
  }

  if (lang) {
    headers['Accept-Language'] = lang;
  }

  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }

  const options = {
    method,
    headers,
    credentials: 'include',
  };

  if (body) {
    options.body = body;
  }

  return fetch(url, options)
    .then(handleFetchErrors)
    .then(handleJSON);
};

export const handleFetchErrors = async res =>
  Promise.resolve({
    ok: res.ok,
    statusText: res.statusText,
    status: res.status,
    response: await res.text(),
  });

export const handleJSON = res => {
  if (res.ok) {
    try {
      return JSON.parse(res.response);
    } catch (e) {
      return res.response;
    }
  } else {
    let toThrow = null;
    try {
      toThrow = JSON.parse(res.response);
    } catch (e) {
      toThrow = Error(res.statusText || res.status);
    }
    throw toThrow;
  }
};

export const formatDate = date => {
  if (date instanceof Date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (day < 10) {
      day = `0${day}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    return `${day}.${month}.${year}`;
  }
  return null;
};

// Получаем куку по имени
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * Декоратор для кэширования первого уровня для аргументов
 * @param func
 * @param argsIndices - массив индексов аргументов, которые нужно кэшировать
 */
export const argumentsCacheDecorator = (func, argsIndices = []) => {
  const cachedArguments = {};
  function inner(...args) {
    const argsCopy = [...args];
    argsIndices.forEach(index => {
      if (argsCopy[index] == null && cachedArguments[index] != null) {
        argsCopy[index] = cachedArguments[index];
      } else if (argsCopy[index] != null) {
        cachedArguments[index] = argsCopy[index];
      }
    });

    return func.apply(this, argsCopy);
  }

  /** Прицеплялем к функции сами аргументы */
  inner.cachedArguments = cachedArguments;

  return inner;
};

export const levels = ['тыс.', 'млн.', 'млрд.', 'трлн.'];

export const formatNumber = (number, digitsCount = 0, precision = 0) => {
  let level = -1;
  let numberToWorkWith = number;
  while (numberToWorkWith / 1000 > 1 || numberToWorkWith / 1000 < -1) {
    numberToWorkWith /= 1000;
    level += 1;
  }

  const unit = levels[level] || '';

  /** The value is lower than 1000 */
  if (level === -1) {
    const value = Math.round(numberToWorkWith * 10 ** precision) / 10 ** precision;
    return `${value.toString().replace('.', ',')}`;
  }

  if (!digitsCount) {
    return `${Math.floor(numberToWorkWith)} ${unit}`;
  }

  const value = Math.round(numberToWorkWith * 10 ** digitsCount) / 10 ** digitsCount;
  return `${value.toString().replace('.', ',')} ${unit}`;
};

/**
 * Функция для получения индекса в разрядности дроби (например, `0.01` вернет 2 )
 * @param {number} number Число
 */
export const getIndexDecimal = number => {
  if (number) {
    const arr = [...number.toString()];
    const separatorIndex = arr.findIndex(separator => separator === '.' || separator === ',');

    if (separatorIndex >= 0) {
      // eslint-disable-next-line eqeqeq
      return arr.slice(separatorIndex + 1).findIndex(i => i != 0) + 1;
    }
    return 0;
  }
  return 0;
};

/**
 * Получаем разрядность дроби (etc, 0.12345 > 0.00001).
 * @param {number | string} number Число
 */
export const getDepthDecimal = number => {
  if (!number && number !== 0) {
    return 0;
  }
  return Number(
    [...number.toString()]
      .map((num, index) => {
        if ([...number.toString()].length - 1 === index) {
          return 1;
        }
        if (num === '.' || num === ',') {
          return '.';
        }
        return 0;
      })
      .join(''),
  );
};

export const debounce = (func, ms) => {
  let timer = null;

  return function inner(...args) {
    const onComplete = () => {
      func.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearInterval(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
};

/**
 * Возвращает дату отформатированную для графика
 * @param date - ISO date
 * @param groupBy [CHART_GROUP_DAY|CHART_GROUP_WEEK|CHART_GROUP_YEAR|CHART_GROUP_HOUR|CHART_GROUP_HALFHOUR] - режим группировки графика
 * @returns {string}
 */
export const generateDate = (date, groupBy) => {
  if (!date) {
    return '';
  }

  const parsedDate = Date.parse(parseDateISOString(date));
  const newDate = new Date(parsedDate);

  if (groupBy === CHART_GROUP_DAY) {
    return `${newDate
      .getDate()
      .toString()
      .padStart(2, '0')} ${MONTHS_SHORT[newDate.getMonth()]} ${newDate.getFullYear()}`;
  }
  if (groupBy === CHART_GROUP_WEEK) {
    return `${getWeekNumber(newDate)
      .toString()
      .padStart(2, '0')} ${newDate.getFullYear()}`;
  }
  if (groupBy === CHART_GROUP_HOUR) {
    const day = newDate
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = MONTHS_SHORT[newDate.getMonth()];
    const hour = newDate
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = newDate
      .getMinutes()
      .toString()
      .padStart(2, '0');
    return `${hour}:${minutes} \n${day} ${month}`;
  }
  if (groupBy === CHART_GROUP_HALFHOUR) {
    const day = newDate
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = MONTHS_SHORT[newDate.getMonth()];
    const hour = newDate
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = newDate
      .getMinutes()
      .toString()
      .padStart(2, '0');
    return `${hour}:${minutes} \n${day} ${month}`;
  }
  return `${MONTHS_SHORT[newDate.getMonth()]} ${newDate.getFullYear()}`;
};

/**
 * Возвращает Date из ISO формата
 * @param date - дата в строковом виде
 * @param timeRange - формат времени (день, неделя, итд)
 * @param withoutTime
 * @return {string}
 */
export const parseDateISOToDate = (date, timeRange = null, withoutTime = false) => {
  if (!timeRange || timeRange !== CHART_GROUP_WEEK) return parseDateISOString(date, withoutTime);
  return parseDateWeekString(date);
};

/** Возвращает дату без учета таймзон
 * @param {string} date - дата в строковом виде
 * @param {boolean} withoutTime - возвращает дату с временем 00:00:00
 * @returns {string}
 */
export const parseDateISOString = (date, withoutTime = false) => {
  if (typeof date !== 'string') {
    if (withoutTime && typeof date.getMonth === 'function') {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    }
    return date;
  }
  const dateString = date.split(/\D+/).map(s => parseInt(s, 10));
  dateString[1] -= 1;
  return new Date(...dateString);
};

/** Возвращает дату без учета таймзон
 * @param {string} date - дата в строковом виде YYYY-WW (номер недели)
 * @param {boolean} withoutTime - возвращает дату с временем 00:00:00
 * @returns {string}
 */
export const parseDateWeekString = date => {
  if (typeof date !== 'string') {
    return date;
  }
  const dateString = date.split(/\D+/).map(s => parseInt(s, 10));
  /** Номер недели преобразовываем в количество дней */
  dateString[1] = dateString[1] * 7 - 1;
  return new Date(dateString[0], null, dateString[1]);
};

/**
 * Возвращает дату отформатированную в DD.MM.YYYY или DD.MM.YYYYTHH:MM:SS
 * @param date - ISO date
 * @param {boolean} withoutTime - возвращает дату без времени
 * @returns {string}
 */
export const generateStringDate = (date, withoutTime = false) => {
  if (!date) {
    return '';
  }

  const parsedDate = Date.parse(parseDateISOString(date));
  const newDate = new Date(parsedDate);

  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const day = newDate
    .getDate()
    .toString()
    .padStart(2, '0');
  const hour = newDate
    .getHours()
    .toString()
    .padStart(2, '0');
  const minutes = newDate
    .getMinutes()
    .toString()
    .padStart(2, '0');
  const seconds = newDate
    .getSeconds()
    .toString()
    .padStart(2, '0');

  return withoutTime
    ? `${day}.${month}.${year}`
    : `${day}.${month}.${year}T${hour}:${minutes}:${seconds}`;
};

/**
 * Возвращает дату отформатированную в MM.YYYY
 * @param month {number} - month index
 * @param year {year} - year in YYYY format
 * @returns {string}
 */
export const generateStringDateFromMonthAndYear = (month, year) => {
  if (month === null || year === null) {
    return '';
  }
  const monthNumber = (month + 1).toString().padStart(2, '0');
  return `${monthNumber}.${year}`;
};

export const generateNumberDate = date => {
  if (!date) {
    return '';
  }
  const parsedDate = Date.parse(date);
  const newDate = new Date(parsedDate);

  const year = newDate.getFullYear();
  const month = newDate.getMonth();

  return [year, month];
};

export const getWeekNumber = date => {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export const forEachPolygon = (maps, singleCoordinates, bounds) => {
  bounds.extend(new maps.package.full.geometry.pixel.Polygon(singleCoordinates).getBounds());
};

export const compressIds = ids => zip.encode(ids, 2);

export const parseIds = string => zip.decode(string);

export const getNoun = (number, one, two, five) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

export const throttleMapBoxEvent = (func, ms) => {
  let savedThis;
  let savedArgs;
  let savedFeatures;

  let isPaused = false;

  return function wrapper(...args) {
    if (isPaused) {
      savedThis = this;
      savedArgs = args;
      if (args[0].features) {
        savedFeatures = [...args[0].features];
      }
      return;
    }

    isPaused = true;
    func.apply(this, args);

    setTimeout(() => {
      isPaused = false;
      if (savedArgs) {
        wrapper.apply(savedThis, [
          {
            ...savedArgs[0],
            features: savedFeatures,
          },
        ]);
        savedThis = null;
        savedArgs = null;
        savedFeatures = null;
      }
    }, ms);
  };
};

/** Парсим location в поисках значений мер */
export const getMeasuresValuesFromURL = () => {
  const url = new window.URL(window.location.toString());
  const { searchParams } = url;
  const measuresValuesTerritories = {};

  searchParams.forEach((value, key) => {
    if (key.indexOf('[') > -1) {
      const parsed = key.split('[');
      const [featureKey] = parsed;
      const measureKey = parseInt(parsed[1].replace(']'), 10);

      /** Measures values */
      if (featureKey === 'mv') {
        measuresValuesTerritories[measureKey] = parseInt(value, 10);
      }

      if (value.includes(',')) {
        const values = value.split(',');

        measuresValuesTerritories[measureKey] = [];
        values.forEach((parsedValue, index) => {
          measuresValuesTerritories[measureKey][index] = parseInt(parsedValue, 10);
        });
      }
    }
  });

  return measuresValuesTerritories;
};

/** Парсим location в поисках mapLevel */
export const getMapLevelFromURL = () => {
  const url = new window.URL(window.location.toString());
  const { searchParams } = url;

  if (searchParams.has('mapLevel')) {
    return +searchParams.get('mapLevel');
  }

  return 0;
};

/** Устанавливает новый локэйшн */
export const setLocation = (name, nextLocation, allowedParams = []) => {
  const url = new window.URL(window.location.toString());
  url.pathname = nextLocation;

  const toDelete = [];
  url.searchParams.forEach((value, key) => {
    if (!allowedParams.includes(key)) {
      toDelete.push(key);
    }
  });

  toDelete.forEach(item => {
    url.searchParams.delete(item);
  });

  window.history.replaceState(null, name, nextLocation);
};

export const setParentCheckboxes = (parents, region) => {
  /** При мердже нужно поставить парентам правильные checked */
  /* eslint-disable no-param-reassign */
  const children = Object.values(parents).filter(
    item =>
      item.parentId === region.id ||
      (Array.isArray(item.parent_ids) && item.parent_ids.includes(region.id)),
  );

  if (!children.length) {
    return;
  }

  /** В этой ветке ставим чекбокс в MIXED режим */
  if (
    children.some(item => item.checked === MIXED_INCLUDE || item.checked === MIXED_EXCLUDE) ||
    (!children.every(item => item.checked === true) &&
      !children.every(item => item.checked === false || item.checked === undefined))
  ) {
    if (region.checked !== MIXED_EXCLUDE && region.checked !== true) {
      region.checked = MIXED_INCLUDE;
    } else if (region.checked === true) {
      region.checked = MIXED_EXCLUDE;
    }
  } else if (children.every(item => item.checked === true)) {
    region.checked = region.children_count === children.length ? true : MIXED_INCLUDE;
  } else if (children.every(item => item.checked === false || item.checked === undefined)) {
    region.checked = false;
  }
  /* eslint-enable no-param-reassign */
};

/**
 * Возвращает из списка текущих объектов из категорий максимальное и минимальное значения по idMeasure
 * @param currentObjects
 * @param idMeasure
 * @return {{min: number, max: number}}
 */
export const getMaxMinValues = (currentObjects, idMeasure) => {
  const objectsWithMeasures = currentObjects
    .map(obj =>
      obj.values.find(measure => measure.id === idMeasure)
        ? obj.values.find(measure => measure.id === idMeasure).value
        : null,
    )
    .filter(item => item !== null);

  if (objectsWithMeasures.length === 0) return { max: null, min: null };

  const maxV = Math.max.apply(null, objectsWithMeasures);
  const minV = Math.min.apply(null, objectsWithMeasures);

  return { max: maxV, min: minV };
};

export const updateFactsForTerritoriesFilters = (
  indicator = null,
  newMeasuresValues,
  newTerritoriesFiltersValues,
  namespace,
  currentIndicator,
  currentMapLevelId,
  polygons,
  getFactsMemo,
  getPolygons,
  dispatch,
) => {
  let queryString = '';
  /** Если строим графики, не принимаем measures (только ter_measures), чтобы карта не перестраивалась  */
  if (namespace !== INDICATOR_CHARTS_TAB) {
    queryString = Object.keys(newMeasuresValues[namespace]).reduce((acc, curItem) => {
      /** Select */
      if (newMeasuresValues[namespace][curItem].value) {
        return `${acc}measures[${curItem}]=${newMeasuresValues[namespace][curItem].value}&`;
      }
      /**
       * Multiselect
       */
      if (
        newMeasuresValues[namespace][curItem][0] &&
        newMeasuresValues[namespace][curItem][0].value
      ) {
        let query = `${acc}measures[${curItem}]=${newMeasuresValues[namespace][curItem][0].value}`;
        Object.values(newMeasuresValues[namespace][curItem]).forEach((item, index) => {
          if (index === 0) return;
          query += `,${item.value}`;
        });
        query += '&';
        return query;
      }

      /** Checkboxes */
      const checkValues = Object.keys(newMeasuresValues[namespace][curItem])
        .filter(key => newMeasuresValues[namespace][curItem][key])
        .join(',');

      if (checkValues) {
        return `${acc}measures[${curItem}]=${checkValues}&`;
      }

      return acc;
    }, '');
  }

  if (newTerritoriesFiltersValues) {
    const updateQueryStringWithTerFilters = (terValues, paramName) => {
      /** Сверяем значение с текущим индикатором */
      Object.keys(terValues)
        .filter(value => (indicator ? indicator.ter_measures.some(i => i.id === value) : value))
        .forEach(measureId => {
          const values = Object.keys(terValues[measureId]).map(valueId => ({
            ...terValues[measureId][valueId],
            id: valueId,
          }));

          const selectedValues = values.filter(value => value.selected).map(value => value.id);

          if (selectedValues.length === 0) {
            return;
          }

          queryString = `${queryString}${paramName}[${measureId}]=${selectedValues.join(',')}&`;
        });
    };

    /** Левая карта */
    updateQueryStringWithTerFilters(newTerritoriesFiltersValues.a, 'ter_measures');

    /** Правая карта */
    updateQueryStringWithTerFilters(newTerritoriesFiltersValues.b, 'to_ter_measures');
  }

  const dualIndicator = indicator ? indicator.has_second_map : currentIndicator.has_second_map;
  const indicatorId = indicator ? indicator.id : currentIndicator.id;

  dispatch(getFactsMemo(dualIndicator, currentMapLevelId, indicatorId, queryString))
    .then(res => {
      /**
       * Получение полигонов по территориям,
       * которые пришли в фактах (мерах)
       */
      const territoryIds = getTerritoryIds(res, polygons);
      if (territoryIds.length) {
        return dispatch(getPolygons(territoryIds)).then();
      }

      return null;
    })
    .catch(() => {});
};

/**
 * Генерирует дату в нужной строке
 * @param dataRanges
 * @param localPeriodFilter
 * @returns {string}
 */
export const generateDateStringPeriod = (dataRanges, localPeriodFilter) => {
  if (dataRanges !== 'monthly') {
    if (localPeriodFilter.dateStart && localPeriodFilter.dateEnd)
      return `${generateStringDate(localPeriodFilter.dateStart, true)} — ${generateStringDate(
        localPeriodFilter.dateEnd,
        true,
      )}`;
  }
  if (
    localPeriodFilter.monthStart !== null &&
    localPeriodFilter.yearStart !== null &&
    localPeriodFilter.monthEnd !== null &&
    localPeriodFilter.yearEnd !== null
  )
    return `${generateStringDateFromMonthAndYear(
      localPeriodFilter.monthStart,
      localPeriodFilter.yearStart,
    )} — ${generateStringDateFromMonthAndYear(
      localPeriodFilter.monthEnd,
      localPeriodFilter.yearEnd,
    )}`;
  return null;
};

/**
 * Загружает объект по uri
 * @param {string} uri
 * @param {string} name
 */
export const downloadURI = (uri, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFactsQueryString = values => {
  const queryString = Object.keys(values).reduce((acc, curItem) => {
    /** Select */
    if (values[curItem].value) {
      return `${acc}measures[${curItem}]=${values[curItem].value}&`;
    }
    /**
     * Multiselect
     */
    if (values[curItem][0] && values[curItem][0].value) {
      let query = `${acc}measures[${curItem}]=${values[curItem][0].value}`;
      Object.values(values[curItem]).forEach((item, index) => {
        if (index === 0) return;
        query += `,${item.value}`;
      });
      query += '&';
      return query;
    }

    /** Checkboxes */
    const checkValues = Object.keys(values[curItem])
      .filter(key => values[curItem][key])
      .join(',');

    if (checkValues) {
      return `${acc}measures[${curItem}]=${checkValues}&`;
    }

    return acc;
  }, '');
  return queryString;
};

import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import InputRange from 'react-input-range';

// Actions
import { changeMeasureValue } from 'modules/main/actions';

// Misc
import { CHECKBOXES, MULTISELECT, NUMBER, RADIO, SELECT, PIE } from 'modules/main/constants';

// Icons
import { ReactComponent as IconRadio } from 'assets/icons/icon-radio.svg';

// Styles
import { selectStyles } from 'assets/styles/select';

// Components
import { MultiSelect } from '../MultiSelect';

export const Measures = ({
  className,
  measuresValues,
  dispatch,
  namespace,
  measures,
  handleChangeMeasuresValues,
}) => {
  const toggleCheckbox = (event, optionsObject, optionId) => {
    const { target } = event;
    const checked = target.getAttribute('aria-checked');
    const value = checked !== 'true';

    dispatch(
      changeMeasureValue(
        optionsObject.id,
        {
          optionId,
          value,
        },
        namespace,
        CHECKBOXES,
      ),
    ).then(newMeasuresValues => {
      if (typeof handleChangeMeasuresValues === 'function') {
        handleChangeMeasuresValues(newMeasuresValues);
      }
    });
  };

  const renderSelect = item => {
    const { name, values } = item;

    if (!values || !measuresValues || !measuresValues[namespace]) {
      return null;
    }

    return (
      <div className="indicators__input-holder" key={`${item.id}`}>
        <p className="indicators__input-name">{name}</p>
        {/* Если мультиселектор сменился на селектор, дефолтное значение берется нулевое */}
        <Select
          value={
            typeof measuresValues[namespace][item.id] === 'object' &&
            measuresValues[namespace][item.id][0]
              ? measuresValues[namespace][item.id][0]
              : measuresValues[namespace][item.id]
          }
          styles={selectStyles}
          classNamePrefix="indicators-select"
          options={values.map(({ id: value, name: label }) => ({ value, label }))}
          onChange={value => {
            dispatch(changeMeasureValue(item.id, value, namespace, SELECT)).then(
              newMeasuresValues => {
                if (typeof handleChangeMeasuresValues === 'function') {
                  handleChangeMeasuresValues(newMeasuresValues);
                }
              },
            );
          }}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          isSearchable={false}
        />
      </div>
    );
  };

  const renderMultiSelect = item => {
    const { name, values } = item;

    if (!values || !measuresValues || !measuresValues[namespace]) {
      return null;
    }

    return (
      <div className="indicators__input-holder" key={`${item.id}`}>
        <p className="indicators__input-name">{name}</p>
        <MultiSelect
          value={measuresValues[namespace][item.id]}
          options={values.map(({ id: value, name: label }) => ({ value, label }))}
          placeholder={name}
          handleChange={value => {
            dispatch(changeMeasureValue(item.id, value, namespace, MULTISELECT)).then(
              newMeasuresValues => {
                if (typeof handleChangeMeasuresValues === 'function') {
                  handleChangeMeasuresValues(newMeasuresValues);
                }
              },
            );
          }}
        />
      </div>
    );
  };

  const renderCheckboxes = item => {
    const { name, values } = item;

    if (!values || !measuresValues || !measuresValues[namespace]) {
      return null;
    }

    return (
      <div className="indicators__input-holder" key={item.id}>
        <p className="indicators__input-name">{name}</p>
        <div className="indicators__checkboxes">
          {values.map(({ id, name: checkboxName }) => (
            <label className="indicators__checkbox-item" key={id}>
              <button
                type="button"
                role="checkbox"
                aria-checked={
                  (measuresValues[namespace][item.id] && measuresValues[namespace][item.id][id]) ||
                  false
                }
                onClick={event => toggleCheckbox(event, item, id)}
                className="indicators__checkbox"
              />
              <span className="indicators__checkbox-name">{checkboxName}</span>
            </label>
          ))}
        </div>
      </div>
    );
  };

  const renderRadio = item => {
    const { name, values } = item;

    if (!values || !measuresValues || !measuresValues[namespace]) {
      return null;
    }

    return (
      <div className="indicators__input-holder" key={item.id}>
        <p className="indicators__input-name">{name}</p>
        <div className="indicators__radio">
          {values.map(({ id: value, name: label }) => (
            <label className="indicators__radio-item" key={value}>
              <input
                className="indicators__radio-input"
                type="radio"
                id={value}
                name={`check-${item.id}`}
                checked={
                  (measuresValues[namespace][item.id] &&
                    measuresValues[namespace][item.id].value) === value
                }
                onChange={() => {
                  dispatch(changeMeasureValue(item.id, { value, label }, namespace, RADIO)).then(
                    newMeasuresValues => {
                      if (typeof handleChangeMeasuresValues === 'function') {
                        handleChangeMeasuresValues(newMeasuresValues, RADIO);
                      }
                    },
                  );
                }}
              />
              <IconRadio className="indicators__radio-icon" />
              <span className="indicators__radio-name">{label}</span>
            </label>
          ))}
        </div>
      </div>
    );
  };

  const renderNumber = item => {
    const { name, values } = item;
    if (!values) {
      return null;
    }
    const { min, max } = values;
    return (
      <div className="indicators__input-holder" key={item.id}>
        <p className="indicators__input-name">{name}</p>
        <div className="indicators__range">
          <InputRange maxValue={max} minValue={min} value={{ max, min }} onChange={() => {}} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={cx('indicators__form', {
        [className]: className,
      })}
    >
      {Array.isArray(measures) &&
        measures.map((item, index) => {
          const { data_type: dataType } = item;
          switch (dataType) {
            case SELECT:
              if (item.select_prop) {
                return renderMultiSelect(item, index);
              }
              return renderSelect(item, index);
            case NUMBER:
              return renderNumber(item);
            case CHECKBOXES:
              return renderCheckboxes(item);
            case PIE:
              return renderCheckboxes(item);
            case RADIO:
              return renderRadio(item);
            default:
              return null;
          }
        })}
    </div>
  );
};

Measures.propTypes = {
  className: PropTypes.string,
  measuresValues: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  namespace: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChangeMeasuresValues: PropTypes.func,
  measures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      data_type: PropTypes.string,
      select_prop: PropTypes.bool,
    }),
  ).isRequired,
};

Measures.defaultProps = {
  className: null,
  handleChangeMeasuresValues: null,
};

const mapStateToProps = state => ({
  measuresValues: state.main.measuresValues,
});

export default connect(mapStateToProps)(Measures);

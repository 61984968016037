import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AuthWindow from 'modules/main/components/AuthWindow';
import AuthHeader from 'modules/main/components/AuthHeader';
import EmailInput from 'modules/main/components/AuthFields/EmailInput';
import AuthButtons from 'modules/main/components/AuthButtons';

// Actions
import { resetPassword } from 'modules/main/actions';

// Icons
import { ReactComponent as IconBackToAuth } from 'assets/icons/icon-back-to-auth.svg';
import { ReactComponent as IconSuccess } from 'assets/icons/icon-success.svg';

// Misc
import { INPUT_EMAIL_PATTERN } from 'modules/main/constants';
import { debounce } from 'helpers';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const AuthPasswordReset = ({ dispatch, closeHandler }) => {
  const [email, setEmail] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [invalidEmailTip, setInvalidEmailTip] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  /** Переводы */
  const langOb = useLang('AuthPasswordReset');

  const onEmailChange = event => {
    const {
      target: { value },
    } = event;

    setEmail(value);
    setIsInvalidEmail(!INPUT_EMAIL_PATTERN.test(value));
    setInvalidEmailTip(false);

    debouncedSetEmailError.current(value);
  };

  const setEmailError = value => {
    setInvalidEmailTip(!INPUT_EMAIL_PATTERN.test(value));
  };

  const debouncedSetEmailError = useRef(debounce(setEmailError, 700));

  const sendNewPassword = () => {
    if (!email || isInvalidEmail || isFetching) {
      return;
    }

    setFieldErrors(null);
    setIsFetching(true);

    dispatch(resetPassword(email))
      .then(() => {
        setIsSent(true);
        setIsFetching(false);
      })
      .catch(err => {
        if (err instanceof Error) {
          setError(err.message);
          setIsFetching(false);
        } else if (Object.keys(err).length) {
          setFieldErrors(err);
          setIsFetching(false);
        }
      });
  };

  if (!langOb) {
    return null;
  }

  return (
    <AuthWindow>
      {!isSent ? (
        <div data-testid="password-reset-form">
          <button
            type="button"
            className="auth__back-to-auth"
            onClick={closeHandler}
            aria-label={langOb.goBack}
          >
            <IconBackToAuth className="auth__back-to-auth-icon" />
          </button>

          <AuthHeader name={langOb.title} description={langOb.description} />

          <div className="auth__input-holder">
            <EmailInput
              name={langOb.emailName}
              onEmailChange={onEmailChange}
              invalidEmail={invalidEmailTip}
              value={email}
              submitForm={sendNewPassword}
              invalidEmailText={langOb.invalidEmailText}
            />
            {fieldErrors && fieldErrors.user && (
              <p className="auth__error-text auth__error-text_red">{fieldErrors.user[0]}</p>
            )}
          </div>

          {error && <p className="auth__error-text auth__error-text_red">{error}</p>}

          <AuthButtons
            actionButtonName={langOb.actionButtonText}
            actionButtonClick={sendNewPassword}
            disabled={!email || isInvalidEmail || isFetching}
          />
        </div>
      ) : (
        <React.Fragment>
          <IconSuccess className="auth__success-icon" />
          <h3 className="auth__success-title">{langOb.successTitle}</h3>
          <p className="auth__success-text">{langOb.successText}</p>
          <div className="auth__success-link-container">
            <button type="button" onClick={closeHandler} className="auth__success-link">
              {langOb.gotoAuth}
            </button>
          </div>
        </React.Fragment>
      )}
    </AuthWindow>
  );
};

AuthPasswordReset.propTypes = {
  dispatch: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default connect()(AuthPasswordReset);

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// components
import InputHintPointer from 'modules/main/components/InputHintPointer';

// icons
import { ReactComponent as IconValidationFailed } from 'assets/icons/icon-validation-failed.svg';

// Мне это не нравится, потому что два одинаковых набора стилей экспортируем в два разных файла
// А если делал бы универсально, то, мне кажется, компонент выглядел ужасно
// TODO: обсудить
import './assets/styles/styles.scss';

const EmailInput = props => {
  const { invalidEmail, name, onEmailChange, value, submitForm, invalidEmailText } = props;

  return (
    <div className="auth__mail">
      <p className="auth__mail-description">{name}</p>
      <div className="auth__mail-input-wrapper">
        <input
          name="email"
          className={cx('auth__mail-input', {
            'auth__mail-input-error': invalidEmail,
          })}
          placeholder="mail@example.com"
          onChange={onEmailChange}
          required
          value={value}
          onKeyUp={e => {
            if (submitForm && e.key === 'Enter' && !invalidEmail) {
              submitForm();
            }
          }}
        />
        {invalidEmail && (
          <React.Fragment>
            <IconValidationFailed className="auth__mail-validation-failed" />
            {invalidEmailText && (
              <InputHintPointer className="auth__mail-pointer" text={invalidEmailText} />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

EmailInput.propTypes = {
  invalidEmail: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func,
  value: PropTypes.string,
  invalidEmailText: PropTypes.string,
};

EmailInput.defaultProps = {
  invalidEmail: false,
  submitForm: null,
  value: '',
  invalidEmailText: null,
};

export default EmailInput;

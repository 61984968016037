import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import zoomControls from 'modules/main/components/ZoomControls';
import { YMaps, Map as YMap, ZoomControl, Placemark } from 'react-yandex-maps';

// Actions
import { getContactsPage } from 'modules/main/actions';

// Misc
import useLang from 'hooks/useLang';

// Icons
import { ReactComponent as IconMarker } from 'assets/icons/icon-marker.svg';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';
import { ReactComponent as IconMail } from 'assets/icons/icon-mail.svg';
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';

// Images
import logo from './assets/images/contacts-marker.png';

import './assets/styles/styles.scss';

export const Contacts = ({ staticPages, lang, dispatch }) => {
  /** Переводы */
  const langOb = useLang('Contacts');

  const [state, setState] = useState({
    ymaps: null,
    zoomControlLayout: null,
  });

  const [content, setContent] = useState(null);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (staticPages && staticPages.contacts) {
      setContent(staticPages.contacts);
    } else {
      setIsFetching(true);
      dispatch(getContactsPage())
        .then(res => {
          setContent(res);
          setIsFetching(false);
        })
        .catch(() => {
          setContent({
            content: langOb ? langOb.pageNotFound : '',
          });
          setIsFetching(false);
        });
    }
  }, []);

  const onMapLoaded = res => {
    setState({
      ymaps: res,
      /** Строим зум контрол */
      zoomControlLayout: zoomControls(res, { className: 'map__zoom-tools' }),
    });
  };

  return (
    <div className="contacts">
      <YMaps
        query={{ lang: lang.full, apikey: process.env.REACT_APP_YANDEX_MAPS_API_KEY }}
        version="2.1.73"
      >
        <YMap
          state={{
            center: content ? content.point : [55.74556, 37.624574],
            zoom: 12,
            controls: [],
          }}
          className="map__yandex-map"
          onLoad={onMapLoaded}
          modules={['package.full']}
        >
          {state.zoomControlLayout && (
            <ZoomControl
              options={{ layout: state.zoomControlLayout, position: { bottom: 40, right: 20 } }}
            />
          )}

          {content && content.point && (
            <Placemark
              geometry={content.point}
              defaultOptions={{
                iconLayout: 'default#image',
                iconImageHref: logo,
                iconImageSize: [40, 40],
                iconImageOffset: [-20, -20],
              }}
            />
          )}
        </YMap>
      </YMaps>

      <div className="contacts__info">
        {content && (
          <React.Fragment>
            <h2 className="contacts__info-title">{content.title}</h2>
            <p className="contacts__company-name">{content.company_name}</p>
            <p className="contacts__info-row">
              <IconMarker className="contacts__address-icon" />
              {content.address}
            </p>
            <p className="contacts__info-row">
              <IconPhone className="contacts__phone-icon" />
              {content.phone}
            </p>
            <p className="contacts__info-row">
              <IconMail className="contacts__mail-icon" />
              {content.email}
            </p>
          </React.Fragment>
        )}

        {isFetching && (
          <div className="contacts__loading" data-testid="contacts-loading">
            <IconLoader className="contacts__loading-icon" />
          </div>
        )}
      </div>
    </div>
  );
};

Contacts.propTypes = {
  staticPages: PropTypes.shape({
    contacts: PropTypes.shape({
      company_name: PropTypes.string,
      address: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      point: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  lang: PropTypes.shape({
    short: PropTypes.string,
    full: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  staticPages: state.main.staticPages,
  lang: state.main.lang,
});

export default connect(mapStateToProps)(Contacts);

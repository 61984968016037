/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Chart } from 'react-google-charts';

// Misc
import { generateDate } from 'helpers';
import {
  CHART_GROUP_DAY,
  CHART_GROUP_MONTH,
  CHART_GROUP_WEEK,
  CHART_GROUP_HOUR,
  CHART_GROUP_HALFHOUR,
  FACT_UNIT_NAMES,
} from 'modules/main/constants';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

const colors = ['#4a90e2', '#D0DCEE', '#D97908', '#f1d6a1'];

const Charts = ({
  chartFacts,
  chartPieFacts,
  currentChartIndicator,
  groupBy,
  className,
  withTitle,
  height,
  setImageURI,
  isAnnotation,
}) => {
  const [wrapper, setWrapper] = useState(null);
  const [headerData, setHeaderData] = useState([]);

  const chartViews = {
    bar: 'ColumnChart',
    line: 'LineChart',
    pie: 'PieChart',
    'measure-bar': 'ColumnChart',
    'measure-line': 'LineChart',
  };

  const chartViewByOneMeasure =
    currentChartIndicator.chart_view === 'measure-bar' ||
    currentChartIndicator.chart_view === 'measure-line';

  const facts = chartViewByOneMeasure ? chartPieFacts : chartFacts;

  const precision =
    facts.unit_name === FACT_UNIT_NAMES.percent
      ? currentChartIndicator.val_precision_percent
      : currentChartIndicator.val_precision;

  const getFloatValue = value =>
    Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision);

  const isAnnotationChart =
    isAnnotation && chartViews[currentChartIndicator.chart_view] !== chartViews.pie;

  /** Переводы */
  const langOb = useLang('Charts');

  useEffect(() => {
    if (chartFacts && chartFacts.headers && langOb) {
      const headerArray = [];
      chartFacts.headers.map(
        header => (
          headerArray.push({
            label: `${header}, ${chartFacts.unit_name}`,
            type: 'number',
          }),
          headerArray.push({
            type: 'number',
            role: isAnnotationChart ? 'annotation' : 'number',
          })
        ),
      );
      setHeaderData(headerArray);
    }
  }, [langOb, chartFacts, isAnnotationChart]);

  useEffect(() => {
    if (wrapper) {
      const chart = wrapper.getChart();

      if (chart && typeof chart.getImageURI === 'function') {
        setImageURI(chart.getImageURI());
      }
    }
  }, [headerData, wrapper, chartFacts, currentChartIndicator, groupBy, isAnnotationChart]);

  let columnsCount = 1;
  if (!chartViewByOneMeasure && chartFacts && chartFacts.data && chartFacts.data.length) {
    columnsCount = chartFacts.data[0].agg_value.length;
  }
  if (chartViewByOneMeasure && chartPieFacts && chartPieFacts.data && chartPieFacts.data.length) {
    columnsCount = chartPieFacts.data.length;
  }

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('charts', {
        [className]: className,
      })}
    >
      {withTitle && (
        <h3 className="charts__title" data-testid="charts-title">
          {currentChartIndicator.description}
        </h3>
      )}

      <div className="charts__chart-itself">
        {(chartViewByOneMeasure ? (
          chartPieFacts && chartPieFacts.data && chartPieFacts.data.length
        ) : (
          chartFacts &&
          chartFacts.data &&
          chartFacts.data.map(item => item.agg_value).some(item => item)
        )) ? (
          <Chart
            chartType={chartViews[currentChartIndicator.chart_view]}
            chartLanguage="ru"
            data={
              chartViewByOneMeasure
                ? [
                    [currentChartIndicator.name, chartPieFacts.unit_name],
                    ...chartPieFacts.data.map(item => [item.header, getFloatValue(item.value)]),
                  ]
                : [
                    [langOb.date, ...headerData],
                    ...chartFacts.data
                      .map(item => {
                        const aggValueWithoutNull = item.agg_value;
                        if (aggValueWithoutNull.length === 0) {
                          return null;
                        }

                        const rowArray = [];
                        item.agg_value.map(
                          value => (
                            rowArray.push(getFloatValue(value)), rowArray.push(getFloatValue(value))
                          ),
                        );

                        return [generateDate(item.time_group, groupBy), ...rowArray];
                      })
                      .filter(item => item !== null),
                  ]
            }
            options={{
              legend: 'bottom',
              chartArea: { width: '79%' },
              colors: colors.slice(0, columnsCount),
              vAxis: {
                format: 'short',
              },
              annotations: {
                alwaysOutside: true,
                textStyle: {
                  fontSize: 14,
                  color: '#000000',
                },
                style:
                  chartViews[currentChartIndicator.chart_view] === chartViews.line &&
                  (chartViewByOneMeasure
                    ? chartPieFacts.data.some(value => value && value.toString().length > 5)
                    : chartFacts.data.some(data =>
                        data.agg_value.some(value => value && value.toString().length > 5),
                      ))
                    ? 'line'
                    : 'point',
              },
            }}
            width="100%"
            height={height}
            getChartWrapper={chartWrapper => {
              setWrapper(chartWrapper);
            }}
          />
        ) : (
          <p className="charts__chart-no-data">{langOb.noData}</p>
        )}
      </div>
    </div>
  );
};

Charts.propTypes = {
  chartFacts: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string),
    unit_name: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        time_group: PropTypes.string,
        agg_value: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  }).isRequired,
  currentChartIndicator: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    unit_name: PropTypes.string,
    chart_view: PropTypes.string,
    val_precision: PropTypes.number,
    val_precision_percent: PropTypes.number,
  }).isRequired,
  chartPieFacts: PropTypes.shape({
    unit_name: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  groupBy: PropTypes.oneOf([
    CHART_GROUP_DAY,
    CHART_GROUP_WEEK,
    CHART_GROUP_MONTH,
    CHART_GROUP_HOUR,
    CHART_GROUP_HALFHOUR,
  ]).isRequired,
  className: PropTypes.string,
  withTitle: PropTypes.bool,
  height: PropTypes.string,
  setImageURI: PropTypes.func.isRequired,
  isAnnotation: PropTypes.bool,
};

Charts.defaultProps = {
  className: null,
  withTitle: false,
  height: '300px',
  isContinuousRange: false,
};

const mapStateToProps = state => ({
  chartFacts: state.main.chartFacts,
  chartPieFacts: state.main.chartPieFacts,
  currentChartIndicator: state.main.currentChartIndicator,
});

export default connect(mapStateToProps)(Charts);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Components
import ToggleControl from 'shared/ToggleControl';
import TerritoriesMeasures from 'modules/main/components/TerritoriesMeasures';
import PeriodSelectTrigger from 'modules/main/components/PeriodSelectTrigger';
import TerritoriesFilters from 'modules/main/components/TerritoriesFilters';

// Actions
import {
  toggleIndicatorTab,
  selectIndicator,
  getFactsMemo,
  getPolygons,
  setURLParams,
  resetCurrentPeriodFilter,
  getObjectsForIndicator,
  setMeasuresValues,
} from 'modules/main/actions';

// Misc
import { INDICATOR_TERRITORY_TAB, INDICATOR_OBJECTS_TAB } from 'modules/main/constants';
import { setLocation, updateFactsForTerritoriesFilters, getFactsQueryString } from 'helpers';
import getTerritoryIds from 'helpers/get-territory-ids';
import useLang from 'hooks/useLang';

// Styles
import { selectStyles } from 'assets/styles/select';

const IndicatorsTerritory = ({
  dispatch,
  currentIndicatorsGroup,
  currentIndicator,
  currentMapLevelId,
  measuresValues,
  polygons,
  enabled,
  indicators,
  territoriesFiltersValues,
}) => {
  /** Переводы */
  const langOb = useLang('IndicatorsTerritory');

  const toggleEnable = () => {
    dispatch(toggleIndicatorTab(INDICATOR_TERRITORY_TAB));
    dispatch(setURLParams({ [`indicatorTabsEnabled[${INDICATOR_TERRITORY_TAB}]`]: enabled }));
  };

  const handleChangeIndicator = option => {
    if (option.id === currentIndicator.id) {
      return;
    }
    if (currentIndicatorsGroup) {
      /** Update location */
      const allowedParams = ['mapLevel', 'currentIndicatorTab'];
      setLocation(
        `${currentIndicatorsGroup.name}_${option.label}`,
        `/${currentIndicatorsGroup.id}/${option.value}`,
        allowedParams,
      );
    }

    /** почему-то стейт не всегда приходит, поэтому двойной диспатч */
    /** TODO: Разобраться почему стейт не приходит вовремя */
    dispatch(selectIndicator(option));

    setTimeout(() => {
      dispatch(selectIndicator(option)).then(newMeasuresValues => {
        setTimeout(() => {
          /** Получение фактов (мер) */
          updateFacts(option, newMeasuresValues);

          /** Обновление списка выбранных объектов */
          updateObjects(option);
        }, 0);
      });
    }, 0);
  };

  /**
   * Получение фактов (мер)
   */
  const updateFacts = (
    indicator = null,
    newMeasuresValues = measuresValues,
    newTerritoriesFiltersValues = territoriesFiltersValues,
  ) => {
    let queryString = getFactsQueryString(newMeasuresValues[INDICATOR_TERRITORY_TAB]);

    if (newTerritoriesFiltersValues) {
      const updateQueryStringWithTerFilters = (terValues, paramName) => {
        /** Сверяем значение с текущим индикатором */
        Object.keys(terValues)
          .filter(value => (indicator ? indicator.ter_measures.some(i => i.id === value) : value))
          .forEach(measureId => {
            const values = Object.keys(terValues[measureId]).map(valueId => ({
              ...terValues[measureId][valueId],
              id: valueId,
            }));

            const selectedValues = values.filter(value => value.selected).map(value => value.id);

            if (selectedValues.length === 0) {
              return;
            }

            queryString = `${queryString}${paramName}[${measureId}]=${selectedValues.join(',')}&`;
          });
      };

      /** Левая карта */
      updateQueryStringWithTerFilters(newTerritoriesFiltersValues.a, 'ter_measures');

      /** Правая карта */
      updateQueryStringWithTerFilters(newTerritoriesFiltersValues.b, 'to_ter_measures');
    }

    const dualIndicator = indicator ? indicator.has_second_map : currentIndicator.has_second_map;
    const indicatorId = indicator ? indicator.id : currentIndicator.id;

    dispatch(getFactsMemo(dualIndicator, currentMapLevelId, indicatorId, queryString))
      .then(res => {
        /**
         * Получение полигонов по территориям,
         * которые пришли в фактах (мерах)
         */
        const territoryIds = getTerritoryIds(res, polygons);
        if (territoryIds.length) {
          return dispatch(getPolygons(territoryIds)).then();
        }

        return null;
      })
      .catch(() => {});
  };

  const updateObjects = options => {
    const newObjectsMeasuresValues = { ...measuresValues[INDICATOR_OBJECTS_TAB] };
    Object.keys(newObjectsMeasuresValues).forEach(measureId => {
      if (!options.objects.find(obj => obj.id === measureId)) {
        newObjectsMeasuresValues[measureId].selected = false;
      }
    });

    dispatch(setMeasuresValues(newObjectsMeasuresValues, INDICATOR_OBJECTS_TAB));
  };

  const applyPeriod = () => {
    dispatch(getFactsMemo(currentIndicator.has_second_map, currentMapLevelId))
      .then(res => {
        /**
         * Получение полигонов по территориям,
         * которые пришли в фактах (мерах)
         */
        const territoryIds = getTerritoryIds(res, polygons);
        if (territoryIds.length) {
          return dispatch(getPolygons(territoryIds));
        }

        return null;
      })
      .catch(() => {});
  };

  const resetPeriod = () => {
    dispatch(resetCurrentPeriodFilter(INDICATOR_TERRITORY_TAB));
    dispatch(getFactsMemo())
      .then(res => {
        /**
         * Получение полигонов по территориям,
         * которые пришли в фактах (мерах)
         */
        const territoryIds = getTerritoryIds(res, polygons);
        if (territoryIds.length) {
          return dispatch(getPolygons(territoryIds));
        }

        return null;
      })
      .catch(() => {});
  };

  const handleChangeMeasuresValues = newMeasuresValues => {
    updateFacts(null, newMeasuresValues);

    /** Записываем значения мер в урл */

    if (newMeasuresValues && newMeasuresValues[INDICATOR_TERRITORY_TAB]) {
      const urlParams = Object.keys(newMeasuresValues[INDICATOR_TERRITORY_TAB]).reduce(
        (acc, curKey) => {
          const values = newMeasuresValues[INDICATOR_TERRITORY_TAB][curKey];
          acc[`mv[${curKey}]`] = Array.isArray(values)
            ? values.map(({ value }) => value).join(',')
            : values.value;

          if (!acc[`mv[${curKey}]`]) {
            acc[`mv[${curKey}]`] = Object.keys(values)
              .filter(key => values[key])
              .join(',');
          }

          return acc;
        },
        {},
      );

      dispatch(setURLParams(urlParams));
    }
  };

  const handleChangeTerritoriesFiltersValues = newTerritoriesFiltersValues => {
    updateFactsForTerritoriesFilters(
      null,
      measuresValues || undefined,
      newTerritoriesFiltersValues || territoriesFiltersValues,
      INDICATOR_TERRITORY_TAB,
      currentIndicator,
      currentMapLevelId,
      polygons,
      getFactsMemo,
      getPolygons,
      dispatch,
    );

    /** Перезагружаем все выбранные объекты (если такие есть) */
    if (measuresValues.objects) {
      const selectedObjects = Object.keys(measuresValues.objects).filter(
        id => measuresValues.objects[id].selected,
      );
      for (let i = 0; i < selectedObjects.length; i += 1) {
        const indicatorId = selectedObjects[i];
        dispatch(getObjectsForIndicator(indicatorId)).then(data =>
          dispatch(getPolygons(data.map(t => t.territory_id), indicatorId)),
        );
      }
    }
  };

  if (!langOb) {
    return null;
  }

  if (!indicators.length) {
    return <p className="indicators__error">{langOb.noIndicators}</p>;
  }

  return (
    <React.Fragment>
      <div className="indicators__toggle">
        <h3 className="indicators__toggle-title">{langOb.indicatorOnMap}</h3>
        <ToggleControl
          checked={enabled}
          className="indicators__toggle-button"
          onToggle={toggleEnable}
        />
      </div>

      {indicators && currentIndicator && (
        <div className="indicators__input-holder">
          <p className="indicators__input-name">{langOb.indicator}</p>
          <div title={currentIndicator.name}>
            <Select
              styles={selectStyles}
              options={indicators.map(item => ({
                ...item,
                value: item.id,
                label: item.name,
              }))}
              value={{
                ...currentIndicator,
                value: currentIndicator.id,
                label: currentIndicator.name,
              }}
              onChange={handleChangeIndicator}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              isSearchable={false}
            />
          </div>
        </div>
      )}

      <TerritoriesFilters
        measures={currentIndicator ? currentIndicator.ter_measures : null}
        namespace={INDICATOR_TERRITORY_TAB}
        handleChangeTerritoriesFiltersValues={handleChangeTerritoriesFiltersValues}
      />

      {currentIndicator && (
        <PeriodSelectTrigger
          className="indicators__period-select-trigger"
          namespace={INDICATOR_TERRITORY_TAB}
          applyPeriod={applyPeriod}
          resetPeriod={resetPeriod}
          dataRanges={currentIndicator.data_ranges}
          dataStat={currentIndicator.data_stat}
        />
      )}

      <TerritoriesMeasures handleChangeMeasuresValues={handleChangeMeasuresValues} />
    </React.Fragment>
  );
};

IndicatorsTerritory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      measures: PropTypes.arrayOf(
        PropTypes.shape({
          data_type: PropTypes.string,
          select_prop: PropTypes.bool,
          id: PropTypes.number,
          name: PropTypes.string,
          values: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
  currentIndicatorsGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  currentIndicator: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    data_stat: PropTypes.shape({}),
    data_ranges: PropTypes.string,
    has_second_map: PropTypes.bool,
    ter_measures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  currentMapLevelId: PropTypes.number,
  measuresValues: PropTypes.shape({
    [INDICATOR_TERRITORY_TAB]: PropTypes.shape(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    objects: PropTypes.shape({}),
  }),
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  territoriesFiltersValues: PropTypes.shape({}),
};

IndicatorsTerritory.defaultProps = {
  currentIndicator: null,
  currentMapLevelId: null,
  currentIndicatorsGroup: null,
  territoriesFiltersValues: null,
  measuresValues: null,
};

const mapStateToProps = state => ({
  enabled: state.main.indicatorTabsEnabled[INDICATOR_TERRITORY_TAB],
  indicators: state.main.indicators,
  currentIndicator: state.main.currentIndicator,
  currentIndicatorsGroup: state.main.currentIndicatorsGroup,
  polygons: state.polygons,
  currentMapLevelId: state.main.currentMapLevelId,
  measuresValues: state.main.measuresValues,
  territoriesFiltersValues:
    state.main.territoriesFiltersValues && state.main.territoriesFiltersValues.territory,
});

export default connect(mapStateToProps)(IndicatorsTerritory);

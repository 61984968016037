import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './assets/styles/styles.scss';

/* eslint-disable react/no-array-index-key */
const PeriodButtons = ({
  title,
  options,
  onOptionClick,
  extraOptions,
  onExtraOptionClick,
  className,
}) => (
  <div
    className={cx('period-buttons', {
      [className]: className,
    })}
  >
    <div className="period-buttons__text-block">
      <h5 className="period-buttons__text-block-title">{title}</h5>

      {extraOptions && extraOptions.length > 0 && (
        <div className="period-buttons__extra-filters">
          {extraOptions.map(({ text, value, checked }, index) => (
            <button
              type="button"
              className="period-buttons__extra-filters-item"
              aria-checked={checked}
              onClick={() => onExtraOptionClick(index, value)}
              role="checkbox"
              key={value}
            >
              {text}
            </button>
          ))}
        </div>
      )}
    </div>

    <div className="period-buttons__filter">
      {options.map(({ text, checked }, index) => (
        <button
          type="button"
          className="period-buttons__filter-item"
          aria-checked={checked}
          onClick={() => onOptionClick(index)}
          key={index}
          role="checkbox"
        >
          {text}
        </button>
      ))}
    </div>
  </div>
);
/* eslint-enable react/no-array-index-key */

PeriodButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ).isRequired,
  onOptionClick: PropTypes.func.isRequired,
  extraOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ),
  onExtraOptionClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PeriodButtons.defaultProps = {
  extraOptions: [],
  className: '',
  onExtraOptionClick: () => {},
};

export default PeriodButtons;

import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import cx from 'classnames';

// Components
import MapButtonGroup from 'modules/main/components/MapButtonGroup';
import MapButtonGroupSeparator from 'modules/main/components/MapButtonGroupSeparator';

// Styles
import './assets/styles/styles.scss';

/** this is not an react component */
const zoomControls = (ymaps, props) => {
  const { className } = props;

  const ZoomLayout = ymaps.package.full.templateLayoutFactory.createClass(
    renderToStaticMarkup(
      <MapButtonGroup
        className={cx('zoom-tools', {
          [className]: className,
        })}
      >
        <button type="button" className="zoom-tools__plus yandex-zoom-plus" />
        <MapButtonGroupSeparator />
        <button type="button" className="zoom-tools__minus yandex-zoom-minus" />
      </MapButtonGroup>,
    ),
    {
      /**
       * Redefining methods of the layout, in order to perform
       * additional steps when building and clearing the layout.
       */
      build() {
        // Calling the "build" parent method.
        ZoomLayout.superclass.build.call(this);

        /**
         * Binding handler functions to the context and storing references
         * to them in order to unsubscribe from the event later.
         */
        this.zoomInCallback = ymaps.package.full.util.bind(this.zoomIn, this);
        this.zoomOutCallback = ymaps.package.full.util.bind(this.zoomOut, this);

        document.querySelector('.yandex-zoom-plus') &&
          document
            .querySelector('.yandex-zoom-plus')
            .addEventListener('click', this.zoomInCallback);

        document.querySelector('.yandex-zoom-minus') &&
          document
            .querySelector('.yandex-zoom-minus')
            .addEventListener('click', this.zoomOutCallback);
      },

      clear() {
        // Removing click handlers.
        document.querySelector('.yandex-zoom-plus') &&
          document
            .querySelector('.yandex-zoom-plus')
            .removeEventListener('click', this.zoomInCallback);

        document.querySelector('.yandex-zoom-minus') &&
          document
            .querySelector('.yandex-zoom-minus')
            .removeEventListener('click', this.zoomOutCallback);

        // Calling the "clear" parent method.
        ZoomLayout.superclass.clear.call(this);
      },

      zoomIn() {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() + 1, { checkZoomRange: true });
      },

      zoomOut() {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() - 1, { checkZoomRange: true });
      },
    },
  );

  return ZoomLayout;
};

export default zoomControls;

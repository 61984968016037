/* istanbul ignore file */
import 'react-app-polyfill/ie11';
import 'url-polyfill';
import 'polyfills/entries';
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Actions
import { getUser, parseURLParams } from 'modules/main/actions';

// Store
import store from 'store/store';

// Components
import App from 'modules/main/containers/App';
import MapContainer from 'modules/main/containers/Map';
import Static from 'modules/main/containers/Static';
import Contacts from 'modules/main/containers/Contacts';
import Reports from 'modules/main/containers/Reports';

// Styles
import './index.scss';

/** Polyfilling css variables */
cssVars({
  onlyLegacy: true,
});

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App>
          <Switch>
            <Route path="/static/reports" component={Reports} />
            <Route path="/static/contacts" component={Contacts} />
            <Route path="/static/:slug" component={Static} />
            <Route path="/:indicatorGroupId?/:indicatorId?" component={MapContainer} />
          </Switch>
        </App>
      </Router>
    </Provider>,
    document.getElementById('root'),
  );
};

store.dispatch(getUser()).catch(() => {});
store.dispatch(parseURLParams()).then(render);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/sw.js`)
    .then(() => {
      /* eslint-disable no-console */
      console.log('Registration succeeded');
    })
    .catch(error => {
      console.log(`Registration failed with ${error}`);
      /* eslint-enable no-console */
    });
}

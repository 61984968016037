import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

// Action
import {
  resetSelectedPolygons,
  filterSelectedPolygons,
  getFactsMemo,
  getPolygons,
  getRatingFactsMemo,
  getChartFactsMemo,
  getObjectsForIndicator,
} from 'modules/main/actions';

// Misc
import { INDICATOR_CHARTS_TAB, CHART_TAB_INNER_MODE_CHART } from 'modules/main/constants';
import { getNoun } from 'helpers';
import getTerritoryIds from 'helpers/get-territory-ids';
import useLang from 'hooks/useLang';

import './assets/styles/styles.scss';

const SelectedPolygons = ({
  className,
  selectedPolygons,
  currentMapLevelId,
  dispatch,
  currentIndicatorTab,
  chartTabInnerMode,
  currentChartIndicator,
  polygons,
  namespace,
  currentObjects,
  measuresValues,
}) => {
  const [isResetting, setIsResetting] = useState(false);

  /** Переводы */
  const langOb = useLang('SelectedPolygons');

  const filterPolygons = () => {
    dispatch(filterSelectedPolygons(namespace))
      .then(() =>
        Promise.all([
          dispatch(getFactsMemo(null, currentMapLevelId)).then(res => {
            const territoryIds = getTerritoryIds(res, polygons);
            if (territoryIds.length) {
              return dispatch(getPolygons(territoryIds)).then();
            }

            return Promise.resolve(true);
          }),
          (() => {
            /** Если открыта вкладка с рейтингами - перестраиваем */
            if (
              currentIndicatorTab === INDICATOR_CHARTS_TAB &&
              chartTabInnerMode === CHART_TAB_INNER_MODE_CHART &&
              currentChartIndicator
            ) {
              if (currentChartIndicator.chart_type === 'rating') {
                return dispatch(getRatingFactsMemo());
              }
              return dispatch(getChartFactsMemo());
            }
            return true;
          })(),
        ]),
      )
      .then(() => {
        if (currentObjects && measuresValues) {
          const objectsIndicatorsIds = Object.keys(currentObjects).filter(
            objectId => measuresValues[objectId].selected,
          );
          for (let i = 0; i < objectsIndicatorsIds.length; i += 1) {
            const currentObjectIndicatorId = objectsIndicatorsIds[i];
            dispatch(getObjectsForIndicator(currentObjectIndicatorId));
          }
        }
      })
      .catch(() => {});
  };

  const uniqSelectedPolygonsLength = selectedPolygons[namespace]
    .map(item => item.id)
    .filter((item, index, self) => self.indexOf(item) === index).length;

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('selected-polygons', {
        [className]: className,
      })}
    >
      <p className="selected-polygons__text">
        Выделено
        {` ${uniqSelectedPolygonsLength} ${getNoun(
          uniqSelectedPolygonsLength,
          langOb.terrritory1,
          langOb.terrritory2,
          langOb.terrritory5,
        )}`}
      </p>
      <button type="button" className="selected-polygons__confirm-button" onClick={filterPolygons}>
        {langOb.select}
      </button>
      <button
        type="button"
        className="selected-polygons__deny-button"
        onClick={() => {
          setIsResetting(true);
          setTimeout(() => {
            dispatch(resetSelectedPolygons(namespace));
          }, 10);
        }}
        disabled={isResetting}
      >
        {isResetting ? <span>{`${langOb.resetting}...`}</span> : <span>{langOb.reset}</span>}
      </button>
    </div>
  );
};

SelectedPolygons.propTypes = {
  className: PropTypes.string,
  selectedPolygons: PropTypes.shape({
    a: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    b: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  currentMapLevelId: PropTypes.number.isRequired,
  currentIndicatorTab: PropTypes.string,
  chartTabInnerMode: PropTypes.string.isRequired,
  currentChartIndicator: PropTypes.shape({
    chart_type: PropTypes.string,
  }),
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  namespace: PropTypes.string.isRequired,
  measuresValues: PropTypes.shape({}),
  currentObjects: PropTypes.shape({}),
};

SelectedPolygons.defaultProps = {
  className: null,
  currentIndicatorTab: null,
  currentChartIndicator: null,
  measuresValues: {},
  currentObjects: {},
};

const mapStateToProps = state => ({
  selectedPolygons: state.main.selectedPolygons,
  currentMapLevelId: state.main.currentMapLevelId,
  currentIndicatorTab: state.main.currentIndicatorTab,
  chartTabInnerMode: state.main.chartTabInnerMode,
  currentChartIndicator: state.main.currentChartIndicator,
  polygons: state.polygons,
  currentObjects: state.main.currentObjects,
  measuresValues: state.main.measuresValues && state.main.measuresValues.objects,
});

export default connect(mapStateToProps)(SelectedPolygons);

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './assets/styles/styles.scss';
import './assets/styles/range.scss';

const Indicators = ({ className, children }) => (
  <div
    className={cx('indicators', {
      [className]: className,
    })}
  >
    <div className="indicators__scroll">{children}</div>
  </div>
);

Indicators.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
  ]).isRequired,
};

Indicators.defaultProps = {
  className: null,
};

export default Indicators;

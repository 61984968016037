/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import { generateMapPdf } from 'helpers/pdf';
import { generateDateStringPeriod, downloadURI } from 'helpers';
import html2canvas from 'html2canvas';

// Icons
import { ReactComponent as IconExport } from 'assets/icons/icon-export.svg';

// Actions
import { getFactsMemo } from 'modules/main/actions';

// Misc
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const possibleFormats = ['csv', 'json', 'xlsx', 'xml'];

export const ExportControl = ({
  className,
  facts,
  factsTwoMaps,
  lang,
  getFactsQueryString,
  currentCityName,
  currentIndicator,
  currentPeriodFilter,
  measuresValues,
  territoriesFiltersValues,
}) => {
  /** Переводы */
  const langOb = useLang('ExportControl');

  const [isDropOpened, dropEl, handleClick] = useToggleAndOutClick();

  const { cachedArguments } = getFactsMemo;

  const territoriesQuery = getFactsQueryString ? `${getFactsQueryString}&` : '';

  const dualIndicatorUrlSegment = cachedArguments[0] ? 'streams/' : '';

  const langQuery = lang && lang.short ? `lang=${lang.short}&` : '';

  const getMedia = (type) => {
    /**
     * Чтобы проигнорировать элементы на выгруженной карте, добавляем нужный атрибут
     */
    for (const elem of document.body.getElementsByClassName('mapboxgl-ctrl-bottom-right'))
      elem.setAttribute('data-html2canvas-ignore', true);
    for (const elem of document.body.getElementsByClassName('input-range__label--value')) {
      elem.setAttribute('data-html2canvas-ignore', true);
      for (const sElem of elem.children) sElem.setAttribute('data-html2canvas-ignore', true);
    }
    for (const elem of document.body.getElementsByClassName('current-area-summary'))
      elem.setAttribute('data-html2canvas-ignore', true);
    for (const elem of document.body.getElementsByClassName('mapboxgl-canvas'))
      elem.setAttribute('data-html2canvas-ignore', true);
    for (const elem of document.body.getElementsByClassName('mapboxgl-ctrl-logo'))
      elem.setAttribute('data-html2canvas-ignore', true);

    html2canvas(document.body.getElementsByClassName('map-wrapper')[0], {}).then(canvas => {

      if (type === 'pdf') {
        return generateMapPdf(canvas.toDataURL(), currentIndicator.name, getData())
      } else if (type === 'png') {
        return downloadURI(canvas.toDataURL(), `${currentIndicator.name}.png`);
      }
    });
  };

  const getData = () => {
    let data = [
      { name: langOb.city, value: currentCityName },
      {
        name: langOb.period,
        value: generateDateStringPeriod(currentIndicator.data_ranges, currentPeriodFilter),
      },
    ];
    if (measuresValues)
      data = [
        ...data,
        ...Object.keys(measuresValues).map(key => ({
          name:
            currentIndicator.measures.find(item => item.id == key) &&
            currentIndicator.measures.find(item => item.id == key).name,
          value:
            currentIndicator.measures.find(item => item.id == key) &&
            currentIndicator.measures.find(item => item.id == key).data_type !== 'checkbox'
              ? measuresValues[key].label ||
                Object.values(measuresValues[key])
                  .map(item => item.label)
                  .join(', ')
              : Object.keys(measuresValues[key])
                  .filter(item => measuresValues[key][item])
                  .map(
                    item =>
                      currentIndicator.measures
                        .find(sitem => sitem.id == key)
                        .values.find(sitem => sitem.id == item).name,
                  )
                  .join(', '),
        })),
      ];
    ['a', 'b'].forEach(namespace => {
      if (territoriesFiltersValues[namespace])
        data = [
          ...data,
          ...Object.keys(territoriesFiltersValues[namespace]).map(key => ({
            name:
              currentIndicator.ter_measures.find(item => item.id == key) &&
              (namespace === 'b'
                ? `${currentIndicator.ter_measures.find(item => item.id == key).name} ${
                    langOb.rightMap
                  }`
                : currentIndicator.ter_measures.find(item => item.id == key).name),
            value: Object.values(territoriesFiltersValues[namespace][key])
              .filter(item => item.selected)
              .map(item => item.name)
              .join(', '),
          })),
        ];
    });

    return data;
  };

  const formats = possibleFormats.map(format => ({
    name: format,
    href: `${process.env.REACT_APP_API_URL}data/${dualIndicatorUrlSegment}level/${
      cachedArguments[1]
    }/indicator/${cachedArguments[2]}/?${territoriesQuery}${
      cachedArguments[3]
    }${langQuery}export=${format}`,
  }));

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('export-control', {
        [className]: className,
      })}
    >
      <button
        type="button"
        className="export-control__button"
        onClick={handleClick}
        disabled={
          !(facts && facts.data) &&
          !(factsTwoMaps && factsTwoMaps.data_from && factsTwoMaps.data_from.data)
        }
        aria-label={langOb.buttonLabel}
      >
        <IconExport className="export-control__button-icon" />
      </button>

      <CSSTransition
        in={isDropOpened}
        timeout={200}
        classNames="export-control__drop"
        unmountOnExit
      >
        <div className="export-control__drop" ref={dropEl}>
          <p className="export-control__drop-title">{langOb.dropTitle}</p>
          <div className="export-control__drop-list">
            {formats &&
              formats.length &&
              formats.map(format => (
                <a
                  href={format.href}
                  target="_blank"
                  rel="noreferrer noopener"
                  download={`export.${format.name}`}
                  key={format.name}
                  className="export-control__drop-option"
                  data-testid="export-control-drop-option"
                >
                  {format.name}
                </a>
              ))}
            <button
              type="button"
              key="pdf"
              className="export-control__drop-option"
              data-testid="export-control__drop-option"
              onClick={() => {
                getMedia('pdf');
              }}
            >
              pdf
            </button>
            <button
              type="button"
              key="png"
              className="export-control__drop-option"
              data-testid="export-control__drop-option"
              onClick={() => {
                getMedia('png');
              }}
            >
              png
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

ExportControl.propTypes = {
  className: PropTypes.string,
  facts: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  factsTwoMaps: PropTypes.shape({
    data_from: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    data_to: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  getFactsQueryString: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    short: PropTypes.string,
  }).isRequired,
  currentCityName: PropTypes.string.isRequired,
  currentIndicator: PropTypes.shape({
    data_ranges: PropTypes.string,
    name: PropTypes.string,
    measures: PropTypes.array,
    ter_measures: PropTypes.array,
  }),
  currentPeriodFilter: PropTypes.shape(),
  measuresValues: PropTypes.shape(),
  territoriesFiltersValues: PropTypes.shape({
    a: PropTypes.shape(),
    b: PropTypes.shape(),
  }),
};

ExportControl.defaultProps = {
  className: null,
  currentIndicator: {},
  currentPeriodFilter: null,
  measuresValues: {},
  territoriesFiltersValues: null,
};

const mapStateToProps = state => ({
  currentCityName: state.main.currentCity.name,
  currentIndicator: state.main.currentIndicator,
  currentPeriodFilter: state.main.currentPeriodFilter && state.main.currentPeriodFilter.territory,
  facts: state.main.facts,
  factsTwoMaps: state.main.factsTwoMaps,
  lang: state.main.lang,
  measuresValues: state.main.measuresValues && state.main.measuresValues.territory,
  territoriesFiltersValues:
    state.main.territoriesFiltersValues && state.main.territoriesFiltersValues.territory,
  getFactsQueryString: state.main.getFactsQueryString,
});

export default connect(mapStateToProps)(ExportControl);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import useWindowSize from '@rehooks/window-size';

// Actions
import {
  changeMonthStart,
  changeMonthEnd,
  changeYearStart,
  changeYearEnd,
  setURLParams,
} from 'modules/main/actions';

// Icons
import { ReactComponent as IconPeriod } from 'assets/icons/icon-period-choice.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';

// Misc
import useLang from 'hooks/useLang';
import { mobile } from 'assets/breakpoints';

// Styles
import './styles.scss';

export const MonthsRange = ({
  periodFilter,
  dispatch,
  className,
  dataStat,
  namespace,
  onApply,
}) => {
  /** Переводы */
  const langOb = useLang('MonthsRange');

  const { innerWidth } = useWindowSize();

  const [isDropOpened, dropEl, handleClick, closeExplicitly] = useToggleAndOutClick();
  const [firstSelectedMonth, setFirstSelectedMonth] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [hoveredRange, setHoveredRange] = useState(null);

  /** Проброс значений из стора в стейт при маунте */
  useEffect(() => {
    const { monthStart, monthEnd, yearStart, yearEnd } = periodFilter;

    if (monthStart && yearStart && monthEnd && yearEnd) {
      const dateStart = new Date('1970-01-01');
      dateStart.setFullYear(yearStart);
      dateStart.setMonth(monthStart);

      const dateEnd = new Date('1970-01-01');
      dateEnd.setFullYear(yearEnd);
      dateEnd.setMonth(monthEnd);

      setSelectedRange({
        start: {
          date: dateStart,
          year: yearStart,
          month: monthStart,
        },
        end: {
          date: dateEnd,
          year: yearEnd,
          month: monthEnd,
        },
      });
    }
  }, []);

  /** Определяем минимальную дату */
  let periodMin = new Date('2000-01-01');
  if (dataStat && dataStat.start_date && Date.parse(dataStat.start_date)) {
    periodMin = new Date(Date.parse(dataStat.start_date));
    periodMin = new Date(periodMin.getFullYear(), periodMin.getMonth(), 1);
  }
  const yearMin = periodMin.getFullYear();

  /** Определяем максимальную дату */
  let periodMax = new Date('2100-01-01');
  if (dataStat && dataStat.end_date && Date.parse(dataStat.end_date)) {
    periodMax = new Date(Date.parse(dataStat.end_date));
    periodMax = new Date(periodMax.getFullYear(), periodMax.getMonth(), 20);
  }
  const yearMax = periodMax.getFullYear();

  /** Создаем опции для выбора года */
  const yearOptions = [];
  for (let i = yearMax; i >= yearMin; i -= 1) {
    yearOptions.unshift(i);
  }

  /** Применение периода и вызов колбэка сверху */
  const applyPeriod = () => {
    if (!selectedRange) {
      return;
    }

    dispatch(changeYearStart(selectedRange.start.year, namespace));
    dispatch(changeMonthStart(selectedRange.start.month, namespace));
    dispatch(changeYearEnd(selectedRange.end.year, namespace));
    dispatch(changeMonthEnd(selectedRange.end.month, namespace));

    dispatch(
      setURLParams({
        [`${namespace}_yearStart`]: selectedRange.start.year,
        [`${namespace}_monthStart`]: selectedRange.start.month,
        [`${namespace}_yearEnd`]: selectedRange.end.year,
        [`${namespace}_monthEnd`]: selectedRange.end.month,
      }),
    );

    /** Закрываем выбор месяца... */
    closeExplicitly();

    /** ...и вызываем колбэк сверху */
    onApply();
  };

  /** Функция для установки выбранного старт и финиш месяца */
  const selectRange = (year, month) => {
    const date = new Date('1970-01-01');
    date.setFullYear(year);
    date.setMonth(month);

    if (firstSelectedMonth) {
      if (date > firstSelectedMonth.date) {
        setSelectedRange({
          start: {
            ...firstSelectedMonth,
          },
          end: {
            date,
            year,
            month,
          },
        });
      } else {
        setSelectedRange({
          start: {
            date,
            year,
            month,
          },
          end: {
            ...firstSelectedMonth,
          },
        });
      }

      setFirstSelectedMonth(null);
      closeExplicitly();
    } else {
      setFirstSelectedMonth({
        date,
        year,
        month,
      });
      setSelectedRange({
        start: {
          date,
          year,
          month,
        },
        end: {
          date,
          year,
          month,
        },
      });
    }

    setHoveredRange(null);
  };

  /** Фукция для установки ховер-райнджа */
  const selectHoverRange = (year, month) => {
    if (!firstSelectedMonth) {
      return;
    }
    const date = new Date('1970-01-01');
    date.setFullYear(year);
    date.setMonth(month);

    if (date > firstSelectedMonth.date) {
      setHoveredRange({
        start: {
          ...firstSelectedMonth,
        },
        end: {
          date,
          year,
          month,
        },
      });
    } else {
      setHoveredRange({
        start: {
          date,
          year,
          month,
        },
        end: {
          ...firstSelectedMonth,
        },
      });
    }
  };

  const resetRange = () => {
    setSelectedRange(null);
    setHoveredRange(null);
    setFirstSelectedMonth(null);
  };

  if (!langOb) {
    return null;
  }

  const renderPeriod = () => {
    if (!firstSelectedMonth && !selectedRange) {
      return langOb.specifyPeriod;
    }

    /** Если есть ховер (а значит есть и firstSelectedMonth ) */
    if (hoveredRange) {
      return (
        <React.Fragment>
          <span
            className={cx('month-range-select__value-part', {
              'month-range-select__value-part_hover':
                firstSelectedMonth.date !== hoveredRange.start.date,
            })}
          >
            {`${langOb.monthsShort[hoveredRange.start.month]} ${hoveredRange.start.year}`}
          </span>
          &nbsp;&mdash;&nbsp;
          <span
            className={cx('month-range-select__value-part', {
              'month-range-select__value-part_hover':
                firstSelectedMonth.date !== hoveredRange.end.date,
            })}
          >
            {`${langOb.monthsShort[hoveredRange.end.month]} ${hoveredRange.end.year}`}
          </span>
        </React.Fragment>
      );
    }

    /** иначе - если есть выбранный рэйндж  */
    if (selectedRange) {
      if (
        selectedRange.start.month === selectedRange.end.month &&
        selectedRange.start.year === selectedRange.end.year
      ) {
        return (
          <span className="month-range-select__value-part">
            {`${langOb.monthsShort[selectedRange.start.month]} ${selectedRange.start.year}`}
          </span>
        );
      }

      return (
        <React.Fragment>
          <span className="month-range-select__value-part">
            {`${langOb.monthsShort[selectedRange.start.month]} ${selectedRange.start.year}`}
          </span>
          &nbsp;&mdash;&nbsp;
          <span className="month-range-select__value-part">
            {`${langOb.monthsShort[selectedRange.end.month]} ${selectedRange.end.year}`}
          </span>
        </React.Fragment>
      );
    }

    /** Иначе - если только одна дата */
    if (firstSelectedMonth) {
      return (
        <React.Fragment>
          <span className="month-range-select__value-part">
            {`${langOb.monthsShort[firstSelectedMonth.month]} ${firstSelectedMonth.year}`}
          </span>
          &nbsp;&mdash;
        </React.Fragment>
      );
    }

    return null;
  };

  return (
    <div
      className={cx('month-range-select', {
        [className]: className,
      })}
    >
      <div className="month-range-select__container">
        <div className="month-range-select__select">
          <div className="month-range-select__input-wrapper">
            <button
              aria-label={langOb.specifyPeriod}
              type="button"
              className="month-range-select__value-button"
              onClick={handleClick}
              disabled={innerWidth <= mobile}
            >
              <span className="month-range-select__value">{renderPeriod()}</span>
              <IconPeriod className="month-range-select__date-icon" />
            </button>
            <button
              type="button"
              className="month-range-select__reset-button"
              aria-label={langOb.resetPeriod}
              onClick={resetRange}
            >
              <IconClose className="month-range-select__clear-icon" />
            </button>
          </div>

          {innerWidth > 800 && (
            <button
              type="button"
              className="month-range-select__apply"
              onClick={applyPeriod}
              disabled={!selectedRange}
            >
              {langOb.save}
            </button>
          )}
        </div>

        {(isDropOpened || innerWidth <= mobile) && (
          <div
            className="month-range-select__selector"
            ref={dropEl}
            data-testid="month-range-select-selector"
          >
            <Scrollbars
              style={{
                height: yearOptions.length > 1 ? 260 : 130,
              }}
            >
              {yearOptions.map((year, yearIndex) => (
                <div className="month-range-select__year" key={year}>
                  <span
                    className="month-range-select__year-value"
                    data-testid="month-range-select-year-value"
                  >
                    {year}
                  </span>
                  <div className="month-range-select__months">
                    {langOb.months.map((month, monthIndex) => {
                      const date = new Date(Date.parse('1970-01-01'));
                      date.setFullYear(year);
                      date.setMonth(monthIndex);

                      return (
                        <button
                          type="button"
                          key={month}
                          className={cx('month-range-select__month', {
                            'month-range-select__month_selected-first':
                              firstSelectedMonth &&
                              firstSelectedMonth.year === year &&
                              firstSelectedMonth.month === monthIndex,
                            'month-range-select__month_boundary':
                              selectedRange &&
                              ((selectedRange.start.year === year &&
                                selectedRange.start.month === monthIndex) ||
                                (selectedRange.end.year === year &&
                                  selectedRange.end.month === monthIndex)),
                            'month-range-select__month_inner':
                              selectedRange &&
                              selectedRange.start.date < date &&
                              selectedRange.end.date > date,
                            'month-range-select__month_hover-range':
                              hoveredRange &&
                              hoveredRange.start.date <= date &&
                              hoveredRange.end.date >= date,
                          })}
                          disabled={
                            (yearIndex === 0 && monthIndex < periodMin.getMonth()) ||
                            (yearIndex === yearOptions.length - 1 &&
                              monthIndex > periodMax.getMonth())
                          }
                          onClick={() => selectRange(year, monthIndex)}
                          onMouseOver={() => selectHoverRange(year, monthIndex)}
                          onFocus={() => selectHoverRange(year, monthIndex)}
                        >
                          <span className="month-range-select__month-text">{month}</span>

                          {/* Background для начального и конечного месяцев */}
                          {selectedRange &&
                            selectedRange.start.year === year &&
                            selectedRange.start.month === monthIndex && (
                              <span className="month-range-select__month-background month-range-select__month-background_start" />
                            )}

                          {selectedRange &&
                            selectedRange.end.year === year &&
                            selectedRange.end.month === monthIndex && (
                              <span className="month-range-select__month-background month-range-select__month-background_end" />
                            )}
                          {/* /Background для начального и конечного месяцев */}

                          {/* Underlay для месяцев внури рейнджа */}
                          {selectedRange &&
                            selectedRange.start.date <= date &&
                            selectedRange.end.date >= date && (
                              <span
                                className={cx('month-range-select__month-underlay', {
                                  'month-range-select__month-underlay_start':
                                    selectedRange.start.year === year &&
                                    selectedRange.start.month === monthIndex,
                                  'month-range-select__month-underlay_end':
                                    selectedRange.end.year === year &&
                                    selectedRange.end.month === monthIndex,
                                })}
                              />
                            )}
                          {/* /Underlay для месяцев внури рейнджа */}

                          {/* Underlay для ховера */}
                          {hoveredRange &&
                            hoveredRange.start.date <= date &&
                            hoveredRange.end.date >= date && (
                              <span
                                className={cx('month-range-select__month-underlay', {
                                  'month-range-select__month-underlay_start':
                                    hoveredRange.start.year === year &&
                                    hoveredRange.start.month === monthIndex &&
                                    hoveredRange.start.date > firstSelectedMonth.date,
                                  'month-range-select__month-underlay_end':
                                    hoveredRange.end.year === year &&
                                    hoveredRange.end.month === monthIndex &&
                                    hoveredRange.end.date < firstSelectedMonth.date,
                                })}
                              />
                            )}
                          {/* /Underlay для ховера */}
                        </button>
                      );
                    })}
                  </div>
                </div>
              ))}
            </Scrollbars>
          </div>
        )}

        {innerWidth <= 800 && (
          <button
            type="button"
            className="month-range-select__apply month-range-select__apply_mobile"
            onClick={applyPeriod}
            disabled={!selectedRange}
          >
            {langOb.save}
          </button>
        )}
      </div>
    </div>
  );
};

MonthsRange.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  periodFilter: PropTypes.shape({
    monthStart: PropTypes.number,
    monthEnd: PropTypes.number,
    yearStart: PropTypes.number,
    yearEnd: PropTypes.number,
  }).isRequired,
  dataStat: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }).isRequired,
  namespace: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

MonthsRange.defaultProps = {
  className: null,
};

export default connect()(MonthsRange);

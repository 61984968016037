import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Popup from 'shared/Popup';
import InputHintPointer from 'modules/main/components/InputHintPointer';

// Styles
import './assets/style/style.scss';

// Misc
import useLang from 'hooks/useLang';

// Icon
import { ReactComponent as IconValidationFailed } from 'assets/icons/icon-validation-failed.svg';

export const AddToFavorites = ({ closeHandler, save, url, name, error }) => {
  const [title, setTitle] = useState(name);
  const state = url || window.location.pathname + window.location.search;

  const lang = useLang('AddToFavorites');

  if (!lang) {
    return null;
  }

  return (
    <Popup closeHandler={closeHandler}>
      <div className="favorites-saver" data-testid="add-to-favorites-modal">
        <div className="favorites-saver__title">{lang.title}</div>
        <div className="favorites-saver__control">
          <label htmlFor="title">{lang.label}</label>
          <div className="favorites-saver__input-wrapper">
            <input
              type="text"
              id="title"
              name="title"
              data-testid="title-favorite-item"
              value={title}
              onChange={event => setTitle(event.target.value)}
            />
            {error && error.title && (
              <React.Fragment>
                <IconValidationFailed className="favorites-saver__error-icon" />
                <InputHintPointer text={error.title} />
              </React.Fragment>
            )}
          </div>
        </div>
        <button
          className="favorites-saver__button"
          type="button"
          onClick={() => save({ title, state })}
        >
          {lang.button}
        </button>
      </div>
    </Popup>
  );
};

AddToFavorites.defaultProps = {
  url: undefined,
  name: '',
  error: {},
};

AddToFavorites.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  url: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default AddToFavorites;

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
import Checkbox from 'shared/Checkbox';
import Search from 'shared/Search';

// Icons
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';

// Actions
import {
  getTerritoriesForIndicator,
  setFilterRegions,
  updateFilterRegions,
  setFilterRegionsItem,
  getTerritoriesBySearch,
} from 'modules/main/actions';

// Misc
import {
  MIXED_EXCLUDE,
  MIXED_INCLUDE,
  MAX_COUNT_TERRITORIES_AREA_SELECT,
} from 'modules/main/constants';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

// Helpers
import { setParentCheckboxes } from 'helpers';

const AreaSelect = ({
  className,
  dispatch,
  regions,
  isFilterRegionsFetched,
  currentIndicator,
  applyHandler,
  namespace,
  isFullRegions,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [foundTerritories, setFoundTerritories] = useState(null);
  const specificRegions = regions[namespace];
  const [toSearch, setToSearch] = useState(false);
  const [searchFilterRegions, setSearchFilterRegions] = useState({});
  /**
   * Состояния для случая, если приходят обрезанные территории
   */
  const isFull = isFullRegions[namespace];

  /** Переводы */
  const langOb = useLang('AreaSelect');

  /**
   * При маунте компонента и при смене группы показателей загружаем первый уровень территорий
   */
  useEffect(() => {
    if (!isFilterRegionsFetched[namespace] && currentIndicator) {
      setIsLoading(true);

      dispatch(getTerritoriesForIndicator(namespace))
        .then(res => {
          setIsLoading(false);
          dispatch(setFilterRegions(res, { initialLoad: true, namespace }));
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [currentIndicator]);

  /** Эффект для сдвигания кнопки применить */
  const areaSelectRef = useRef(null);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  useEffect(() => {
    if (areaSelectRef && areaSelectRef.current) {
      const { offsetHeight, scrollHeight, offsetWidth, clientWidth } = areaSelectRef.current;
      if (scrollHeight > offsetHeight) {
        setScrollBarWidth(offsetWidth - clientWidth);
      } else {
        setScrollBarWidth(0);
      }
    }
  });

  const [selectedRegions, setSelectedRegions] = useState([]);

  const handleCheckItem = event => {
    const { target } = event;
    const { dataset } = target;
    let { area, parents } = dataset;
    area = +area;
    parents = parents ? parents.split(',') : [];
    parents = parents.map(item => +item);

    const nextRegions = { ...specificRegions };

    /** Находим детей и ставим им checked */
    const setCheckedForChildren = localParentId => {
      const localChildrenIds = Object.values(nextRegions)
        .filter(
          item =>
            item.parentId === localParentId ||
            (Array.isArray(item.parent_ids) && item.parent_ids.includes(localParentId)),
        )
        .map(item => item.id);

      localChildrenIds.forEach(id => {
        nextRegions[id].checked = checked;
      });
      localChildrenIds.forEach(setCheckedForChildren);
    };

    /** Работа с территориями в поиске */
    if (toSearch) {
      const currentRegion = searchFilterRegions.find(region => region.id === area);
      /** Ставим новый checked */
      const checked =
        currentRegion.checked === false ||
        currentRegion.checked === undefined ||
        currentRegion.checked === MIXED_EXCLUDE ||
        currentRegion.checked === MIXED_INCLUDE;

      currentRegion.checked = checked;

      /** Проставляем родителям верные checked */
      if (nextRegions[currentRegion.parent_ids[0]])
        if (currentRegion.checked && currentRegion.parent_ids.length > 0) {
          nextRegions[currentRegion.parent_ids[0]].checked = 'MIXED_INCLUDE';
        } else if (!currentRegion.checked && currentRegion.parent_ids.length > 0) {
          nextRegions[currentRegion.parent_ids[0]].checked = 'MIXED_EXCLUDE';
        }

      nextRegions[currentRegion.id] = currentRegion;
      setCheckedForChildren(area, nextRegions);

      /** Обновляем checkbox */
      const copySelectedRegions = selectedRegions;
      !copySelectedRegions.some(region => region === currentRegion.id) &&
        copySelectedRegions.push(currentRegion.id);
      setSelectedRegions(copySelectedRegions);

      dispatch(updateFilterRegions(nextRegions, namespace));
      return;
    }

    /** Текущий регион */
    const currentRegion = nextRegions[area];
    if (!currentRegion) {
      return;
    }

    /** Ставим новый checked */
    const checked =
      currentRegion.checked === false ||
      currentRegion.checked === undefined ||
      currentRegion.checked === MIXED_EXCLUDE ||
      currentRegion.checked === MIXED_INCLUDE;

    currentRegion.checked = checked;

    setCheckedForChildren(area);

    /** Делаем прогон для установки парентов элемента */
    while (parents.length) {
      const currentParentRegion = nextRegions[parents[parents.length - 1]];

      if (currentParentRegion) {
        setParentCheckboxes(nextRegions, currentParentRegion);
      }

      parents.pop();
    }

    /** Обновляем checkbox */

    const copySelectedRegions = selectedRegions;
    !copySelectedRegions.some(region => region === currentRegion.id) &&
      copySelectedRegions.push(currentRegion.id);
    setSelectedRegions(copySelectedRegions);
    dispatch(updateFilterRegions(nextRegions, namespace));
  };

  const handleExpandItem = event => {
    const { target } = event;
    const { dataset } = target;
    let { area } = dataset;
    area = +area;
    /** Текущий регион */
    const currentRegion = { ...specificRegions[area] };

    if (!currentRegion) {
      return;
    }

    /** Тогглим expanded */
    currentRegion.expanded = !currentRegion.expanded;

    /** Если открыт и нет детей - ставим флаг загрузки */
    const hasItems = Object.values(specificRegions).some(
      item => item.parentId === currentRegion.id,
    );
    if (currentRegion.expanded && !hasItems) {
      currentRegion.loading = true;
    }

    /** Сначала обновляем expanded */
    dispatch(setFilterRegionsItem(currentRegion, namespace));

    /**
     * Загружаем суб территории
     */
    if (!hasItems) {
      getTerritories(area)
        .then(res => {
          dispatch(
            setFilterRegions(res, {
              namespace,
              parentId: currentRegion.id,
              checked: currentRegion.checked === MIXED_EXCLUDE || currentRegion.checked === true,
            }),
          );
        })
        .catch(() => {});
    }
  };

  const loadMore = event => {
    const { target } = event;
    const { dataset } = target;
    let { area, page } = dataset;
    area = +area;
    page = +page;

    /** Находим по цепочке родителей нужный регион */
    const currentRegion = { ...specificRegions[area] };

    if (!currentRegion) {
      return;
    }

    currentRegion.loading = true;

    /** Сначала обновляем loading */
    dispatch(setFilterRegionsItem(currentRegion, namespace));

    /**
     * Загружаем суб территории
     */
    getTerritories(area, page)
      .then(result => {
        dispatch(
          setFilterRegions(result, {
            parentId: currentRegion.id,
            checked: currentRegion.checked === MIXED_EXCLUDE || currentRegion.checked === true,
            namespace,
          }),
        );
      })
      .catch(() => {});
  };

  const toggleCheckedFirstLevel = () => {
    const isFirstLevelChecked = Object.values(specificRegions)
      .filter(region => region.parentId === null)
      .some(item => item.checked);

    let nextRegions = { ...specificRegions };

    nextRegions = Object.keys(nextRegions).reduce((acc, key) => {
      acc[key] = nextRegions[key];
      acc[key].checked = !isFirstLevelChecked;
      return acc;
    }, {});

    dispatch(updateFilterRegions(nextRegions, namespace));
  };

  const getTerritories = (areaId, page = null) =>
    dispatch(getTerritoriesForIndicator(namespace, areaId, page));

  const searchTerritories = query => {
    if (query === '') {
      setToSearch(false);
      return setFoundTerritories(null);
    }

    setToSearch(true);

    return dispatch(getTerritoriesBySearch(query, namespace)).then(({ results }) => {
      results.forEach(territory => {
        // eslint-disable-next-line no-param-reassign
        if (firstLevelCheckedStatus) {
          /**
           * Если ищут нулевой уровень (нет родителей), то выбранность известна
           * */
          if (territory.parent_ids && territory.parent_ids.length === 0) {
            const region = specificRegions[territory.id];
            territory.checked = region ? region.checked : true;
          } else {
            territory.checked = true;
          }
        } else {
          territory.checked =
            territory.parent_ids &&
            territory.parent_ids.length > 0 &&
            territory.parent_ids.every(
              parentId =>
                Object.values(specificRegions).some(region => region.id === parentId) &&
                Object.values(specificRegions).find(region => region.id === parentId).checked,
            );
        }
      });
      setSearchFilterRegions(results);
      setFoundTerritories(results.map(region => region.id));
    });
  };

  const renderList = (itemRegions, level, parents) => (
    <React.Fragment>
      {itemRegions &&
        itemRegions
          .filter(region =>
            !parents.length
              ? (region.parent_ids && region.parent_ids[0] === undefined) ||
                foundTerritories !== null
              : region.parentId === parents[parents.length - 1] ||
                (!region.parentId && region.parentId !== null),
          )
          .map(region => (
            <li className="area-select__item" key={region.id}>
              <div className={`area-select__item-button area-select__item-button_level-${level}`}>
                <Checkbox
                  onClick={handleCheckItem}
                  data-area={region.id}
                  data-level={level}
                  data-parents={region.parent_ids}
                  checked={region.checked || false}
                  disabled={region.loading}
                  className="area-select__item-checkbox"
                  label={region.name}
                />
                <span className="area-select__item-name">{region.name}</span>
                {region.has_children && (
                  <button
                    type="button"
                    className={cx('area-select__item-expand', {
                      'area-select__item-expand_expanded': region.expanded,
                    })}
                    aria-label={region.expanded ? langOb.collapse : langOb.expand}
                    data-area={region.id}
                    data-level={level}
                    data-parents={parents}
                    onClick={handleExpandItem}
                    disabled={region.loading}
                  />
                )}
              </div>

              {region.expanded && itemRegions.some(item => item.parentId === region.id) && (
                <ul className="area-select__list">
                  {renderList(
                    sortByChecked(
                      itemRegions,
                      selectedRegions.length !== 0 ? selectedRegions : null,
                    ),
                    level + 1,
                    [...parents, region.id],
                  )}
                </ul>
              )}

              {region.expanded && region.next && !region.loading && (
                <button
                  type="button"
                  className={`area-select__item-load-more area-select__item-load-more_level-${level}`}
                  data-area={region.id}
                  data-parents={parents}
                  data-page={region.next}
                  onClick={loadMore}
                >
                  {`${langOb.load_more} 20 (${langOb.total} ${region.count})`}
                </button>
              )}

              {region.loading && (
                <div className="area-select__loading" data-testid="area-select-loading">
                  <IconLoader className="area-select__loading-icon" />
                </div>
              )}
            </li>
          ))}
    </React.Fragment>
  );

  const sortByChecked = (arr, excludeItems) => {
    let arrChecked;
    let arrUnchecked;
    if (excludeItems) {
      arrChecked = arr
        .filter(item => (excludeItems.includes(item.id) ? false : item.checked))
        .sort(sortByName);
      arrUnchecked = arr
        .filter(item => (excludeItems.includes(item.id) ? true : !item.checked))
        .sort(sortByName);
    } else {
      arrChecked = arr.filter(item => item.checked).sort(sortByName);
      arrUnchecked = arr.filter(item => !item.checked).sort(sortByName);
    }

    return arrChecked.concat(arrUnchecked);
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  };

  let firstLevelCheckedStatus;

  if (Object.values(specificRegions).every(item => item.checked)) {
    firstLevelCheckedStatus = true;
  } else if (
    Object.values(specificRegions).every(
      item => item.checked === false || item.checked === undefined,
    )
  ) {
    firstLevelCheckedStatus = false;
  } else {
    firstLevelCheckedStatus = MIXED_INCLUDE;
  }

  if (!langOb) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className={cx('area-select', {
          [className]: className,
        })}
        ref={areaSelectRef}
      >
        <div className="area-select__inner-container">
          {!currentIndicator && <h3 className="area-select__title">{langOb.no_indicators}</h3>}

          {currentIndicator && (
            <React.Fragment>
              <h3 className="area-select__title">{langOb.title}</h3>

              {isLoading && (
                <div className="area-select__loading" data-testid="area-select-loading">
                  <IconLoader className="area-select__loading-icon" />
                </div>
              )}

              {!isLoading && Object.values(specificRegions).length > 0 && (
                <React.Fragment>
                  <Search onSearch={searchTerritories} onClear={() => setToSearch(false)} />
                  {foundTerritories !== null ? (
                    <ul className="area-select__list">{renderList(searchFilterRegions, 1, [])}</ul>
                  ) : (
                    <React.Fragment>
                      <div className="area-select__select-all">
                        <Checkbox
                          onClick={toggleCheckedFirstLevel}
                          checked={firstLevelCheckedStatus}
                          className="area-select__item-checkbox"
                          label={langOb.all_check}
                        />
                        <p className="area-select__item-name">{langOb.selectAll}</p>
                      </div>

                      <ul className="area-select__list">
                        {renderList(
                          Object.values(specificRegions)
                            .slice(0, MAX_COUNT_TERRITORIES_AREA_SELECT)
                            .sort(sortByName),
                          1,
                          [],
                        )}
                      </ul>
                      {/* Проверяем пришел ли массив полный (только для 1-го уровня) */}
                      {!isFull &&
                        Object.values(specificRegions).every(region => region.level === 1) && (
                          <p className="area-select__not-full-note">{langOb.not_full}</p>
                        )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {Object.values(specificRegions).length > 0 && (
        <div
          className="area-select__apply"
          style={{
            right: `${scrollBarWidth}px`,
          }}
        >
          <button className="area-select__apply-button" type="button" onClick={applyHandler}>
            {langOb.apply}
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

AreaSelect.propTypes = {
  className: PropTypes.string,
  regions: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number,
        loading: PropTypes.bool,
      }),
    ),
  ).isRequired,
  isFilterRegionsFetched: PropTypes.shape({
    a: PropTypes.bool,
    b: PropTypes.bool,
  }).isRequired,
  currentIndicator: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  applyHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  isFullRegions: PropTypes.shape({
    a: PropTypes.bool,
    b: PropTypes.bool,
  }),
};

AreaSelect.defaultProps = {
  className: null,
  currentIndicator: null,
  isFullRegions: { a: true, b: true },
};

const mapStateToProps = state => ({
  regions: state.main.filterRegions,
  isFilterRegionsFetched: state.main.isFilterRegionsFetched,
  currentMapLevelId: state.main.currentMapLevelId,
  currentIndicator: state.main.currentIndicator,
  isFullRegions: state.main.filterRegionsIsFull,
});

export default connect(mapStateToProps)(AreaSelect);

import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Measures from 'modules/main/components/Measures';

// Actions
import { setMeasuresValues, setURLParams } from 'modules/main/actions';

// Misc
import { SELECT, CHECKBOXES, INDICATOR_CHARTS_TAB, PIE } from 'modules/main/constants';
import { getMeasuresValuesFromURL } from 'helpers';

export const ChartsMeasures = ({
  currentChartIndicator,
  dispatch,
  isInSidebar,
  measuresValues,
}) => {
  let measures = null;
  if (currentChartIndicator) {
    ({ measures } = currentChartIndicator);
  }

  const savedCurrentChartIndicator = useRef(null);

  /** Ставим первые значения для мер при инициализации и смене показателя */
  useEffect(() => {
    if (!currentChartIndicator) {
      return;
    }
    if (
      (!savedCurrentChartIndicator.current && isInSidebar) ||
      (savedCurrentChartIndicator.current &&
        (currentChartIndicator.id !== savedCurrentChartIndicator.current.id ||
          currentChartIndicator.chart_type !== savedCurrentChartIndicator.current.chart_type))
    ) {
      savedCurrentChartIndicator.current = { ...currentChartIndicator };

      let fieldValues = {};

      /** Парсим location в поисках значений мер */
      const measuresValuesTerritories = getMeasuresValuesFromURL();

      if (measures) {
        fieldValues = measures.reduce((acc, curItem) => {
          const { data_type: dataType } = curItem;
          switch (dataType) {
            case SELECT: {
              /** TODO: Избавится от дублирования кода! */
              /** Если есть в урл */
              if (measuresValuesTerritories[curItem.id]) {
                const isMulti = Array.isArray(measuresValuesTerritories[curItem.id]);

                let options = curItem.values.filter(
                  item => item.id === measuresValuesTerritories[curItem.id],
                );

                if (isMulti) {
                  options = curItem.values.filter(item =>
                    measuresValuesTerritories[curItem.id].includes(item.id),
                  );
                }

                if (!options) {
                  break;
                }
                /** Если мультиселект */
                if (isMulti) {
                  acc[curItem.id] = [];

                  options.forEach(option => {
                    acc[curItem.id].push({
                      value: option.id,
                      label: option.name,
                    });
                  });
                } else {
                  acc[curItem.id] = {
                    value: (curItem.values[0] && curItem.values[0].id) || null,
                    label: (curItem.values[0] && curItem.values[0].name) || null,
                  };
                }

                break;
              }

              /** Если есть в предыдущем показателе */
              if (
                measuresValues &&
                measuresValues[INDICATOR_CHARTS_TAB] &&
                measuresValues[INDICATOR_CHARTS_TAB][curItem.id]
              ) {
                acc[curItem.id] = {
                  ...measuresValues[INDICATOR_CHARTS_TAB][curItem.id],
                };
                break;
              }

              /** Если мультиселект */
              if (curItem.select_prop && curItem.values.length) {
                acc[curItem.id] = curItem.values.map(({ id: value, name: label }) => ({
                  value,
                  label,
                }));

                break;
              }

              /** Если обычный селект */
              acc[curItem.id] = {
                value: (curItem.values[0] && curItem.values[0].id) || null,
                label: (curItem.values[0] && curItem.values[0].name) || null,
              };

              break;
            }

            case CHECKBOXES:
              /** Если есть в URL */
              if (measuresValuesTerritories[curItem.id]) {
                if (!acc[curItem.id]) {
                  acc[curItem.id] = {};
                }
                if (Array.isArray(measuresValuesTerritories[curItem.id])) {
                  measuresValuesTerritories[curItem.id].forEach(id => {
                    acc[curItem.id][id] = true;
                  });
                }
                else {
                  acc[curItem.id][measuresValuesTerritories[curItem.id]] = true;
                }

                break;
              }

              /** Если есть в предыдущем показателе */
              if (
                measuresValues &&
                measuresValues[INDICATOR_CHARTS_TAB] &&
                measuresValues[INDICATOR_CHARTS_TAB][curItem.id]
              ) {
                acc[curItem.id] = {
                  ...measuresValues[INDICATOR_CHARTS_TAB][curItem.id],
                };
                break;
              }

              acc[curItem.id] = curItem.values.reduce(
                (chAcc, chOption, chIndex) => ({
                  ...chAcc,
                  [chOption.id]: chIndex === 0,
                }),
                {},
              );
              break;
            case PIE:
              acc[curItem.id] = curItem.values.reduce(
                (chAcc, chOption, chIndex) => ({
                  ...chAcc,
                  [chOption.id]: true,
                }),
                {},
              );
              break;

            default:
              acc[curItem.id] = {
                value: (curItem.values[0] && curItem.values[0].id) || null,
                label: (curItem.values[0] && curItem.values[0].name) || null,
              };
          }
          return acc;
        }, {});
      }

      dispatch(setMeasuresValues(fieldValues, INDICATOR_CHARTS_TAB));
    }
    if (!savedCurrentChartIndicator.current && currentChartIndicator) {
      savedCurrentChartIndicator.current = { ...currentChartIndicator };
    }
  }, [currentChartIndicator]);

  const handleChangeMeasuresValues = newMeasuresValues => {
    if (newMeasuresValues && newMeasuresValues[INDICATOR_CHARTS_TAB]) {
      const urlParams = Object.keys(newMeasuresValues[INDICATOR_CHARTS_TAB]).reduce(
        (acc, curKey) => {
          const values = newMeasuresValues[INDICATOR_CHARTS_TAB][curKey];
          acc[`mv[${curKey}]`] = Array.isArray(values)
            ? values.map(({ value }) => value).join(',')
            : values.value;

          if (!acc[`mv[${curKey}]`]) {
            acc[`mv[${curKey}]`] = Object.keys(values)
              .filter(key => values[key])
              .join(',');
          }

          return acc;
        },
        {},
      );

      dispatch(setURLParams(urlParams));
    }
  };

  if (!currentChartIndicator) {
    return null;
  }

  return (
    <Measures
      measures={measures}
      key={currentChartIndicator.chart_type}
      namespace={INDICATOR_CHARTS_TAB}
      handleChangeMeasuresValues={handleChangeMeasuresValues}
    />
  );
};

ChartsMeasures.propTypes = {
  currentChartIndicator: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    chart_type: PropTypes.string,
  }),
  measuresValues: PropTypes.shape({
    [INDICATOR_CHARTS_TAB]: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
  isInSidebar: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

ChartsMeasures.defaultProps = {
  currentChartIndicator: null,
  isInSidebar: false,
};

const mapStateToProps = state => ({
  currentChartIndicator: state.main.currentChartIndicator,
  measuresValues: state.main.measuresValues,
});

export default connect(mapStateToProps)(ChartsMeasures);

import gradient from 'gradient-color';

export const LANG_RU = {
  short: 'ru',
  full: 'ru-RU',
};

export const LANG_EN = {
  short: 'en',
  full: 'en-US',
};

export const INDICATOR_TERRITORY_TAB = 'territory';
export const INDICATOR_OBJECTS_TAB = 'objects';
export const INDICATOR_CHARTS_TAB = 'charts';

export const CHART_TAB_INNER_MODE_CONFIG = 'config';
export const CHART_TAB_INNER_MODE_CHART = 'chart';

export const SELECT_WEEKDAYS = 'SELECT_WEEKDAYS';
export const SELECT_HOLIDAYS = 'SELECT_HOLIDAYS';
export const SELECT_MANUALLY = 'SELECT_MANUALLY';

export const WEEK_DAYS = [
  {
    text: 'ПН',
    value: 1,
    checked: false,
  },
  {
    text: 'ВТ',
    value: 2,
    checked: false,
  },
  {
    text: 'СР',
    value: 3,
    checked: false,
  },
  {
    text: 'ЧТ',
    value: 4,
    checked: false,
  },
  {
    text: 'ПТ',
    value: 5,
    checked: false,
  },
  {
    text: 'СБ',
    value: 6,
    checked: false,
  },
  {
    text: 'ВС',
    value: 7,
    checked: false,
  },
];

export const MONTHS_SHORT = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Май',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек',
];

export const WEEK_DAYS_ADDITIONAL_FILTER = [
  {
    text: 'будни',
    value: SELECT_WEEKDAYS,
    checked: false,
  },
  {
    text: 'выходные',
    value: SELECT_HOLIDAYS,
    checked: false,
  },
  {
    text: 'вручную',
    value: SELECT_MANUALLY,
    checked: true,
  },
];

export const DAYTIME = [
  {
    text: 'Утро',
    value: 'morning',
    checked: false,
  },
  {
    text: 'День',
    value: 'afternoon',
    checked: false,
  },
  {
    text: 'Вечер',
    value: 'evening',
    checked: false,
  },
  {
    text: 'Ночь',
    value: 'night',
    checked: false,
  },
];

export const CHECKBOXES = 'checkbox';
export const NUMBER = 'number';
export const SELECT = 'list';
export const RADIO = 'radio';
export const PIE = 'pie';
export const MULTISELECT = 'multi';

export const INPUT_EMAIL_PATTERN = new RegExp(
  /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,20})$/,
);
// gotta come up with a language switcher
// and one more: default date to datepicker

export const CHART_GROUP_HALFHOUR = 'halfhour';
export const CHART_GROUP_HOUR = 'hour';
export const CHART_GROUP_DAY = 'day';
export const CHART_GROUP_WEEK = 'week';
export const CHART_GROUP_MONTH = 'month';
export const CHART_GROUP_YEAR = 'year';

export const DIMENSIONS_NAMES = {
  avg_value: 'Среднее за период',
  min_value: 'Мин. за период',
  max_value: 'Макс. за период',
  sum_value: 'Сумма за период',
  med_value: 'Медиана за период',
  median_value: 'Медиана за период',
  last_value: 'Последнее за период',
  avg_summary: 'Среднее за период',
  min_summary: 'Мин. за период',
  max_summary: 'Макс. за период',
  sum_summary: 'Сумма за период',
  med_summary: 'Медиана за период',
  median_summary: 'Медиана за период',
  last_summary: 'Последнее за период',
};

export const DIMENSIONS_NAMES_CONSTRUCTOR = {
  avg_total: 'Среднее',
  min_total: 'Мин.',
  max_total: 'Макс.',
  sum_total: 'Сумма',
  med_total: 'Медиана',
  median_total: 'Медиана',
  last_total: 'Последнее',
  avg_value: 'средних',
  min_value: 'мин.',
  max_value: 'макс.',
  sum_value: 'сумм',
  last_value: 'последних',
  median_value: 'медиан',
  med_value: 'медиан',
  for_period: 'за период',
};

export const MIXED_INCLUDE = 'MIXED_INCLUDE';
export const MIXED_EXCLUDE = 'MIXED_EXCLUDE';

export const INDICATORS_GROUP_PALETTE_DEFAULT = 'default';
export const INDICATORS_GROUP_PALETTE_TEMPERATURE = 'temperature';
export const INDICATORS_GROUP_PALETTE_TEMPERATURE_REVERSE = 'temperature-reverse';
export const INDICATORS_GROUP_PALETTE_RED = 'red';
export const INDICATORS_GROUP_PALETTE_YELLOW_RED = 'yellow-red';

export const PALETTE_DEFAULT_HSL_COLORS = [240, 22];
export const PALETTE_DEFAULT_HSL_COLORS_SECOND = [32, 93];
export const PALETTE_TEMPERATURE_HEX_COLORS = ['#016711', '#C3BE03', '#B00303'];
export const PALETTE_TEMPERATURE_HEX_COLORS_GRADIENT = gradient(
  PALETTE_TEMPERATURE_HEX_COLORS,
  100,
);
export const PALETTE_TEMPERATURE_REVERSE_HEX_COLORS_GRADIENT = gradient(
  PALETTE_TEMPERATURE_HEX_COLORS.reverse(),
  100,
);
export const PALETTE_RED_HEX_COLORS = ['#FF9898', '#FF5555', '#E71D1D', '#A30000', '#530000'];
export const PALETTE_RED_HEX_COLORS_GRADIENT = gradient(PALETTE_RED_HEX_COLORS, 100);
export const PALETTE_YELLOW_RED_HEX_COLORS = [
  '#FFE27C',
  '#F7B86C',
  '#EE804F',
  '#E44631',
  '#BE1313',
];
export const PALETTE_YELLOW_RED_HEX_COLORS_GRADIENT = gradient(PALETTE_YELLOW_RED_HEX_COLORS, 100);

export const MAX_COUNT_TERRITORIES_AREA_SELECT = 1000;

export const FACT_UNIT_NAMES = {
  percent: '%',
};

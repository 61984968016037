import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';

// Misc
import { MIXED_EXCLUDE, MIXED_INCLUDE } from 'modules/main/constants';

// Styles
import './assets/styles/styles.scss';

const Checkbox = ({ onClick, checked, className, disabled, label, ...dataAttrs }) => (
  <button
    role="checkbox"
    aria-checked={checked === MIXED_INCLUDE || checked === MIXED_EXCLUDE ? 'mixed' : checked}
    className={cx('checkbox', {
      [className]: className,
    })}
    type="button"
    onClick={onClick}
    disabled={disabled}
    aria-label={label}
    {...dataAttrs}
  >
    {checked === true && <IconCheck className="checkbox__checked" />}
    {(checked === MIXED_INCLUDE || checked === MIXED_EXCLUDE) && (
      <span className="checkbox__mixed" />
    )}
  </button>
);

Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  checked: PropTypes.oneOf([true, false, MIXED_EXCLUDE, MIXED_INCLUDE]).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  className: null,
  disabled: false,
};

export default Checkbox;

import React from 'react';
import ReactDatePicker from 'react-datepicker';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconPeriod } from 'assets/icons/icon-period-choice.svg';
import { parseDateISOString } from 'helpers';

import 'react-datepicker/dist/react-datepicker.css';

const Datepicker = ({ className, date, minDate, maxDate, onChange, timeRanges }) => (
  <div
    className={cx('datepicker', {
      [className]: className,
    })}
  >
    <ReactDatePicker
      selected={date}
      onChange={onChange}
      dateFormat="dd.MM.Y"
      dropdownMode="scroll"
      minDate={minDate}
      maxDate={maxDate}
      includeDates={
        timeRanges && timeRanges.map(range => new Date(parseDateISOString(range.value)))
      }
    />
    <IconPeriod className="period-select__dates-icon" />
  </div>
);

Datepicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

Datepicker.defaultProps = {
  className: null,
  date: null,
  minDate: null,
  maxDate: null,
};

export default Datepicker;

import Select, { components } from 'react-select';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useLang from 'hooks/useLang';

// Components
import { selectStyles } from 'assets/styles/select';
import Checkbox from '../../../../shared/Checkbox';

// Styles

export const MultiSelect = ({ value, options, placeholder, handleChange }) => {
  /** Переводы */
  const langOb = useLang('MultiSelect');

  const selectAllOption = {
    value: 'all',
    label: langOb && langOb.selectAll,
  };

  const isSelectAllSelected = () => value && value.length === options.length;

  const getOptions = () => [selectAllOption, ...options];

  const getValue = () => (isSelectAllSelected() ? getOptions() : value);

  const Option = props => (
    <components.Option {...props}>
      <Checkbox checked={props.isSelected} label={props.label} onClick={() => null} />
      <span>{props.data.label}</span>
    </components.Option>
  );

  const ValueContainer = ({ children, getValue, ...props }) => {
    const length = getValue().filter(option => option.value !== selectAllOption.value).length;

    return (
      <components.ValueContainer {...props}>
        {`${placeholder} (${length} из ${options.length})`}
        {React.cloneElement(children[1])}
      </components.ValueContainer>
    );
  };

  // const MultiValue = ({ children, ...props }) => {
  //   const multiValueProps = { ...props };

  //   multiValueProps.components = {
  //     Container: components.MultiValueContainer,
  //     Label: components.MultiValueLabel,
  //     Remove: components.MultiValueRemove,
  //   };
  //   return <components.MultiValue {...multiValueProps}>{children[1]}</components.MultiValue>;
  // };

  const onChange = useCallback(
    (value, actionMeta) => {
      const { action, option } = actionMeta;

      if (action === 'select-option' && option.value === selectAllOption.value) {
        /** Если включаем чекбокс "выбрано все" */
        handleChange(options);
      } else if (action === 'deselect-option' && option.value === selectAllOption.value) {
        /** Если выключаем чекбокс "выбрано все" */
        handleChange([]);
      } else if (action === 'deselect-option' && isSelectAllSelected()) {
        /** Если снимаем другой чекбокс, когда включен чекбокс "выбрано все" */
        handleChange(options.filter(({ value }) => value !== option.value));
      } else {
        /** Поведение чекбоксов, кроме "выбрано все" */
        handleChange(value);
      }
    },
    [handleChange],
  );

  return (
    <Select
      key={JSON.stringify(value)}
      closeMenuOnSelect={false}
      isMulti
      hideSelectedOptions={false}
      removeSelected={false}
      defaultValue={value ? (value[0] && Object.values(value)) || value : null}
      components={{ Option, ValueContainer, ClearIndicator: () => null }}
      styles={selectStyles}
      classNamePrefix="indicators-multiselect"
      options={getOptions()}
      onChange={onChange}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      value={getValue()}
      isSearchable={false}
    />
  );
};

MultiSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
};

MultiSelect.defaultProps = {
  value: null,
  options: null,
  placeholder: null,
  handleChange: () => {},
};

export default MultiSelect;

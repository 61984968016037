import * as types from 'modules/main/actions/action-types';
import { combineReducers } from 'redux';
import { filterActions } from 'redux-ignore';

import main from 'modules/main/reducer/main';
import polygons from 'modules/main/reducer/polygons';

export default combineReducers({
  main,
  // main: ignoreActions(main, [types.GET_POLYGONS_SUCCESS]),
  // polygons,
  polygons: filterActions(polygons, action => action.type === types.GET_POLYGONS_SUCCESS),
});

import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
import Datepicker from 'shared/Datepicker';

// Actions
import { changeDateStart, changeDateEnd, setURLParams } from 'modules/main/actions';

// Icons
import { ReactComponent as IconDelimiter } from 'assets/icons/icon-date-delimiter.svg';

// Styles
import './assets/styles/styles.scss';

export const DatesRange = ({ periodFilter, dispatch, className, dataStat, namespace, timeRanges }) => {
  const onHandleDateStartChange = date => {
    dispatch(changeDateStart(date, namespace));
    dispatch(
      setURLParams({
        dateStart: Date.parse(date),
      }),
    );
  };

  const onHandleDateEndChange = date => {
    const endOfTheDay = new Date(date);
    endOfTheDay.setHours(23);
    endOfTheDay.setMinutes(59);
    dispatch(changeDateEnd(endOfTheDay, namespace));
    dispatch(
      setURLParams({
        dateEnd: Date.parse(endOfTheDay),
      }),
    );
  };

  const { dateStart, dateEnd } = periodFilter;

  let periodMin = new Date(1970);
  if (dataStat && dataStat.start_date && Date.parse(dataStat.start_date)) {
    periodMin = new Date(Date.parse(dataStat.start_date));
  }

  let periodMax = new Date(2500);
  if (dataStat && dataStat.end_date && Date.parse(dataStat.end_date)) {
    periodMax = new Date(Date.parse(dataStat.end_date));
  }

  let maxDateForStartDate = new Date(new Date(periodMax).setDate(periodMax.getDate()));
  if (dateEnd) {
    maxDateForStartDate = new Date(new Date(dateEnd).setDate(new Date(dateEnd).getDate()));
  }

  let minDateForEndDate = new Date(new Date(periodMin));
  if (dateStart) {
    minDateForEndDate = new Date(new Date(dateStart));
  }

  return (
    <div
      className={cx('dates-range', {
        [className]: className,
      })}
    >
      <div className="dates-range__datepickers-wrapper">
        <Datepicker
          className="dates-range__datepicker"
          date={dateStart}
          onChange={onHandleDateStartChange}
          minDate={periodMin}
          maxDate={maxDateForStartDate}
          timeRanges={timeRanges}
        />
        <div className="dates-range__delimiter">
          <IconDelimiter className="dates-range__delimiter-icon" />
        </div>
        <Datepicker
          className="dates-range__datepicker"
          date={dateEnd}
          onChange={onHandleDateEndChange}
          minDate={minDateForEndDate}
          maxDate={periodMax}
          timeRanges={timeRanges}
        />
      </div>
    </div>
  );
};

DatesRange.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  periodFilter: PropTypes.shape({
    dateStart: PropTypes.instanceOf(Date),
    dateEnd: PropTypes.instanceOf(Date),
  }).isRequired,
  dataStat: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }).isRequired,
  timeRanges: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  namespace: PropTypes.string.isRequired,
};

DatesRange.defaultProps = {
  className: null,
};

export default connect()(DatesRange);

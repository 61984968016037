import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { getReports, getReportsPage } from 'modules/main/actions';

// Misc
import useLang from 'hooks/useLang';

// Icons
import { ReactComponent as IconDownload } from 'assets/icons/icon-download.svg';
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';
import { ReactComponent as IconClearSearch } from 'assets/icons/icon-clear-search.svg';

// Styles
import './assets/styles/styles.scss';

export const Reports = ({ reports, currentCityId, title, dispatch }) => {
  /** Переводы */
  const langOb = useLang('Reports');

  const [isFetching, setIsFetching] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const prevCurrentCityId = useRef(currentCityId);

  useEffect(() => {
    if (!title || !title.length) {
      dispatch(getReportsPage);
    }
  }, []);

  useEffect(() => {
    if (!reports || !reports.count || currentCityId !== prevCurrentCityId.current) {
      prevCurrentCityId.current = currentCityId;
      loadReports();
    }
  }, [currentCityId]);

  const loadReports = explicitSearchValue => {
    if (currentCityId) {
      setIsFetching(true);
      dispatch(
        getReports(
          currentCityId,
          explicitSearchValue !== undefined ? explicitSearchValue : searchValue,
        ),
      )
        .then(() => {
          setIsFetching(false);
        })
        .catch(() => {
          setIsFetching(false);
        });
    }
  };

  if (!langOb) {
    return null;
  }

  return (
    <div className="reports">
      <h1 className="reports__title">{title.length ? title : langOb.title}</h1>

      <div className="reports__search">
        <p className="reports__search-title">{langOb.searchTitle}</p>
        <div className="reports__search-row">
          <div className="reports__search-input-holder">
            <input
              type="text"
              className="reports__search-input"
              value={searchValue}
              onChange={({ target }) => {
                setSearchValue(target.value);
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  loadReports();
                }
              }}
            />
            <button
              type="button"
              className="reports__search-clear-button"
              onClick={() => {
                setSearchValue('');
                loadReports('');
              }}
              aria-label={langOb.clearSearch}
            >
              <IconClearSearch className="reports__search-clear-icon" />
            </button>
          </div>
          <button type="button" className="reports__search-button" onClick={() => loadReports()}>
            {langOb.search}
          </button>
        </div>
      </div>

      {!isFetching && reports && reports.count > 0 && reports.results && (
        <div className="reports__list">
          {reports.results.map(item => (
            <a
              href={`${item.file_link}`}
              download={`${item.name}.${item.file_extend}`}
              target="_blank"
              rel="noreferrer noopener"
              className="reports__item"
              key={item.id}
            >
              <IconDownload className="reports__item-icon" />
              <div className="reports__item-content">
                <h4 className="reports__item-file-row">
                  <div className="reports__item-file-name">
                    {`${item.name}.${item.file_extend}`}
                  </div>
                  <div className="reports__item-file-info">
                    {`(${item.file_size}, ${item.created})`}
                  </div>
                </h4>
                <div className="reports__item-desc">{item.description}</div>
              </div>
            </a>
          ))}
        </div>
      )}

      {isFetching && (
        <div className="reports__loading" data-testid="reports-loading">
          <IconLoader className="reports__loading-icon" />
        </div>
      )}
    </div>
  );
};

Reports.propTypes = {
  reports: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        file_size: PropTypes.string,
        file_link: PropTypes.string,
        file_extend: PropTypes.string,
        description: PropTypes.string,
        created: PropTypes.string,
      }),
    ),
    count: PropTypes.number,
  }).isRequired,
  title: PropTypes.string,
  currentCityId: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
};

Reports.defaultProps = {
  currentCityId: null,
  title: '',
};

const mapStateToProps = state => ({
  reports: state.main.reports,
  currentCityId: state.main.currentCity.id,
  title: state.main.reportsPageTitle.title,
});

export default connect(mapStateToProps)(Reports);

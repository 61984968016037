import React, { useState } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import InputRange from 'react-input-range';
import DEFAULT_CLASS_NAMES from 'react-input-range/src/js/input-range/default-class-names';
import useToggleAndOutClick from 'use-toggle-and-outclick';

// Actions
import { setPolygonsOpacity } from 'modules/main/actions';

// Icons
import { ReactComponent as IconCompare } from 'assets/icons/icon-compare.svg';

// Misc
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const OpacityControl = ({ className, polygonsOpacity, dispatch }) => {
  const [isDropOpened, dropEl, handleClick] = useToggleAndOutClick();
  const [rangeValue, setRangeValue] = useState(polygonsOpacity);

  /** Переводы */
  const langOb = useLang('OpacityControl');

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('opacity-control', {
        [className]: className,
      })}
    >
      <button
        type="button"
        className={cx('opacity-control__button', {
          'opacity-control__button_active': isDropOpened || rangeValue < 100,
        })}
        onClick={handleClick}
        aria-label={langOb.toggleButtonLabel}
      >
        <IconCompare className="opacity-control__button-icon" />
        {rangeValue < 100 && <span className="opacity-control__current-value">{rangeValue}</span>}
      </button>

      <CSSTransition
        in={isDropOpened}
        timeout={200}
        classNames="opacity-control__drop"
        unmountOnExit
      >
        <div className="opacity-control__drop" ref={dropEl} data-testid="opacity-control-drop">
          <p className="opacity-control__drop-title">{langOb.title}</p>
          <div className="opacity-control__drop-range">
            <InputRange
              maxValue={100}
              minValue={0}
              value={rangeValue}
              onChange={setRangeValue}
              onChangeComplete={value => {
                dispatch(setPolygonsOpacity(value));
              }}
              formatLabel={value => `${value}%`}
              classNames={{
                ...DEFAULT_CLASS_NAMES,
                track: `${DEFAULT_CLASS_NAMES.track} opacity-control__range-track`,
                valueLabel: `${DEFAULT_CLASS_NAMES.valueLabel} opacity-control__range-value-label`,
                activeTrack: `${DEFAULT_CLASS_NAMES.activeTrack} opacity-control__range-track_active`,
              }}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

OpacityControl.propTypes = {
  className: PropTypes.string,
  polygonsOpacity: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

OpacityControl.defaultProps = {
  className: null,
};

const mapStateToProps = state => ({
  polygonsOpacity: state.main.polygonsOpacity,
});

export default connect(mapStateToProps)(OpacityControl);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Components
import LayersControl from 'modules/main/components/LayersControl';
import ExportControl from 'modules/main/components/ExportControl';
import OpacityControl from 'modules/main/components/OpacityControl';
import MapButtonGroup from 'modules/main/components/MapButtonGroup';
import MapButtonGroupSeparator from 'modules/main/components/MapButtonGroupSeparator';
import FiltersContainer from 'modules/main/components/FiltersContainer';
import ExitSelectionMode from 'modules/main/components/ExitSelectionMode';
import MapWrapper from 'modules/main/components/MapWrapper';

// Actions
import {
  getFactsMemo,
  getIndicators,
  getIndicatorsGroup,
  getPolygons,
  selectIndicator,
  toggleSelectionMode,
  exitSelectionMode,
  toggleFiltersPanel,
  getDefaultIndicatorGroup,
} from 'modules/main/actions';

// Helpers
import { getMeasuresValuesFromURL, getMapLevelFromURL } from 'helpers';
import getTerritoryIds from 'helpers/get-territory-ids';
import useLang from 'hooks/useLang';

// Icons
import { ReactComponent as IconArrow } from 'assets/icons/icon-arrow.svg';

// Misc
import { CHECKBOXES, SELECT } from 'modules/main/constants';

// Styles
import './assets/styles/styles.scss';

const ChartsFullScreen = React.lazy(() => import('modules/main/components/ChartsFullScreen'));

const MapContainer = props => {
  const {
    className,
    polygons,
    isChartsFullScreen,
    match,
    currentCity,
    isInSelectionMode,
    dispatch,
    isFiltersPanelOpen,
    user,
  } = props;

  /** Переводы */
  const langOb = useLang('MapContainer');

  /** Загружаем группу показателей по id из URL */
  useEffect(() => {
    if (match.params.indicatorGroupId) {
      const indicatorGroupId = +match.params.indicatorGroupId;
      dispatch(getIndicatorsGroup(indicatorGroupId))
        .then(res => {
          if (res.active) {
            loadDataByIndicatorGroup(indicatorGroupId, {
              name: res.name,
            });
          }
        })
        .catch(() => {
          window.history.replaceState(null, '', '/');
        });
    }
  }, []);

  /** Загружаем группу показателей по дефолту для города (если нет в url) */
  useEffect(() => {
    /** Загружаем только сразу после того как загрузился город */
    if (currentCity.id && !match.params.indicatorGroupId) {
      dispatch(getDefaultIndicatorGroup(currentCity.id)).then(res => {
        if (res && res.id && res.active) {
          window.history.replaceState(null, res.name, `/${res.id}`);
          loadDataByIndicatorGroup(res.id, {
            name: res.name,
          });
        }
      });
    }
  }, [currentCity, match.params.indicatorGroupId]);

  const loadDataByIndicatorGroup = (groupId, additionalData = {}) => {
    /** Сюда будем складывать территории для показателей полученных по группе */
    let allTerritoryIds = [];

    /** Получаем показатели для выбранной группы показателей */
    dispatch(getIndicators(groupId))
      .then(indicators => {
        if (indicators.length) {
          /** Выбираем показатель из location (если есть) или первый */
          let fromURL = false;
          let indicator;
          const indicatorIdFromURL = +match.params.indicatorId;
          if (indicatorIdFromURL) {
            indicator = indicators.find(item => item.id === indicatorIdFromURL);
            if (indicator) {
              fromURL = true;
              dispatch(selectIndicator(indicator, true));
            }
          }
          if (!fromURL) {
            [indicator] = indicators;
            dispatch(selectIndicator(indicator, true));

            /** Update location */
            window.history.replaceState(null, additionalData.name, `/${groupId}/${indicator.id}`);
          }

          /** Получение фактов (мер) */
          const { measures, id } = indicator;

          /** Парсим location в поисках значений мер */
          const measuresValuesTerritories = getMeasuresValuesFromURL();

          /** Формируем поисковую строку */
          const queryString = measures.reduce((acc, curItem) => {
            /** Смотрим есть ли значения и что это не чекбокс */
            if (curItem.values.length && curItem.data_type !== CHECKBOXES) {
              /** Get from URL */
              if (
                measuresValuesTerritories[curItem.id] &&
                curItem.values.find(item => item.id === measuresValuesTerritories[curItem.id])
              ) {
                return `${acc}measures[${curItem.id}]=${measuresValuesTerritories[curItem.id]}&`;
              }

              if (curItem.select_prop && curItem.data_type === SELECT && curItem.values.length) {
                /** Multiselect */
                return `${acc}measures[${curItem.id}]=${curItem.values.map(v => v.id)}&`;
              }
              /** Default */
              return `${acc}measures[${curItem.id}]=${curItem.values[0].id}&`;
            }
            return '';
          }, '');

          let mapLevelId = getMapLevelFromURL();
          if (!mapLevelId && !indicator.hierarchy.find(item => item.id === mapLevelId)) {
            mapLevelId = indicator.hierarchy[0].id;
          }

          return dispatch(
            getFactsMemo(indicator.has_second_map, mapLevelId, id, queryString, false),
          );
        }
        return Promise.reject(Error('Нет показателей'));
      })
      .then(res => {
        /**
         * Получение полигонов по территориям,
         * которые пришли в фактах (мерах)
         */
        allTerritoryIds = getTerritoryIds(res, polygons);
        if (allTerritoryIds.length) {
          return dispatch(getPolygons(allTerritoryIds)).then();
        }

        return Promise.reject();
      })
      .catch(() => {});
  };

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('map', {
        [className]: className,
      })}
    >
      {isFiltersPanelOpen && !isInSelectionMode.a && !isInSelectionMode.b && (
        <FiltersContainer
          className="map__filter-container"
          toggleSelectionMode={namespace => {
            dispatch(toggleSelectionMode(namespace));
          }}
        />
      )}

      {isFiltersPanelOpen && (isInSelectionMode.a || isInSelectionMode.b) && (
        <ExitSelectionMode
          className="map__exit-selection-mode"
          handleClick={() => {
            dispatch(exitSelectionMode());
          }}
        />
      )}

      {!isFiltersPanelOpen && (
        <button
          type="button"
          className="map__open-filters-panel-button"
          onClick={() => {
            dispatch(toggleFiltersPanel());
          }}
        >
          {langOb.filters}
          <IconArrow className="map__open-filters-panel-icon" />
        </button>
      )}

      {isChartsFullScreen && (
        <React.Suspense fallback={null}>
          <ChartsFullScreen />
        </React.Suspense>
      )}

      <MapWrapper />

      {/* ВЕРХНЯЯ ГРУППА КОНТРОЛОВ */}
      {!isInSelectionMode.a && !isInSelectionMode.b && (
        <div className="map__top-controls">
          {user && user.can_export && (
            <MapButtonGroup className="map__export">
              <ExportControl />
            </MapButtonGroup>
          )}
          <MapButtonGroup className="map__layer-and-compare">
            <LayersControl />
            <MapButtonGroupSeparator />
            <OpacityControl />
          </MapButtonGroup>
        </div>
      )}
      {/* /ВЕРХНЯЯ ГРУППА КОНТРОЛОВ */}
    </div>
  );
};

MapContainer.propTypes = {
  className: PropTypes.string,
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  dispatch: PropTypes.func.isRequired,
  isChartsFullScreen: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      indicatorGroupId: PropTypes.string,
      indicatorId: PropTypes.string,
    }),
  }).isRequired,
  currentCity: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    point: PropTypes.arrayOf(PropTypes.number),
  }),
  isInSelectionMode: PropTypes.shape({
    a: PropTypes.bool,
    b: PropTypes.bool,
  }).isRequired,
  isFiltersPanelOpen: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    can_export: PropTypes.bool,
  }),
};

MapContainer.defaultProps = {
  className: 'app__map',
  currentCity: null,
};

const mapStateToProps = state => ({
  polygons: state.polygons,
  isChartsFullScreen: state.main.isChartsFullScreen,
  selectedPolygons: state.main.selectedPolygons,
  currentCity: state.main.currentCity,
  isInSelectionMode: state.main.isInSelectionMode,
  isFiltersPanelOpen: state.main.isFiltersPanelOpen,
  user: state.main.user,
});

export default connect(mapStateToProps)(MapContainer);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Misc
import useLang from 'hooks/useLang';

// Actions
import { getFavoritesItems, removeFavoritesItem, updateFavoritesItem } from '../../actions';

// Components
import FavoriteItem from './Item';
import AddToFavorites from '../AddToFavorites';

// Styles
import './assets/styles/styles.scss';

export const Favorites = ({ dispatch, favorites }) => {
  const [renamedItemId, setRenamedItemId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (favorites && !favorites.isLoaded) {
      dispatch(getFavoritesItems());
    }
  }, []);

  const renameItem = async fields => {
    try {
      await dispatch(updateFavoritesItem(fields, renamedItemId));
      setRenamedItemId(null);
    } catch (err) {
      setError(err);
    }
  };

  const lang = useLang('Favorites');

  if (!lang || !favorites.isLoaded) {
    return null;
  }

  if (favorites.error) {
    return <div>Произошла ошибка</div>;
  }

  if (renamedItemId) {
    const favoritesItem = favorites.items.find(({ id }) => renamedItemId === id);
    return (
      <AddToFavorites
        closeHandler={() => setRenamedItemId(null)}
        save={renameItem}
        url={favoritesItem.state}
        name={favoritesItem.title}
        error={error}
      />
    );
  }

  return (
    <div className="favorites">
      <div className="favorites__title">{lang.title}</div>
      <div className="favorites-container">
        {favorites.items.map(({ id, title, state }) => (
          <FavoriteItem
            id={id}
            url={state}
            key={id}
            title={title}
            remove={() => dispatch(removeFavoritesItem(id))}
            rename={() => setRenamedItemId(id)}
          />
        ))}
      </div>
    </div>
  );
};

Favorites.propTypes = {
  dispatch: PropTypes.func.isRequired,
  favorites: PropTypes.shape({
    isLoaded: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    error: PropTypes.any, // в нашем случае пофиг какого типа ошибка
  }).isRequired,
};

const mapStateToProps = state => ({ favorites: state.main.favorites });

export default connect(mapStateToProps)(Favorites);

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useLang = namespace => {
  const [dict, setDict] = useState(null);
  const lang = useSelector(state => state.main.lang);

  useEffect(() => {
    const getLang = async () => {
      try {
        if (lang && lang.short) {
          const i18n = await import(`i18n/${namespace}/${lang.short}.json`);
          setDict(i18n.default);
        } else {
          setDict({});
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error);
        /* eslint-enable no-console */
        setDict({});
      }
    };

    getLang();
  }, [namespace, lang]);

  return dict;
};

export default useLang;

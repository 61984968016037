import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Checkbox from 'shared/Checkbox';
import Search from 'shared/Search';

// Styles
import './assets/styles/styles.scss';

const TerritoriesFiltersSelect = ({ measure, territoriesFiltersValues, onClose, applyChanges }) => {
  const [foundTerritories, setFoundTerritories] = useState(null);

  const values = Object.keys(territoriesFiltersValues[measure.id]).map(id => ({
    id,
    name: territoriesFiltersValues[measure.id][id].name,
    selected: territoriesFiltersValues[measure.id][id].selected,
  }));

  const [selectedValues, setSelectedValues] = useState(
    values.filter(v => v.selected).map(v => v.id),
  );

  const allSelected = selectedValues.length === values.length;

  const toggleSelectAll = () => {
    if (allSelected) {
      return setSelectedValues([]);
    }

    return setSelectedValues(values.map(v => v.id));
  };

  const toggleSelect = valueId => {
    if (selectedValues.includes(valueId)) {
      const newState = [...selectedValues];
      const index = newState.indexOf(valueId);
      newState.splice(index, 1);

      return setSelectedValues(newState);
    }

    return setSelectedValues([...selectedValues, valueId]);
  };

  const saveChanges = () => {
    applyChanges(measure.id, selectedValues);
    onClose();
  };

  // eslint-disable-next-line consistent-return
  const searchTerritories = query => {
    if (query === '') {
      return setFoundTerritories(null);
    }

    setFoundTerritories(
      values
        .filter(region => region.name.toLowerCase().includes(query.toLowerCase()))
        .map(region => region),
    );
  };

  if (!territoriesFiltersValues) {
    return null;
  }

  const renderList = regions => (
    <React.Fragment>
      {regions.map(value => (
        <div
          className="territories-filters-select__item-container territories-filters-select__item-container_dark"
          key={value.id}
        >
          <Checkbox
            onClick={() => toggleSelect(value.id)}
            checked={selectedValues.includes(value.id)}
            className="territories-filters-select__item-checkbox"
            label="Выбрать всё"
          />
          <span className="territories-filters-select__item-name">{value.name}</span>
        </div>
      ))}
    </React.Fragment>
  );

  return (
    <div className="territories-filters-select">
      <h3 className="territories-filters-select__title">Выберите один или несколько типов</h3>

      <React.Fragment>
        <Search onSearch={searchTerritories} />
        {foundTerritories !== null ? (
          <div className="territories-filters-select__items">{renderList(foundTerritories)}</div>
        ) : (
          <React.Fragment>
            <div className="territories-filters-select__item-container">
              <Checkbox
                onClick={() => toggleSelectAll()}
                checked={allSelected}
                className="territories-filters-select__item-checkbox"
                label="Выбрать всё"
              />
              <span className="territories-filters-select__item-name">Выбрать всё</span>
            </div>

            <div className="territories-filters-select__items">{renderList(values)}</div>
          </React.Fragment>
        )}

        <div className="territories-filters-select__buttons">
          <button
            type="button"
            className="territories-filters-select__clear-button"
            onClick={() => setSelectedValues([])}
          >
            Снять выделение
          </button>

          <button
            type="button"
            className="territories-filters-select__apply-button"
            onClick={saveChanges}
          >
            Применить
          </button>
        </div>
      </React.Fragment>
    </div>
  );
};

TerritoriesFiltersSelect.propTypes = {
  measure: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  territoriesFiltersValues: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
};

export default TerritoriesFiltersSelect;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './assets/styles/styles.scss';

const ToggleControl = ({ onToggle, checked, className }) => (
  <button
    type="button"
    role="checkbox"
    aria-checked={checked}
    className={cx('toggle-control', {
      [className]: className,
    })}
    onClick={onToggle}
  />
);

ToggleControl.propTypes = {
  onToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
};

ToggleControl.defaultProps = {
  className: null,
  checked: false,
};

export default ToggleControl;

import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useWindowSize from '@rehooks/window-size';

// Components
import Popup from 'shared/Popup';
import AreaSelect from 'modules/main/components/AreaSelect';

// Actions
import {
  setTerritoriesCleanState,
  getFactsMemo,
  getPolygons,
  getChartFactsMemo,
  getRatingFactsMemo,
  getObjectsForIndicator,
  updateFilterRegions,
} from 'modules/main/actions';

// Icons
import { ReactComponent as IconClose } from 'assets/icons/icon-close-white.svg';
import { ReactComponent as IconTerritory } from 'assets/icons/icon-territory.svg';
import { ReactComponent as IconTerritoryA } from 'assets/icons/icon-territory-a.svg';
import { ReactComponent as IconTerritoryB } from 'assets/icons/icon-territory-b.svg';
import { ReactComponent as IconSelection } from 'assets/icons/icon-selection.svg';

// Misc
import { INDICATOR_CHARTS_TAB, CHART_TAB_INNER_MODE_CHART } from 'modules/main/constants';
import getTerritoryIds from 'helpers/get-territory-ids';
import { getNoun } from 'helpers';
import useLang from 'hooks/useLang';

// Styles
import { mobile } from 'assets/breakpoints';
import './assets/styles/styles.scss';

const AreasFilter = ({
  isChartsFullScrenn,
  dispatch,
  className,
  currentIndicatorsGroup,
  currentIndicator,
  facts,
  factsTwoMaps,
  territoriesCleanState,
  currentIndicatorTab,
  chartTabInnerMode,
  currentChartIndicator,
  measuresValues,
  currentMapLevelId,
  polygons,
  toggleSelectionMode,
  regions,
  mapLevels,
}) => {
  const langOb = useLang('AreasFilter');

  const windowSize = useWindowSize();

  const [isTerritoryModalOpen, setIsTerritoryModalOpen] = useState(false);
  const [territoryModalNamespace, setTerritoryModalNamespace] = useState(null);
  const [areaModalNamespace, setAreaModalNamespace] = useState(null);

  const getDataName = (levels, id) => {
    if (levels.length && id) {
      const dataNames = levels.find(item => item.id === id).data_names;
      if (dataNames && dataNames.length < 20) return dataNames;
    }
    if (langOb) return langOb.territory;
    return '';
  };

  const dataName = useMemo(() => getDataName(mapLevels, currentMapLevelId), [
    mapLevels,
    currentMapLevelId,
  ]);

  const activateTerritoryModal = namespace => {
    /**
     * При открытии попапа с территориями ставим
     * флаг что фильтр не был изменен
     */
    dispatch(setTerritoriesCleanState(namespace));
    setIsTerritoryModalOpen(true);
    setTerritoryModalNamespace(namespace);
    setAreaModalNamespace(namespace);
  };

  const deactivateTerritoryModal = () => {
    setIsTerritoryModalOpen(false);

    if (!currentMapLevelId) {
      return;
    }

    /**
     * При закрытии попапа с территориями проверяем
     * если изменился фильтр территорий, то делаем
     * запрос на факты
     */
    if (!territoriesCleanState[territoryModalNamespace]) {
      changeTerritories();
    }

    setTerritoryModalNamespace(null);
  };

  const changeTerritories = () => {
    if (!territoriesCleanState[territoryModalNamespace]) {
      dispatch(getFactsMemo(null, currentMapLevelId))
        .then(res => {
          const allTerritoryIds = getTerritoryIds(res, polygons);
          if (allTerritoryIds.length) {
            return dispatch(getPolygons(allTerritoryIds)).then();
          }

          return Promise.resolve(true);
        })
        .catch(() => {});

      /** Если открыта вкладка с рейтингами - перестраиваем */
      if (
        currentIndicatorTab === INDICATOR_CHARTS_TAB &&
        chartTabInnerMode === CHART_TAB_INNER_MODE_CHART &&
        currentChartIndicator
      ) {
        if (currentChartIndicator.chart_type === 'rating') {
          dispatch(getRatingFactsMemo());
        } else {
          dispatch(getChartFactsMemo());
        }
      }

      /** Перезагружаем все выбранные объекты */
      const selectedObjects = Object.keys(measuresValues).filter(id => measuresValues[id].selected);
      for (let i = 0; i < selectedObjects.length; i += 1) {
        const indicatorId = selectedObjects[i];
        dispatch(getObjectsForIndicator(indicatorId)).then(data =>
          dispatch(getPolygons(data.map(t => t.territory_id), indicatorId)),
        );
      }
    }
  };

  const cancelTerritories = namespace => {
    if (!currentMapLevelId) {
      return;
    }
    const canceledTerritories = Object.keys(regions[namespace]).reduce((acc, key) => {
      acc[key] = regions[namespace][key];
      acc[key].checked = false;
      return acc;
    }, {});
    dispatch(updateFilterRegions(canceledTerritories, namespace));
    /**
     * Делаем запрос на факты
     */
    if (!territoriesCleanState[territoryModalNamespace]) {
      changeTerritories();
    }
  };

  if (!langOb) {
    return null;
  }

  return (
    <div className={cx('areas-filter', { 'areas-filter_dark': isChartsFullScrenn }, className)}>
      {/* Territory A */}
      <div className="areas-filter__item areas-filter__item_flex">
        <button
          className="areas-filter__item-button areas-filter__item-button_justify"
          type="button"
          onClick={() => activateTerritoryModal('a')}
          disabled={!currentIndicatorsGroup}
        >
          <div className="areas-filter__item-left">
            {!currentIndicator || !currentIndicator.has_second_map ? (
              <IconTerritory className="areas-filter__item-icon" />
            ) : (
              <IconTerritoryA className="areas-filter__item-icon" />
            )}

            <div className="areas-filter__item-names">
              <p className="areas-filter__item-name">
                {currentIndicator && currentIndicator.has_second_map ? langOb.leftMap : dataName}
              </p>
              <p className="areas-filter__item-desc">
                {/* Одна карта */}
                {currentIndicator &&
                  !currentIndicator.has_second_map &&
                  facts.data &&
                  (facts.data.length === facts.selected_territories
                    ? `${facts.selected_territories} ${getNoun(
                        facts.selected_territories,
                        langOb.territory1,
                        langOb.territory2,
                        langOb.territory5,
                      )}`
                    : `${facts.data.length} (из ${facts.selected_territories}) ${getNoun(
                        facts.data.length,
                        langOb.territory1,
                        langOb.territory2,
                        langOb.territory5,
                      )}`)}

                {/* Две карты */}
                {currentIndicator &&
                  currentIndicator.has_second_map &&
                  factsTwoMaps.data_from.data &&
                  (factsTwoMaps.data_from.data.length ===
                  factsTwoMaps.data_from.selected_territories
                    ? `${factsTwoMaps.data_from.selected_territories} ${getNoun(
                        factsTwoMaps.data_from.selected_territories,
                        langOb.territory1,
                        langOb.territory2,
                        langOb.territory5,
                      )}`
                    : `${factsTwoMaps.data_from.data.length} (из ${
                        factsTwoMaps.data_from.selected_territories
                      }) ${getNoun(
                        factsTwoMaps.data_from.data.length,
                        langOb.territory1,
                        langOb.territory2,
                        langOb.territory5,
                      )}`)}
              </p>
            </div>
          </div>
        </button>
        <button
          type="button"
          className="areas-filter__cancel-terrs-button"
          onClick={() => cancelTerritories('a')}
          disabled={false}
        >
          <IconClose />
        </button>
        {!isChartsFullScrenn && windowSize.innerWidth > mobile && (
          <button
            type="button"
            className="areas-filter__create-selection-button"
            onClick={() => {
              toggleSelectionMode('a');
            }}
            disabled={!currentIndicatorsGroup}
          >
            <IconSelection />
          </button>
        )}
      </div>
      {/* /Territory A */}

      {/* Territory B */}
      {currentIndicator && currentIndicator.has_second_map && (
        <div className="areas-filter__item areas-filter__item_flex">
          <button
            className="areas-filter__item-button areas-filter__item-button_justify"
            type="button"
            onClick={() => activateTerritoryModal('b')}
            disabled={!currentIndicatorsGroup}
          >
            <div className="areas-filter__item-left">
              <IconTerritoryB className="areas-filter__item-icon" />
              <div className="areas-filter__item-names">
                <p className="areas-filter__item-name">{langOb.rightMap}</p>
                <p className="areas-filter__item-desc">
                  {/* Две карты */}
                  {currentIndicator &&
                    currentIndicator.has_second_map &&
                    factsTwoMaps.data_to.data &&
                    (factsTwoMaps.data_to.data.length === factsTwoMaps.data_to.selected_territories
                      ? `${factsTwoMaps.data_to.selected_territories} ${getNoun(
                          factsTwoMaps.data_to.selected_territories,
                          langOb.territory1,
                          langOb.territory2,
                          langOb.territory5,
                        )}`
                      : `${factsTwoMaps.data_to.data.length} (из ${
                          factsTwoMaps.data_to.selected_territories
                        }) ${getNoun(
                          factsTwoMaps.data_to.data.length,
                          langOb.territory1,
                          langOb.territory2,
                          langOb.territory5,
                        )}`)}
                </p>
              </div>
            </div>
          </button>

          <button
            type="button"
            className="areas-filter__cancel-terrs-button"
            onClick={() => cancelTerritories('b')}
            disabled={false}
          >
            <IconClose />
          </button>

          {!isChartsFullScrenn && windowSize.innerWidth > mobile && (
            <button
              type="button"
              className="areas-filter__create-selection-button"
              onClick={() => {
                toggleSelectionMode('b');
              }}
              disabled={!currentIndicatorsGroup}
            >
              <IconSelection className="areas-filter__item-selection-icon" />
            </button>
          )}
        </div>
      )}
      {/* /Territory B */}

      {isTerritoryModalOpen && (
        <Popup closeHandler={deactivateTerritoryModal}>
          <AreaSelect applyHandler={deactivateTerritoryModal} namespace={areaModalNamespace} />
        </Popup>
      )}
    </div>
  );
};

AreasFilter.propTypes = {
  isChartsFullScrenn: PropTypes.bool,
  className: PropTypes.string,
  currentIndicatorsGroup: PropTypes.shape({
    name: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  currentIndicator: PropTypes.shape({
    has_second_map: PropTypes.bool,
    objects: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  facts: PropTypes.shape({
    data: PropTypes.array,
    selected_territories: PropTypes.number,
  }),
  factsTwoMaps: PropTypes.shape({
    data_to: PropTypes.shape({
      data: PropTypes.array,
      selected_territories: PropTypes.number,
    }),
    data_from: PropTypes.shape({
      data: PropTypes.array,
      selected_territories: PropTypes.number,
    }),
  }),
  regions: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number,
        loading: PropTypes.bool,
      }),
    ),
  ).isRequired,
  territoriesCleanState: PropTypes.shape({
    a: PropTypes.bool,
    b: PropTypes.bool,
  }).isRequired,
  currentIndicatorTab: PropTypes.string,
  chartTabInnerMode: PropTypes.string.isRequired,
  currentChartIndicator: PropTypes.shape({
    chart_type: PropTypes.string,
  }),
  measuresValues: PropTypes.shape({}),
  currentMapLevelId: PropTypes.number,
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  toggleSelectionMode: PropTypes.func.isRequired,
  mapLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      data_names: PropTypes.string,
      data_names_to: PropTypes.string,
    }),
  ),
};

AreasFilter.defaultProps = {
  className: null,
  isChartsFullScrenn: false,
  currentIndicatorsGroup: null,
  currentIndicator: null,
  facts: { data: [] },
  factsTwoMaps: { data_to: {}, data_from: {} },
  currentIndicatorTab: null,
  currentChartIndicator: null,
  measuresValues: {},
  currentMapLevelId: null,
  mapLevels: [],
};

const mapStateToProps = state => ({
  regions: state.main.filterRegions,
  currentIndicatorsGroup: state.main.currentIndicatorsGroup,
  currentIndicator: state.main.currentIndicator,
  facts: state.main.facts,
  factsTwoMaps: state.main.factsTwoMaps,
  territoriesCleanState: state.main.territoriesCleanState,
  currentIndicatorTab: state.main.currentIndicatorTab,
  chartTabInnerMode: state.main.chartTabInnerMode,
  currentChartIndicator: state.main.currentChartIndicator,
  measuresValues: state.main.measuresValues.objects,
  mapLevels: state.main.mapLevels,
  currentMapLevelId: state.main.currentMapLevelId,
  polygons: state.polygons,
});

export default connect(mapStateToProps)(AreasFilter);

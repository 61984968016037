/**
 * Получение айдшников территорий из запроса не меры
 * @param {object} res - ответ с ручки "факты/меры"
 * @param {object} polygons - объект с закэшированными полигонами
 */
const getTerritoryIds = (res, polygons) => {
  /**
   * Получение полигонов по территориям,
   * которые пришли в фактах (мерах) для обычной карты
   */
  if (res && res.data) {
    return res.data.map(item => item.territory_id).filter(id => !polygons[id]);
  }

  /**
   * Получение полигонов по территориям,
   * которые пришли в фактах (мерах) для двойной карты
   */
  if (res && res.data_from && res.data_to) {
    return [
      ...res.data_from.data.map(item => item.territory_id).filter(id => !polygons[id]),
      ...res.data_to.data.map(item => item.territory_id).filter(id => !polygons[id]),
    ].filter((item, index, self) => self.indexOf(item) === index);
  }

  return [];
};

export default getTerritoryIds;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './assets/styles/styles.scss';

/**
 * Компонент для оборачивания нескольких
 * контролов для карты в группу
 */
const MapButtonGroup = ({ className, children }) => (
  <div
    className={cx('map-button-group', {
      [className]: className,
    })}
  >
    {children}
  </div>
);

MapButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

MapButtonGroup.defaultProps = {
  className: null,
};

export default MapButtonGroup;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
import Popup from 'shared/Popup';
import PeriodSelect from 'modules/main/components/PeriodSelect';

// Misc
import { generateNumberDate, generateDateStringPeriod, parseDateISOString } from 'helpers';

// Icons
import { ReactComponent as IconCalendar } from 'assets/icons/icon-calendar.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';

// Misc
import { INDICATOR_CHARTS_TAB, INDICATOR_TERRITORY_TAB } from 'modules/main/constants';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const PeriodSelectTrigger = ({
  periodFilter,
  namespace,
  className,
  applyPeriod,
  resetPeriod,
  dataRanges,
  dataStat,
  timeRanges
}) => {
  const localPeriodFilter = periodFilter[namespace];

  /** Переводы */
  const langOb = useLang('PeriodSelectTrigger');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const deactivatePeriodModal = () => {
    setIsModalOpen(false);
  };

  if (!localPeriodFilter) {
    return null;
  }

  if (!langOb) {
    return null;
  }

  /**
   * Генерим период по умолчанию из dataStat
   */
  if (dataRanges !== 'monthly' && !localPeriodFilter.dateStart && !localPeriodFilter.dateEnd) {
    localPeriodFilter.dateStart =
      new Date(dataStat.start_date) instanceof Date && !isNaN(new Date(dataStat.start_date))
        ? new Date(parseDateISOString(dataStat.start_date))
        : null;
    localPeriodFilter.dateEnd =
      new Date(dataStat.end_date) instanceof Date && !isNaN(new Date(dataStat.end_date))
        ? new Date(parseDateISOString(dataStat.end_date))
        : null;
  }

  if (
    dataRanges === 'monthly' &&
    !localPeriodFilter.monthStart &&
    !localPeriodFilter.monthEnd &&
    !localPeriodFilter.yearStart &&
    !localPeriodFilter.yearEnd
  ) {
    [localPeriodFilter.yearStart, localPeriodFilter.monthStart] = generateNumberDate(
      dataStat.start_date,
    );
    [localPeriodFilter.yearEnd, localPeriodFilter.monthEnd] = generateNumberDate(dataStat.end_date);
  }

  return (
    <div
      className={cx('period-select-trigger', {
        [className]: className,
      })}
    >
      <button
        className="period-select-trigger__button"
        type="button"
        onClick={() => {
          setIsModalOpen(true);
        }}
        aria-label={langOb.specifyPeriod}
      >
        <IconCalendar className="period-select-trigger__icon" />
        <div className="period-select-trigger__names">
          <p className="period-select-trigger__name">{langOb.period}</p>
          <p className="period-select-trigger__desc">
            {generateDateStringPeriod(dataRanges, localPeriodFilter)}
          </p>
        </div>
      </button>

      {(localPeriodFilter.monthStart !== null ||
        localPeriodFilter.yearStart !== null ||
        localPeriodFilter.monthEnd !== null ||
        localPeriodFilter.yearEnd !== null ||
        localPeriodFilter.dateStart !== null ||
        localPeriodFilter.dateEnd !== null) && (
        <button
          className="period-select-trigger__reset-button"
          type="button"
          onClick={resetPeriod}
          aria-label={langOb.resetPeriod}
        >
          <IconClose className="period-select-trigger__reset-icon" />
        </button>
      )}

      {isModalOpen && (
        <Popup closeHandler={deactivatePeriodModal}>
          <PeriodSelect
            closeModal={deactivatePeriodModal}
            periodFilter={localPeriodFilter}
            applyPeriod={applyPeriod}
            namespace={namespace}
            dataRanges={dataRanges}
            dataStat={dataStat}
            timeRanges={timeRanges}
          />
        </Popup>
      )}
    </div>
  );
};

PeriodSelectTrigger.propTypes = {
  dataRanges: PropTypes.string.isRequired,
  periodFilter: PropTypes.shape({
    dateStart: PropTypes.instanceOf(Date),
    dateEnd: PropTypes.instanceOf(Date),
    monthStart: PropTypes.number,
    monthEnd: PropTypes.number,
    yearStart: PropTypes.number,
    yearEnd: PropTypes.number,
  }).isRequired,
  timeRanges: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  namespace: PropTypes.oneOf([INDICATOR_TERRITORY_TAB, INDICATOR_CHARTS_TAB]).isRequired,
  className: PropTypes.string,
  applyPeriod: PropTypes.func.isRequired,
  resetPeriod: PropTypes.func.isRequired,
  dataStat: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }).isRequired,
};

PeriodSelectTrigger.defaultProps = {
  className: null,
  timeRanges: null
};

const mapStateToProps = state => ({
  periodFilter: state.main.currentPeriodFilter,
  timeRanges: state.main.currentIndicator.time_ranges,
});

export default connect(mapStateToProps)(PeriodSelectTrigger);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import {
  setTerritoriesFiltersValues,
  setSelectedTerritoriesFilters,
  setURLParams,
  dropParsedParameters,
  getObjectsForIndicator,
} from 'modules/main/actions';

// Constants
import { INDICATOR_OBJECTS_TAB } from 'modules/main/constants';

// Components
import ObjectsCategoryFilter from '../ObjectsCategoryFilter';

const ObjectsCategories = ({
  measures,
  dispatch,
  handleChangeTerritoriesFiltersValues,
  territoriesFiltersValues,
  currentIndicator,
  objectId,
  hide,
  currentFacts,
  urlParsedTerritoriesFiltersValues,
  objects,
}) => {
  /** Сюда попадают отфильтрованные по выбранным территориям объекты */
  let measuresSelectTerritories = measures;
  const [isSelectedObjects, setIsSelectedObjects] = useState(false);

  useEffect(() => {
    if (!measures) {
      return;
    }
    /** Фильтруем объекты по выбранным территориям */
    if (measures && currentFacts.data) {
      const idSelectedTerritories = currentFacts.data.map(item => item.territory_id);
      measuresSelectTerritories = measures.map(category =>
        Object.assign({}, category, {
          items: category.items.filter(
            item =>
              idSelectedTerritories.includes(item.parent_ids[0]) ||
              idSelectedTerritories.includes(item.parent_ids[1]),
          ),
        }),
      );

      const fieldValues = measuresSelectTerritories.reduce((acc, current) => {
        const values = current.items.reduce((valAcc, valCurrent) => {
          // eslint-disable-next-line no-param-reassign
          valAcc[valCurrent.id] = {
            name: valCurrent.name,
            selected: false,
          };

          return valAcc;
        }, {});

        acc.a = { ...acc.a };
        acc.b = { ...acc.b };

        acc.a[`${objectId}-${current.id}`] = JSON.parse(JSON.stringify(values));
        acc.b[`${objectId}-${current.id}`] = JSON.parse(JSON.stringify(values));

        return acc;
      }, {});

      if (fieldValues.a === undefined) {
        fieldValues.a = [];
      }

      if (fieldValues.b === undefined) {
        fieldValues.b = [];
      }

      const mapsToUpdate = {
        a: true,
        b: true,
      };

      ['a', 'b'].forEach(mapMarker => {
        /** Проставляем выбранные объекты в зависимости от territoriesFiltersValues */
        if (Object.values(territoriesFiltersValues[mapMarker]).length !== 0) {
          for (const prop in fieldValues[mapMarker]) {
            if ({}.hasOwnProperty.call(fieldValues[mapMarker], prop)) {
              for (const sprop in fieldValues[mapMarker][prop]) {
                if (
                  territoriesFiltersValues[mapMarker][prop] &&
                  {}.hasOwnProperty.call(territoriesFiltersValues[mapMarker][prop], sprop)
                ) {
                  fieldValues[mapMarker][prop][sprop].selected =
                    (territoriesFiltersValues[mapMarker][prop][sprop] &&
                      territoriesFiltersValues[mapMarker][prop][sprop].selected) ||
                    false;
                }
              }
            }
          }
        }

        if (mapsToUpdate[mapMarker]) {
          dispatch(
            setTerritoriesFiltersValues(fieldValues[mapMarker], INDICATOR_OBJECTS_TAB, mapMarker),
          );
        }
      });
    }
  }, [currentFacts]);

  useEffect(() => {
    if (
      territoriesFiltersValues &&
      currentFacts.data &&
      objects &&
      Object.keys(objects).length &&
      urlParsedTerritoriesFiltersValues
    ) {
      Object.values(urlParsedTerritoriesFiltersValues).forEach(value => {
        const { measureId, mapMarker, selectedValues } = value;
        dispatch(setSelectedTerritoriesFilters(measureId, selectedValues, mapMarker)).then(() =>
          dispatch(getObjectsForIndicator(measureId.split('-')[0])),
        );
      });

      dispatch(dropParsedParameters('territoriesFiltersValues'));
    }
  }, [territoriesFiltersValues, currentFacts, objects]);

  /** Проверяем выбранность объектов, чтобы выделить default-состояние */
  useEffect(() => {
    let acc = 1;
    for (const [key, category] of Object.entries(territoriesFiltersValues.a)) {
      if (key.startsWith(`${objectId}-`)) {
        acc *= Object.values(category)
          .map(item => item.selected)
          .every(item => !item);
      }
    }

    acc !== 0 ? setIsSelectedObjects(false) : setIsSelectedObjects(true);
  }, [territoriesFiltersValues]);

  /** Помечаем выбранные в попапе фильтры. Запрашиваем обновление данных */
  const applyChanges = mapMarker => (measureId, selectedValues) => {
    dispatch(setSelectedTerritoriesFilters(measureId, selectedValues, mapMarker)).then(newValues =>
      handleChangeTerritoriesFiltersValues(newValues),
    );

    dispatch(
      setURLParams({
        [`territoriesFiltersValues[${measureId}]`]: JSON.stringify({
          measureId,
          selectedValues,
          mapMarker,
        }),
      }),
    );
  };

  if (!measures || !territoriesFiltersValues) {
    return null;
  }

  /** Скрываем тер. фильтры, если объект не выбран */
  if (hide) {
    return null;
  }

  return (
    <div className="territories-filters">
      {territoriesFiltersValues &&
        measuresSelectTerritories
          .map(measure => ({
            id: `${objectId}-${measure.id}`,
            name: measure.name,
            items: measure.items,
          }))
          .map(measure => (
            <React.Fragment key={measure.id}>
              <ObjectsCategoryFilter
                measure={measure}
                applyChanges={applyChanges('a')}
                territoriesFiltersValues={territoriesFiltersValues.a}
                byDefault={!isSelectedObjects}
              />
              {currentIndicator && currentIndicator.has_second_map && (
                <ObjectsCategoryFilter
                  measure={measure}
                  applyChanges={applyChanges('b')}
                  territoriesFiltersValues={territoriesFiltersValues.b}
                  byDefault={!isSelectedObjects}
                />
              )}
            </React.Fragment>
          ))}
    </div>
  );
};

ObjectsCategories.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleChangeTerritoriesFiltersValues: PropTypes.func.isRequired,
  measures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  territoriesFiltersValues: PropTypes.shape({
    a: PropTypes.shape({}),
    b: PropTypes.shape({}),
  }),
  currentIndicator: PropTypes.shape({
    has_second_map: PropTypes.bool,
  }),
  hide: PropTypes.bool,
  objectId: PropTypes.number,
  currentFacts: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  urlParsedTerritoriesFiltersValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

ObjectsCategories.defaultProps = {
  measures: null,
  territoriesFiltersValues: null,
  currentIndicator: null,
  hide: false,
  objectId: null,
  urlParsedTerritoriesFiltersValues: null,
};

const mapStateToProps = state => ({
  objects: state.main.currentObjects,
  territoriesFiltersValues: state.main.territoriesFiltersValues.objects,
  currentIndicator: state.main.currentIndicator,
  currentFacts: state.main.facts,
  urlParsedTerritoriesFiltersValues: state.main.parsedParameters.territoriesFiltersValues,
});

export default connect(mapStateToProps)(ObjectsCategories);

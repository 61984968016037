import React from 'react';
import ReactDom from 'react-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './assets/styles/styles.scss';

const MapSelection = ({ className, selection }) => {
  if (!selection || selection.endX === undefined || selection.endY === undefined) {
    return null;
  }

  const { startX, startY, endX, endY } = selection;

  const style = {
    top: `${Math.min(startY, endY)}px`,
    left: `${Math.min(startX, endX)}px`,
    width: `${Math.abs(startX - endX)}px`,
    height: `${Math.abs(startY - endY)}px`,
  };

  return ReactDom.createPortal(
    <div
      className={cx('map-selection', {
        [className]: className,
      })}
      style={style}
    />,
    document.body,
  );
};

MapSelection.propTypes = {
  className: PropTypes.string,
  selection: PropTypes.shape({
    startX: PropTypes.number,
    startY: PropTypes.number,
    endX: PropTypes.number,
    endY: PropTypes.number,
  }).isRequired,
};

MapSelection.defaultProps = {
  className: null,
};

export default MapSelection;

import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// Components
import Header from 'modules/main/components/Header';

//  Actions
import { resetNotification, setNotificationRemovable, removeNotifications } from 'modules/main/actions';

// Misc
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/app.scss';

export const App = ({ children, location, notification, notificationsRemovable, dispatch }) => {
  /** Переводы */
  const langOb = useLang('App');

  const notificationDOMRef = useRef(null);

  /** Эффект показывает уведомления */
  useEffect(() => {
    if (
      langOb &&
      notificationDOMRef &&
      notificationDOMRef.current &&
      notification.title &&
      notification.message
    ) {
      const idNotification = notificationDOMRef.current.addNotification({
        title: langOb[notification.title],
        message: langOb[notification.message],
        type: notification.type,
        insert: 'top',
        container: 'top-center',
        animationIn: ['in'],
        animationOut: ['out'],
        dismiss: { duration: notification.duration || 0 },
        dismissable: { click: true },
      });
      if (notification.removable) {
        dispatch(setNotificationRemovable(idNotification));
      }

      dispatch(resetNotification());
    }
  }, [notification.title, notification.message]);

  useEffect(() => {
    if (notificationsRemovable.remove) {
      notificationsRemovable.ids.forEach(id => notificationDOMRef.current.removeNotification(id));
      dispatch(removeNotifications());
    }
  }, [notificationsRemovable.remove])

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('app', {
        'app_unlimited-height':
          location &&
          ((location.pathname.indexOf('static') > -1 &&
            location.pathname.indexOf('contacts') < 0) ||
            location.pathname.indexOf('reports') > -1),
      })}
    >
      <ReactNotification ref={notificationDOMRef} />
      <Header className="app__header" />
      {children}
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  notification: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    duration: PropTypes.number,
    removable: PropTypes.bool,
  }).isRequired,
  notificationsRemovable: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.string),
    remove: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

App.defaultProps = {
  location: null,
};

const mapStateToProps = state => ({
  notification: state.main.notification,
  notificationsRemovable: state.main.notificationsRemovable,
});

export default withRouter(connect(mapStateToProps)(App));

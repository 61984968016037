import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import DEFAULT_CLASS_NAMES from 'react-input-range/src/js/input-range/default-class-names';

// Actions
import { setTerritoryRangeFilter } from 'modules/main/actions';

// Misc
import { formatNumber, getIndexDecimal, getDepthDecimal } from 'helpers';

// Constants
import {
  INDICATORS_GROUP_PALETTE_TEMPERATURE,
  INDICATORS_GROUP_PALETTE_TEMPERATURE_REVERSE,
  INDICATORS_GROUP_PALETTE_RED,
  INDICATORS_GROUP_PALETTE_YELLOW_RED,
} from 'modules/main/constants';

import './assets/styles/styles.scss';

export const MapTerritoryRangeFilter = ({
  className,
  currentIndicator,
  facts,
  namespace,
  palette,
  dispatch,
}) => {
  const { agg_func: aggFunc = '', data = [] } = facts || {};

  /**
   * Вычисляем минимальное и максимальное значения
   */
  const minValue = facts && facts.min_value;
  const maxValue = facts && facts.max_value;

  const [value, setValue] = useState({
    min: minValue,
    max: maxValue,
  });

  const [areLabelsVisible, setAreLabelsVisible] = useState(false);

  if (!data.length || !aggFunc) {
    return null;
  }

  return (
    <div
      className={cx('map-territory-filter', {
        [className]: className,
      })}
    >
      {currentIndicator && Boolean(currentIndicator.name) && (
        <p className="map-territory-filter__name" title={currentIndicator.name}>
          {currentIndicator.map_name || currentIndicator.name}
        </p>
      )}
      <div className="map-territory-filter__range-container">
        <span className="map-territory-filter__label">
          {formatNumber(minValue, 1, getIndexDecimal(minValue))}
        </span>
        <div className="map-territory-filter__range">
          <InputRange
            minValue={minValue}
            maxValue={maxValue}
            disabled={data.length < 2}
            step={getDepthDecimal(minValue)}
            onChange={rangeValue => {
              setValue(
                Object.keys(rangeValue).reduce((acc, key) => {
                  if (key === 'min') {
                    acc[key] = Math.max(minValue, rangeValue[key]);
                  } else if (key === 'max') {
                    acc[key] = Math.min(maxValue, rangeValue[key]);
                  }
                  return acc;
                }, {}),
              );
              setAreLabelsVisible(true);
            }}
            onChangeComplete={rangeValue => {
              setTimeout(() => {
                setAreLabelsVisible(false);
              }, 500);
              dispatch(setTerritoryRangeFilter(rangeValue, namespace));
            }}
            value={value}
            formatLabel={number => formatNumber(number, 1, getIndexDecimal(minValue))}
            classNames={{
              ...DEFAULT_CLASS_NAMES,
              track: cx(DEFAULT_CLASS_NAMES.track, 'map-territory-filter__range-track', {
                [`map-territory-filter__range-track_${namespace}`]: true,
                [`map-territory-filter__range-track_${namespace}-mono`]: data.length < 2,
                [`map-territory-filter__range-track_${namespace}-temperature`]:
                  palette === INDICATORS_GROUP_PALETTE_TEMPERATURE,
                [`map-territory-filter__range-track_${namespace}-temperature-reverse`]:
                  palette === INDICATORS_GROUP_PALETTE_TEMPERATURE_REVERSE,
                [`map-territory-filter__range-track_${namespace}-red`]:
                  palette === INDICATORS_GROUP_PALETTE_RED,
                [`map-territory-filter__range-track_${namespace}-yellow-red`]:
                  palette === INDICATORS_GROUP_PALETTE_YELLOW_RED,
              }),
              labelContainer: cx(
                DEFAULT_CLASS_NAMES.labelContainer,
                'map-territory-filter__range-label-container',
                {
                  'map-territory-filter__range-label-container_hidden': !areLabelsVisible,
                },
              ),
              activeTrack: cx(
                DEFAULT_CLASS_NAMES.activeTrack,
                'map-territory-filter__range-track_active',
              ),
            }}
          />
        </div>
        <span className="map-territory-filter__label">
          {`${formatNumber(maxValue, 1, getIndexDecimal(maxValue))} ${facts.unit_name}`}
        </span>
      </div>
    </div>
  );
};

MapTerritoryRangeFilter.propTypes = {
  className: PropTypes.string,
  currentIndicator: PropTypes.shape({
    map_name: PropTypes.string,
    name: PropTypes.string,
    unit_name: PropTypes.string,
  }),
  facts: PropTypes.shape({
    agg_func: PropTypes.string,
    unit_name: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({}),
      }),
    ),
    min_value: PropTypes.number,
    max_value: PropTypes.number,
  }).isRequired,
  namespace: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  palette: PropTypes.string.isRequired,
};

MapTerritoryRangeFilter.defaultProps = {
  className: null,
  currentIndicator: {},
};

export default connect()(MapTerritoryRangeFilter);

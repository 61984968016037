import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { DIMENSIONS_NAMES } from 'modules/main/constants';
import { formatNumber } from 'helpers';

import './assets/styles.scss';

const InfoBox = ({
  langOb,
  className,
  color,
  territoryName,
  currentIndicator,
  facts,
  territoryId,
  comment,
  parents,
  measurePercents,
  measureName,
  measureValue,
  isObject,
}) => {
  const unitName = facts && facts.unit_name;
  let territoryFacts = null;

  if (facts && facts.data) {
    const territoryFact = facts.data.find(item => item.territory_id === territoryId);
    if (territoryFact && territoryFact.data) {
      territoryFacts = territoryFact.data;
    }
  }

  const { agg_period: aggPeriod = [], val_precision: precision } = currentIndicator;

  return (
    <div
      className={cx('info-box', {
        [className]: className,
      })}
    >
      <h3 className="info-box__territory-name">{territoryName}</h3>
      {!isObject && <p className="info-box__indicator-name">{currentIndicator.description}</p>}

      {territoryFacts && (
        <React.Fragment>
          {Object.keys(territoryFacts)
            .filter(item => item !== 'count' && aggPeriod.includes(item.replace('_value', '')))
            .map(item => (
              <p className="info-box__fact" key={item}>
                {`${DIMENSIONS_NAMES[item]}:`}
                {` `}
                {territoryFacts[item] !== null ? (
                  <span className="info-box__fact-value">
                    {formatNumber(territoryFacts[item], 1, precision)}
                    {` ${unitName}`}
                  </span>
                ) : (
                  <span className="info-box__fact-value">0</span>
                )}
              </p>
            ))}
        </React.Fragment>
      )}

      {comment && (
        <div
          className={cx('info-box__description', { 'info-box__description_no-border': isObject })}
        >
          {comment}
        </div>
      )}

      {isObject && measureValue && measureValue !== 'null' && (
        <div>
          <p className="info-box__measure">
            {measureName && measureName.length > 0 ? `${measureName}:` : ''}
            {` `}
            {measureValue !== null || measureValue !== 'null' ? (
              <span className="info-box__measure-value">{measureValue}</span>
            ) : (
              <span className="info-box__measure-value">0</span>
            )}
          </p>
          <div className="info-box__measure-indicator">
            <div
              className="info-box__measure-indicator-value"
              style={{
                width: Number(measurePercents) > 1 ? `${measurePercents}%` : '1%',
                backgroundColor: color,
              }}
            />
          </div>
        </div>
      )}

      {!isObject && (
        <div className="info-box__select-container">
          <button
            type="button"
            className="info-box__select-button"
            data-id={territoryId}
            data-name={territoryName}
            data-parents={parents}
          >
            <span>{langOb.selectTerritory}</span>
          </button>
          <div className="info-box__select-text">
            {langOb.selectInfo1}
            <br />
            {langOb.selectInfo2}
            <b> shift</b>
          </div>
        </div>
      )}
    </div>
  );
};

InfoBox.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  comment: PropTypes.string,
  currentIndicator: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    unit_name: PropTypes.string,
    agg_period: PropTypes.arrayOf(PropTypes.string),
    val_precision: PropTypes.number,
  }).isRequired,
  facts: PropTypes.shape({
    avg_value: PropTypes.number,
    unit_name: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        territory_id: PropTypes.number,
      }),
    ),
  }).isRequired,
  measureName: PropTypes.string,
  measurePercents: PropTypes.string,
  measureValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  parents: PropTypes.string,
  territoryName: PropTypes.string,
  territoryId: PropTypes.number.isRequired,
  langOb: PropTypes.shape({
    selectTerritory: PropTypes.string,
    selectInfo1: PropTypes.string,
    selectInfo2: PropTypes.string,
  }).isRequired,
  isObject: PropTypes.bool.isRequired,
};

InfoBox.defaultProps = {
  className: null,
  color: null,
  territoryName: '',
  measureName: null,
  measurePercents: null,
  measureValue: null,
  comment: null,
  parents: null,
};

export default InfoBox;

import PropTypes from 'prop-types';

/* eslint-disable import/prefer-default-export */
export const factsType = PropTypes.shape({
  agg_func: PropTypes.string,
  sum_summary: PropTypes.number,
  min_summary: PropTypes.number,
  max_summary: PropTypes.number,
  avg_summary: PropTypes.number,
  median_summary: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      territory_id: PropTypes.number,
      territory_parents: PropTypes.arrayOf(PropTypes.number),
      data: PropTypes.shape({
        avg_value: PropTypes.number,
      }),
    }),
  ),
});

import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import useWindowSize from '@rehooks/window-size';

// Components
import HeaderTools from 'modules/main/components/HeaderTools';
import HeaderNav from 'modules/main/components/HeaderNav';

// Actions
import { getMenu } from 'modules/main/actions';

// Images
import defualtLogo from 'assets/images/logo.svg';

// Icons
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconMenu } from 'assets/icons/icon-menu.svg';

// Styles
import { mobile } from 'assets/breakpoints';
import './assets/styles/styles.scss';

export const Header = ({ className, menu, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [menuItems, setMenuItems] = useState(menu);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const logo = `${process.env.REACT_APP_API_URL}pages/logo` || defualtLogo;

  const { innerWidth } = useWindowSize();

  /** Загружаем элементы меню*/
  useEffect(() => {
    setIsLoading(true);
    dispatch(getMenu())
      .then(data => {
        setIsLoading(false);
        setMenuItems(data);
      })
      .catch(() => {});
  }, []);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header
      className={cx('header', {
        [className]: className,
      })}
    >
      <div className="header__container">
        <Link className="header__logo" to="/">
          <img className="header__logo-img" src={logo} alt="InfoNet" />
        </Link>

        {/* DESKTOP */}
        {innerWidth > mobile && (
          <React.Fragment>
            {!isLoading && menuItems && Array.isArray(menuItems) && menuItems.length > 0 && (
              <HeaderNav menuItems={menuItems} className="header__nav-desktop" />
            )}

            {/* TOOLS */}
            <HeaderTools className="header__tools-desktop" isEnabledLanguageSelector />
            {/* /TOOLS */}
          </React.Fragment>
        )}
        {/* /DESKTOP */}

        {/* MOBILE */}
        {innerWidth <= mobile && (
          <button
            type="button"
            className="header__open-mobile-menu-button"
            onClick={() => {
              setIsMobileMenuOpen(true);
            }}
            aria-label="Open menu"
          >
            <IconMenu className="header__open-mobile-menu-icon" />
          </button>
        )}
        {/* /MOBILE */}
      </div>

      {/* MOBILE MENU */}
      {innerWidth <= mobile &&
        isMobileMenuOpen &&
        createPortal(
          <div className="header__mobile-menu">
            <button
              type="button"
              className="header__mobile-menu-close-button"
              onClick={closeMobileMenu}
              aria-label="Close menu"
            >
              <IconClose className="header__mobile-menu-close-icon" />
            </button>

            {!isLoading && menuItems && Array.isArray(menuItems) && menuItems.length > 0 && (
              <HeaderNav
                menuItems={menuItems}
                className="header__nav-mobile"
                closeMobileMenu={closeMobileMenu}
              />
            )}

            {/* TOOLS */}
            <HeaderTools
              className="header__tools-mobile"
              closeMobileMenu={closeMobileMenu}
              isEnabledLanguageSelector
            />
            {/* /TOOLS */}
          </div>,
          document.body,
        )}
      {/* /MOBILE MENU */}
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Header.defaultProps = {
  className: null,
};

const mapStateToProps = state => ({
  menu: state.main.menu,
});

export default connect(mapStateToProps)(Header);

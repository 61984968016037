import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AuthWindow from 'modules/main/components/AuthWindow';
import AuthHeader from 'modules/main/components/AuthHeader';
import EmailInput from 'modules/main/components/AuthFields/EmailInput';
import PasswordInput from 'modules/main/components/AuthFields/PasswordInput';
import AuthButtons from 'modules/main/components/AuthButtons';

// Actions
import { signIn } from 'modules/main/actions';

// Misc
import { INPUT_EMAIL_PATTERN } from 'modules/main/constants';
import { debounce } from 'helpers';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const AuthSignIn = ({ dispatch, successHandler, openPasswordResetForm }) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [invalidEmailTip, setInvalidEmailTip] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  /** Переводы */
  const langOb = useLang('AuthSignIn');

  const passwordInput = useRef();

  const onEmailChange = event => {
    const {
      target: { value },
    } = event;

    setEmail(value);
    setIsInvalidEmail(!INPUT_EMAIL_PATTERN.test(value));
    setInvalidEmailTip(false);

    debouncedSetEmailError.current(value);
  };

  const setEmailError = value => {
    setInvalidEmailTip(!INPUT_EMAIL_PATTERN.test(value));
  };

  const debouncedSetEmailError = useRef(() => debounce(setEmailError, 700));

  const togglePasswordVisibility = () => {
    if (!passwordInput.current) {
      return;
    }

    if (passwordInput.current.type === 'password') {
      passwordInput.current.type = 'text';
    } else {
      passwordInput.current.type = 'password';
    }
  };

  const onPasswordChange = event => {
    const {
      target: { value },
    } = event;

    setPassword(value);
  };

  const signInHandler = () => {
    if (!password || !email || isInvalidEmail || isFetching) {
      return;
    }

    setIsFetching(true);
    setError(null);
    setFieldErrors(null);

    dispatch(signIn(email, password))
      .then(() => {
        setIsFetching(false);
        successHandler();
        window.location.reload();
      })
      .catch(err => {
        if (err instanceof Error) {
          setError(err.message);
        } else if (Object.keys(err).length) {
          setFieldErrors(err);
        }
        setIsFetching(false);
      });
  };

  if (!langOb) {
    return null;
  }

  return (
    <AuthWindow>
      <AuthHeader name={langOb.title} />

      <form className="auth__signing-form" data-testid="auth-form">
        <div className="auth__input-holder">
          <EmailInput
            name={langOb.emailName}
            onEmailChange={onEmailChange}
            invalidEmail={invalidEmailTip}
            invalidEmailText={langOb.invalidEmailText}
            value={email}
            submitForm={signInHandler}
          />
          {fieldErrors && fieldErrors.email && (
            <p className="auth__error-text auth__error-text_red">{fieldErrors.email[0]}</p>
          )}
        </div>

        <div className="auth__input-holder">
          <PasswordInput
            name={langOb.passwordName}
            togglePasswordVisibility={togglePasswordVisibility}
            value={password}
            onPasswordChange={onPasswordChange}
            ref={passwordInput}
            submitForm={signInHandler}
          />
          {fieldErrors && fieldErrors.password && (
            <p className="auth__error-text auth__error-text_red">{fieldErrors.password[0]}</p>
          )}
        </div>

        {error && (
          <p className="auth__error-text auth__error-text_bottom auth__error-text_red">{error}</p>
        )}

        <AuthButtons
          actionButtonName={langOb.actionButtonText}
          actionButtonClick={signInHandler}
          linkName={langOb.linkText}
          linkClick={openPasswordResetForm}
          disabled={!password || !email || isInvalidEmail || isFetching}
        />
      </form>
    </AuthWindow>
  );
};

AuthSignIn.propTypes = {
  dispatch: PropTypes.func.isRequired,
  successHandler: PropTypes.func.isRequired,
  openPasswordResetForm: PropTypes.func.isRequired,
};

export default connect()(AuthSignIn);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { formatNumber } from 'helpers';

import './assets/styles/styles.scss';

const CurrentAreaSummary = ({ className, summary, precision, mapName }) => (
  <div
    className={cx('current-area-summary', {
      [className]: className,
    })}
  >
    {summary.map(item => (
      <React.Fragment key={item.id}>
        {mapName && (
          <p className="current-area-summary__map-name">
            {`${mapName} —`}
            &nbsp;
          </p>
        )}
        <p className="current-area-summary__caption">{`${item.caption}:`}</p>
        <p className="current-area-summary__value" data-testid="current-area-summary-value">
          {`${formatNumber(item.value, 1, precision)} ${item.unit}`}
        </p>
      </React.Fragment>
    ))}
  </div>
);

CurrentAreaSummary.propTypes = {
  className: PropTypes.string,
  summary: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      caption: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unit: PropTypes.string,
    }),
  ).isRequired,
  precision: PropTypes.number.isRequired,
  mapName: PropTypes.string,
};

CurrentAreaSummary.defaultProps = {
  className: null,
  mapName: null,
};

export default CurrentAreaSummary;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './assets/styles/styles.scss';

const AuthButtons = props => {
  const { actionButtonName, actionButtonClick, linkName, linkHref, linkClick, disabled } = props;
  return (
    <div className="auth-actions">
      <div className="auth-actions__block">
        {actionButtonName && actionButtonClick && (
          <button
            className="auth-actions__button"
            type="button"
            onClick={actionButtonClick}
            disabled={disabled}
          >
            {actionButtonName}
          </button>
        )}

        {linkName && linkHref && (
          <Link className="auth-actions__link" to={linkHref}>
            {linkName}
          </Link>
        )}

        {linkName && !linkHref && linkClick && (
          <button type="button" className="auth-actions__link" onClick={linkClick}>
            {linkName}
          </button>
        )}
      </div>
    </div>
  );
};

AuthButtons.propTypes = {
  actionButtonName: PropTypes.string,
  actionButtonClick: PropTypes.func,
  linkName: PropTypes.string,
  linkHref: PropTypes.string,
  linkClick: PropTypes.func,
  disabled: PropTypes.bool,
};

AuthButtons.defaultProps = {
  actionButtonName: null,
  actionButtonClick: null,
  linkName: null,
  linkHref: null,
  linkClick: null,
  disabled: false,
};

export default AuthButtons;

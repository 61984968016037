import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
import PeriodButtons from 'modules/main/components/PeriodButtons';
import DatesRange from 'modules/main/components/DatesRange';
import MonthsRange from 'modules/main/components/MonthsRange';

// Actions
import {
  toggleWeekDay,
  changeWeekdayFilter,
  toggleDaytime,
  resetCurrentPeriodFilter,
  setURLParams,
} from 'modules/main/actions';

// Misc
import { SELECT_WEEKDAYS, SELECT_HOLIDAYS, SELECT_MANUALLY } from 'modules/main/constants';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

const PeriodSelect = ({
  className,
  periodFilter,
  dispatch,
  namespace,
  applyPeriod,
  closeModal,
  dataStat,
  dataRanges,
  timeRanges
}) => {
  /** Переводы */
  const langOb = useLang('PeriodSelect');

  const onSpecificDayClick = index => {
    const { weekDays } = periodFilter;

    const newWeekDays = weekDays.map((item, localIndex) => {
      if (index === localIndex) {
        return {
          ...item,
          checked: !item.checked,
        };
      }
      return item;
    });

    dispatch(toggleWeekDay(newWeekDays, namespace));

    const localWeekdays = weekDays.slice(0, 5);
    const localHolidays = weekDays.slice(5);

    if (weekDays.every(item => item.checked)) {
      /** Отмечаем фильтр "вручную" */
      onWeekDaysFilterClick(2);
    } else if (
      localHolidays.every(item => item.checked) &&
      !localWeekdays.some(item => item.checked)
    ) {
      /** Отмечаем фильтр "выходные" */
      onWeekDaysFilterClick(1);
    } else if (
      localWeekdays.every(item => item.checked) &&
      !localHolidays.some(item => item.checked)
    ) {
      /** Отмечаем фильтр "будни" */
      onWeekDaysFilterClick(0);
    } else {
      /** Иначе - фильтр "вручную" */
      onWeekDaysFilterClick(2);
    }
  };

  const onWeekDaysFilterClick = (index, value) => {
    const { weekDaysFilter } = periodFilter;

    const newWeekDaysFilter = weekDaysFilter.map((item, localIndex) => {
      if (localIndex === index) {
        return {
          ...item,
          checked: true,
        };
      }
      return {
        ...item,
        checked: false,
      };
    });

    dispatch(changeWeekdayFilter(newWeekDaysFilter, namespace));

    /** Выбираем дни недели */
    switch (value) {
      case SELECT_WEEKDAYS:
        selectWeekdays(0, 4);
        break;
      case SELECT_HOLIDAYS:
        selectWeekdays(5, 6);
        break;
      case SELECT_MANUALLY:
        selectWeekdays(0, 6);
        break;
      default:
        break;
    }
  };

  const selectWeekdays = (start, end) => {
    const { weekDays } = periodFilter;

    const newWeekDays = weekDays.map((item, index) => {
      if (index >= start && index <= end) {
        return {
          ...item,
          checked: true,
        };
      }
      return {
        ...item,
        checked: false,
      };
    });

    dispatch(toggleWeekDay(newWeekDays, namespace));
  };

  const onDaytimeClick = index => {
    const { dayTime } = periodFilter;

    const newDayTime = dayTime.map((item, localIndex) => {
      if (index === localIndex) {
        return {
          ...item,
          checked: !item.checked,
        };
      }
      return item;
    });

    dispatch(toggleDaytime(newDayTime, namespace));
  };

  const resetPeriod = () => {
    dispatch(resetCurrentPeriodFilter(namespace));
    dispatch(
      setURLParams({
        [`${namespace}_dateStart`]: null,
        [`${namespace}_dateEnd`]: null,
        [`${namespace}_monthStart`]: null,
        [`${namespace}_monthEnd`]: null,
        [`${namespace}_yearStart`]: null,
        [`${namespace}_yearEnd`]: null,
      }),
    );
  };

  if (!langOb) {
    return null;
  }

  const { weekDays, weekDaysFilter, dayTime } = periodFilter;

  return (
    <div
      className={cx('period-select', {
        [className]: className,
      })}
    >
      <h3 className="period-select__title">{langOb.period}</h3>

      {dataRanges === 'monthly' ? (
        <MonthsRange
          className="period-select__months-range"
          periodFilter={periodFilter}
          namespace={namespace}
          dataStat={dataStat}
          onApply={() => {
            applyPeriod();
            closeModal();
          }}
        />
      ) : (
        <DatesRange
          className="period-select__dates-range"
          periodFilter={periodFilter}
          namespace={namespace}
          dataStat={dataStat}
          timeRanges={timeRanges}
        />
      )}

      {false && (
        <PeriodButtons
          title={langOb.weekDays}
          options={weekDays}
          onOptionClick={onSpecificDayClick}
          extraOptions={weekDaysFilter}
          onExtraOptionClick={onWeekDaysFilterClick}
          className="period-select__weekdays"
        />
      )}

      {false && (
        <PeriodButtons
          title={langOb.dayTime}
          options={dayTime}
          onOptionClick={onDaytimeClick}
          className="period-select__daytime"
        />
      )}

      {dataRanges !== 'monthly' && (
        <div className="period-select__buttons">
          <button
            className="period-select__save-button"
            onClick={() => {
              applyPeriod();
              closeModal();
            }}
            type="button"
          >
            {langOb.apply}
          </button>
          <button className="period-select__reset-button" onClick={resetPeriod} type="button">
            {langOb.reset}
          </button>
        </div>
      )}
    </div>
  );
};

PeriodSelect.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  periodFilter: PropTypes.shape({
    dateStart: PropTypes.instanceOf(Date),
    dateEnd: PropTypes.instanceOf(Date),
    weekDays: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.number,
        checked: PropTypes.bool,
      }),
    ),
    weekDaysFilter: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        checked: PropTypes.bool,
      }),
    ),
    dayTime: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        checked: PropTypes.bool,
      }),
    ),
  }).isRequired,
  timeRanges: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  closeModal: PropTypes.func,
  dataRanges: PropTypes.oneOf(['monthly', 'daily', 'half_hourly', 'hourly']).isRequired,
  namespace: PropTypes.string.isRequired,
  applyPeriod: PropTypes.func.isRequired,
  dataStat: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }).isRequired,
};

PeriodSelect.defaultProps = {
  className: null,
  closeModal: () => {},
};

export default connect()(PeriodSelect);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';

// Actions
import {
  getIndicatorsGroups,
  selectIndicatorsGroup,
  getIndicators,
  selectIndicator,
  getFactsMemo,
  getPolygons,
  selectIndicatorTab,
} from 'modules/main/actions';

// Misc
import { INDICATOR_TERRITORY_TAB, CHECKBOXES, SELECT } from 'modules/main/constants';
import getTerritoryIds from 'helpers/get-territory-ids';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

export const IndicatorsGroupSelect = ({
  className,
  dispatch,
  currentIndicator,
  currentIndicatorsGroup,
  closeHandler,
  polygons,
}) => {
  /** Переводы */
  const langOb = useLang('IndicatorsGroupSelect');

  const [indicatorsGroups, setIndicatorsGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rootOptionId, setRootOptionId] = useState(null);
  const [expandedIds, setExpandedIds] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [hasDisableOption, setHasDisableOption] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(getIndicatorsGroups());
        if (!result || !result.results || !result.results.length) {
          throw new Error(langOb.noIndicators);
        }
        setIsLoading(false);
        setIndicatorsGroups(result.results);
        setRootOptionId(
          currentIndicatorsGroup
            ? result.results.find(option =>
                option.subgroups.find(group => group.id === currentIndicatorsGroup.id),
              ).id
            : result.results[0].id,
        );
      } catch (error) {
        setIsLoading(false);
        setHasError(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (currentIndicator) {
      setHasDisableOption(false);
    }
  }, [currentIndicator]);

  const handleRootOptionClick = id => {
    setRootOptionId(id);
  };

  const handleSecondLevelOptionClick = id => {
    setExpandedIds(prevState =>
      prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id],
    );
  };

  const handleThirdLevelOptionClick = group => {
    if (!currentIndicatorsGroup || group.id === currentIndicatorsGroup.id) {
      return;
    }
    setHasDisableOption(true);
    dispatch(selectIndicatorsGroup({ ...group }));

    /** Сюда будем складывать территории для показателей полученных по группе */
    let allTerritoryIds;

    /** Получаем показатели для выбранной группы показателей */
    let indicatorsInScope;
    dispatch(getIndicators(group.id))
      .then(indicators => {
        indicatorsInScope = indicators;
        if (Array.isArray(indicators) && indicators.length) {
          /** Update location */
          window.history.replaceState(
            null,
            `${group.name}_${indicators[0].name}`,
            `/${group.id}/${indicators[0].id}`,
          );

          /** Выбираем первый индикатор */
          return dispatch(selectIndicator(indicators[0]));
        }
        return Promise.reject(Error(langOb.noIndicators));
      })
      .then(() => {
        /** Получение фактов (мер) */
        const { measures = [], id } = indicatorsInScope[0];

        const queryString = measures.reduce((acc, curItem) => {
          if (curItem.data_type === CHECKBOXES) {
            return acc;
          }
          if (curItem.values.length) {
            if (curItem.select_prop && curItem.data_type === SELECT && curItem.values.length) {
              /** Multiselect */
              return `${acc}measures[${curItem.id}]=${curItem.values.map(v => v.id)}&`;
            }
            /** Default */
            return `${acc}measures[${curItem.id}]=${curItem.values[0].id}&`;
          }
          return '';
        }, '');

        return dispatch(
          getFactsMemo(
            indicatorsInScope[0].has_second_map,
            indicatorsInScope[0].hierarchy[0].id,
            id,
            queryString,
          ),
        );
      })
      .then(res => {
        setHasDisableOption(false);
        /**
         * Получение полигонов по территориям,
         * которые пришли в фактах (мерах)
         */
        allTerritoryIds = getTerritoryIds(res, polygons);
        if (allTerritoryIds.length) {
          return dispatch(getPolygons(allTerritoryIds));
        }

        return Promise.reject();
      })
      /**
       * Когда полигоны получены
       * открываем вкладку "Территориальный показатель"
       */
      .then(() => {
        dispatch(selectIndicatorTab(INDICATOR_TERRITORY_TAB));
        closeHandler();
      })
      .catch(() => {});
  };

  let subGroups = [];
  if (rootOptionId) {
    const rootPackage = indicatorsGroups.find(item => item.id === rootOptionId);
    if (rootPackage && rootPackage.subgroups) {
      subGroups = rootPackage.subgroups;
    }
  }

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('package-select', {
        [className]: className,
      })}
    >
      {hasError && <div className="package-select__error">{langOb.error}</div>}

      {isLoading && (
        <div className="package-select__loading" data-testid="package-select-loading">
          <IconLoader className="package-select__loading-icon" />
        </div>
      )}

      {!isLoading && !hasError && (
        <React.Fragment>
          {/* LEFT */}
          <div className="package-select__left">
            <h3 className="package-select__left-title">{langOb.infomodules}</h3>

            <ul className="package-select__root-list">
              {indicatorsGroups
                .filter(item => item && item.id)
                .map((item, index) => (
                  <li
                    className={cx('package-select__root-item', {
                      'package-select__root-item_active':
                        (rootOptionId === null && index === 0) || item.id === rootOptionId,
                    })}
                    key={item.id}
                  >
                    <button
                      className="package-select__root-button"
                      type="button"
                      onClick={() => handleRootOptionClick(item.id)}
                      disabled={!item.active}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
          {/* /LEFT */}

          {/* RIGHT */}
          <div className="package-select__right">
            <h3 className="package-select__title">{langOb.selectPackage}</h3>

            <ul className="package-select__list">
              {subGroups.map(item => {
                if (item.subgroups && item.subgroups.length) {
                  return (
                    <li className="package-select__item" key={item.id}>
                      <button
                        type="button"
                        className="package-select__item-button package-select__item-button_level-1"
                        aria-labelledby={
                          expandedIds.includes(item.id) ? langOb.collapse : langOb.expand
                        }
                        onClick={() => handleSecondLevelOptionClick(item.id)}
                      >
                        <span className="package-select__item-name">{item.name}</span>
                        <span
                          className={cx('package-select__item-expand', {
                            'package-select__item-expand_expanded': expandedIds.includes(item.id),
                          })}
                        />
                      </button>

                      {expandedIds.includes(item.id) && item.subgroups && (
                        <ul className="package-select__list">
                          {item.subgroups &&
                            item.subgroups.map(sitem => (
                              <li className="package-select__item" key={sitem.id}>
                                <button
                                  type="button"
                                  className="package-select__item-button package-select__item-button_level-2"
                                  onClick={() => handleThirdLevelOptionClick(sitem)}
                                  disabled={!sitem.active || hasDisableOption}
                                >
                                  {currentIndicatorsGroup &&
                                    currentIndicatorsGroup.id === sitem.id && (
                                      <IconCheck
                                        className="package-select__icon-checkbox"
                                        data-testid="package-select-icon-checkbox"
                                      />
                                    )}
                                  <span className="package-select__item-name">{sitem.name}</span>
                                </button>
                              </li>
                            ))}
                        </ul>
                      )}
                    </li>
                  );
                }

                // Else
                return (
                  <li className="package-select__item" key={item.id}>
                    <button
                      type="button"
                      className="package-select__item-button package-select__item-button_level-2"
                      onClick={() => handleThirdLevelOptionClick(item)}
                      disabled={!item.active || hasDisableOption}
                    >
                      {currentIndicatorsGroup && currentIndicatorsGroup.id === item.id && (
                        <IconCheck
                          data-testid="package-select-icon-checkbox"
                          className="package-select__icon-checkbox"
                        />
                      )}
                      <span className="package-select__item-name">{item.name}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* /RIGHT */}
        </React.Fragment>
      )}
    </div>
  );
};

IndicatorsGroupSelect.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  currentIndicator: PropTypes.shape({}),
  currentIndicatorsGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  closeHandler: PropTypes.func.isRequired,
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
};

IndicatorsGroupSelect.defaultProps = {
  className: null,
  currentIndicator: null,
  currentIndicatorsGroup: null,
};

const mapStateToProps = state => ({
  currentIndicator: state.main.currentIndicator,
  currentIndicatorsGroup: state.main.currentIndicatorsGroup,
  polygons: state.polygons,
});

export default connect(mapStateToProps)(IndicatorsGroupSelect);

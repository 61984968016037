import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Misc
import useLang from 'hooks/useLang';

// Styles
import { resetAllFilters } from 'modules/main/actions';
import { setFavoritesItem } from '../../actions';

// Components
import AddToFavorites from '../AddToFavorites';

export const FiltersButtons = ({ dispatch }) => {
  const [savingPopupIsActive, setStatusPopup] = useState(false);
  const [error, setError] = useState(null);

  const save = async fields => {
    try {
      await dispatch(setFavoritesItem(fields));
      setStatusPopup(false);
    } catch (err) {
      setError(err);
    }
  };

  const lang = useLang('FiltersContainer');

  if (!lang) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="filters-container__selectors">
        <div className="filters-container__item_flex">
          <button
            type="button"
            className="filters-container__save-button"
            onClick={() => setStatusPopup(true)}
          >
            {lang.saveButton}
          </button>
          <button
            className="filters-container__reset-button"
            type="button"
            onClick={() => dispatch(resetAllFilters())}
          >
            {lang.clearButton}
          </button>
        </div>
      </div>

      {savingPopupIsActive && (
        <AddToFavorites save={save} closeHandler={() => setStatusPopup(false)} error={error} />
      )}
    </React.Fragment>
  );
};

FiltersButtons.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(FiltersButtons);

/* istanbul ignore file */
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from 'store/rootReducer';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

/** Создаем логгер для девелопмента */
const loggerMiddleware = createLogger({
  duration: true,
  diff: true,
  collapsed: true,
});

/** Определяем компоуз функцию */
let composeEnhancers = compose;

/** Для разработки переопределяем компоуз в ридакс дев тулз */
if (process.env.NODE_ENV === 'development') {
  /* eslint-disable no-underscore-dangle */
  composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
    compose;
  /* eslint-enable */
}

/** Создаем список мидлвар */
const middlewares = [thunk];

/**
 * Добавляем в список логгер для разработки
 */
if (process.env.NODE_ENV === 'development') {
  middlewares.push(loggerMiddleware);
}

export default createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Popup from 'shared/Popup';
import TerritoriesFiltersSelect from 'modules/main/components/TerritoriesFiltersSelect';

// Icons
import { ReactComponent as IconObjectCategories } from 'assets/icons/icon-categories.svg';

// Styles
import './assets/styles/styles.scss';

const ObjectsCategoryFilter = ({ measure, territoriesFiltersValues, applyChanges, byDefault }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (
    !territoriesFiltersValues ||
    Object.keys(territoriesFiltersValues).length === 0 ||
    !territoriesFiltersValues[measure.id]
  ) {
    return null;
  }

  const allCount = Object.keys(territoriesFiltersValues[measure.id]).length;
  const selectedCount = Object.keys(territoriesFiltersValues[measure.id]).reduce((acc, current) => {
    const { selected } = territoriesFiltersValues[measure.id][current];

    return selected ? acc + 1 : acc;
  }, 0);

  return (
    <div className="territories-filter">
      <button
        className="territories-filter__item"
        key={measure.id}
        type="button"
        onClick={() => setIsModalOpen(true)}
      >
        <IconObjectCategories className="territories-filter__icon" />
        <div>
          {measure.name}
          {!byDefault ? (
            <span className="territories-filter__count">{` (${selectedCount} из ${allCount})`}</span>
          ) : (
            <span className="territories-filter__count">{` - ${allCount}`}</span>
          )}
        </div>
      </button>

      {isModalOpen && (
        <Popup closeHandler={() => setIsModalOpen(false)}>
          <TerritoriesFiltersSelect
            measure={measure}
            onClose={() => setIsModalOpen(false)}
            territoriesFiltersValues={territoriesFiltersValues}
            applyChanges={applyChanges}
          />
        </Popup>
      )}
    </div>
  );
};

ObjectsCategoryFilter.propTypes = {
  measure: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  territoriesFiltersValues: PropTypes.shape({}),
  applyChanges: PropTypes.func.isRequired,
  byDefault: PropTypes.bool,
};

ObjectsCategoryFilter.defaultProps = {
  territoriesFiltersValues: null,
  byDefault: true,
};

export default ObjectsCategoryFilter;

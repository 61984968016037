import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconSelection } from 'assets/icons/icon-selection.svg';

// Misc
import useLang from 'hooks/useLang';

import './assets/styles/styles.scss';

const ExitSelectionMode = ({ className, handleClick }) => {
  /** Переводы */
  const langOb = useLang('ExitSelectionMode');

  if (!langOb) {
    return null;
  }

  return (
    <button
      type="button"
      className={cx('exit-selection-mode', {
        [className]: className,
      })}
      onClick={handleClick}
      aria-label={langOb.buttonLabel}
    >
      <IconSelection className="exit-selection-mode__icon" />
    </button>
  );
};

ExitSelectionMode.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

ExitSelectionMode.defaultProps = {
  className: null,
};

export default ExitSelectionMode;

import cx from 'classnames';

/* eslint-disable import/prefer-default-export */
export const selectStyles = {
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999,
  }),
  container: provided => ({
    ...provided,
    height: 36,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: 'none',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'black',
    fontSize: 14,
    fontFamily: `'PT Sans', sans-serif`,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#4A90E2',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    color: cx({
      white: state.isSelected && !state.isDisabled,
      black: !state.isSelected && !state.isDisabled,
      '#cccccc': state.isDisabled,
    }),
    fontFamily: `'PT Sans', sans-serif`,
    display: 'flex',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 240,
  }),
  valueContainer: provided => ({
    ...provided,
    color: 'black',
    fontSize: 14,
    fontFamily: `'PT Sans', sans-serif`,
  })
};
export const selectStylesCondensed = {
  ...selectStyles,
  dropdownIndicator: provided => ({
    ...provided,
    color: '#4A90E2',
    padding: '0 4px 0 0',
  }),
};

import * as types from 'modules/main/actions/action-types';

import {
  getCookie,
  argumentsCacheDecorator,
  generateStringDate,
  fetchWrapper,
  compressIds,
  getMaxMinValues,
  parseDateISOToDate,
} from 'helpers';
import getRegionsQueryParams from 'helpers/get-regions-query-params';
import { INDICATOR_CHARTS_TAB, INDICATOR_TERRITORY_TAB, SELECT } from 'modules/main/constants';

export const setLang = lang => {
  window.localStorage && window.localStorage.setItem('lang', JSON.stringify(lang));
  return {
    type: types.SET_LANG,
    payload: {
      lang,
    },
  };
};

export const getCitiesTree = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}territory/country/tree/`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(res => {
        dispatch({
          type: types.GET_CITIES_TREE_SUCCESS,
          payload: {
            cities: res,
          },
        });
        resolve(res);
      })
      .catch(reject);
  });
};

export const getTerritoriesForIndicator = (namespace = 'a', parentId = null, page = null) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { currentIndicator, currentCity } = getState().main;

  if (!currentIndicator) {
    return Promise.reject(new Error('current indicator should exist'));
  }

  let url = `${process.env.REACT_APP_API_URL}data/territory/indicator/${currentIndicator.id}/`;
  const requestData = {
    parent: parentId,
    all: !parentId ? 1 : null,
    city: currentCity.id,
    page,
    to: namespace !== 'a',
  };
  const queryString = Object.keys(requestData)
    .filter(key => requestData[key])
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(requestData[key])}`)
    .join('&');
  if (queryString) {
    url = `${url}?${queryString}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_FILTER_REGIONS_IS_FULL,
          payload: {
            namespace,
            isFull: data.is_full,
          },
        });
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

export const getIndicatorsGroups = (page = null) => (dispatch, getState) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { currentCity } = getState().main;

  let url = `${process.env.REACT_APP_API_URL}indicator/group/tree/`;

  const requestData = {
    page,
    city: currentCity.id,
  };

  const queryString = Object.keys(requestData)
    .filter(key => requestData[key])
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(requestData[key])}`)
    .join('&');

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_INDICATORS_GROUPS_SUCCESS,
          payload: {
            indicatorsGroups: data && data.results ? data.results : [],
          },
        });
        resolve(data);
      })
      .catch(err => {
        dispatch({
          type: types.GET_INDICATORS_GROUPS_FAIL,
        });
        reject(err);
      });
  });
};

export const getIndicatorsGroup = groupId => dispatch => {
  dispatch({
    type: types.LOADING_START,
  });

  const url = `${process.env.REACT_APP_API_URL}indicator/group/${groupId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_INDICATORS_GROUP_SUCCESS,
          payload: {
            indicatorsGroup: data,
          },
        });
        resolve(data);
      })
      .catch(err => {
        dispatch({
          type: types.GET_INDICATORS_GROUP_FAIL,
        });
        reject(err);
      });
  });
};

export const getCsrfToken = () =>
  new Promise(resolve => {
    if (getCookie('csrftoken')) {
      resolve(getCookie('csrftoken'));
    } else {
      fetchWrapper(`${process.env.REACT_APP_API_URL}user/get-csrf-token/`).then(res => {
        if (res && res.token) {
          resolve(res.token);
        } else {
          resolve(getCookie('csrftoken'));
        }
      });
    }
  });

export const selectIndicatorsGroup = indicatorsGroup => ({
  type: types.SELECT_INDICATORS_GROUP,
  payload: {
    indicatorsGroup,
  },
});

export const selectIndicator = (indicator, isInitiallySelected = false) => (dispatch, getState) => {
  dispatch({
    type: types.SELECT_INDICATOR,
    payload: {
      indicator,
      isInitiallySelected,
    },
  });

  if (indicator.objects) {
    const currentObjectsMeasures = indicator.objects
      .filter(obj => obj.num_measures.length > 0)
      .map(obj => ({
        id: obj.id,
        measures: obj.num_measures.map(measure => ({
          id: measure.id,
          name: measure.name,
          max: null,
          min: null,
        })),
      }));

    dispatch({
      type: types.SET_CURRENT_OBJECTS_MEASURES,
      payload: { currentObjectsMeasures },
    });
  }

  const { measuresValues } = getState().main;

  return Promise.resolve(measuresValues);
};

export const selectChartIndicator = indicator => dispatch => {
  dispatch({
    type: types.SELECT_CHART_INDICATOR,
    payload: {
      indicator,
    },
  });

  return Promise.resolve();
};

export const selectIndicatorTab = tab => ({
  type: types.SELECT_INDICATOR_TAB,
  payload: {
    tab,
  },
});

export const deselectIndicatorTab = () => ({
  type: types.DESELECT_INDICATOR_TAB,
});

export const toggleIndicatorTab = tab => ({
  type: types.TOGGLE_INDICATOR_TAB,
  payload: {
    tab,
  },
});

export const changeDateStart = (dateStart, namespace) => ({
  type: types.SELECT_DATE_START,
  payload: {
    dateStart,
    namespace,
  },
});

export const changeDateEnd = (dateEnd, namespace) => ({
  type: types.SELECT_DATE_END,
  payload: {
    dateEnd,
    namespace,
  },
});

export const changeMonthStart = (monthStart, namespace) => ({
  type: types.SELECT_MONTH_START,
  payload: {
    monthStart,
    namespace,
  },
});

export const changeMonthEnd = (monthEnd, namespace) => ({
  type: types.SELECT_MONTH_END,
  payload: {
    monthEnd,
    namespace,
  },
});

export const changeYearStart = (yearStart, namespace) => ({
  type: types.SELECT_YEAR_START,
  payload: {
    yearStart,
    namespace,
  },
});

export const changeYearEnd = (yearEnd, namespace) => ({
  type: types.SELECT_YEAR_END,
  payload: {
    yearEnd,
    namespace,
  },
});

export const resetCurrentPeriodFilter = namespace => ({
  type: types.RESET_CURRENT_PERIOD_FILTER,
  payload: {
    namespace,
  },
});

export const toggleWeekDay = (weekDays, namespace) => ({
  type: types.TOGGLE_WEEK_DAY,
  payload: {
    weekDays,
    namespace,
  },
});

export const changeWeekdayFilter = (weekDaysFilter, namespace) => ({
  type: types.CHANGE_WEEKDAY_FILTER,
  payload: {
    weekDaysFilter,
    namespace,
  },
});

export const toggleDaytime = (dayTime, namespace) => ({
  type: types.TOGGLE_DAYTIME,
  payload: {
    dayTime,
    namespace,
  },
});

export const getIndicators = groupId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}indicator/group/${groupId}/list/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        const results = data && data.results ? data.results : [];

        dispatch({
          type: types.GET_INDICATORS_SUCCESS,
          payload: {
            results,
          },
        });
        resolve(results);
      })
      .catch(err => {
        dispatch({
          type: types.GET_INDICATORS_FAIL,
        });

        reject(err);
      });
  });
};

export const getPolygons = (ids, indicatorId = null) => dispatch => {
  dispatch({
    type: types.LOADING_START,
  });
  dispatch({
    type: types.GET_POLYGONS,
  });

  let url = `${process.env.REACT_APP_API_URL}territory/geometry/`;
  const idsString = compressIds(ids);
  url = `${url}?territories=${idsString}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_POLYGONS_SUCCESS,
          payload: {
            polygons: data,
            indicatorId,
          },
        });
        resolve(data);
      })
      .catch(err => {
        dispatch({
          type: types.GET_POLYGONS_FAIL,
        });
        reject(err);
      });
  });
};

export const getUser = () => (dispatch, getState) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { lang } = getState().main;
  const url = `${process.env.REACT_APP_API_URL}user/info/`;
  return new Promise((resolve, reject) => {
    getCsrfToken().then(csrfToken => {
      fetchWrapper(url, {
        csrfToken,
        lang: lang.full,
      })
        .then(data => {
          dispatch({
            type: types.LOG_IN_SUCCESS,
            payload: {
              data,
            },
          });
          resolve(data);
        })
        .catch(err => {
          dispatch({
            type: types.LOG_IN_FAIL,
          });
          reject(err);
        });
    });
  });
};

export const logOut = () => (dispatch, getState) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { lang } = getState().main;
  const url = `${process.env.REACT_APP_API_URL}user/logout/`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      lang: lang.full,
    })
      .then(data => {
        dispatch({
          type: types.LOG_OUT_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const signIn = (email, password) => (dispatch, getState) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { lang } = getState().main;
  const url = `${process.env.REACT_APP_API_URL}user/login/`;
  return new Promise((resolve, reject) => {
    getCsrfToken().then(csrfToken => {
      fetchWrapper(url, {
        method: 'POST',
        lang: lang.full,
        csrfToken,
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then(data => {
          dispatch({
            type: types.LOG_IN_SUCCESS,
            payload: {
              data,
            },
          });
          resolve(data);
        })
        .catch(err => {
          dispatch({
            type: types.LOG_IN_FAIL,
          });
          reject(err);
        });
    });
  });
};

export const resetPassword = email => (dispatch, getState) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { lang } = getState().main;
  const url = `${process.env.REACT_APP_API_URL}user/recovery-password/`;
  return new Promise((resolve, reject) => {
    getCsrfToken().then(csrfToken => {
      fetchWrapper(url, {
        method: 'POST',
        lang: lang.full,
        csrfToken,
        body: JSON.stringify({
          email,
        }),
      })
        .then(data => {
          dispatch({
            type: types.LOADING_STOP,
          });
          resolve(data);
        })
        .catch(err => {
          dispatch({
            type: types.LOADING_STOP,
          });
          reject(err);
        });
    });
  });
};

export const getMenu = () => (dispatch, getState) => {
  const { lang } = getState().main;
  const url = `${process.env.REACT_APP_API_URL}pages/${lang.short}/menu/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      lang: lang.full,
    })
      .then(data => {
        dispatch({
          type: types.GET_MENU_SUCCESS,
          payload: {
            data,
          },
        });

        resolve(data);
      })
      .catch(reject);
  });
};

/**
 * Экшн для запроса фактов. Запрос на факты для показателей с двумя картами делается здесь же
 * @param {boolean} dualIndicator - если true - индикатор с двумя наборами данных
 * @param {number} mapLevel
 * @param {number} indicatorId
 * @param {string} queryString
 */
export const getFacts = (dualIndicator, mapLevel, indicatorId, queryString) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.GET_POLYGONS,
  });

  const dualIndicatorUrlSegment = dualIndicator ? 'streams/' : '';

  const {
    currentPeriodFilter,
    filterRegions,
    currentMapLevelId,
    currentCity,
    mapLevels,
  } = getState().main;

  let url = `${process.env.REACT_APP_API_URL}data/${dualIndicatorUrlSegment}level/${currentMapLevelId}/indicator/${indicatorId}/`;
  let localQueryString = '';

  /** Добавляем в запрос инфу о региональном фильтре (для первой (или единственной) карты) */
  const { terrs, parentsArr, excludeArr } = getRegionsQueryParams(
    filterRegions.a, // Здесь a - это не ошибка, это первый набор фильтров
    currentMapLevelId,
    mapLevels,
  );

  if (terrs.length) {
    localQueryString = `${localQueryString}&territories=${compressIds(terrs.map(item => item.id))}`;
  }
  if (parentsArr.length) {
    localQueryString = `${localQueryString}&parents=${parentsArr.map(item => item.id).join(',')}`;
  }
  if (excludeArr.length) {
    localQueryString = `${localQueryString}&exclude=${excludeArr.map(item => item.id).join(',')}`;
  }

  /** Добавляем инфу о городе */
  if (currentCity.id) {
    localQueryString = `${localQueryString}&city=${currentCity.id}`;
  }

  /** Добавляем территориальный фильтр в location */
  const combined = [...terrs, ...parentsArr, ...excludeArr];
  const unusedFields = ['name', 'expanded', 'loading', 'next', 'count'];
  const filtersRegionsForLocation = combined
    .filter(item => item && item.checked && item.id)
    .reduce((gAcc, item) => {
      /* eslint-disable no-param-reassign */
      gAcc[item.id] = Object.keys(item).reduce((acc, curField) => {
        if (!unusedFields.includes(curField)) {
          acc[curField] = item[curField];
        }
        return acc;
      }, {});
      return gAcc;
      /* eslint-enable no-param-reassign */
    }, {});

  if (Object.keys(filtersRegionsForLocation).length > 0) {
    dispatch(
      setURLParams({
        territories: JSON.stringify(filtersRegionsForLocation),
      }),
    );
  } else {
    dispatch(
      setURLParams({
        territories: null,
      }),
    );
  }

  /**
   * Флаг установки territoriesTo в location.
   * Если false - то убираем territoriesTo из location
   */
  let hasTerritotiesToQueryString = false;

  if (dualIndicator) {
    /** Добавляем в запрос инфу о региональном фильтре (для второй карты) */
    const {
      terrs: terrsTo,
      parentsArr: parentsArrTo,
      excludeArr: excludeArrTo,
    } = getRegionsQueryParams(
      filterRegions.b, // Здесь b - это второй набор фильтров
      currentMapLevelId,
      mapLevels,
    );

    if (terrsTo.length) {
      localQueryString = `${localQueryString}&to_territories=${terrsTo
        .map(item => item.id)
        .join(',')}`;
    }
    if (parentsArrTo.length) {
      localQueryString = `${localQueryString}&to_parents=${parentsArrTo
        .map(item => item.id)
        .join(',')}`;
    }
    if (excludeArrTo.length) {
      localQueryString = `${localQueryString}&to_exclude=${excludeArrTo
        .map(item => item.id)
        .join(',')}`;
    }

    /** Добавляем территориальный фильтр в location */
    const combinedTo = [...terrsTo, ...parentsArrTo, ...excludeArrTo];
    const filtersRegionsForLocationTo = combinedTo
      .filter(item => item && item.checked && item.id)
      .reduce((gAcc, item) => {
        /* eslint-disable no-param-reassign */
        gAcc[item.id] = Object.keys(item).reduce((acc, curField) => {
          if (!unusedFields.includes(curField)) {
            acc[curField] = item[curField];
          }
          return acc;
        }, {});
        return gAcc;
        /* eslint-enable no-param-reassign */
      }, {});

    if (Object.keys(filtersRegionsForLocationTo).length > 0) {
      hasTerritotiesToQueryString = true;
      dispatch(
        setURLParams({
          territoriesTo: JSON.stringify(filtersRegionsForLocationTo),
        }),
      );
    }
  }

  /**
   * Если показатель без двух карт или фильтры territoriesTo не установлены
   * убираем territoriesTo из location
   */
  if (!hasTerritotiesToQueryString) {
    dispatch(
      setURLParams({
        territoriesTo: null,
      }),
    );
  }

  /** Добавляем в запрос инфу о датах */
  if (currentPeriodFilter[INDICATOR_TERRITORY_TAB].dateStart) {
    localQueryString = `${localQueryString}&from_date=${generateStringDate(
      currentPeriodFilter[INDICATOR_TERRITORY_TAB].dateStart,
    )}`;
  } else if (
    currentPeriodFilter[INDICATOR_TERRITORY_TAB].monthStart !== null &&
    currentPeriodFilter[INDICATOR_TERRITORY_TAB].yearStart !== null
  ) {
    const { monthStart, yearStart } = currentPeriodFilter[INDICATOR_TERRITORY_TAB];
    localQueryString = `${localQueryString}&from_date=${monthStart + 1}.${yearStart}`;
  }

  if (currentPeriodFilter[INDICATOR_TERRITORY_TAB].dateEnd) {
    localQueryString = `${localQueryString}&to_date=${generateStringDate(
      currentPeriodFilter[INDICATOR_TERRITORY_TAB].dateEnd,
    )}`;
  } else if (
    currentPeriodFilter[INDICATOR_TERRITORY_TAB].monthEnd !== null &&
    currentPeriodFilter[INDICATOR_TERRITORY_TAB].yearEnd !== null
  ) {
    const { monthEnd, yearEnd } = currentPeriodFilter[INDICATOR_TERRITORY_TAB];
    localQueryString = `${localQueryString}&to_date=${monthEnd + 1}.${yearEnd}`;
  }

  localQueryString =
    localQueryString.indexOf('&') === 0 ? localQueryString.substr(1) : localQueryString;

  /** Сохраняем данные запроса касательно периода и терр. фильтра */
  dispatch({
    type: types.SAVE_GET_FACTS_QUERY_STRING,
    payload: {
      localQueryString,
    },
  });

  if (queryString) {
    localQueryString = `${localQueryString}&${queryString}`;
  }

  if (localQueryString) {
    url = `${url}?${localQueryString}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: !dualIndicator ? types.GET_FACTS_SUCCESS : types.GET_FACTS_DUAL_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      /**
       * Отображение объектов меняется при изменении фактов
       */
      .then(() => {
        const measuresValues = getState().main.measuresValues.objects;
        if (
          getState().main.indicatorTabsEnabled.objects &&
          Object.keys(measuresValues).length > 0
        ) {
          const indicatorIds = Object.keys(measuresValues);
          for (let i = 0; i < indicatorIds.length; i += 1) {
            const newIndicatorId = indicatorIds[i];
            if (measuresValues[newIndicatorId].selected) {
              dispatch(getObjectsForIndicator(newIndicatorId)).then(data =>
                dispatch(getPolygons(data.map(t => t.territory_id), newIndicatorId)),
              );
            }
          }
        }
      })

      .catch(err => {
        dispatch({
          type: !dualIndicator ? types.GET_FACTS_FAIL : types.GET_FACTS_DUAL_FAIL,
        });

        reject(err);
      });
  });
};

export const getFactsMemo = argumentsCacheDecorator(getFacts, [0, 1, 2, 3]);

export const getChartFacts = (indicatorId, mapLevel, group, queryString, slice) => (
  dispatch,
  getState,
) => {
  let url = `${process.env.REACT_APP_API_URL}data/group/${group}/level/${mapLevel}/indicator/${indicatorId}/`;

  let localQueryString = '';

  const {
    filterRegions,
    currentMapLevelId,
    currentPeriodFilter,
    currentCity,
    chartTimeRanges,
    mapLevels,
  } = getState().main;

  /** Добавляем в запрос инфу о региональном фильтре */
  const { terrs, parentsArr, excludeArr } = getRegionsQueryParams(
    filterRegions.a,
    currentMapLevelId,
    mapLevels,
  );

  if (terrs.length) {
    localQueryString = `${localQueryString}&territories=${terrs.map(item => item.id).join(',')}`;
  }
  if (parentsArr.length) {
    localQueryString = `${localQueryString}&parents=${parentsArr.map(item => item.id).join(',')}`;
  }
  if (excludeArr.length) {
    localQueryString = `${localQueryString}&exclude=${excludeArr.map(item => item.id).join(',')}`;
  }

  /** Добавляем инфу о городе */
  if (currentCity.id) {
    localQueryString = `${localQueryString}&city=${currentCity.id}`;
  }
  /** Если есть непрерывный диапазон, указываем только value */
  if (chartTimeRanges && chartTimeRanges.length) {
    localQueryString = `${localQueryString}&values=${chartTimeRanges
      .filter(range => {
        if (
          currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart &&
          currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd
        ) {
          return (
            new Date(parseDateISOToDate(range.value, range.timeRange)) >=
              parseDateISOToDate(currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart, null, true) &&
            new Date(parseDateISOToDate(range.value, range.timeRange)) <=
              parseDateISOToDate(currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd)
          );
        }
        if (
          currentPeriodFilter[INDICATOR_CHARTS_TAB].monthStart !== null &&
          currentPeriodFilter[INDICATOR_CHARTS_TAB].yearStart !== null &&
          currentPeriodFilter[INDICATOR_CHARTS_TAB].monthEnd !== null &&
          currentPeriodFilter[INDICATOR_CHARTS_TAB].yearEnd !== null
        ) {
          const { monthStart, yearStart } = currentPeriodFilter[INDICATOR_CHARTS_TAB];
          const { monthEnd, yearEnd } = currentPeriodFilter[INDICATOR_CHARTS_TAB];

          return (
            new Date(parseDateISOToDate(range.value, range.timeRange)) >=
              new Date(parseDateISOToDate(`${yearStart}-${monthStart + 1}`)) &&
            new Date(parseDateISOToDate(range.value, range.timeRange)) <=
              new Date(parseDateISOToDate(`${yearEnd}-${monthEnd + 1}`))
          );
        }
        return null;
      })
      .slice(slice[0], slice[1])
      .map(range => range.value)}`;
  } else {
    /** Добавляем в запрос инфу о датах */
    if (currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart) {
      localQueryString = `${localQueryString}&from_date=${generateStringDate(
        currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart,
      )}`;
    } else if (
      currentPeriodFilter[INDICATOR_CHARTS_TAB].monthStart !== null &&
      currentPeriodFilter[INDICATOR_CHARTS_TAB].yearStart !== null
    ) {
      const { monthStart, yearStart } = currentPeriodFilter[INDICATOR_CHARTS_TAB];
      localQueryString = `${localQueryString}&from_date=${monthStart + 1}.${yearStart}`;
    }

    if (currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd) {
      localQueryString = `${localQueryString}&to_date=${generateStringDate(
        currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd,
      )}`;
    } else if (
      currentPeriodFilter[INDICATOR_CHARTS_TAB].monthEnd !== null &&
      currentPeriodFilter[INDICATOR_CHARTS_TAB].yearEnd !== null
    ) {
      const { monthEnd, yearEnd } = currentPeriodFilter[INDICATOR_CHARTS_TAB];
      localQueryString = `${localQueryString}&to_date=${monthEnd + 1}.${yearEnd}`;
    }
  }

  localQueryString =
    localQueryString.indexOf('&') === 0 ? localQueryString.substr(1) : localQueryString;

  /** Сохраняем данные запроса касательно периода и терр. фильтра */
  dispatch({
    type: types.SAVE_GET_CHART_FACTS_QUERY_STRING,
    payload: {
      localQueryString,
    },
  });

  if (queryString) {
    if (localQueryString) {
      localQueryString = `${localQueryString}&${queryString}`;
    } else {
      localQueryString = queryString;
    }
  }

  if (localQueryString) {
    url = `${url}?${localQueryString}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_CHART_FACTS_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(err => {
        dispatch({
          type: types.GET_CHART_FACTS_FAIL,
        });

        reject(err);
      });
  });
};

export const getChartFactsMemo = argumentsCacheDecorator(getChartFacts, [0, 1, 2, 3, 4]);

export const getRatingFacts = (indicatorId, mapLevel, queryString) => (dispatch, getState) => {
  let url = `${process.env.REACT_APP_API_URL}data/rating/level/${mapLevel}/indicator/${indicatorId}/`;

  let localQueryString = '';

  const {
    filterRegions,
    currentMapLevelId,
    currentPeriodFilter,
    currentCity,
    mapLevels,
  } = getState().main;

  /** Добавляем в запрос инфу о региональном фильтре */
  const { terrs, parentsArr, excludeArr } = getRegionsQueryParams(
    filterRegions.a,
    currentMapLevelId,
    mapLevels,
  );

  if (terrs.length) {
    localQueryString = `${localQueryString}&territories=${terrs.map(item => item.id).join(',')}`;
  }
  if (parentsArr.length) {
    localQueryString = `${localQueryString}&parents=${parentsArr.map(item => item.id).join(',')}`;
  }
  if (excludeArr.length) {
    localQueryString = `${localQueryString}&exclude=${excludeArr.map(item => item.id).join(',')}`;
  }
  localQueryString = `${localQueryString}&page_size=1000`;

  localQueryString =
    localQueryString.indexOf('&') === 0 ? localQueryString.substr(1) : localQueryString;

  /** Добавляем инфу о городе */
  if (currentCity.id) {
    localQueryString = `${localQueryString}&city=${currentCity.id}`;
  }

  /** Добавляем в запрос инфу о датах */
  if (currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart) {
    localQueryString = `${localQueryString}&from_date=${generateStringDate(
      currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart,
    )}`;
  } else if (
    currentPeriodFilter[INDICATOR_CHARTS_TAB].monthStart !== null &&
    currentPeriodFilter[INDICATOR_CHARTS_TAB].yearStart !== null
  ) {
    const { monthStart, yearStart } = currentPeriodFilter[INDICATOR_CHARTS_TAB];
    localQueryString = `${localQueryString}&from_date=${monthStart + 1}.${yearStart}`;
  }

  if (currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd) {
    localQueryString = `${localQueryString}&to_date=${generateStringDate(
      currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd,
    )}`;
  } else if (
    currentPeriodFilter[INDICATOR_CHARTS_TAB].monthEnd !== null &&
    currentPeriodFilter[INDICATOR_CHARTS_TAB].yearEnd !== null
  ) {
    const { monthEnd, yearEnd } = currentPeriodFilter[INDICATOR_CHARTS_TAB];
    localQueryString = `${localQueryString}&to_date=${monthEnd + 1}.${yearEnd}`;
  }

  /** Сохраняем данные запроса касательно периода и терр. фильтра */
  dispatch({
    type: types.SAVE_GET_RATING_FACTS_QUERY_STRING,
    payload: {
      localQueryString,
    },
  });

  if (queryString) {
    localQueryString = `${localQueryString}&${queryString}`;
  }

  if (localQueryString) {
    url = `${url}?${localQueryString}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_CHART_RATINGS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(err => {
        dispatch({
          type: types.GET_CHART_RATINGS_FAIL,
        });

        reject(err);
      });
  });
};

export const getRatingFactsMemo = argumentsCacheDecorator(getRatingFacts, [0, 1, 2]);

export const getChartPieFacts = (indicatorId, mapLevel, queryString) => (dispatch, getState) => {
  let url = `${process.env.REACT_APP_API_URL}data/pie/level/${mapLevel}/indicator/${indicatorId}/`;

  let localQueryString = '';

  const {
    filterRegions,
    currentMapLevelId,
    currentPeriodFilter,
    currentCity,
    mapLevels,
  } = getState().main;

  /** Добавляем в запрос инфу о региональном фильтре */
  const { terrs, parentsArr, excludeArr } = getRegionsQueryParams(
    filterRegions.a,
    currentMapLevelId,
    mapLevels,
  );

  if (terrs.length) {
    localQueryString = `${localQueryString}&territories=${terrs.map(item => item.id).join(',')}`;
  }
  if (parentsArr.length) {
    localQueryString = `${localQueryString}&parents=${parentsArr.map(item => item.id).join(',')}`;
  }
  if (excludeArr.length) {
    localQueryString = `${localQueryString}&exclude=${excludeArr.map(item => item.id).join(',')}`;
  }

  /** Добавляем инфу о городе */
  if (currentCity.id) {
    localQueryString = `${localQueryString}&city=${currentCity.id}`;
  }

  /** Добавляем в запрос инфу о датах */
  if (currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart) {
    localQueryString = `${localQueryString}&from_date=${generateStringDate(
      currentPeriodFilter[INDICATOR_CHARTS_TAB].dateStart,
    )}`;
  } else if (
    currentPeriodFilter[INDICATOR_CHARTS_TAB].monthStart !== null &&
    currentPeriodFilter[INDICATOR_CHARTS_TAB].yearStart !== null
  ) {
    const { monthStart, yearStart } = currentPeriodFilter[INDICATOR_CHARTS_TAB];
    localQueryString = `${localQueryString}&from_date=${monthStart + 1}.${yearStart}`;
  }

  if (currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd) {
    localQueryString = `${localQueryString}&to_date=${generateStringDate(
      currentPeriodFilter[INDICATOR_CHARTS_TAB].dateEnd,
    )}`;
  } else if (
    currentPeriodFilter[INDICATOR_CHARTS_TAB].monthEnd !== null &&
    currentPeriodFilter[INDICATOR_CHARTS_TAB].yearEnd !== null
  ) {
    const { monthEnd, yearEnd } = currentPeriodFilter[INDICATOR_CHARTS_TAB];
    localQueryString = `${localQueryString}&to_date=${monthEnd + 1}.${yearEnd}`;
  }

  localQueryString =
    localQueryString.indexOf('&') === 0 ? localQueryString.substr(1) : localQueryString;

  /** Сохраняем данные запроса касательно периода и терр. фильтра */
  dispatch({
    type: types.SAVE_GET_CHART_PIE_FACTS_QUERY_STRING,
    payload: {
      localQueryString,
    },
  });

  if (queryString) {
    if (localQueryString) {
      localQueryString = `${localQueryString}&${queryString}`;
    } else {
      localQueryString = queryString;
    }
  }

  if (localQueryString) {
    url = `${url}?${localQueryString}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_CHART_PIE_FACTS_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(err => {
        dispatch({
          type: types.GET_CHART_PIE_FACTS_FAIL,
        });

        reject(err);
      });
  });
};

export const getChartPieFactsMemo = argumentsCacheDecorator(getChartPieFacts, [0, 1, 2]);

export const setChartTabInnerMode = mode => ({
  type: types.SET_CHART_TAB_INNER_MODE,
  payload: {
    mode,
  },
});

export const setCurrentMapLevel = (levelId, options) => dispatch => {
  dispatch({
    type: types.SET_CURRENT_MAP_LEVEL,
    payload: {
      levelId,
      options,
    },
  });

  return Promise.resolve();
};

export const setPolygonsOpacity = polygonsOpacity => {
  window.localStorage && window.localStorage.setItem('opacity', polygonsOpacity);
  return {
    type: types.SET_POLYGONS_OPACITY,
    payload: {
      polygonsOpacity,
    },
  };
};

export const setTerritoryRangeFilter = (filter, namespace) => ({
  type: types.SET_TERRITORY_RANGE_FILTER,
  payload: {
    filter,
    namespace,
  },
});

export const setObjectsRangeFilter = filter => ({
  type: types.SET_OBJECTS_RANGE_FILTER,
  payload: { filter },
});

export const toggleChartsFullScreen = () => ({
  type: types.TOGGLE_CHARTS_FULL_SCREEN,
});

export const setMeasuresValues = (measuresValues, namespace = 'territory') => ({
  type: types.SET_MEASURES_VALUES,
  payload: {
    measuresValues,
    namespace,
  },
});

export const changeMeasureValue = (
  measureId,
  measureValue,
  namespace = 'territory',
  type = SELECT,
) => (dispatch, getState) => {
  dispatch({
    type: types.CHANGE_MEASURE_VALUE,
    payload: {
      measureId,
      measureValue,
      namespace,
      type,
    },
  });

  const { measuresValues } = getState().main;

  return Promise.resolve(measuresValues);
};

export const getStaticPage = slug => (dispatch, getState) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { lang } = getState().main;

  return new Promise((resolve, reject) => {
    fetchWrapper(`${process.env.REACT_APP_API_URL}pages/${lang.short}/url/${slug}/`)
      .then(res => {
        dispatch({
          type: types.GET_STATIC_PAGE_SUCCESS,
          payload: {
            page: res,
          },
        });
        resolve(res);
      })
      .catch(reject);
  });
};

export const getContactsPage = () => (dispatch, getState) => {
  dispatch({
    type: types.LOADING_START,
  });

  const { lang } = getState().main;

  return new Promise((resolve, reject) => {
    fetchWrapper(`${process.env.REACT_APP_API_URL}pages/${lang.short}/contacts/`)
      .then(res => {
        dispatch({
          type: types.GET_STATIC_PAGE_SUCCESS,
          payload: {
            page: {
              url: 'contacts',
              ...res,
            },
          },
        });
        resolve(res);
      })
      .catch(reject);
  });
};

export const parseURLParams = () => dispatch => {
  dispatch({
    type: types.PARSE_URL_PARAMS,
  });

  return Promise.resolve();
};

export const setURLParams = params => ({
  type: types.SET_URL_PARAMS,
  payload: {
    params,
  },
});

export const setFilterRegions = (
  res,
  { namespace, initialLoad = false, parentId = null, checked = null } = {},
) => dispatch => {
  dispatch({
    type: types.SET_FILTER_REGIONS,
    payload: {
      res,
      initialLoad,
      parentId,
      checked,
      namespace,
    },
  });
  return Promise.resolve();
};

export const updateFilterRegions = (filterRegions, namespace) => ({
  type: types.UPDATE_FILTER_REGIONS,
  payload: {
    filterRegions,
    namespace,
  },
});

export const setFilterRegionsItem = (item, namespace) => ({
  type: types.SET_FILTER_REGIONS_ITEM,
  payload: {
    item,
    namespace,
  },
});

export const selectPolygon = (polygon, namespace) => ({
  type: types.SELECT_POLYGON,
  payload: {
    polygon,
    namespace,
  },
});

export const selectPolygons = (selectedPolygons, namespace) => ({
  type: types.SELECT_POLYGONS,
  payload: {
    selectedPolygons,
    namespace,
  },
});

export const resetSelectedPolygons = namespace => ({
  type: types.RESET_SELECTED_POLYGONS,
  payload: {
    namespace,
  },
});

export const filterSelectedPolygons = namespace => dispatch => {
  dispatch({
    type: types.FILTER_SELECTED_POLYGONS,
    payload: {
      namespace,
    },
  });
  return Promise.resolve();
};

export const setTerritoriesCleanState = namespace => ({
  type: types.SET_TERRITORIES_CLEAN_STATE,
  payload: {
    namespace,
  },
});

export const setCurrentCity = (city, options = null) => {
  window.localStorage && window.localStorage.setItem('city', JSON.stringify(city));
  return {
    type: types.SET_CURRENT_CITY,
    payload: {
      city,
      options,
    },
  };
};

export const resetAllFilters = () => dispatch => {
  dispatch({
    type: types.RESET_ALL_FILTERS,
  });
  dispatch({
    type: types.SET_RESET_FILTERS_STATE,
    payload: {
      isReset: true,
    },
  });
};

export const setResetFilterState = isReset => ({
  type: types.SET_RESET_FILTERS_STATE,
  payload: {
    isReset,
  },
});

export const selectParentPolygonByDoubleClick = (polygon, namespace) => ({
  type: types.SELECT_PARENT_POLYGON_BY_DOUBLE_CLICK,
  payload: {
    polygon,
    namespace,
  },
});

export const toggleSelectionMode = namespace => ({
  type: types.TOGGLE_SELECTION_MODE,
  payload: {
    namespace,
  },
});

export const exitSelectionMode = () => ({
  type: types.EXIT_SELECTION_MODE,
});

export const getReports = (cityId, search) => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}reports/?city=${cityId}`;
  if (search) {
    url = `${url}&search=${search}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        if (!data || !data.results) {
          reject();
        } else {
          dispatch({
            type: types.GET_REPORTS_SUCCESS,
            payload: {
              data,
            },
          });
          resolve(data);
        }
      })
      .catch(reject);
  });
};

export const toggleFiltersPanel = () => ({
  type: types.TOGGLE_FILTERS_PANEL,
});

export const getDefaultIndicatorGroup = cityId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}indicator/group/default/?city=${parseInt(
    cityId,
    10,
  )}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(res => {
        dispatch({
          type: types.GET_DEFAULT_INDICATOR_GROUP_SUCCESS,
          payload: {
            defaultIndicatorGroup: res,
          },
        });
        resolve(res);
      })
      .catch(reject);
  });
};

export const resetNotification = () => ({
  type: types.RESET_NOTIFICATION,
});

export const getObjectsForIndicator = indicatorId => (dispatch, getState) => {
  let url = `${process.env.REACT_APP_API_URL}data/objects/indicator/${indicatorId}/`;
  let queryString = '';
  dispatch({
    type: types.GET_OBJECTS_FOR_INDICATOR,
  });

  /** Выбранные тер. фильтры для объектов (пока что только для одной карты) */
  const filterValues = getState().main.territoriesFiltersValues.objects.a;
  if (filterValues) {
    let selectedValues = [];
    Object.values(filterValues).forEach(category => {
      selectedValues = [
        ...selectedValues,
        ...Object.keys(category).filter(valueId => category[valueId].selected),
      ];
    });
    if (selectedValues.length > 0) {
      queryString = `${queryString}objects=${selectedValues.join(',')}&`;
    }
  }

  /** Выбранные тер. фильтры для тер. показателя (пока что только для одной карты) */
  const filterValuesTer = getState().main.territoriesFiltersValues.territory.a;
  if (filterValuesTer) {
    Object.keys(filterValuesTer).forEach(measureId => {
      const selectedValues = Object.keys(filterValuesTer[measureId]).filter(
        valueId => filterValuesTer[measureId][valueId].selected,
      );
      if (selectedValues.length > 0) {
        queryString = `${queryString}ter_measures[${measureId}]=${selectedValues.join(',')}&`;
      }
    });
  }

  const { filterRegions, currentMapLevelId, mapLevels } = getState().main;

  /** Добавляем в запрос инфу о региональном фильтре (для первой (или единственной) карты) */
  const { terrs, parentsArr, excludeArr } = getRegionsQueryParams(
    filterRegions.a, // Здесь a - это не ошибка, это первый набор фильтров
    currentMapLevelId,
    mapLevels,
  );

  if (terrs.length) {
    queryString = `${queryString}territories=${terrs.map(item => item.id).join(',')}&`;
  } else {
    queryString = `${queryString}territories=${getState()
      .main.facts.data.map(item => item.territory_id)
      .join(',')}&`;
  }
  if (parentsArr.length) {
    queryString = `${queryString}parents=${parentsArr.map(item => item.id).join(',')}&`;
  }
  if (excludeArr.length) {
    queryString = `${queryString}exclude=${excludeArr.map(item => item.id).join(',')}&`;
  }

  /** Добавляем инфу о городе */
  const { currentCity } = getState().main;
  queryString = `${queryString}&city=${currentCity.id}`;

  /**
   * Добавляем измерения объектов
   */
  if (
    getState().main.indicatorTabsEnabled.objects &&
    Object.values(getState().main.measuresValues.objects)[0][0] &&
    Object.values(getState().main.measuresValues.objects)[0][0].value
  ) {
    const measures = getState().main.measuresValues.objects;
    for (const keyIndicator in measures) {
      if (measures[keyIndicator][0]) {
        queryString = `${queryString}$object_measures[${keyIndicator}]=`;
        for (const keyObject in measures[keyIndicator]) {
          if (Object.prototype.hasOwnProperty.call(measures[keyIndicator], keyObject)) {
            queryString = `${queryString}${measures[keyIndicator][keyObject].value}`;
            if (measures[keyIndicator][keyObject + 1]) queryString += ',';
          }
        }
      }
    }
  }

  /** Добавляем частичную передачу объектов */
  queryString = `${queryString}&partial=1`;

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        const { items, is_full: isFull } = data;
        dispatch({
          type: types.GET_OBJECTS_FOR_INDICATOR_SUCCESS,
          payload: {
            items,
            indicatorId,
            isFull,
          },
        });
        const currentObjectsMeasures = getState().main.currentObjectsMeasures.map(obj => ({
          ...obj,
          measures: obj.measures.map(measure => ({
            ...measure,
            max: getMaxMinValues(items, measure.id).max,
            min: getMaxMinValues(items, measure.id).min,
          })),
        }));
        dispatch({
          type: types.SET_CURRENT_OBJECTS_MEASURES,
          payload: {
            currentObjectsMeasures,
          },
        });
        resolve(items);
      })
      .catch(err => {
        dispatch({
          type: types.GET_OBJECTS_FOR_INDICATOR_FAIL,
        });
        reject(err);
      });
  });
};

export const toggleIndicatorObject = indicatorId => dispatch => {
  dispatch({
    type: types.TOGGLE_INDICATOR_OBJECT,
    payload: { indicatorId },
  });
};

export const setTerritoriesFiltersValues = (fieldValues, namespace, mapMarker) => dispatch => {
  /**
   * В графики и в территориальные показатели будут передаваться одни и те же терр. фильтры
   */
  if (namespace === INDICATOR_TERRITORY_TAB || namespace === INDICATOR_CHARTS_TAB) {
    dispatch({
      type: types.SET_TERRITORIES_FILTERS_VALUES,
      payload: { fieldValues, namespace: INDICATOR_TERRITORY_TAB, mapMarker },
    });
    dispatch({
      type: types.SET_TERRITORIES_FILTERS_VALUES,
      payload: { fieldValues, namespace: INDICATOR_CHARTS_TAB, mapMarker },
    });
    return;
  }
  dispatch({
    type: types.SET_TERRITORIES_FILTERS_VALUES,
    payload: { fieldValues, namespace, mapMarker },
  });
};

export const setSelectedTerritoriesFilters = (measureId, selectedValues, mapMarker) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.SET_SELECTED_TERRITORIES_FILTERS,
    payload: { measureId, selectedValues, mapMarker },
  });

  const namespace = getState().main.currentIndicatorTab;

  return Promise.resolve(getState().main.territoriesFiltersValues[namespace]);
};

export const getTerritoriesBySearch = (query, namespace) => (dispatch, getState) => {
  const { currentIndicator, currentCity } = getState().main;
  let url = `${process.env.REACT_APP_API_URL}territory/?geometry_only=1&indicator=${currentIndicator.id}&city=${currentCity.id}&search=${query}`;
  if (namespace === 'b') url += '&to=1';
  return fetchWrapper(url);
};

export const setChartGroupMode = mode => dispatch => {
  dispatch({
    type: types.SET_CHART_GROUP_MODE,
    payload: { mode },
  });
};

export const setNotificationRemovable = id => dispatch => {
  dispatch({
    type: types.SET_NOTIFICATION_REMOVABLE,
    payload: { id },
  });
};

export const removeNotifications = () => dispatch => {
  dispatch({
    type: types.REMOVE_NOTIFICATIONS,
  });
};

export const getTimeRangeByIndicator = (indicatorId, timeRange) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}indicator/time/${indicatorId}/${timeRange}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_TIME_RANGE_BY_INDICATOR,
          payload: {
            data: data.map(item => ({ ...item, timeRange })),
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const clearTimeRangeByIndicator = () => ({
  type: types.CLEAR_TIME_RANGE_BY_INDICATOR,
});

export const getReportsPage = (dispatch, getState) => {
  const { lang } = getState().main;
  const url = `${process.env.REACT_APP_API_URL}reports/${lang.short}/page/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORTS_PAGE,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const getFavoritesItems = () => async dispatch => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}user/favorites/`);

    dispatch({
      type: types.GET_FAVORITES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.GET_FAVORITES_ERROR,
      payload: error,
    });
  }
};

export const setFavoritesItem = fields => async dispatch => {
  try {
    const csrfToken = await getCsrfToken();
    await fetchWrapper(`${process.env.REACT_APP_API_URL}user/favorites/`, {
      method: 'POST',
      csrfToken,
      body: JSON.stringify(fields),
    });
  } catch (error) {
    throw error;
  }

  dispatch(getFavoritesItems());
};

export const removeFavoritesItem = id => async dispatch => {
  try {
    await fetchWrapper(`${process.env.REACT_APP_API_URL}user/favorites/${id}/`, {
      method: 'DELETE',
    });
  } catch (error) {
    throw error;
  }

  dispatch(getFavoritesItems());
};

export const updateFavoritesItem = (fields, id) => async dispatch => {
  try {
    await fetchWrapper(`${process.env.REACT_APP_API_URL}user/favorites/${id}/`, {
      method: 'PUT',
      body: JSON.stringify(fields),
    });
  } catch (error) {
    throw error;
  }

  dispatch(getFavoritesItems());
};

export const dropParsedParameters = key => ({
  type: types.DROP_PARAMETERS,
  payload: key,
});

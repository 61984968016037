import { connect } from 'react-redux';

// Actions
import {
  getRatingFactsMemo,
  getChartFactsMemo,
  getFactsMemo,
  getPolygons,
  setCurrentMapLevel,
  setURLParams,
} from 'modules/main/actions';

const mapStateToProps = state => ({
  mapLevels: state.main.mapLevels,
  currentMapLevelId: state.main.currentMapLevelId,
  polygons: state.polygons,
  mapZoomLevel: state.main.mapZoomLevel,
  currentChartIndicator: state.main.currentChartIndicator,
  chartTabInnerMode: state.main.chartTabInnerMode,
  currentIndicatorTab: state.main.currentIndicatorTab,
  territoriesFiltersValues: state.main.territoriesFiltersValues,
  currentIndicator: state.main.currentIndicator,
});

const mapDispatchToProps = {
  setCurrentMapLevel,
  getFactsMemoAction: getFactsMemo,
  getPolygons,
  getRatingFactsMemo,
  getChartFactsMemo,
  setURLParams,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import TerritoriesFilter from 'modules/main/components/TerritoriesFilter';

// Actions
import { setTerritoriesFiltersValues, setSelectedTerritoriesFilters } from 'modules/main/actions';

// MICS
import { INDICATOR_OBJECTS_TAB } from 'modules/main/constants';

// Styles
import './assets/styles/styles.scss';

const TerritoriesFilters = ({
  measures,
  dispatch,
  handleChangeTerritoriesFiltersValues,
  namespace,
  territoriesFiltersValues,
  currentMapLevelId,
  currentIndicator,
  hide,
}) => {
  /** Установка значений при инициализации и при смене показателя */
  useEffect(() => {
    if (!measures) {
      return;
    }

    if (measures) {
      const fieldValues = measures.reduce((acc, current) => {
        const values = current.values.reduce((valAcc, valCurrent) => {
          // eslint-disable-next-line no-param-reassign
          valAcc[valCurrent.id] = {
            name: valCurrent.name,
            selected: false,
          };

          return valAcc;
        }, {});

        acc.a = { ...acc.a };
        acc.b = { ...acc.b };

        acc.a[current.id] = JSON.parse(JSON.stringify(values));

        if (current.maps_use === 'all' || current.maps_use === 'to') {
          acc.b[current.id] = JSON.parse(JSON.stringify(values));
        }

        return acc;
      }, {});

      if (fieldValues.a === undefined) {
        fieldValues.a = [];
      }

      if (fieldValues.b === undefined) {
        fieldValues.b = [];
      }

      const mapsToUpdate = {
        a: true,
        b: true,
      };

      /** Показатель не изменился */
      if (
        territoriesFiltersValues &&
        territoriesFiltersValues[namespace] &&
        Object.keys(fieldValues.a).every(id =>
          Object.keys(territoriesFiltersValues[namespace].a).includes(id),
        )
      ) {
        mapsToUpdate.a = false;
      }

      if (
        territoriesFiltersValues &&
        territoriesFiltersValues[namespace] &&
        Object.keys(fieldValues.b).every(id =>
          Object.keys(territoriesFiltersValues[namespace].b).includes(id),
        )
      ) {
        mapsToUpdate.b = false;
      }

      ['a', 'b'].forEach(mapMarker => {
        if (mapsToUpdate[mapMarker]) {
          dispatch(setTerritoriesFiltersValues(fieldValues[mapMarker], namespace, mapMarker));
        }
      });
    }
  }, [measures, territoriesFiltersValues[namespace], namespace]);

  /** Помечаем выбранные в попапе фильтры. Запрашиваем обновление данных */
  const applyChanges = mapMarker => (measureId, selectedValues) => {
    dispatch(setSelectedTerritoriesFilters(measureId, selectedValues, mapMarker)).then(newValues =>
      handleChangeTerritoriesFiltersValues(newValues),
    );
  };

  if (!measures || !territoriesFiltersValues || !territoriesFiltersValues[namespace]) {
    return null;
  }

  /** Скрываем тер. фильтры, если объект не выбран */
  if (hide) {
    return null;
  }

  return (
    <div className="territories-filters">
      {measures
        .filter(
          measure =>
            measure.ter_type_ids.includes(currentMapLevelId) || namespace === INDICATOR_OBJECTS_TAB,
        )
        .map(measure => (
          <React.Fragment key={measure.id}>
            {currentIndicator && (measure.maps_use === 'all' || measure.maps_use === 'from') && (
              <TerritoriesFilter
                measure={measure}
                applyChanges={applyChanges('a')}
                territoriesFiltersValues={territoriesFiltersValues[namespace].a}
              />
            )}
            {currentIndicator &&
              currentIndicator.has_second_map &&
              (measure.maps_use === 'all' || measure.maps_use === 'to') && (
                <TerritoriesFilter
                  measure={measure}
                  applyChanges={applyChanges('b')}
                  territoriesFiltersValues={territoriesFiltersValues[namespace].b}
                  second
                />
              )}
          </React.Fragment>
        ))}
    </div>
  );
};

TerritoriesFilters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleChangeTerritoriesFiltersValues: PropTypes.func.isRequired,
  measures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  namespace: PropTypes.string.isRequired,
  territoriesFiltersValues: PropTypes.shape({}),
  currentMapLevelId: PropTypes.number,
  currentIndicator: PropTypes.shape({
    has_second_map: PropTypes.bool,
  }),
  hide: PropTypes.bool,
};

TerritoriesFilters.defaultProps = {
  measures: null,
  territoriesFiltersValues: null,
  currentMapLevelId: null,
  currentIndicator: null,
  hide: false,
};

const mapStateToProps = state => ({
  territoriesFiltersValues: state.main.territoriesFiltersValues,
  currentMapLevelId: state.main.currentMapLevelId,
  currentIndicator: state.main.currentIndicator,
});

export default connect(mapStateToProps)(TerritoriesFilters);

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import useToggleAndOutClick from 'use-toggle-and-outclick';

// Actions
import { getFactsMemo } from 'modules/main/actions';

// Icons
import { ReactComponent as IconLayers } from 'assets/icons/icon-layers.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';

// Misc
import { INDICATOR_CHARTS_TAB, CHART_TAB_INNER_MODE_CHART } from 'modules/main/constants';
import getTerritoryIds from 'helpers/get-territory-ids';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

const LayersControl = ({
  className,
  currentMapLevelId,
  mapLevels,
  setCurrentMapLevel,
  getFactsMemoAction,
  getPolygons,
  currentIndicator,
  currentChartIndicator,
  currentIndicatorTab,
  chartTabInnerMode,
  getRatingFactsMemo,
  getChartFactsMemo,
  polygons,
  setURLParams,
  territoriesFiltersValues,
}) => {
  const [isDropOpened, dropEl, handleClick, closeExplicitly] = useToggleAndOutClick();

  /** Переводы */
  const langOb = useLang('LayersControl');

  const handleLevelClick = levelId => {
    if (levelId === currentMapLevelId) {
      return;
    }

    closeExplicitly();

    setCurrentMapLevel(levelId)
      .then(() => {
        setURLParams({
          mapLevel: levelId,
        });

        /** Если открыта вкладка с рейтингами - перестраиваем */
        if (
          currentIndicatorTab === INDICATOR_CHARTS_TAB &&
          chartTabInnerMode === CHART_TAB_INNER_MODE_CHART &&
          currentChartIndicator
        ) {
          if (currentChartIndicator.chart_type === 'rating') {
            getRatingFactsMemo(null, levelId);
          } else {
            getChartFactsMemo(null, levelId);
          }
        }

        /** Берем из cached query string тер. фильтры */
        const { cachedArguments: cachedArgumentsFacts } = getFactsMemo;
        const oldQueryString = cachedArgumentsFacts[3];
        let newQueryString = '';

        const filterValues = territoriesFiltersValues.territory.a;

        /** Если ter_measures входят в список "ter_type_ids" и есть фильтры, записываем тер. фильтры */
        if (
          currentIndicator.ter_measures.filter(measure => measure.ter_type_ids.includes(levelId))
            .length > 0 &&
          filterValues
        ) {
          newQueryString = oldQueryString;
          Object.keys(filterValues).forEach(measureId => {
            const selectedValues = Object.keys(filterValues[measureId]).filter(
              valueId => filterValues[measureId][valueId].selected,
            );
            if (selectedValues.length > 0) {
              newQueryString = `${newQueryString}ter_measures[${measureId}]=${selectedValues.join(
                ',',
              )}&`;
            }
          });
        } else {
          /** Иначе вырезаем из cached query string тер. фильтры */
          newQueryString = `${oldQueryString
            .split('&')
            .filter(param => !param.includes('ter_measures') && param !== '')
            .join('&')}&`;
        }

        return getFactsMemoAction(null, levelId, cachedArgumentsFacts[2], newQueryString);
      })
      .then(res => {
        /**
         * Получение полигонов по территориям,
         * которые пришли в фактах (мерах)
         */
        const territoryIds = getTerritoryIds(res, polygons);
        if (territoryIds.length) {
          return getPolygons(territoryIds);
        }

        return null;
      })
      .catch(() => {});
  };

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('layers-control', {
        [className]: className,
      })}
    >
      <button
        type="button"
        className={cx('layers-control__button', {
          'layers-control__button_active': isDropOpened,
        })}
        onClick={handleClick}
        disabled={!mapLevels || !mapLevels.length || mapLevels.length < 2}
        aria-label={langOb.toggleLabel}
      >
        <IconLayers className="layers-control__button-icon" />
      </button>

      <CSSTransition
        in={isDropOpened}
        timeout={200}
        classNames="layers-control__drop"
        unmountOnExit
      >
        <div className="layers-control__drop" ref={dropEl} data-testid="layers-control-drop">
          <p className="layers-control__drop-title">{langOb.title}</p>
          <div className="layers-control__drop-list">
            {mapLevels &&
              mapLevels.length &&
              mapLevels.map(level => (
                <button
                  key={level.id}
                  type="button"
                  className={cx('layers-control__drop-option', {
                    'layers-control__drop-option_active': level.id === currentMapLevelId,
                  })}
                  onClick={() => {
                    handleLevelClick(level.id);
                  }}
                >
                  {level.id === currentMapLevelId && (
                    <IconCheck className="layers-control__drop-option-icon" />
                  )}
                  {level.name}
                </button>
              ))}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

LayersControl.propTypes = {
  className: PropTypes.string,
  currentMapLevelId: PropTypes.number,
  mapLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  setCurrentMapLevel: PropTypes.func.isRequired,
  getFactsMemoAction: PropTypes.func.isRequired,
  getRatingFactsMemo: PropTypes.func.isRequired,
  getChartFactsMemo: PropTypes.func.isRequired,
  getPolygons: PropTypes.func.isRequired,
  currentIndicator: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  currentIndicatorTab: PropTypes.string,
  chartTabInnerMode: PropTypes.string.isRequired,
  currentChartIndicator: PropTypes.shape({
    chart_type: PropTypes.string,
  }),
  territoriesFiltersValues: PropTypes.shape({}),
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  setURLParams: PropTypes.func.isRequired,
};

LayersControl.defaultProps = {
  territoriesFiltersValues: null,
  className: null,
  currentMapLevelId: null,
  currentIndicator: null,
  currentIndicatorTab: null,
  currentChartIndicator: null,
};

export default LayersControl;

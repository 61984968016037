import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { formatNumber } from 'helpers';

// Misc
import { FACT_UNIT_NAMES } from 'modules/main/constants';

// Styles
import './assets/styles/styles.scss';

export const Ratings = ({
  chartRatings,
  currentChartIndicator,
  withTitle,
  className,
  setImageURI,
}) => {
  const {
    val_precision: valPrecision = 2,
    val_precision_percent: valPrecisionPercent = 2,
    unit_name: unitName = '',
    description,
  } = currentChartIndicator;

  const wrapper = useRef();

  const precision =
    currentChartIndicator.unit_name === FACT_UNIT_NAMES.percent
      ? valPrecisionPercent
      : valPrecision;

  useEffect(() => {
    if (wrapper.current) {
      setImageURI(wrapper.current);
    }
  }, [chartRatings, currentChartIndicator]);

  return (
    <div
      className={cx('ratings', {
        [className]: className,
      })}
    >
      {withTitle && Boolean(description) && <h3 className="ratings__title">{description}</h3>}

      <div className="ratings__ratings" ref={wrapper}>
        {chartRatings &&
          chartRatings.length > 0 &&
          chartRatings.map((item, index) => {
            const width = (item.agg_value / chartRatings[0].agg_value) * 100;

            return (
              <div className="ratings__ratings-item" key={index}>
                <p className="ratings__ratings-item-name">{item.territory.name}</p>
                <div className="ratings__ratings-item-row">
                  <div
                    className={cx('ratings__ratings-item-bar', {
                      'ratings__ratings-item-bar_small': width <= 40,
                    })}
                    style={{ width: `${width}%` }}
                  >
                    {width > 10 && <div className="ratings__ratings-item-rank">{index + 1}</div>}
                    {width > 30 && (
                      <div className="ratings__ratings-item-value">
                        {`${formatNumber(item.agg_value, 1, precision)} ${unitName}`}
                      </div>
                    )}
                  </div>
                  {width <= 10 && (
                    <div className="ratings__ratings-item-rank ratings__ratings-item-rank_dark">
                      {index + 1}
                    </div>
                  )}
                  {width <= 30 && (
                    <div className="ratings__ratings-item-value ratings__ratings-item-value_dark">
                      {`${formatNumber(item.agg_value, 1, precision)} ${unitName}`}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

Ratings.propTypes = {
  chartRatings: PropTypes.arrayOf(
    PropTypes.shape({
      territory: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      agg_value: PropTypes.number,
    }),
  ).isRequired,
  currentChartIndicator: PropTypes.shape({
    description: PropTypes.string,
    unit_name: PropTypes.string,
    val_precision: PropTypes.number,
    val_precision_percent: PropTypes.number,
  }).isRequired,
  setImageURI: PropTypes.func.isRequired,
  withTitle: PropTypes.bool,
  className: PropTypes.string,
};

Ratings.defaultProps = {
  withTitle: false,
  className: null,
};

const mapStateToProps = state => ({
  chartRatings: state.main.chartRatings,
  currentChartIndicator: state.main.currentChartIndicator,
});

export default connect(mapStateToProps)(Ratings);

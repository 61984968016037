import * as types from 'modules/main/actions/action-types';

const initialState = {};

const polygonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_POLYGONS_SUCCESS: {
      let prevState = state;

      if (action.payload.indicatorId !== null) {
        prevState = Object.keys(prevState)
          .map(key => prevState[key])
          .filter(polygon => polygon.indicatorId !== action.payload.indicatorId)
          .reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {});
      }

      return {
        ...prevState,
        ...action.payload.polygons.reduce((acc, curItem) => {
          if (curItem && curItem.geometry) {
            acc[curItem.id] = curItem;
            if (action.payload.indicatorId) {
              acc[curItem.id].indicatorId = action.payload.indicatorId;
            }
          }
          return acc;
        }, {}),
      };
    }

    default:
      return state;
  }
};

export default polygonsReducer;

import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as IconAction } from 'assets/icons/icon-action.svg';

// Misc
import useLang from 'hooks/useLang';

export const FavoriteItem = ({ title, remove, rename, url }) => {
  const [isDropOpened, dropEl, handleClick, closeExplicitly] = useToggleAndOutClick();
  const [position, setClickPosition] = useState({ top: 0, left: 0 });

  const lang = useLang('Favorites');

  let timeoutFunction = null;

  const preventDefaultActionLink = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClickAction = event => {
    preventDefaultActionLink(event);

    const { top, left } = event.currentTarget.getBoundingClientRect();

    setClickPosition({ top, left });
    handleClick(event);

    if (timeoutFunction) {
      clearTimeout(timeoutFunction);
    }

    timeoutFunction = setTimeout(closeExplicitly, 3000); // Убираем tooltip, если пользователь ничего не нажал
  };

  const renameHandler = event => {
    preventDefaultActionLink(event);
    rename();
  };

  const removeHandler = event => {
    preventDefaultActionLink(event);
    remove();
  };

  if (!lang) {
    return null;
  }

  return (
    <a className="favorites-container__item" href={url}>
      <div className="favorites-container__item-name">{title}</div>

      <button
        onClick={handleClickAction}
        type="button"
        className="favorites-container__item-button"
        data-testid="tooltip"
      >
        <IconAction />
      </button>

      <CSSTransition
        in={isDropOpened}
        timeout={200}
        classNames="favorites-container__item-drop"
        unmountOnExit
      >
        <div
          className="favorites-container__item-drop"
          ref={dropEl}
          style={{
            top: position.top - 7,
            left: position.left + 30,
          }}
        >
          <button
            type="button"
            className="favorites-container__item-drop-button"
            onClick={renameHandler}
          >
            {lang.tooltipRename}
          </button>
          <button
            type="button"
            className="favorites-container__item-drop-button"
            onClick={removeHandler}
          >
            {lang.tooltipRemove}
          </button>
        </div>
      </CSSTransition>
    </a>
  );
};

FavoriteItem.propTypes = {
  title: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default FavoriteItem;

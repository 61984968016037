import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useWindowSize from '@rehooks/window-size';

// Components
import AreasFilter from 'modules/main/components/AreasFilter';
import Popup from 'shared/Popup';
import IndicatorsGroupSelect from 'modules/main/components/IndicatorsGroupSelect';
import Indicators from 'modules/main/components/Indicators';
import IndicatorsTerritory from 'modules/main/components/IndicatorsTerritory';
import IndicatorsObjects from 'modules/main/components/IndicatorsObjects';
import IndicatorsCharts from 'modules/main/components/IndicatorsCharts';

// Actions
import {
  selectIndicatorTab,
  deselectIndicatorTab,
  setURLParams,
  toggleFiltersPanel,
} from 'modules/main/actions';

// Icons
import { ReactComponent as IconPackage } from 'assets/icons/icon-package.svg';
import { ReactComponent as IconTerritoryStats } from 'assets/icons/icon-territory-stats.svg';
import { ReactComponent as IconObjectsStats } from 'assets/icons/icon-objects-stats.svg';
import { ReactComponent as IconGraphicsStats } from 'assets/icons/icon-graphics-stats.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconFilters } from 'assets/icons/icon-filters.svg';
import { ReactComponent as IconArrow } from 'assets/icons/icon-arrow.svg';

// Misc
import {
  INDICATOR_CHARTS_TAB,
  INDICATOR_OBJECTS_TAB,
  INDICATOR_TERRITORY_TAB,
} from 'modules/main/constants';
import useLang from 'hooks/useLang';

// Styles
import { mobile } from 'assets/breakpoints';
import './assets/styles/styles.scss';
import FiltersButtons from '../FiltersButtons';

const FiltersContainer = ({
  className,
  currentIndicatorsGroup,
  currentIndicatorTab,
  indicatorTabsEnabled,
  dispatch,
  chartTabInnerMode,
  isChartsFullScreen,
  currentChartIndicator,
  currentIndicator,
  currentIndicatorTabEnabled,
  toggleSelectionMode,
  user,
}) => {
  /** Переводы */
  const langOb = useLang('FiltersContainer');

  const [isIndicatorsGroupModalOpen, setIsIndicatorsGroupModalOpen] = useState(false);
  const [isFiltersPopupMobileOpen, setIsFiltersPopupMobileOpen] = useState(false);

  const windowSize = useWindowSize();

  const activateIndicatorsGroupModal = () => {
    setIsIndicatorsGroupModalOpen(true);
  };

  const deactivateIndicatorsGroupModal = () => {
    setIsIndicatorsGroupModalOpen(false);
  };

  const clickIndicatorTabHandler = tab => {
    if (currentIndicatorTab === tab) {
      dispatch(deselectIndicatorTab());
      dispatch(
        setURLParams({
          currentIndicatorTab: null,
        }),
      );
    } else {
      dispatch(selectIndicatorTab(tab));
      dispatch(
        setURLParams({
          currentIndicatorTab: tab,
        }),
      );
    }
  };

  const renderFiltersContainer = () => (
    <div
      className={cx('filters-container', {
        [className]: className,
        [`${className}_fixed`]: className && isFiltersPopupMobileOpen,
      })}
    >
      {/* TOGGLE */}
      <button
        type="button"
        className="filters-container__toggle-button"
        onClick={() => {
          dispatch(toggleFiltersPanel());
        }}
      >
        <IconArrow className="filters-container__toggle-icon" />
      </button>
      {/* /TOGGLE */}
      {/* SELECTORS */}
      <div className="filters-container__selectors">
        {isFiltersPopupMobileOpen && (
          <div className="filters-container__item">
            <div className="filters-container__mobile-top">
              <p className="filters-container__mobile-title">{langOb.mobileTitle}</p>
              <button
                className="filters-container__mobile-close-button"
                type="button"
                onClick={() => {
                  setIsFiltersPopupMobileOpen(false);
                }}
              >
                <IconClose className="filters-container__mobile-close-button-icon" />
              </button>
            </div>
          </div>
        )}

        <div className="filters-container__item">
          <button
            className="filters-container__item-button"
            type="button"
            onClick={activateIndicatorsGroupModal}
          >
            <IconPackage className="filters-container__item-icon" />
            <div className="filters-container__item-names">
              <p className="filters-container__item-name">{langOb.dataPackage}</p>
              <p className="filters-container__item-desc">
                {currentIndicatorsGroup && currentIndicatorsGroup.name}
                {!currentIndicatorsGroup && langOb.selectDataPackage}
              </p>
            </div>
          </button>
        </div>

        <AreasFilter toggleSelectionMode={toggleSelectionMode} />
      </div>
      {/* /SELECTORS */}
      {/* TABS */}
      <div className="filters-container__tabs">
        <button
          type="button"
          className={cx('filters-container__tab', {
            'filters-container__tab_active': currentIndicatorTab === INDICATOR_TERRITORY_TAB,
          })}
          onClick={() => clickIndicatorTabHandler(INDICATOR_TERRITORY_TAB)}
          disabled={!currentIndicatorsGroup}
        >
          <IconTerritoryStats className="filters-container__tab-icon" />
          <span className="filters-container__tab-name">{langOb.tabTerritoryName}</span>
        </button>

        <button
          type="button"
          className={cx('filters-container__tab', {
            'filters-container__tab_active': currentIndicatorTab === INDICATOR_OBJECTS_TAB,
          })}
          onClick={() => clickIndicatorTabHandler(INDICATOR_OBJECTS_TAB)}
          disabled={currentIndicator && currentIndicator.objects.length === 0}
        >
          <IconObjectsStats className="filters-container__tab-icon" />
          <span className="filters-container__tab-name">{langOb.tabObjectsName}</span>
        </button>

        <button
          type="button"
          className={cx('filters-container__tab', {
            'filters-container__tab_active': currentIndicatorTab === INDICATOR_CHARTS_TAB,
          })}
          onClick={() => clickIndicatorTabHandler(INDICATOR_CHARTS_TAB)}
          disabled={
            !currentIndicatorsGroup || (currentIndicator && currentIndicator.charts.length === 0)
          }
        >
          <IconGraphicsStats className="filters-container__tab-icon" />
          <span className="filters-container__tab-name">{langOb.tabChartsName}</span>
        </button>
      </div>
      {/* TABS */}
      {/* INDICATORS */}
      {currentIndicatorTab && currentIndicatorTabEnabled && (
        <Indicators
          className="filters-container__indicators"
          currentTab={currentIndicatorTab}
          isEnabled={indicatorTabsEnabled[currentIndicatorTab]}
          chartTabInnerMode={chartTabInnerMode}
          isChartsFullScreen={isChartsFullScreen}
          currentChartIndicator={currentChartIndicator}
        >
          {currentIndicatorTab === INDICATOR_TERRITORY_TAB && (
            <IndicatorsTerritory className="filters-container__territory-indicators" />
          )}
          {currentIndicatorTab === INDICATOR_OBJECTS_TAB && (
            <IndicatorsObjects className="filters-container__objects-indicators" />
          )}
          {currentIndicatorTab === INDICATOR_CHARTS_TAB && (
            <IndicatorsCharts className="filters-container__charts-indicators" />
          )}
        </Indicators>
      )}
      {/* /INDICATORS */}

      {/* BUTTONS */}
      {currentIndicatorTab !== INDICATOR_CHARTS_TAB && user && <FiltersButtons />}
      {/* BUTTONS */}
    </div>
  );

  if (!langOb) {
    return null;
  }

  return (
    <React.Fragment>
      {windowSize.innerWidth > mobile && renderFiltersContainer()}
      {windowSize.innerWidth <= mobile && !isFiltersPopupMobileOpen && (
        <button
          type="button"
          onClick={() => {
            setIsFiltersPopupMobileOpen(true);
          }}
          className="filters-container__mobile-toggle"
        >
          <IconFilters className="filters-container__mobile-toggle-icon" />
        </button>
      )}
      {windowSize.innerWidth <= mobile &&
        isFiltersPopupMobileOpen &&
        createPortal(renderFiltersContainer(), document.body)}
      return acc;
      {isIndicatorsGroupModalOpen && (
        <Popup
          closeHandler={deactivateIndicatorsGroupModal}
          popupBodyClassName="filters-container__package-popup"
        >
          <IndicatorsGroupSelect
            closeHandler={deactivateIndicatorsGroupModal}
            className="filters-container__package-select"
          />
        </Popup>
      )}
    </React.Fragment>
  );
};

FiltersContainer.propTypes = {
  className: PropTypes.string,
  currentIndicatorsGroup: PropTypes.shape({
    name: PropTypes.string,
  }),
  currentIndicatorTab: PropTypes.string,
  indicatorTabsEnabled: PropTypes.shape({
    [INDICATOR_TERRITORY_TAB]: PropTypes.bool,
    [INDICATOR_OBJECTS_TAB]: PropTypes.bool,
    [INDICATOR_CHARTS_TAB]: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  chartTabInnerMode: PropTypes.string.isRequired,
  isChartsFullScreen: PropTypes.bool.isRequired,
  currentChartIndicator: PropTypes.shape({
    chart_type: PropTypes.string,
  }),
  currentIndicator: PropTypes.shape({
    has_second_map: PropTypes.bool,
    objects: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentIndicatorTabEnabled: PropTypes.bool,
  toggleSelectionMode: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

FiltersContainer.defaultProps = {
  className: null,
  currentIndicatorsGroup: null,
  currentIndicatorTab: null,
  currentChartIndicator: null,
  currentIndicator: null,
};

const mapStateToProps = state => ({
  currentIndicatorsGroup: state.main.currentIndicatorsGroup,
  currentIndicatorTab: state.main.currentIndicatorTab,
  indicatorTabsEnabled: state.main.indicatorTabsEnabled,
  chartTabInnerMode: state.main.chartTabInnerMode,
  isChartsFullScreen: state.main.isChartsFullScreen,
  currentChartIndicator: state.main.currentChartIndicator,
  currentIndicator: state.main.currentIndicator,
  currentIndicatorTabEnabled: state.main.currentIndicatorTabEnabled,
  indicators: state.main.indicators,
  user: state.main.user,
});

export default connect(mapStateToProps)(FiltersContainer);

import React from 'react';
import PropTypes from 'prop-types';

import './assets/styles/styles.scss';

const AuthHeader = ({ name, description }) => (
  <React.Fragment>
    <h1 className="auth__caption">{name}</h1>
    {description && <p className="auth__description">{description}</p>}
  </React.Fragment>
);

AuthHeader.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

AuthHeader.defaultProps = {
  name: null,
  description: null,
};

export default AuthHeader;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { getStaticPage } from 'modules/main/actions';

// Misc
import useLang from 'hooks/useLang';

// icons
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';

// Styles
import './assets/styles/styles.scss';

export const Static = ({ match, dispatch, staticPages }) => {
  /** Переводы */
  const langOb = useLang('Static');

  const [content, setContent] = useState({
    content: '',
  });

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    if (staticPages && staticPages[match.params.slug]) {
      setContent(staticPages[match.params.slug]);
    } else {
      setIsFetching(true);
      dispatch(getStaticPage(match.params.slug))
        .then(res => {
          setContent(res);
          setIsFetching(false);
        })
        .catch(() => {
          setContent({
            content: langOb ? langOb.pageNotFound : '',
          });
          setIsFetching(false);
        });
    }
  }, [match.params.slug]);

  /* eslint-disable react/no-danger */
  return (
    <div className="static-page">
      {content && content.content && (
        <React.Fragment>
          {content.image && (
            <div className="static-page__image">
              <img src={content.image} alt="" className="static-page__img" />
            </div>
          )}
          <div
            className="static-page__content"
            dangerouslySetInnerHTML={{ __html: content.content }}
          />
        </React.Fragment>
      )}

      {isFetching && (
        <div className="static-page__loading" data-testid="static-page-loading">
          <IconLoader className="static-page__loading-icon" />
        </div>
      )}
    </div>
  );
};

Static.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }).isRequired,
  staticPages: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  menuItems: state.main.menuItems,
  staticPages: state.main.staticPages,
});

export default connect(mapStateToProps)(Static);

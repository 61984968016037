import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './assets/styles/styles.scss';

// Icons
import { ReactComponent as IncorrectPointer } from 'assets/images/incorrect-pointer.svg';

const InputHintPointer = ({ className, text }) => (
  <div
    className={cx('hint-pointer', {
      [className]: className,
    })}
  >
    <IncorrectPointer className="hint-pointer__icon" />
    <p className="hint-pointer__caption">{text}</p>
  </div>
);

InputHintPointer.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

InputHintPointer.defaultProps = {
  className: null,
};

export default InputHintPointer;

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Chart } from 'react-google-charts';
import gradient from 'gradient-color';

// Misc
import { FACT_UNIT_NAMES, PALETTE_TEMPERATURE_HEX_COLORS } from 'modules/main/constants';
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

const colors = {
  default: ['#4a90e2', '#D0DCEE', '#D97908', '#f1d6a1'],
  temperature: PALETTE_TEMPERATURE_HEX_COLORS,
};

const ChartsPie = ({
  chartPieFacts,
  currentChartIndicator,
  palette,
  className,
  withTitle,
  height,
  setImageURI,
  fullScreen,
}) => {
  const [wrapper, setWrapper] = useState(null);

  /** Переводы */
  const langOb = useLang('ChartsPie');

  const {
    val_precision: valPrecision = 2,
    val_precision_percent: valPrecisionPercent = 2,
  } = currentChartIndicator;
  const sumOfValues =
    chartPieFacts &&
    chartPieFacts.data &&
    chartPieFacts.data.reduce((acc, cur) => acc + cur.value, 0);

  const getColorSlices = () => {
    /** Выбор цветовой палитры */
    const colorsPalette = (() => {
      if (palette === 'temperature') {
        if (chartPieFacts.data.length > colors[palette].length) {
          return gradient(colors[palette], chartPieFacts.data.length);
        }
      }
      return colors[palette];
    })();

    chartPieFacts.data.sort((a, b) => (a.value < b.value ? 1 : -1));
    /**
     * Определяем цвет слайса по величине значения по убывающей
     */
    return chartPieFacts.data.reduce(
      (acc, currentValue, index) => ({
        ...acc,
        [index]: colorsPalette && { color: colorsPalette[colorsPalette.length - index - 1] },
      }),
      {},
    );
  };

  const getPercentage = value => Number(((value / sumOfValues) * 100).toFixed(valPrecisionPercent));

  const getFloatValue = value =>
    Math.floor(value * Math.pow(10, valPrecision)) / Math.pow(10, valPrecision);

  useEffect(() => {
    if (wrapper) {
      const chart = wrapper.getChart();
      if (chart && typeof chart.getImageURI === 'function') {
        setImageURI(chart.getImageURI());
      }
    }
  }, [wrapper, chartPieFacts, currentChartIndicator]);

  if (!langOb) {
    return null;
  }

  return (
    <div
      className={cx('charts', {
        [className]: className,
      })}
    >
      {withTitle && (
        <h3 className="charts__title" data-testid="charts-title">
          {currentChartIndicator.description}
        </h3>
      )}

      <div className="charts__chart-itself">
        {chartPieFacts &&
        chartPieFacts.data &&
        chartPieFacts.data.map(item => item.value).some(item => item) ? (
          <Chart
            chartType="PieChart"
            data={[
              [
                currentChartIndicator.name,
                chartPieFacts.unit_name,
                { type: 'string', role: 'tooltip', p: { html: true } },
              ],
              ...chartPieFacts.data.map(item => [
                item.header,
                chartPieFacts.unit_name === FACT_UNIT_NAMES.percent
                  ? getPercentage(item.value)
                  : getFloatValue(item.value),
                `<div class="charts__tooltip ${
                  fullScreen ? 'charts__tooltip_is-full-screen' : ''
                }">\
                    ${item.header}\
                    <div class="charts__tooltip-content">\
                      ${getFloatValue(item.value).toLocaleString()} ${
                  langOb.unit_absolute
                } (${getPercentage(item.value)
                  .toString()
                  .replace(/\./g, ',')}%)\
                      </div>\
                  </div>`,
              ]),
            ]}
            chartLanguage="ru"
            formatters={[
              chartPieFacts.unit_name === FACT_UNIT_NAMES.percent && {
                type: 'NumberFormat',
                column: 1,
                options: {
                  suffix: FACT_UNIT_NAMES.percent,
                  fractionDigits: valPrecisionPercent,
                },
              },
            ]}
            options={{
              chartArea: { width: '73%' },
              legend: 'top',
              pieSliceText: 'value',
              slices: getColorSlices(),
              tooltip: { isHtml: true },
            }}
            width="100%"
            height={height}
            getChartWrapper={chartWrapper => {
              setWrapper(chartWrapper);
            }}
          />
        ) : (
          <p className="charts__chart-no-data">{langOb.noData}</p>
        )}
      </div>
    </div>
  );
};

ChartsPie.propTypes = {
  chartPieFacts: PropTypes.shape({
    unit_name: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  currentChartIndicator: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    unit_name: PropTypes.string,
    chart_view: PropTypes.string,
    val_precision: PropTypes.number,
    val_precision_percent: PropTypes.number,
  }).isRequired,
  palette: PropTypes.string.isRequired,
  className: PropTypes.string,
  withTitle: PropTypes.bool,
  height: PropTypes.string,
  setImageURI: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
};

ChartsPie.defaultProps = {
  className: null,
  withTitle: false,
  height: '300px',
  fullScreen: false,
};

const mapStateToProps = state => ({
  chartPieFacts: state.main.chartPieFacts,
  currentChartIndicator: state.main.currentChartIndicator,
  palette: state.main.currentIndicatorsGroup.palette,
});

export default connect(mapStateToProps)(ChartsPie);

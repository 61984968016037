import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Components
import Charts from 'modules/main/components/Charts';
import ChartsPie from 'modules/main/components/ChartsPie';
import Ratings from 'modules/main/components/Ratings';
import ChartsIntervalSelect from 'modules/main/components/ChartsIntervalSelect';

import ChartsTimeNav from 'modules/main/components/ChartsTimeNav';
import ChartsMeasures from 'modules/main/components/ChartsMeasures';
import PeriodSelectTrigger from 'modules/main/components/PeriodSelectTrigger';
import TerritoriesFilters from 'modules/main/components/TerritoriesFilters';

// Actions
import {
  selectChartIndicator,
  getChartFactsMemo,
  getRatingFactsMemo,
  getChartPieFactsMemo,
  getFactsMemo,
  getPolygons,
  setChartTabInnerMode,
  resetCurrentPeriodFilter,
  setChartGroupMode,
  setURLParams,
  dropParsedParameters,
} from 'modules/main/actions';

// Misc
import {
  INDICATOR_CHARTS_TAB,
  CHART_GROUP_DAY,
  CHART_GROUP_WEEK,
  CHART_GROUP_MONTH,
  CHART_GROUP_YEAR,
  CHART_GROUP_HOUR,
  CHART_GROUP_HALFHOUR,
  CHART_TAB_INNER_MODE_CHART,
  CHART_TAB_INNER_MODE_CONFIG,
} from 'modules/main/constants';
import useLang from 'hooks/useLang';

import { updateFactsForTerritoriesFilters } from 'helpers';

// Icons
import { ReactComponent as IconLoader } from 'assets/icons/icon-loader.svg';

// Styles
import { selectStyles } from 'assets/styles/select';

export const ChartsNav = React.lazy(() => import('../ChartsNav'));

const IndicatorsCharts = props => {
  const {
    currentChartIndicator,
    currentIndicator,
    chartIndicators,
    measuresValues,
    dispatch,
    chartTabInnerMode,
    currentMapLevelId,
    getChartFactsQueryString,
    getChartPieFactsQueryString,
    getRatingFactsQueryString,
    territoriesFiltersValues,
    chartGroupMode,
    polygons,
    parsedIndicator,
    user,
  } = props;

  /** Переводы */
  const langOb = useLang('IndicatorsCharts');

  const [isChartFactsLoadings, setIsChartFactsLoadings] = useState(false);
  const [isChartDatesChanging, setIsChartDatesChanging] = useState(false);
  const [chartImageURI, setChartImageURI] = useState(null);

  useEffect(() => {
    if (chartIndicators && chartIndicators.length && parsedIndicator) {
      dispatch(selectChartIndicator(chartIndicators.find(({ id }) => id === parsedIndicator)));
      dispatch(dropParsedParameters('indicator'));
    }
  }, [chartIndicators]);

  const handleChangeChartIndicator = option => {
    dispatch(selectChartIndicator(option));
    dispatch(setURLParams({ 'chart[indicator]': option.id }));
  };

  const handleChangeTerritoriesFiltersValues = newTerritoriesFiltersValues => {
    updateFactsForTerritoriesFilters(
      null,
      measuresValues || undefined,
      newTerritoriesFiltersValues || territoriesFiltersValues.a,
      INDICATOR_CHARTS_TAB,
      currentIndicator,
      currentMapLevelId,
      polygons,
      getFactsMemo,
      getPolygons,
      dispatch,
    );
  };

  /** Установка начального значения для интервала отображения графика */
  useEffect(() => {
    if (!currentChartIndicator) {
      return;
    }

    const initChartIndicator = currentChartIndicator.time_buttons
      ? currentChartIndicator.time_buttons.find(button => button.is_default).value
      : CHART_GROUP_MONTH;

    dispatch(setChartGroupMode(initChartIndicator));
  }, [currentChartIndicator]);

  /**
   * Получение фактов (мер) для построения графика
   */
  const generateTimeChart = ({ fromDate = null, toDate = null, bigPreloader = false } = {}) => {
    /** Показываем прелоадер */
    if (bigPreloader) {
      setIsChartFactsLoadings(true);
    }
    setIsChartDatesChanging(true);

    /** Добавляем в запрос фильтрацию по значениям мер */
    let queryString = Object.keys(measuresValues[INDICATOR_CHARTS_TAB]).reduce((acc, curItem) => {
      /** Select */
      if (measuresValues[INDICATOR_CHARTS_TAB][curItem].value) {
        return `${acc}measures[${curItem}]=${measuresValues[INDICATOR_CHARTS_TAB][curItem].value}&`;
      }

      /** Multiselect */
      if (
        measuresValues[INDICATOR_CHARTS_TAB][curItem][0] &&
        measuresValues[INDICATOR_CHARTS_TAB][curItem][0].value
      ) {
        let query = `${acc}measures[${curItem}]=${measuresValues[INDICATOR_CHARTS_TAB][curItem][0].value}`;
        Object.values(measuresValues[INDICATOR_CHARTS_TAB][curItem]).forEach((item, index) => {
          if (index === 0) return;
          query += `,${item.value}`;
        });
        query += '&';
        return query;
      }

      /** Checkboxes */
      const checkValues = Object.keys(measuresValues[INDICATOR_CHARTS_TAB][curItem])
        .filter(key => measuresValues[INDICATOR_CHARTS_TAB][curItem][key])
        .join(',');

      if (checkValues) {
        return `${acc}measures[${curItem}]=${checkValues}&`;
      }

      return acc;
    }, '');

    /** Добавляем в запрос территориальные фильтры */
    const filterValues = territoriesFiltersValues;
    ['a', 'b'].forEach(mapMarker => {
      if (filterValues[mapMarker]) {
        Object.keys(filterValues[mapMarker]).forEach(measureId => {
          const selectedValues = Object.keys(filterValues[mapMarker][measureId]).filter(
            valueId => filterValues[mapMarker][measureId][valueId].selected,
          );
          if (selectedValues.length > 0) {
            mapMarker === 'a'
              ? (queryString = `${queryString}ter_measures[${measureId}]=${selectedValues.join(
                  ',',
                )}&`)
              : (queryString = `${queryString}to_ter_measures[${measureId}]=${selectedValues.join(
                  ',',
                )}&`);
          }
        });
      }
    });

    const maxItems = {
      [CHART_GROUP_DAY]: 7,
      [CHART_GROUP_WEEK]: 6,
      [CHART_GROUP_MONTH]: 6,
      [CHART_GROUP_YEAR]: 6,
      [CHART_GROUP_HOUR]: 6,
      [CHART_GROUP_HALFHOUR]: 6,
    };

    /** Добавляем в запрос максимальное кол-во записей */
    queryString = `${queryString}max_items=${maxItems[chartGroupMode]}&`;

    /** Добавляем в запрос даты, ограничивающие результаты */
    if (fromDate) {
      queryString = `${queryString}from_date=${fromDate}&`;
    }
    if (toDate) {
      queryString = `${queryString}to_date=${toDate}&`;
    }

    dispatch(
      getChartFactsMemo(currentChartIndicator.id, currentMapLevelId, chartGroupMode, queryString),
    )
      .then(() => {
        setIsChartFactsLoadings(false);
        setIsChartDatesChanging(false);
        dispatch(setChartTabInnerMode(CHART_TAB_INNER_MODE_CHART));
      })
      .catch(() => {
        setIsChartFactsLoadings(false);
        setIsChartDatesChanging(false);
      });
  };

  /**
   * Получение фактов (мер) для построения рейтинга
   */
  const generateRating = () => {
    if (!currentChartIndicator) {
      return;
    }

    /** Показываем прелоадер */
    setIsChartFactsLoadings(true);

    /** Добавляем в запрос фильтрацию по значениям мер */
    let queryString = Object.keys(measuresValues[INDICATOR_CHARTS_TAB]).reduce((acc, curItem) => {
      /** Select */
      if (measuresValues[INDICATOR_CHARTS_TAB][curItem].value) {
        return `${acc}measures[${curItem}]=${measuresValues[INDICATOR_CHARTS_TAB][curItem].value}&`;
      }
      /** Multiselect */
      if (
        measuresValues[INDICATOR_CHARTS_TAB][curItem][0] &&
        measuresValues[INDICATOR_CHARTS_TAB][curItem][0].value
      ) {
        let query = `${acc}measures[${curItem}]=${measuresValues[INDICATOR_CHARTS_TAB][curItem][0].value}`;
        Object.values(measuresValues[INDICATOR_CHARTS_TAB][curItem]).forEach((item, index) => {
          if (index === 0) return;
          query += `,${item.value}`;
        });
        query += '&';
        return query;
      }

      /** Checkboxes */
      const checkValues = Object.keys(measuresValues[INDICATOR_CHARTS_TAB][curItem])
        .filter(key => measuresValues[INDICATOR_CHARTS_TAB][curItem][key])
        .join(',');

      if (checkValues) {
        return `${acc}measures[${curItem}]=${checkValues}&`;
      }

      return acc;
    }, '');

    const filterValues = territoriesFiltersValues;
    ['a', 'b'].forEach(mapMarker => {
      if (filterValues[mapMarker]) {
        Object.keys(filterValues[mapMarker]).forEach(measureId => {
          const selectedValues = Object.keys(filterValues[mapMarker][measureId]).filter(
            valueId => filterValues[mapMarker][measureId][valueId].selected,
          );
          if (selectedValues.length > 0) {
            mapMarker === 'a'
              ? (queryString = `${queryString}ter_measures[${measureId}]=${selectedValues.join(
                  ',',
                )}&`)
              : (queryString = `${queryString}to_ter_measures[${measureId}]=${selectedValues.join(
                  ',',
                )}&`);
          }
        });
      }
    });

    dispatch(getRatingFactsMemo(currentChartIndicator.id, currentMapLevelId, queryString))
      .then(() => {
        dispatch(setChartTabInnerMode(CHART_TAB_INNER_MODE_CHART));
        setIsChartFactsLoadings(false);
      })
      .catch(() => {
        setIsChartFactsLoadings(false);
      });
  };

  /**
   * Получение фактов (мер) для построения круговой диаграммы
   */
  const generatePieChart = ({
    fromDate = null,
    toDate = null,
    bigPreloader = false,
    allMeasures = false,
  } = {}) => {
    if (!currentChartIndicator) {
      return;
    }

    /** Показываем прелоадер */
    if (bigPreloader) {
      setIsChartFactsLoadings(true);
    }
    setIsChartDatesChanging(true);

    /** Добавляем в запрос фильтрацию по значениям мер */
    let queryString = Object.keys(measuresValues[INDICATOR_CHARTS_TAB]).reduce((acc, curItem) => {
      /** Select */
      if (measuresValues[INDICATOR_CHARTS_TAB][curItem].value) {
        return `${acc}measures[${curItem}]=${measuresValues[INDICATOR_CHARTS_TAB][curItem].value}&`;
      }
      /** Multiselect */
      if (
        measuresValues[INDICATOR_CHARTS_TAB][curItem][0] &&
        measuresValues[INDICATOR_CHARTS_TAB][curItem][0].value
      ) {
        let query = `${acc}measures[${curItem}]=${measuresValues[INDICATOR_CHARTS_TAB][curItem][0].value}`;
        Object.values(measuresValues[INDICATOR_CHARTS_TAB][curItem]).forEach((item, index) => {
          if (index === 0) return;
          query += `,${item.value}`;
        });
        query += '&';
        return query;
      }

      /** Checkboxes */
      const checkValues = Object.keys(measuresValues[INDICATOR_CHARTS_TAB][curItem])
        .filter(key => measuresValues[INDICATOR_CHARTS_TAB][curItem][key])
        .join(',');

      if (checkValues) {
        return `${acc}measures[${curItem}]=${checkValues}&`;
      }

      if (allMeasures) {
        return `${acc}all_measures=1&`;
      }

      return acc;
    }, '');

    /**
     * Добавляем в запрос территориальные фильтры
     */
    const filterValues = territoriesFiltersValues;
    ['a', 'b'].forEach(mapMarker => {
      if (filterValues[mapMarker]) {
        Object.keys(filterValues[mapMarker]).forEach(measureId => {
          const selectedValues = Object.keys(filterValues[mapMarker][measureId]).filter(
            valueId => filterValues[mapMarker][measureId][valueId].selected,
          );
          if (selectedValues.length > 0) {
            mapMarker === 'a'
              ? (queryString = `${queryString}ter_measures[${measureId}]=${selectedValues.join(
                  ',',
                )}&`)
              : (queryString = `${queryString}to_ter_measures[${measureId}]=${selectedValues.join(
                  ',',
                )}&`);
          }
        });
      }
    });

    /** Добавляем в запрос даты, ограничивающие результаты */
    if (fromDate) {
      queryString = `${queryString}from_date=${fromDate}&`;
    }
    if (toDate) {
      queryString = `${queryString}to_date=${toDate}&`;
    }

    dispatch(getChartPieFactsMemo(currentChartIndicator.id, currentMapLevelId, queryString))
      .then(() => {
        setIsChartFactsLoadings(false);
        setIsChartDatesChanging(false);
        dispatch(setChartTabInnerMode(CHART_TAB_INNER_MODE_CHART));
      })
      .catch(() => {
        setIsChartFactsLoadings(false);
        setIsChartDatesChanging(false);
      });
  };

  const getData = () => {
    if (currentChartIndicator && currentChartIndicator.chart_type === 'pie') {
      generatePieChart({ bigPreloader: true });
    }

    if (currentChartIndicator && currentChartIndicator.chart_type === 'time') {
      // Если график по одному измерению, обращаемся к данным круговой диаграммы
      if (
        currentChartIndicator.chart_view === 'measure-bar' ||
        currentChartIndicator.chart_view === 'measure-line'
      ) {
        generatePieChart({ bigPreloader: true, allMeasures: true });
      } else {
        generateTimeChart({ bigPreloader: true });
      }
    }
    if (currentChartIndicator && currentChartIndicator.chart_type === 'rating') {
      generateRating();
    }
  };

  const resetPeriod = () => {
    dispatch(resetCurrentPeriodFilter(INDICATOR_CHARTS_TAB));
  };

  if (!langOb) {
    return null;
  }

  /** Сообщение на случай отсутствия показателей */
  if (!chartIndicators.length) {
    return <p className="indicators__error">{langOb.noIndicators}</p>;
  }

  /** График или рейтинг */
  if (currentChartIndicator) {
    return (
      <React.Fragment>
        <div
          className={cx('indicators__chart-tab-config', {
            'indicators__chart-tab-config_visible':
              chartTabInnerMode === CHART_TAB_INNER_MODE_CONFIG,
          })}
        >
          {/* CONFIG */}
          <div
            className="indicators__chart-config"
            style={{ visibility: isChartFactsLoadings ? 'hidden' : 'visible' }}
          >
            <div className="indicators__input-holder">
              <p className="indicators__input-name">{langOb.title}</p>
              <Select
                styles={selectStyles}
                classNamePrefix="chart-indicator-select"
                options={chartIndicators.map(item => ({
                  ...item,
                  value: `${item.id}_${item.chart_type}`,
                  label: item.name,
                }))}
                value={{
                  ...currentChartIndicator,
                  value: `${currentChartIndicator.id}_${currentChartIndicator.chart_type}`,
                  label: currentChartIndicator.name,
                }}
                onChange={handleChangeChartIndicator}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                isSearchable={false}
              />
            </div>

            <TerritoriesFilters
              measures={currentChartIndicator && currentChartIndicator.ter_measures}
              namespace={INDICATOR_CHARTS_TAB}
              handleChangeTerritoriesFiltersValues={handleChangeTerritoriesFiltersValues}
            />

            {currentChartIndicator && (
              <PeriodSelectTrigger
                className="indicators__period-select-trigger"
                namespace={INDICATOR_CHARTS_TAB}
                applyPeriod={() => {}}
                resetPeriod={resetPeriod}
                dataRanges={currentChartIndicator.data_ranges}
                dataStat={currentChartIndicator.data_stat}
              />
            )}

            {currentChartIndicator &&
              currentChartIndicator.chart_type === 'time' &&
              currentChartIndicator.time_buttons &&
              currentChartIndicator.time_buttons.length > 1 && (
                <ChartsIntervalSelect
                  className="indicators__charts-interval-select"
                  handleChange={mode => {
                    dispatch(setChartGroupMode(mode));
                  }}
                  chartGroupMode={chartGroupMode}
                  timeButtons={currentChartIndicator.time_buttons}
                  withTitle
                />
              )}

            <ChartsMeasures isInSidebar />

            <div className="indicators__build-chart">
              <button
                className="indicators__build-chart-button"
                onClick={getData}
                type="button"
                /**
                 * График нельзя построить тогда когда среди мер есть чекбоксы (!measure.value)
                 * и ни один из них не чекнут (Object.values(measure).every(value => !value)
                 */
                disabled={
                  !measuresValues ||
                  !measuresValues[INDICATOR_CHARTS_TAB] ||
                  Object.values(measuresValues[INDICATOR_CHARTS_TAB]).find(
                    measure =>
                      measure && !measure.value && Object.values(measure).every(value => !value),
                  )
                }
              >
                {langOb.buildChart}
              </button>
            </div>
          </div>

          {isChartFactsLoadings && (
            <div className="indicators__chart-loading" data-testid="indicators-chart-loading">
              <IconLoader className="indicators__chart-loading-icon" />
            </div>
          )}
        </div>
        {/* /CONFIG */}

        {chartTabInnerMode === CHART_TAB_INNER_MODE_CHART &&
          currentChartIndicator.chart_type === 'time' && (
            <React.Fragment>
              <React.Suspense fallback={<div style={{ height: 50 }} />}>
                <ChartsNav
                  goBack={() => {
                    dispatch(setChartTabInnerMode(CHART_TAB_INNER_MODE_CONFIG));
                  }}
                  className="indicators__chart-nav"
                  chartImageURI={chartImageURI}
                  chartName={currentChartIndicator.description}
                  territoriesAndPeriodQueryString={getChartFactsQueryString}
                  chartType={currentChartIndicator.chart_type}
                  isExportVisible={user && user.can_export}
                />
              </React.Suspense>
              <Charts
                className="indicators__chart"
                groupBy={chartGroupMode}
                withTitle
                setImageURI={setChartImageURI}
              />
              <ChartsTimeNav
                className="indicators__chart-time-nav"
                groupBy={chartGroupMode}
                generateChart={generateTimeChart}
                loading={isChartDatesChanging}
              />
            </React.Fragment>
          )}

        {chartTabInnerMode === CHART_TAB_INNER_MODE_CHART &&
          currentChartIndicator.chart_type === 'rating' && (
            <React.Fragment>
              <React.Suspense fallback={<div style={{ height: 50 }} />}>
                <ChartsNav
                  goBack={() => {
                    dispatch(setChartTabInnerMode(CHART_TAB_INNER_MODE_CONFIG));
                  }}
                  className="indicators__chart-nav"
                  territoriesAndPeriodQueryString={getRatingFactsQueryString}
                  chartName={currentChartIndicator.name}
                  chartType={currentChartIndicator.chart_type}
                  chartImageURI={chartImageURI}
                  isExportVisible={user && user.can_export}
                />
              </React.Suspense>
              <Ratings className="indicators__ratings" setImageURI={setChartImageURI} withTitle />
            </React.Fragment>
          )}

        {chartTabInnerMode === CHART_TAB_INNER_MODE_CHART &&
          currentChartIndicator.chart_type === 'pie' && (
            <React.Fragment>
              <React.Suspense fallback={<div style={{ height: 50 }} />}>
                <ChartsNav
                  goBack={() => {
                    dispatch(setChartTabInnerMode(CHART_TAB_INNER_MODE_CONFIG));
                  }}
                  className="indicators__chart-nav"
                  chartImageURI={chartImageURI}
                  chartName={currentChartIndicator.name}
                  territoriesAndPeriodQueryString={getChartPieFactsQueryString}
                  chartType={currentChartIndicator.chart_type}
                  isExportVisible={user && user.can_export}
                />
              </React.Suspense>
              <ChartsPie
                className="indicators__charts-pie"
                setImageURI={setChartImageURI}
                withTitle
              />
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }

  return null;
};

IndicatorsCharts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  chartIndicators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      chart_type: PropTypes.string,
      measures: PropTypes.arrayOf(
        PropTypes.shape({
          data_type: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string,
          values: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
  currentChartIndicator: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    data_stat: PropTypes.shape({}),
    description: PropTypes.string,
    hierarchy: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    chart_type: PropTypes.string,
    chart_view: PropTypes.string,
    data_ranges: PropTypes.string,
    time_buttons: PropTypes.arrayOf(PropTypes.shape({})),
    ter_measures: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentIndicator: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    data_stat: PropTypes.shape({}),
    data_ranges: PropTypes.string,
    has_second_map: PropTypes.bool,
  }),
  chartTabInnerMode: PropTypes.string.isRequired,
  measuresValues: PropTypes.shape({
    [INDICATOR_CHARTS_TAB]: PropTypes.objectOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    objects: PropTypes.shape({}),
  }).isRequired,
  currentMapLevelId: PropTypes.number,
  getChartFactsQueryString: PropTypes.string.isRequired,
  getChartPieFactsQueryString: PropTypes.string.isRequired,
  getRatingFactsQueryString: PropTypes.string.isRequired,
  territoriesFiltersValues: PropTypes.shape({
    a: PropTypes.shape({}),
    b: PropTypes.shape({}),
  }),
  chartGroupMode: PropTypes.string.isRequired,
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
  parsedIndicator: PropTypes.number,
  user: PropTypes.shape({
    can_export: PropTypes.bool,
  }),
};

IndicatorsCharts.defaultProps = {
  currentChartIndicator: null,
  currentIndicator: null,
  currentMapLevelId: null,
  territoriesFiltersValues: {},
  parsedIndicator: null,
};

const mapStateToProps = state => ({
  chartIndicators: state.main.chartIndicators,
  currentChartIndicator: state.main.currentChartIndicator,
  currentIndicator: state.main.currentIndicator,
  chartGroupMode: state.main.currentChartGroupMode,
  chartTabInnerMode: state.main.chartTabInnerMode,
  measuresValues: state.main.measuresValues,
  currentMapLevelId: state.main.currentMapLevelId,
  getChartFactsQueryString: state.main.getChartFactsQueryString,
  getChartPieFactsQueryString: state.main.getChartPieFactsQueryString,
  getRatingFactsQueryString: state.main.getRatingFactsQueryString,
  polygons: state.polygons,
  parsedIndicator: (state.main.parsedParameters.chart || {}).indicator,
  territoriesFiltersValues:
    state.main.territoriesFiltersValues && state.main.territoriesFiltersValues.territory,
  user: state.main.user,
});

export default connect(mapStateToProps)(IndicatorsCharts);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// Misc
import useLang from 'hooks/useLang';

// Styles
import './assets/styles/styles.scss';

const HeaderNav = ({ className, menuItems, closeMobileMenu }) => {
  /** Переводы */
  const langOb = useLang('HeaderNav');

  const renderNavItem = (title, url) => (
    <Link className="header-nav__item" to={url} key={url} onClick={closeMobileMenu || (() => {})}>
      {title}
    </Link>
  );

  if (!langOb) {
    return null;
  }

  return (
    <nav
      className={cx('header-nav', {
        [className]: className,
      })}
    >
      {renderNavItem(langOb.mainPage, '/')}
      {menuItems &&
        Array.isArray(menuItems) &&
        menuItems.length &&
        menuItems.map(item => renderNavItem(item.title, `/static/${item.url}`))}
    </nav>
  );
};

HeaderNav.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  closeMobileMenu: PropTypes.func,
};

HeaderNav.defaultProps = {
  className: null,
  closeMobileMenu: null,
};

export default HeaderNav;

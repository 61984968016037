import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Measures from 'modules/main/components/Measures';

// Actions
import {
  getFactsMemo,
  getPolygons,
  setMeasuresValues,
  setResetFilterState,
} from 'modules/main/actions';

// Misc
import { CHECKBOXES, SELECT, RADIO, INDICATOR_TERRITORY_TAB } from 'modules/main/constants';
import { getMeasuresValuesFromURL, getFactsQueryString } from 'helpers';
import getTerritoryIds from '../../../../helpers/get-territory-ids';

const TerritoriesMeasures = ({
  currentIndicator,
  dispatch,
  handleChangeMeasuresValues,
  measuresValues,
  resetAllFilters,
  polygons,
}) => {
  let measures = null;
  if (currentIndicator) {
    ({ measures } = currentIndicator);
  }

  const savedCurrentIndicator = useRef(null);

  /**
   * Ставим первые значения для мер при инициализации и смене показателя
   * При смене показателя оставляем неизменными значения совпадащих мер
   */
  useEffect(() => {
    if (!currentIndicator) {
      return;
    }
    /** Выполняем обновление измерений, когда поменялся показатель ЛИБО когда сбросили фильтры */
    if (
      !savedCurrentIndicator.current ||
      (savedCurrentIndicator.current && currentIndicator.id !== savedCurrentIndicator.current.id) ||
      resetAllFilters
    ) {
      savedCurrentIndicator.current = { ...currentIndicator };

      let fieldValues = {};

      /** Парсим location в поисках значений мер */
      const measuresValuesTerritories = getMeasuresValuesFromURL();

      if (measures) {
        fieldValues = measures.reduce((acc, curItem) => {
          const { data_type: dataType } = curItem;
          switch (dataType) {
            case SELECT: {
              /** Если есть в урл */
              if (measuresValuesTerritories[curItem.id]) {
                const isMulti = Array.isArray(measuresValuesTerritories[curItem.id]);

                let options = curItem.values.filter(
                  item => item.id === measuresValuesTerritories[curItem.id],
                );

                if (isMulti) {
                  options = curItem.values.filter(item =>
                    measuresValuesTerritories[curItem.id].includes(item.id),
                  );
                }

                if (!options) {
                  break;
                }

                if (isMulti) {
                  acc[curItem.id] = [];

                  options.forEach(option => {
                    acc[curItem.id].push({
                      value: option.id,
                      label: option.name,
                    });
                  });
                } else {
                  acc[curItem.id] = {
                    value: options[0].id,
                    label: options[0].name,
                  };
                }

                break;
              }

              /** Если есть в предыдущем показателе */
              if (
                measuresValues &&
                measuresValues[INDICATOR_TERRITORY_TAB] &&
                measuresValues[INDICATOR_TERRITORY_TAB][curItem.id]
              ) {
                if (curItem.select_prop && curItem.values.length) {
                  acc[curItem.id] = measuresValues[INDICATOR_TERRITORY_TAB][curItem.id];
                  break;
                }

                acc[curItem.id] = {
                  ...measuresValues[INDICATOR_TERRITORY_TAB][curItem.id],
                };
                break;
              }

              /** TODO: Избавится от дублирования кода! */
              /** Если мультиселект */
              if (curItem.select_prop && curItem.values.length) {
                acc[curItem.id] = curItem.values.map(({ id: value, name: label }) => ({
                  value,
                  label,
                }));

                break;
              }

              /** Если обычный селект */
              acc[curItem.id] = {
                value: (curItem.values[0] && curItem.values[0].id) || null,
                label: (curItem.values[0] && curItem.values[0].name) || null,
              };

              break;
            }

            case CHECKBOXES:
              /** Если есть в предыдущем показателе */
              if (
                measuresValues &&
                measuresValues[INDICATOR_TERRITORY_TAB] &&
                measuresValues[INDICATOR_TERRITORY_TAB][curItem.id]
              ) {
                acc[curItem.id] = {
                  ...measuresValues[INDICATOR_TERRITORY_TAB][curItem.id],
                };
                break;
              }

              acc[curItem.id] = curItem.values.reduce(
                (chAcc, chOption) => ({
                  ...chAcc,
                  [chOption.id]: false,
                }),
                {},
              );
              break;
            case RADIO:
              /** Если есть в урл */
              if (measuresValuesTerritories[curItem.id]) {
                const option = curItem.values.find(
                  val => val.id === measuresValuesTerritories[curItem.id],
                );
                if (option) {
                  acc[curItem.id] = {
                    value: option.id || null,
                    label: option.name || null,
                  };
                  return acc;
                }
              }
              acc[curItem.id] = {
                value: (curItem.values[0] && curItem.values[0].id) || null,
                label: (curItem.values[0] && curItem.values[0].name) || null,
              };
              break;
            default:
              acc[curItem.id] = {
                value: (curItem.values[0] && curItem.values[0].id) || null,
                label: (curItem.values[0] && curItem.values[0].name) || null,
              };
          }
          return acc;
        }, {});
      }

      dispatch(setMeasuresValues(fieldValues, INDICATOR_TERRITORY_TAB));

      /** Если сбросили фильтры, то подгружаем факты для восстановления полигонов */
      if (resetAllFilters && fieldValues) {
        const queryString = getFactsQueryString(fieldValues);
        dispatch(getFactsMemo(null, null, null, queryString))
          .then(res => {
            /**
             * Получение полигонов по территориям,
             * которые пришли в фактах (мерах)
             */
            const territoryIds = getTerritoryIds(res, polygons);
            if (territoryIds.length) {
              return dispatch(getPolygons(territoryIds)).then();
            }

            return null;
          })
          .catch(() => {});

        /** Обновляем значение резета */
        dispatch(setResetFilterState(false));
      }
    }
    if (!savedCurrentIndicator.current && currentIndicator) {
      savedCurrentIndicator.current = { ...currentIndicator };
    }
  }, [currentIndicator, resetAllFilters]);

  if (!currentIndicator) {
    return null;
  }

  return (
    <Measures
      measures={measures}
      namespace={INDICATOR_TERRITORY_TAB}
      handleChangeMeasuresValues={handleChangeMeasuresValues}
    />
  );
};

TerritoriesMeasures.propTypes = {
  currentIndicator: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  measuresValues: PropTypes.shape({
    [INDICATOR_TERRITORY_TAB]: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChangeMeasuresValues: PropTypes.func.isRequired,
  resetAllFilters: PropTypes.bool.isRequired,
  polygons: PropTypes.oneOfType([
    PropTypes.objectOf({
      id: PropTypes.number,
      geometry: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]).isRequired,
};

TerritoriesMeasures.defaultProps = {
  currentIndicator: null,
};

const mapStateToProps = state => ({
  currentIndicator: state.main.currentIndicator,
  measuresValues: state.main.measuresValues,
  resetAllFilters: state.main.resetAllFilters,
  polygons: state.polygons,
});

export default connect(mapStateToProps)(TerritoriesMeasures);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ToggleControl from 'shared/ToggleControl';
import Checkbox from 'shared/Checkbox';
import Measures from 'modules/main/components/Measures';
import ObjectsCategories from 'modules/main/components/ObjectsCategories';

// Actions
import {
  setMeasuresValues,
  toggleIndicatorTab,
  getObjectsForIndicator,
  getPolygons,
  toggleIndicatorObject,
  setURLParams,
  dropParsedParameters,
} from 'modules/main/actions';

// Misc
import { INDICATOR_OBJECTS_TAB } from 'modules/main/constants';
import useLang from 'hooks/useLang';

const COLORS = ['#EA3C5B', '#0D3D85', 'orange'];

const IndicatorsObjects = ({
  enabled,
  objects,
  dispatch,
  measuresValues,
  currentIndicator,
  currentObjectsMeasures,
  urlParsedMeasuresValue,
  urlParsedTerritoriesFiltersValues,
}) => {
  /** Переводы */
  const langOb = useLang('IndicatorsObjects');

  const toggleEnable = () => {
    dispatch(toggleIndicatorTab(INDICATOR_OBJECTS_TAB));
    dispatch(setURLParams({ [`indicatorTabsEnabled[${INDICATOR_OBJECTS_TAB}]`]: !enabled }));
  };

  const toggleIndicator = indicatorId => {
    dispatch(
      setURLParams({
        [`measuresValues[${indicatorId}]`]: !(
          measuresValues[indicatorId] && measuresValues[indicatorId].selected
        ),
      }),
    );

    if (measuresValues[indicatorId] && measuresValues[indicatorId].selected) {
      return dispatch(toggleIndicatorObject(indicatorId));
    }

    /** Загружаем список территорий для выбранного объекта */
    return dispatch(getObjectsForIndicator(indicatorId))
      .then(data => dispatch(getPolygons(data.map(t => t.territory_id), indicatorId)))
      .then(() => dispatch(toggleIndicatorObject(indicatorId)));
  };

  /**
   * Загружаем список территорий с учетом фильтра территорий
   * для выбранного объекта
   */
  const handleChangeTerritoriesFiltersValues = indicatorId => () =>
    dispatch(getObjectsForIndicator(indicatorId)).then(data =>
      dispatch(getPolygons(data.map(t => t.territory_id), indicatorId)),
    );

  const handleNewMeasures = indicatorId =>
    dispatch(getObjectsForIndicator(indicatorId)).then(data =>
      dispatch(getPolygons(data.map(t => t.territory_id), indicatorId)),
    );

  /** Ставим первые значения для мер при инициализации */
  useEffect(() => {
    if (!objects || !currentIndicator) {
      return;
    }

    const fieldValues = objects.reduce((acc, curItem, index) => {
      if (measuresValues[curItem.id]) {
        acc[curItem.id] = { ...measuresValues[curItem.id] };
        return acc;
      }
      /**
       * Проверяем на наличие измерений
       */

      acc[curItem.id] = {
        selected: false,
        count: null,
        color: COLORS[index % COLORS.length],
        colorsMeasures:
          curItem.num_measures.length > 0
            ? curItem.num_measures.map((measure, indexMeasure) => ({
                id: measure.id,
                color: COLORS[indexMeasure % COLORS.length],
              }))
            : null,
      };

      return acc;
    }, {});

    if (urlParsedMeasuresValue) {
      Object.entries(urlParsedMeasuresValue).forEach(([key, value]) => {
        if (fieldValues[key]) {
          fieldValues[key].selected = value;
        }
      });

      dispatch(dropParsedParameters('measuresValue'));
    }

    dispatch(setMeasuresValues(fieldValues, INDICATOR_OBJECTS_TAB));
  }, [objects, currentIndicator]);

  if (!langOb) {
    return null;
  }

  /* eslint-disable react/no-danger */
  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          // Выглядит как некая жесть
          __html: `
              .red {
                background: #EA3C5B;
              }
              .blue {
                background: #0D3D85;
              }
              .orange {
                background: orange;
              }
            `,
        }}
      />

      <div className="indicators__toggle">
        <h3 className="indicators__toggle-title">{langOb.title}</h3>
        <ToggleControl
          checked={enabled}
          className="indicators__toggle-button"
          onToggle={toggleEnable}
        />
      </div>

      {enabled && (
        <div className="indicators__form">
          {/* OBJECT */}
          {objects &&
            objects.length > 0 &&
            objects.map(item => (
              <div className="indicators__object" key={item.id}>
                <div className="indicators__object-header">
                  <Checkbox
                    onClick={() => toggleIndicator(item.id)}
                    checked={measuresValues[item.id] && measuresValues[item.id].selected}
                    className="indicators__object-checkbox"
                    label=""
                  />
                  <div className="indicators__object-names">
                    <p className="indicators__object-name">
                      {item.name}
                      {measuresValues[item.id] && measuresValues[item.id].selected
                        ? ` — ${measuresValues[item.id].count} шт.`
                        : null}
                      {measuresValues[item.id] && (
                        <span
                          className="indicators__object-color"
                          style={{ backgroundColor: measuresValues[item.id].color }}
                        />
                      )}
                    </p>
                    {item.description && (
                      <p className="indicators__object-desc">{item.description}</p>
                    )}
                  </div>
                </div>
                {item.measures && (
                  <Measures
                    measures={item.measures}
                    namespace="objects"
                    handleChangeMeasuresValues={handleNewMeasures}
                    indicatorId={item.id}
                    hide={measuresValues[item.id] && !measuresValues[item.id].selected}
                  />
                )}
                {item.categories && item.categories.length > 0 && (
                  <ObjectsCategories
                    measures={item.categories}
                    handleChangeTerritoriesFiltersValues={handleChangeTerritoriesFiltersValues(
                      item.id,
                    )}
                    objectId={item.id}
                    hide={!measuresValues[item.id] || !measuresValues[item.id].selected}
                  />
                )}
                {/* {item.num_measures && */}
                {/*  item.num_measures.length > 0 && */}
                {/*  measuresValues[item.id].selected && */}
                {/*  item.num_measures.map(measure => ( */}
                {/*    <React.Fragment key={measure.id}> */}
                {/*      <ObjectMeasureRangeFilter */}
                {/*        className="indicators__object-measure" */}
                {/*        name={measure.name} */}
                {/*        measure={currentObjectsMeasures */}
                {/*          .find(obj => obj.id === item.id) */}
                {/*          .measures.find(smeasure => smeasure.id === measure.id)} */}
                {/*        color={measuresValues[item.id].colorsMeasures[item.id]} */}
                {/*      /> */}
                {/*    </React.Fragment> */}
                {/*  ))} */}
              </div>
            ))}
        </div>
      )}
    </React.Fragment>
  );
};

IndicatorsObjects.propTypes = {
  dispatch: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      measures: PropTypes.arrayOf(
        PropTypes.shape({
          data_type: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string,
          values: PropTypes.array,
        }),
      ),
      ter_measures: PropTypes.array,
      categories: PropTypes.array,
    }),
  ),
  currentIndicator: PropTypes.shape({}).isRequired,
  measuresValues: PropTypes.shape({}),
  currentObjectsMeasures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      measures: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
  ),
  indicatorTabsEnabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  urlParsedMeasuresValue: PropTypes.object,
  urlParsedTerritoriesFiltersValues: PropTypes.object,
};

IndicatorsObjects.defaultProps = {
  objects: {
    measures: [],
    ter_measures: [],
    num_measures: [],
  },
  measuresValues: {},
  currentObjectsMeasures: [],
  urlParsedMeasuresValue: null,
  urlParsedTerritoriesFiltersValues: null,
};

const mapStateToProps = state => ({
  enabled: state.main.indicatorTabsEnabled[INDICATOR_OBJECTS_TAB],
  objects: state.main.currentIndicator && state.main.currentIndicator.objects,
  measuresValues: state.main.measuresValues.objects,
  indicatorTabsEnabled: state.main.indicatorTabsEnabled.objects,
  currentIndicator: state.main.currentIndicator,
  currentObjectsMeasures: state.main.currentObjectsMeasures,
  urlParsedMeasuresValue: state.main.parsedParameters.measuresValue,
  urlParsedTerritoriesFiltersValues: state.main.parsedParameters.territoriesFiltersValues,
});

export default connect(mapStateToProps)(IndicatorsObjects);

import * as types from 'modules/main/actions/action-types';
import {
  LANG_RU,
  INDICATOR_OBJECTS_TAB,
  INDICATOR_TERRITORY_TAB,
  INDICATOR_CHARTS_TAB,
  WEEK_DAYS,
  WEEK_DAYS_ADDITIONAL_FILTER,
  DAYTIME,
  SELECT,
  CHECKBOXES,
  RADIO,
  MIXED_INCLUDE,
  MIXED_EXCLUDE,
  CHART_TAB_INNER_MODE_CONFIG,
  CHART_GROUP_MONTH,
  MULTISELECT,
} from 'modules/main/constants';

import { setParentCheckboxes } from 'helpers';

let savedLang = null;
if (window.localStorage && window.localStorage.getItem('lang')) {
  savedLang = JSON.parse(window.localStorage.getItem('lang'));
}

let savedOpacity = null;
if (window.localStorage && window.localStorage.getItem('opacity')) {
  savedOpacity = +window.localStorage.getItem('opacity');
}

let savedCity = null;
if (window.localStorage && window.localStorage.getItem('city')) {
  savedCity = JSON.parse(window.localStorage.getItem('city'));
}

export const initialState = {
  user: null,
  lang: savedLang || LANG_RU,
  cities: [],
  currentCity: savedCity || {},
  resetAllFilters: false,
  /** Группы показателей */
  indicatorsGroups: [],
  /** Текущая группа показателей (в ней внутри нет самих показателей, только название и др.) */
  currentIndicatorsGroup: null,
  /** Показатели внутри группы */
  indicators: [],
  /** Текущий показатель */
  currentIndicator: null,
  /** Показатели графиков */
  chartIndicators: [],
  /** Текущий показатель графиков */
  currentChartIndicator: null,

  currentTerritories: [],
  currentPeriodFilter: {
    [INDICATOR_TERRITORY_TAB]: {
      dateStart: null,
      dateEnd: null,
      monthStart: null,
      monthEnd: null,
      yearStart: null,
      yearEnd: null,
      weekDays: WEEK_DAYS.map(item => ({ ...item })),
      weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
      dayTime: DAYTIME.map(item => ({ ...item })),
    },
    [INDICATOR_CHARTS_TAB]: {
      dateStart: null,
      dateEnd: null,
      monthStart: null,
      monthEnd: null,
      yearStart: null,
      yearEnd: null,
      weekDays: WEEK_DAYS.map(item => ({ ...item })),
      weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
      dayTime: DAYTIME.map(item => ({ ...item })),
    },
  },
  currentIndicatorTab: INDICATOR_TERRITORY_TAB,
  currentIndicatorTabEnabled: true,
  /** Текущие измерения у объектов: id, minValue, maxValue этого измерения */
  currentObjectsMeasures: [],
  indicatorTabsEnabled: {
    [INDICATOR_TERRITORY_TAB]: true,
    [INDICATOR_OBJECTS_TAB]: false,
    [INDICATOR_CHARTS_TAB]: false,
  },
  facts: {},
  factsTwoMaps: {
    data_to: {},
    data_from: {},
  },
  getFactsQueryString: '',
  getChartFactsQueryString: '',
  getRatingFactsQueryString: '',
  getChartPieFactsQueryString: '',
  territoryRangeFilter: {
    a: {
      min: -Infinity,
      max: Infinity,
    },
    b: {
      min: -Infinity,
      max: Infinity,
    },
  },
  objectsRangeFilter: {
    min: 0,
    max: Infinity,
  },
  chartFacts: {},
  chartRatings: [],
  chartPieFacts: {},
  mapLevels: [],
  currentMapLevelId: null,
  isLoadingPolygons: false,
  isLoadingObjects: false,
  polygonsOpacity: savedOpacity !== null && savedOpacity !== undefined ? savedOpacity : 100,
  chartTabInnerMode: CHART_TAB_INNER_MODE_CONFIG,
  isChartsFullScreen: false,
  measuresValues: {},
  menu: [],
  staticPages: {},
  urlParams: {},

  /** Фильтр территорий */
  filterRegions: {
    a: {}, // Регионы "из" или просто регионы, если показатель без has_second_map
    b: {}, // Регионы "в"
  },
  isFilterRegionsFetched: {
    a: false,
    b: false,
  },
  filterRegionsIsFull: { a: true, b: true },
  selectedPolygons: { a: [], b: [] },
  territoriesCleanState: { a: true, b: true },
  savedSelectedPolygons: { a: [], b: [] },
  isInSelectionMode: {
    a: false,
    b: false,
  },

  reports: {},
  isFiltersPanelOpen: true,
  notification: {
    title: null,
    message: null,
  },
  notificationsRemovable: {
    ids: [],
    remove: false,
  },
  currentObjects: {},
  territoriesFiltersValues: {
    objects: {
      a: {},
      b: {},
    },
    territory: {
      a: {},
      b: {},
    },
  },
  currentChartGroupMode: CHART_GROUP_MONTH,
  reportsPageTitle: '',
  favorites: {
    items: [],
    isLoaded: false,
  },
  parsedParameters: {},
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LANG:
      return {
        ...state,
        lang: action.payload.lang,
      };

    case types.GET_INDICATORS_GROUPS_SUCCESS:
      return {
        ...state,
        indicatorsGroups: action.payload.indicatorsGroups,
      };

    case types.GET_INDICATORS_GROUPS_FAIL:
      return {
        ...state,
        indicatorsGroups: [],
      };

    case types.SELECT_INDICATORS_GROUP:
      return {
        ...state,
        currentIndicatorsGroup: action.payload.indicatorsGroup,
        currentIndicatorTab: INDICATOR_TERRITORY_TAB,
        isFilterRegionsFetched: Object.keys(state.isFilterRegionsFetched).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        currentPeriodFilter: {
          [INDICATOR_CHARTS_TAB]: {
            dateStart: null,
            dateEnd: null,
            monthStart: null,
            monthEnd: null,
            yearStart: null,
            yearEnd: null,
            weekDays: WEEK_DAYS.map(item => ({ ...item })),
            weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
            dayTime: DAYTIME.map(item => ({ ...item })),
          },
          [INDICATOR_TERRITORY_TAB]: {
            dateStart: null,
            dateEnd: null,
            monthStart: null,
            monthEnd: null,
            yearStart: null,
            yearEnd: null,
            weekDays: WEEK_DAYS.map(item => ({ ...item })),
            weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
            dayTime: DAYTIME.map(item => ({ ...item })),
          },
        },
        indicatorTabsEnabled: {
          ...state.indicatorTabsEnabled,
          [INDICATOR_OBJECTS_TAB]: false,
        },
        territoriesFiltersValues: {
          objects: {
            a: {},
            b: {},
          },
          territory: {
            a: {},
            b: {},
          },
        },
      };

    case types.GET_DEFAULT_INDICATOR_GROUP_SUCCESS:
      return {
        ...state,
        currentIndicatorsGroup: action.payload.defaultIndicatorGroup,
        isFilterRegionsFetched: Object.keys(state.isFilterRegionsFetched).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
      };

    case types.GET_INDICATORS_GROUP_SUCCESS:
      return {
        ...state,
        currentIndicatorsGroup: action.payload.indicatorsGroup,
        isFilterRegionsFetched: Object.keys(state.isFilterRegionsFetched).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
      };

    case types.SELECT_INDICATOR: {
      const nextIndicator = action.payload.indicator;

      const nextState = {
        ...state,
        currentIndicator: nextIndicator,
        indicatorTabsEnabled: {
          ...state.indicatorTabsEnabled,
          [INDICATOR_OBJECTS_TAB]: false,
        },
        isFilterRegionsFetched: Object.keys(state.isFilterRegionsFetched).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
        currentObjects: {},
        territoryRangeFilter: {
          a: {
            min: -Infinity,
            max: Infinity,
          },
          b: {
            min: -Infinity,
            max: Infinity,
          },
        },
        territoriesFiltersValues: {
          ...state.territoriesFiltersValues,
          [INDICATOR_OBJECTS_TAB]: {
            a: {},
            b: {},
          },
        },
      };

      /** Если загрузка неизначальная - то сбрасываем фильтры периодов */
      if (!action.payload.isInitiallySelected) {
        /** Для графиков сбрасываем безусловно */
        nextState.currentPeriodFilter = {
          ...state.currentPeriodFilter,
          [INDICATOR_CHARTS_TAB]: {
            dateStart: null,
            dateEnd: null,
            monthStart: null,
            monthEnd: null,
            yearStart: null,
            yearEnd: null,
            weekDays: WEEK_DAYS.map(item => ({ ...item })),
            weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
            dayTime: DAYTIME.map(item => ({ ...item })),
          },
        };

        /** Если нет текущего показателя или его "рамки дат" не совпадают с устанавлевыемыми - сбрасываем период */
        if (
          !(
            state.currentIndicator &&
            state.currentIndicator.data_stat &&
            nextIndicator.data_stat &&
            state.currentIndicator.data_stat.start_date === nextIndicator.data_stat.start_date &&
            state.currentIndicator.data_stat.end_date === nextIndicator.data_stat.end_date
          )
        ) {
          nextState.currentPeriodFilter = {
            ...state.currentPeriodFilter,
            [INDICATOR_TERRITORY_TAB]: {
              dateStart: null,
              dateEnd: null,
              monthStart: null,
              monthEnd: null,
              yearStart: null,
              yearEnd: null,
              weekDays: WEEK_DAYS.map(item => ({ ...item })),
              weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
              dayTime: DAYTIME.map(item => ({ ...item })),
            },
          };

          /** Добавляем нотификэйшн */
          nextState.notification = {
            title: 'periodFilterWasResetTitle',
            message: 'periodFilterWasResetMessage',
            type: 'warning',
            duration: 5000,
          };
        }
      }

      /** Устанавливаем уровни детализации для текущего показателя */
      if (nextIndicator.hierarchy && nextIndicator.hierarchy.length) {
        nextState.mapLevels = nextIndicator.hierarchy;
        /** Если уровни не совпадают, сбрасываем терр фильтры */
        if (
          state.mapLevels.length !== nextState.mapLevels.length ||
          state.mapLevels.some((item, index) => item.id !== nextState.mapLevels[index].id)
        ) {
          nextState.filterRegions = {
            a: {},
            b: {},
          };
        }
        /** Если предыдущего уровня нет в текущем или если иерархия уровней не совпадает, сбрасываем уровень до нулевого */
        if (
          !nextIndicator.hierarchy.find(level => level.id === state.currentMapLevelId) ||
          nextIndicator.hierarchy.findIndex(level => level.id === state.currentMapLevelId) !==
            state.mapLevels.findIndex(level => level.id === state.currentMapLevelId)
        ) {
          nextState.currentMapLevelId = nextIndicator.hierarchy[0].id;
        }
      }

      /** Устанавливаем показатели графика и текущий показатель графика */
      if (nextIndicator.charts && nextIndicator.charts.length) {
        nextState.chartIndicators = nextIndicator.charts;
        [nextState.currentChartIndicator] = nextIndicator.charts;
      } else {
        nextState.chartIndicators = [];
        [nextState.currentChartIndicator] = nextIndicator.charts;
      }

      /** Сбрасываем факты графиков */
      nextState.chartFacts = {};
      nextState.chartTabInnerMode = CHART_TAB_INNER_MODE_CONFIG;

      return nextState;
    }

    case types.SELECT_CHART_INDICATOR:
      return {
        ...state,
        currentChartIndicator: action.payload.indicator,
        chartFacts: {},
        chartRatings: [],
        chartPieFacts: {},
      };

    case types.SELECT_TERRITORIES:
      return {
        ...state,
        currentTerritories: action.payload.territories,
      };

    case types.SELECT_INDICATOR_TAB:
      return {
        ...state,
        currentIndicatorTab: action.payload.tab,
      };

    case types.DESELECT_INDICATOR_TAB:
      return {
        ...state,
        currentIndicatorTab: null,
      };

    case types.TOGGLE_INDICATOR_TAB:
      return {
        ...state,
        indicatorTabsEnabled: {
          ...state.indicatorTabsEnabled,
          [action.payload.tab]: !state.indicatorTabsEnabled[action.payload.tab],
        },
      };

    case types.SELECT_DATE_START:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            dateStart: action.payload.dateStart,
          },
        },
      };

    case types.SELECT_DATE_END:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            dateEnd: action.payload.dateEnd,
          },
        },
      };

    case types.SELECT_MONTH_START:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            monthStart: action.payload.monthStart,
          },
        },
      };

    case types.SELECT_MONTH_END:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            monthEnd: action.payload.monthEnd,
          },
        },
      };

    case types.SELECT_YEAR_START:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            yearStart: action.payload.yearStart,
          },
        },
      };

    case types.SELECT_YEAR_END:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            yearEnd: action.payload.yearEnd,
          },
        },
      };

    case types.RESET_CURRENT_PERIOD_FILTER:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            dateStart: null,
            dateEnd: null,
            monthStart: null,
            monthEnd: null,
            yearStart: null,
            yearEnd: null,
            weekDays: WEEK_DAYS.map(item => ({ ...item })),
            weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
            dayTime: DAYTIME.map(item => ({ ...item })),
          },
        },
      };

    case types.CHANGE_WEEKDAY_FILTER:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            weekDaysFilter: action.payload.weekDaysFilter,
          },
        },
      };

    case types.TOGGLE_WEEK_DAY:
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            weekDays: action.payload.weekDays,
          },
        },
      };

    case types.TOGGLE_DAYTIME: {
      return {
        ...state,
        currentPeriodFilter: {
          ...state.currentPeriodFilter,
          [action.payload.namespace]: {
            ...state.currentPeriodFilter[action.payload.namespace],
            dayTime: action.payload.dayTime,
          },
        },
      };
    }

    case types.GET_INDICATORS_SUCCESS: {
      const newState = {
        ...state,
        indicators: action.payload.results,
      };

      /** Устанавливаем уровни детализации для первого показателя */
      // if (
      //   action.payload.results &&
      //   action.payload.results.length &&
      //   action.payload.results[0].hierarchy
      // ) {
      //   newState = {
      //     ...newState,
      //     mapLevels: action.payload.results[0].hierarchy,
      //   };

      //   /** Устанавливаем выбранный уровень детализации */
      //   if (action.payload.results[0].hierarchy.length) {
      //     newState = {
      //       ...newState,
      //       currentMapLevelId: action.payload.results[0].hierarchy[0].id,
      //     };
      //   }
      // }

      return newState;
    }

    case types.GET_INDICATORS_FAIL:
      return {
        ...state,
        indicators: [],
      };

    case types.GET_FACTS_SUCCESS: {
      const nextState = {
        ...state,
        facts: action.payload,
        factsTwoMaps: {
          data_from: {},
          data_to: {},
        },
        isLoadingPolygons: false,
        territoryRangeFilter: {
          a: {
            min: -Infinity,
            max: Infinity,
          },
          b: {
            min: -Infinity,
            max: Infinity,
          },
        },
      };
      if (!action.payload.is_full) {
        nextState.notification = {
          title: 'tooManyRegionsTitle',
          message: 'tooManyRegionsMessage',
          type: 'warning',
          removable: true,
        };
      } else if (state.notificationsRemovable.ids.length > 0) {
        nextState.notificationsRemovable = {
          ...state.notificationsRemovable,
          remove: true,
        };
      }

      return nextState;
    }

    case types.GET_FACTS_FAIL:
      return {
        ...state,
        facts: {},
        factsTwoMaps: {
          data_from: {},
          data_to: {},
        },
        isLoadingPolygons: false,
        territoryRangeFilter: {
          a: {
            min: -Infinity,
            max: Infinity,
          },
          b: {
            min: -Infinity,
            max: Infinity,
          },
        },
      };

    case types.GET_FACTS_DUAL_SUCCESS: {
      const nextState = {
        ...state,
        facts: {},
        factsTwoMaps: action.payload,
        isLoadingPolygons: false,
        territoryRangeFilter: {
          a: {
            min: -Infinity,
            max: Infinity,
          },
          b: {
            min: -Infinity,
            max: Infinity,
          },
        },
      };

      if (!action.payload.data_from.is_full) {
        nextState.notification = {
          title: 'tooManyRegionsTitle',
          message: 'tooManyRegionsLeftMapMessage',
          type: 'warning',
        };
      } else if (state.notificationsRemovable.ids.length > 0) {
        nextState.notificationsRemovable = {
          ...state.notificationsRemovable,
          remove: true,
        };
      }
      if (!action.payload.data_to.is_full) {
        nextState.notification = {
          title: 'tooManyRegionsTitle',
          message: 'tooManyRegionsRightMapMessage',
          type: 'warning',
        };
      } else if (state.notificationsRemovable.ids.length > 0) {
        nextState.notificationsRemovable = {
          ...state.notificationsRemovable,
          remove: true,
        };
      }
      return nextState;
    }

    case types.GET_FACTS_DUAL_FAIL:
      return {
        ...state,
        facts: {},
        factsTwoMaps: {
          data_from: {},
          data_to: {},
        },
        isLoadingPolygons: false,
        territoryRangeFilter: {
          a: {
            min: -Infinity,
            max: Infinity,
          },
          b: {
            min: -Infinity,
            max: Infinity,
          },
        },
      };

    case types.SAVE_GET_FACTS_QUERY_STRING:
      return {
        ...state,
        getFactsQueryString: action.payload.localQueryString,
      };

    case types.SAVE_GET_CHART_FACTS_QUERY_STRING:
      return {
        ...state,
        getChartFactsQueryString: action.payload.localQueryString,
      };

    case types.SAVE_GET_CHART_PIE_FACTS_QUERY_STRING:
      return {
        ...state,
        getChartPieFactsQueryString: action.payload.localQueryString,
      };

    case types.SAVE_GET_RATING_FACTS_QUERY_STRING:
      return {
        ...state,
        getRatingFactsQueryString: action.payload.localQueryString,
      };

    case types.GET_CHART_FACTS_SUCCESS:
      return {
        ...state,
        chartFacts: action.payload,
        chartRatings: [],
        chartPieFacts: {},
      };

    case types.GET_CHART_FACTS_FAIL:
      return {
        ...state,
        chartFacts: {},
      };

    case types.GET_CHART_RATINGS_SUCCESS:
      return {
        ...state,
        chartRatings: action.payload.data.results,
        currentChartIndicator: {
          ...state.currentChartIndicator,
          unit_name: action.payload.data.unit_name,
        },
        chartFacts: {},
        chartPieFacts: {},
      };

    case types.GET_CHART_RATINGS_FAIL:
      return {
        ...state,
        chartRatings: [],
      };

    case types.GET_CHART_PIE_FACTS_SUCCESS:
      return {
        ...state,
        chartPieFacts: action.payload,
        chartRatings: [],
        chartFacts: {},
      };

    case types.GET_CHART_PIE_FACTS_FAIL:
      return {
        ...state,
        chartPieFacts: {},
      };

    case types.GET_POLYGONS:
      return {
        ...state,
        isLoadingPolygons: true,
      };

    case types.GET_POLYGONS_SUCCESS:
      return {
        ...state,
        isLoadingPolygons: false,
      };

    case types.GET_POLYGONS_FAIL:
      return {
        ...state,
        isLoadingPolygons: false,
      };

    case types.SET_CURRENT_MAP_LEVEL:
      return {
        ...state,
        currentMapLevelId: action.payload.levelId,
      };

    case types.SET_POLYGONS_OPACITY:
      return {
        ...state,
        polygonsOpacity: action.payload.polygonsOpacity,
      };

    case types.SET_CHART_TAB_INNER_MODE:
      return {
        ...state,
        chartTabInnerMode: action.payload.mode,
      };

    case types.SET_TERRITORY_RANGE_FILTER:
      return {
        ...state,
        territoryRangeFilter: {
          ...state.territoryRangeFilter,
          [action.payload.namespace]: action.payload.filter,
        },
      };

    case types.SET_OBJECTS_RANGE_FILTER:
      return {
        ...state,
        objectsRangeFilter: {
          ...state.objectsRangeFilter,
          ...action.payload.filter,
        },
      };

    case types.TOGGLE_CHARTS_FULL_SCREEN:
      return {
        ...state,
        isChartsFullScreen: !state.isChartsFullScreen,
        chartTabInnerMode: CHART_TAB_INNER_MODE_CONFIG,
        currentIndicatorTabEnabled: !state.currentIndicatorTabEnabled,
      };

    case types.SET_MEASURES_VALUES:
      return {
        ...state,
        measuresValues: {
          ...state.measuresValues,
          [action.payload.namespace]: action.payload.measuresValues,
        },
      };

    case types.CHANGE_MEASURE_VALUE: {
      let value;

      if (action.payload.type === CHECKBOXES) {
        value = {
          ...state.measuresValues[action.payload.namespace][action.payload.measureId],
          [action.payload.measureValue.optionId]: action.payload.measureValue.value,
        };
      }

      if (
        action.payload.type === SELECT ||
        action.payload.type === RADIO ||
        action.payload.type === MULTISELECT
      ) {
        value = action.payload.measureValue;
      }

      return {
        ...state,
        measuresValues: {
          ...state.measuresValues,
          [action.payload.namespace]: {
            ...state.measuresValues[action.payload.namespace],
            [action.payload.measureId]: value,
          },
        },
      };
    }

    case types.LOG_IN_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
      };

    case types.LOG_OUT_SUCCESS:
      return {
        ...state,
        user: null,
      };

    case types.GET_MENU_SUCCESS:
      return {
        ...state,
        menu: action.payload.data,
      };

    case types.GET_STATIC_PAGE_SUCCESS:
      return {
        ...state,
        staticPages: {
          ...state.staticPages,
          [action.payload.page.url]: action.payload.page,
        },
      };

    case types.SET_URL_PARAMS: {
      const params = {
        ...state.urlParams,
        ...action.payload.params,
      };

      const url = new window.URL(window.location.toString());
      Object.entries(params).forEach(pair => {
        if (pair[1] !== null) {
          url.searchParams.set(pair[0], pair[1]);
        } else {
          url.searchParams.delete(pair[0]);
        }
      });

      window.history.replaceState(null, '', url.toString());

      return {
        ...state,
        urlParams: params,
      };
    }

    case types.PARSE_URL_PARAMS: {
      const url = new window.URL(window.location.toString());
      const { searchParams } = url;
      const nextState = {
        ...state,
      };

      searchParams.forEach((value, key) => {
        if (key.indexOf('territories') === 0) {
          const allowedKeys = ['id', 'level', 'name', 'checked', 'has_children', 'parentId'];
          const possibleCheckedValues = [MIXED_EXCLUDE, MIXED_INCLUDE, true, false, undefined];

          try {
            let parsedTerritories = JSON.parse(value);
            if (!Array.isArray(parsedTerritories)) {
              parsedTerritories = Object.keys(parsedTerritories).reduce((acc, curKey) => {
                if (
                  typeof parsedTerritories[curKey] !== 'object' ||
                  Array.isArray(parsedTerritories[curKey])
                ) {
                  return acc;
                }
                acc[curKey] = Object.keys(parsedTerritories[curKey]).reduce((region, curField) => {
                  /* eslint-disable no-param-reassign */
                  if (allowedKeys.includes(curField)) {
                    switch (curField) {
                      case 'level':
                      case 'id':
                        region[curField] = +parsedTerritories[curKey][curField];
                        break;

                      case 'checked':
                        region[curField] =
                          possibleCheckedValues.find(
                            v => v === parsedTerritories[curKey][curField],
                          ) || false;
                        break;

                      case 'has_children':
                        region[curField] = Boolean(parsedTerritories[curKey][curField]);
                        break;

                      case 'parentId':
                        region[curField] =
                          parsedTerritories[curKey] !== null ? +parsedTerritories[curKey] : null;
                        break;

                      case 'name':
                        region[curField] = +parsedTerritories[curKey][curField];
                        break;

                      default:
                        break;
                    }
                  }
                  /* eslint-enable no-param-reassign */
                  return region;
                }, {});

                return acc;
              }, {});
            }

            const namespace = key === 'territories' ? 'a' : 'b';
            if (nextState.filterRegions) {
              nextState.filterRegions = {
                ...nextState.filterRegions,
                [namespace]: parsedTerritories,
              };
            } else {
              nextState.filterRegions = {
                ...state.filterRegions,
                [namespace]: parsedTerritories,
              };
            }
          } catch {
            // eslint-disable-next-line
            console.warn('Bad territory filter');
          }
        }
        if (key === 'mapLevel') {
          nextState.currentMapLevelId = +value;
        }
        if (key === 'currentIndicatorTab') {
          if (
            [INDICATOR_TERRITORY_TAB, INDICATOR_CHARTS_TAB, INDICATOR_OBJECTS_TAB].includes(value)
          ) {
            nextState[key] = value;
          }
        }
        if (key === `${INDICATOR_TERRITORY_TAB}_dateStart`) {
          const date = new Date(+value);
          if (date instanceof Date && !isNaN(date)) {
            nextState.currentPeriodFilter[INDICATOR_TERRITORY_TAB].dateStart = date;
          }
        }
        if (key === `${INDICATOR_TERRITORY_TAB}_dateEnd`) {
          const date = new Date(+value);
          if (date instanceof Date && !isNaN(date)) {
            nextState.currentPeriodFilter[INDICATOR_TERRITORY_TAB].dateEnd = date;
          }
        }
        if (key === `${INDICATOR_TERRITORY_TAB}_monthStart` && +value >= 0 && +value < 12) {
          nextState.currentPeriodFilter[INDICATOR_TERRITORY_TAB].monthStart = +value;
        }
        if (key === `${INDICATOR_TERRITORY_TAB}_monthEnd` && +value >= 0 && +value < 12) {
          nextState.currentPeriodFilter[INDICATOR_TERRITORY_TAB].monthEnd = +value;
        }
        if (
          key === `${INDICATOR_TERRITORY_TAB}_yearStart` &&
          value.length === 4 &&
          parseInt(value, 10).toString() === value
        ) {
          nextState.currentPeriodFilter[INDICATOR_TERRITORY_TAB].yearStart = +value;
        }
        if (
          key === `${INDICATOR_TERRITORY_TAB}_yearEnd` &&
          value.length === 4 &&
          parseInt(value, 10).toString() === value
        ) {
          nextState.currentPeriodFilter[INDICATOR_TERRITORY_TAB].yearEnd = +value;
        }

        if (key.startsWith('indicatorTabsEnabled')) {
          const type = key.match(/\[(.*)\]/).pop();

          nextState.indicatorTabsEnabled[type] = JSON.parse(value);
        }

        if (key.startsWith('measuresValue')) {
          const id = key.match(/\[(.*)\]/).pop();
          const parsedValue = JSON.parse(value);

          if (!nextState.parsedParameters.measuresValue) {
            nextState.parsedParameters.measuresValue = {};
          }

          nextState.parsedParameters.measuresValue = {
            ...nextState.parsedParameters.measuresValue,
            [id]: parsedValue,
          };
        }

        if (key.startsWith('territoriesFiltersValues')) {
          const type = key.match(/\[(.*)\]/).pop();
          const parsedValue = JSON.parse(value);

          if (!nextState.parsedParameters.territoriesFiltersValues) {
            nextState.parsedParameters.territoriesFiltersValues = {};
          }

          nextState.parsedParameters.territoriesFiltersValues = {
            ...nextState.parsedParameters.territoriesFiltersValues,
            [type]: parsedValue,
          };
        }

        if (key.startsWith('chart')) {
          const type = key.match(/\[(.*)\]/).pop();
          const parsedValue = JSON.parse(value);

          if (!nextState.parsedParameters.chart) {
            nextState.parsedParameters.chart = {};
          }

          nextState.parsedParameters.chart = {
            ...nextState.parsedParameters.chart,
            [type]: parsedValue,
          };
        }
      });

      return nextState;
    }

    case types.DROP_PARAMETERS: {
      const nextState = { ...state };

      nextState.parsedParameters[action.payload] = null;

      return nextState;
    }

    case types.SET_FILTER_REGIONS: {
      const { res, parentId, checked, initialLoad, namespace } = action.payload;

      const nextState = {
        ...state,
        territoriesCleanState: {
          ...state.territoriesCleanState,
          [namespace]: initialLoad,
        },
        isFilterRegionsFetched: {
          ...state.isFilterRegionsFetched,
          [namespace]: true,
        },
        savedSelectedPolygons: {
          ...state.savedSelectedPolygons,
          [namespace]: [],
        },
      };

      /** Для вновь пришедших проставляем checked */
      let nextFilterRegions = res.results.reduce((acc, item) => {
        let nextChecked;
        if (state.filterRegions[namespace][item.id]) {
          nextChecked = state.filterRegions[namespace][item.id].checked;
        } else if (checked !== null) {
          nextChecked = checked;
        }

        acc[+item.id] = {
          ...item,
          parentId: parentId || null,
          checked: nextChecked,
        };

        return acc;
      }, {});

      /** Объединяем текущие и новые, текущим сбрасываем loading */
      nextFilterRegions = {
        ...Object.keys(state.filterRegions[namespace]).reduce((acc, key) => {
          acc[key] = {
            ...state.filterRegions[namespace][key],
            loading: false,
          };
          if (parentId && +key === parentId) {
            acc[key].next = res.next;
            acc[key].count = res.count;
          }
          return acc;
        }, {}),
        ...nextFilterRegions,
      };

      Object.values(nextFilterRegions).forEach(region => {
        setParentCheckboxes(nextFilterRegions, region);
      });

      nextState.filterRegions = {
        ...state.filterRegions,
        [namespace]: nextFilterRegions,
      };

      return nextState;
    }

    case types.UPDATE_FILTER_REGIONS:
      return {
        ...state,
        territoriesCleanState: {
          ...state.territoriesCleanState,
          [action.payload.namespace]: false,
        },
        filterRegions: {
          ...state.filterRegions,
          [action.payload.namespace]: action.payload.filterRegions,
        },
      };

    case types.SET_FILTER_REGIONS_ITEM: {
      const { namespace, item } = action.payload;
      return {
        ...state,
        filterRegions: {
          ...state.filterRegions,
          [namespace]: Object.keys(state.filterRegions[namespace]).reduce((acc, key) => {
            if (+key === +item.id) {
              acc[+key] = item;
            } else {
              acc[+key] = state.filterRegions[namespace][key];
            }
            return acc;
          }, {}),
        },
      };
    }

    case types.GET_CITIES_TREE_SUCCESS:
      return {
        ...state,
        cities: action.payload.cities.results,
      };

    case types.SET_CURRENT_CITY: {
      let nextState = {
        ...state,
        currentCity: action.payload.city,
      };

      if (action.payload.options && action.payload.options.resetAllData) {
        nextState = {
          ...nextState,
          indicatorsGroups: [],
          currentIndicatorsGroup: null,
          indicators: [],
          currentIndicator: null,
          chartIndicators: [],
          currentChartIndicator: null,
          currentTerritories: [],
          currentPeriodFilter: {
            [INDICATOR_TERRITORY_TAB]: {
              dateStart: null,
              dateEnd: null,
              monthStart: null,
              monthEnd: null,
              yearStart: null,
              yearEnd: null,
              weekDays: WEEK_DAYS.map(item => ({ ...item })),
              weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
              dayTime: DAYTIME.map(item => ({ ...item })),
            },
            [INDICATOR_CHARTS_TAB]: {
              dateStart: null,
              dateEnd: null,
              monthStart: null,
              monthEnd: null,
              yearStart: null,
              yearEnd: null,
              weekDays: WEEK_DAYS.map(item => ({ ...item })),
              weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
              dayTime: DAYTIME.map(item => ({ ...item })),
            },
          },
          currentIndicatorTab: null,
          currentIndicatorTabEnabled: true,
          indicatorTabsEnabled: {
            [INDICATOR_TERRITORY_TAB]: true,
            [INDICATOR_OBJECTS_TAB]: false,
            [INDICATOR_CHARTS_TAB]: false,
          },
          facts: {},
          factsTwoMaps: {
            data_from: {},
            data_to: {},
          },
          territoryRangeFilter: {
            a: {
              min: -Infinity,
              max: Infinity,
            },
            b: {
              min: -Infinity,
              max: Infinity,
            },
          },
          chartFacts: {},
          chartRatings: [],
          mapLevels: [],
          currentMapLevelId: null,
          isLoadingPolygons: false,
          isLoadingObjects: false,
          chartTabInnerMode: CHART_TAB_INNER_MODE_CONFIG,
          isChartsFullScreen: false,
          measuresValues: {},
          urlParams: {},

          filterRegions: {
            a: {},
            b: {},
          },
          isFilterRegionsFetched: {
            a: false,
            b: false,
          },
          filterRegionsIsFull: {
            a: true,
            b: true,
          },
          selectedPolygons: { a: [], b: [] },
          territoriesCleanState: { a: true, b: true },
          savedSelectedPolygons: { a: [], b: [] },
          territoriesFiltersValues: {
            objects: {
              a: {},
              b: {},
            },
            territory: {
              a: {},
              b: {},
            },
          },
        };

        window.history.replaceState(null, '', '/');
      }

      return nextState;
    }

    case types.SET_RESET_FILTERS_STATE: {
      const { isReset } = action.payload;

      return {
        ...state,
        resetAllFilters: isReset,
      };
    }

    case types.RESET_ALL_FILTERS: {
      const nextState = {
        ...state,
        currentTerritories: [],
        currentPeriodFilter: {
          [INDICATOR_TERRITORY_TAB]: {
            dateStart: null,
            dateEnd: null,
            monthStart: null,
            monthEnd: null,
            yearStart: null,
            yearEnd: null,
            weekDays: WEEK_DAYS.map(item => ({ ...item })),
            weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
            dayTime: DAYTIME.map(item => ({ ...item })),
          },
          [INDICATOR_CHARTS_TAB]: {
            dateStart: null,
            dateEnd: null,
            monthStart: null,
            monthEnd: null,
            yearStart: null,
            yearEnd: null,
            weekDays: WEEK_DAYS.map(item => ({ ...item })),
            weekDaysFilter: WEEK_DAYS_ADDITIONAL_FILTER.map(item => ({ ...item })),
            dayTime: DAYTIME.map(item => ({ ...item })),
          },
        },
        currentIndicatorTabEnabled: true,
        territoryRangeFilter: {
          a: {
            min: -Infinity,
            max: Infinity,
          },
          b: {
            min: -Infinity,
            max: Infinity,
          },
        },
        isLoadingPolygons: false,
        isLoadingObjects: false,
        isChartsFullScreen: false,
        measuresValues: {},
        urlParams: {},

        filterRegions: {
          a: {},
          b: {},
        },
        isFilterRegionsFetched: {
          a: false,
          b: false,
        },
        filterRegionsIsFull: {
          a: true,
          b: true,
        },
        selectedPolygons: { a: [], b: [] },
        territoriesCleanState: { a: true, b: true },
        savedSelectedPolygons: { a: [], b: [] },
        territoriesFiltersValues: {
          objects: {
            a: {},
            b: {},
          },
          territory: {
            a: {},
            b: {},
          },
        },
      };
      window.history.replaceState(null, '', '/');
      return nextState;
    }

    case types.SELECT_POLYGON: {
      const { polygon, namespace } = action.payload;

      const nextState = {
        ...state,
        selectedPolygons: {
          ...state.selectedPolygons,
          [namespace]: state.selectedPolygons[namespace].find(item => item.id === polygon.id)
            ? [...state.selectedPolygons[namespace].filter(item => item.id !== polygon.id)]
            : [...state.selectedPolygons[namespace], polygon],
        },
      };
      return nextState;
    }

    case types.SELECT_POLYGONS:
      return {
        ...state,
        selectedPolygons: {
          ...state.selectedPolygons,
          [action.payload.namespace]: [
            ...state.selectedPolygons[action.payload.namespace],
            ...action.payload.selectedPolygons,
          ],
        },
      };

    case types.RESET_SELECTED_POLYGONS:
      return {
        ...state,
        selectedPolygons: {
          ...state.selectedPolygons,
          [action.payload.namespace]: [],
        },
      };

    case types.FILTER_SELECTED_POLYGONS: {
      const { namespace } = action.payload;

      const filterRegions = {
        /** Сначала добавляем выбранные полигоны */
        ...state.selectedPolygons[namespace].reduce((acc, key) => {
          acc[key.id] = {
            id: key.id,
            name: key.name,
            checked: true,
            level: state.currentMapLevelId,
            parent_ids: key.parent_ids,
          };
          return acc;
        }, {}),
        /**
         * После выбранных полигонов проходимся по уже присутствующим в стейте
         * регионам, и проставляем им checked
         */
        ...Object.keys(state.filterRegions[namespace]).reduce((acc, key) => {
          /** Если полигон среди выделенных - checked = true */
          let checked = Boolean(state.selectedPolygons[namespace].find(item => item.id === +key));

          /** Если полигон родитель для выбранного - ставим INCLUDE */
          if (
            state.selectedPolygons[namespace].find(item =>
              item.parent_ids.find(parent => +parent === +key),
            )
          ) {
            checked = MIXED_INCLUDE;
          }

          acc[key] = {
            ...state.filterRegions[namespace][key],
            checked,
          };
          return acc;
        }, {}),
      };

      return {
        ...state,
        selectedPolygons: { a: [], b: [] },
        filterRegions: {
          ...state.filterRegions,
          [namespace]: filterRegions,
        },
        isInSelectionMode: {
          ...state.isInSelectionMode,
          [namespace]: false,
        },
      };
    }

    case types.SET_TERRITORIES_CLEAN_STATE:
      return {
        ...state,
        territoriesCleanState: {
          ...state.territoriesCleanState,
          [action.payload.namespace]: true,
        },
      };

    case types.SELECT_PARENT_POLYGON_BY_DOUBLE_CLICK:
      return {
        ...state,
        filterRegions: {
          ...state.filterRegions,
          [action.payload.namespace]: {
            ...state.filterRegions[action.payload.namespace],
            [action.payload.polygon.id]: {
              id: action.payload.polygon.id,
              checked: true,
              level: state.currentMapLevelId,
              parent_ids: action.payload.polygon.parent_ids,
            },
          },
        },
        isFilterRegionsFetched: {
          ...state.isFilterRegionsFetched,
          [action.payload.namespace]: false,
        },
      };

    case types.TOGGLE_SELECTION_MODE:
      return {
        ...state,
        isInSelectionMode: {
          ...state.isInSelectionMode,
          [action.payload.namespace]: !state.isInSelectionMode[action.payload.namespace],
        },
      };

    case types.EXIT_SELECTION_MODE:
      return {
        ...state,
        isInSelectionMode: {
          a: false,
          b: false,
        },
      };

    case types.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload.data,
      };

    case types.TOGGLE_FILTERS_PANEL:
      return {
        ...state,
        isFiltersPanelOpen: !state.isFiltersPanelOpen,
      };

    case types.RESET_NOTIFICATION:
      return {
        ...state,
        notification: {
          title: null,
          message: null,
        },
      };

    case types.GET_OBJECTS_FOR_INDICATOR:
      return {
        ...state,
        isLoadingObjects: true,
      };

    case types.GET_OBJECTS_FOR_INDICATOR_FAIL:
      return {
        ...state,
        isLoadingObjects: false,
      };

    case types.GET_OBJECTS_FOR_INDICATOR_SUCCESS: {
      const nextState = {
        ...state,
        isLoadingObjects: false,
        currentObjects: {
          ...state.currentObjects,
          [action.payload.indicatorId]: action.payload.items,
        },
        measuresValues: {
          ...state.measuresValues,
          objects: {
            ...state.measuresValues.objects,
            [action.payload.indicatorId]: {
              ...state.measuresValues.objects[action.payload.indicatorId],
              count: action.payload.items.length,
            },
          },
        },
      };
      if (!action.payload.isFull) {
        nextState.notification = {
          title: 'tooManyObjectsTitle',
          message: 'tooManyRegionsMessage',
          type: 'warning',
          removable: true,
        };
      } else if (state.notificationsRemovable.ids.length > 0) {
        nextState.notificationsRemovable = {
          ...state.notificationsRemovable,
          remove: true,
        };
      }
      return nextState;
    }

    case types.TOGGLE_INDICATOR_OBJECT:
      return {
        ...state,
        measuresValues: {
          ...state.measuresValues,
          objects: {
            ...state.measuresValues.objects,
            [action.payload.indicatorId]: {
              ...state.measuresValues.objects[action.payload.indicatorId],
              selected: !state.measuresValues.objects[action.payload.indicatorId].selected,
            },
          },
        },
      };

    case types.SET_TERRITORIES_FILTERS_VALUES: {
      if (state.territoriesFiltersValues[action.payload.namespace])
        return {
          ...state,
          territoriesFiltersValues: {
            ...state.territoriesFiltersValues,
            [action.payload.namespace]: {
              ...state.territoriesFiltersValues[action.payload.namespace],
              [action.payload.mapMarker]: {
                ...state.territoriesFiltersValues[action.payload.namespace][
                  action.payload.mapMarker
                ],
                ...action.payload.fieldValues,
              },
            },
          },
        };
      return {
        ...state,
        territoriesFiltersValues: {
          ...state.territoriesFiltersValues,
          [action.payload.namespace]: {
            ...state.territoriesFiltersValues[action.payload.namespace],
            [action.payload.mapMarker]: {
              ...action.payload.fieldValues,
            },
          },
        },
      };
    }

    case types.SET_SELECTED_TERRITORIES_FILTERS: {
      const { mapMarker, measureId, selectedValues } = action.payload;
      const values = {
        ...state.territoriesFiltersValues[state.currentIndicatorTab][mapMarker][measureId],
      };

      Object.keys(values).forEach(valueId => {
        values[valueId].selected = false;
      });

      selectedValues.forEach(valueId => {
        values[valueId].selected = true;
      });

      return {
        ...state,
        territoriesFiltersValues: {
          ...state.territoriesFiltersValues,
          [state.currentIndicatorTab]: {
            ...state.territoriesFiltersValues[state.currentIndicatorTab],
            [mapMarker]: {
              ...state.territoriesFiltersValues[state.currentIndicatorTab][mapMarker],
              [measureId]: values,
            },
          },
        },
      };
    }

    case types.SET_CHART_GROUP_MODE: {
      return {
        ...state,
        currentChartGroupMode: action.payload.mode,
      };
    }

    case types.SET_CURRENT_OBJECTS_MEASURES: {
      return {
        ...state,
        currentObjectsMeasures: action.payload.currentObjectsMeasures,
      };
    }

    case types.GET_FILTER_REGIONS_IS_FULL: {
      return {
        ...state,
        filterRegionsIsFull: {
          ...state.filterRegionsIsFull,
          [action.payload.namespace]: action.payload.isFull,
        },
      };
    }

    case types.SET_NOTIFICATION_REMOVABLE: {
      return {
        ...state,
        notificationsRemovable: {
          ids: [...state.notificationsRemovable.ids, action.payload.id],
          remove: false,
        },
      };
    }

    case types.REMOVE_NOTIFICATIONS: {
      return {
        ...state,
        notificationsRemovable: {
          ids: [],
          remove: false,
        },
      };
    }

    case types.GET_TIME_RANGE_BY_INDICATOR: {
      return {
        ...state,
        chartTimeRanges: action.payload.data,
      };
    }

    case types.CLEAR_TIME_RANGE_BY_INDICATOR: {
      return {
        ...state,
        chartTimeRanges: [],
      };
    }
    case types.GET_REPORTS_PAGE: {
      return {
        ...state,
        reportsPageTitle: action.payload.data,
      };
    }

    case types.GET_FAVORITES_SUCCESS: {
      return {
        ...state,
        favorites: {
          items: action.payload,
          error: null,
          isLoaded: true,
        },
      };
    }

    case types.GET_FAVORITES_ERROR: {
      return {
        ...state,
        favorites: {
          items: [],
          error: action.payload,
          isLoaded: false,
        },
      };
    }

    default:
      return state;
  }
};

export default mainReducer;
